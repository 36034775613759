import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {
    detailsPosizioneOrganizzativa,
    uploadDeterminaNomina
} from '../../store/posizioneOrganizzativaSlice';
import {
    CODICE_UNIVOCO_PO,
    DATA_FINE_VALIDITA_PO,
    DATA_INIZIO_VALIDITA_PO,
    DECLARATORIA_PO,
    DENOMINAZIONE_PO,
    STRUTTURA_ORGANIZZATIVA_PO,
    TIPO_PO
} from './posizioniOrganizzativeConstants';
import {STATUS_FULLFILLED} from '../../store/store-constants';
import {Button} from 'design-react-kit';
import PosizioniOrganizzativeDetailsComponent
    from '../../components/posizioni-organizzative-detail/PosizioniOrganizzativeDetailsComponent';
import {HREF_ELENCO_POSIZIONI_ORGANIZZATIVE} from '../../components/layout/sidemenu/sidemenuConstants';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import {
    PosizioneOrganizzativaUploadDeterminaDiNominaRequestDtoAllOf,
    PosizioneOrganizzativaUploadDeterminaIstituzioneAvvisoRequestDto
} from "../../services/ms-anagrafica-unica";
import {z} from "zod";
import {FormikProps, useFormik} from "formik";
import {toFormikValidationSchema} from "zod-formik-adapter";
import PageHeader from '../../components/common/page-header/PageHeader';

const UploadNomina = () => {

    const {t} = useTranslation();
    const {idCodiceIncarico} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fieldUsed: Array<string> = [CODICE_UNIVOCO_PO, DENOMINAZIONE_PO, DECLARATORIA_PO, STRUTTURA_ORGANIZZATIVA_PO, DATA_INIZIO_VALIDITA_PO, DATA_FINE_VALIDITA_PO, TIPO_PO];
    let idFile: number = -1;
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const posizioneOrganizzativa = useAppSelector((state) => state.posizioneOrganizzativa.detailsResult);
    const posizioneOrganizzativaResult = useAppSelector((state) => state.posizioneOrganizzativa.detailsStatus);
    const comunicaStatus = useAppSelector((state) => state.posizioneOrganizzativa.determinaNominaStatus);

    const initialValues: PosizioneOrganizzativaUploadDeterminaDiNominaRequestDtoAllOf = {
        idFile: -1
    }

    useEffect(() => {
        setOpenAlert(false);
        getDetailsFromApi(idCodiceIncarico!)
    }, []);

    const getDetailsFromApi = (idCodiceIncarico: string) => { dispatch(detailsPosizioneOrganizzativa({idCodiceIncarico}));
    }

    const schema = z.object({
        idFile: z.number({required_error: "Carica il documento prima di proseguire"}).refine(data => (data !== -1), {
            message: "Carica il documento prima di proseguire",
            path: ["idFile"], // path of error
        })
    })

    const formik: FormikProps<PosizioneOrganizzativaUploadDeterminaDiNominaRequestDtoAllOf> = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            let params: PosizioneOrganizzativaUploadDeterminaIstituzioneAvvisoRequestDto = {
                codiceUnivoco: idCodiceIncarico,
                requestType: 'UploadDeterminaDiNomina',
                idFile: values.idFile as number
            }
            setOpenAlert(true);
            dispatch(uploadDeterminaNomina({patchPosizioneOrganizzativaRequest: params, idCodiceIncarico: idCodiceIncarico as string}));
        }
    })

    const setUploadDeterminaNominaId = (id: number | undefined, name: string) => {
        if (id) {
            formik.setFieldValue(name, id);
        } else {
            console.error('errore durante la chiamata di caricamento documento');
        }
    }
    const getFieldError = (form: FormikProps<PosizioneOrganizzativaUploadDeterminaDiNominaRequestDtoAllOf>, fieldName: keyof PosizioneOrganizzativaUploadDeterminaDiNominaRequestDtoAllOf): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE} state={{form: true}} title={t("upload nomina")!}></PageHeader>
            {posizioneOrganizzativaResult === STATUS_FULLFILLED && <div>
                <div className="row">
                    <PosizioniOrganizzativeDetailsComponent fields={fieldUsed} value={posizioneOrganizzativa!}/>
                    <div className="col-12">
                        <DocumentUpload invalid={!!getFieldError(formik, "idFile")}
                                        infoText={t(getFieldError(formik, "idFile")) || ""}
                                        documentName='Determina di nomina'
                                        setDocumentId={(id) => setUploadDeterminaNominaId(id, 'idFile')}/>
                    </div>
                </div>
                <div className="postion-relative py-5">
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2" onClick={formik.submitForm}>Inserisci</Button>
                        <Button outline color='primary' className="mt-2 mr-2"
                                onClick={() => navigate(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE, {state:{form: true}})}>Annulla</Button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default UploadNomina