import { Col, Icon, Row } from 'design-react-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.scss'

export interface PageTopHeaderProps {
    title?: string | null;
    descrizione?: string| null
    subDescrizione?: string| null
    urlBack?: string
    showIconArrow?: boolean
    contextRight?: any
}

/**
 * Componente da usare nelle pagine con intestazione
 * @param props 
 * @returns 
 */
const PageTopHeader = (props: PageTopHeaderProps) => {

    const navigate = useNavigate();
    const { t } = useTranslation()

    const toBack = () => {
        if (props.urlBack === 'INDIETRO') {
            navigate(-1)
        }
        if (props.urlBack) {
            navigate(props.urlBack)
        }
    }

    return (

        <Row>
            <Col>
                <div className='page-top-header'>
                    <div className='d-flex align-items-center'>
                        {props.showIconArrow && <Icon className="mr-3" color="" style={{ cursor: 'pointer' }} onClick={() => toBack()} icon="it-arrow-left" size="" />}
                        <span className='titolo'>{t(props.title!)} </span>
                    </div>

                    {props.descrizione && (
                        <div className='m-3'>
                            <span className='descrizione-header'>{t(props.descrizione)}</span>
                        </div>
                    )}

                    {props.subDescrizione && (
                        <div className='m-3'>
                            <span className='sub-descrizione-header'>{t(props.subDescrizione)}</span>
                        </div>
                    )}
                </div>
            </Col>
            <Col>
                {props.contextRight}
            </Col>
        </Row>
    )
}

export default PageTopHeader