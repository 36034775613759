import { Accordion, AccordionBody, AccordionHeader, Alert, Button, Col, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Table, UncontrolledDropdown } from 'design-react-kit'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { STATUS_FULLFILLED } from '../../store/store-constants'
import { formSrachPopulate, resetAll, searchElencoCandidati } from '../../store/candidatoSlice'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../components/common/pagination/Pagination'
import { HREF_COM_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO, HREF_GEST_ACCETTAZIONE_CONTRATTO, HREF_GEST_CESSAZIONE_CONTRATTO, HREF_INSERIMENTO_CANDIDATO, HREF_MODIFICA_CANDIDATO, HREF_VERIFICA_DATI_DOCUMENTI_CANDIDATO, HREF_VISUALIZZA_CANDIDATO } from '../../components/layout/sidemenu/sidemenuConstants'
import PageHeader from '../../components/common/page-header/PageHeader'
import { ElencoNuoveAssunzioniDtoStatoCandidatoEnum, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica'
import TableTheadCustom from 'components/common/custom-table/table-thead-custom'



const initialValues = {
    nome: '',
    cognome: '',
    codiceFiscale: '',
    stato: '',
    pageNum: 0,
    pageSize: 10,
    sort: "",
    direction: "",
}

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}


const ElencoCandidati = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const { t } = useTranslation()
    const [formCollapseOpenElencoCandidati, setFormCollapseOpenElencoCandidati] = useState(false);
    const { searchResult, searchStatus, formSearchResult, searchInput } = useAppSelector((state) => state.candidato)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    const schema = z.object({
        cognome: z.string().max(200, { message: 'lengthcognomecandidato' }).optional(),
        nome: z.string().max(500, { message: 'lengthnomecandidato' }).optional(),
        codiceFiscale: z.string().max(16, { message: 'lengthcodicefiscale' }).optional(),
        stato: z.string().optional()
    })

    const searchFormElencoCandidati = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    useEffect(() => {
        if ((searchInput?.codiceFiscale == '' && searchInput.cognome == '' && searchInput.nome == '' && searchInput.stato == '') || searchInput == null) {
            doSearch()
        }
        dispatch(resetAll())
        dispatch(formSrachPopulate());
    }, [])

    let hasResults =
        searchResult && searchResult.totalElements
            ? searchResult.totalElements > 0
            : false;

    let statoOptions: CustomSelectItem[] = [];
    formSearchResult?.stati?.forEach((s) => {
        statoOptions.push({ label: s.label, value: s.valore });
    });

    const doSearch = () => {
        dispatch(searchElencoCandidati(searchFormElencoCandidati.values));
    }

    const searchStartKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            searchFormElencoCandidati.setFieldValue("pageNum", 0);
            searchFormElencoCandidati.handleSubmit();
        }
    }

    const handleChange = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        searchFormElencoCandidati.setFieldValue(name, selectedOption);
    };

    const handlePageChange = (pageNum: number) => {
        console.log("Handle page change", pageNum - 1);
        searchFormElencoCandidati.setFieldValue("pageNum", pageNum - 1);
        searchFormElencoCandidati.handleSubmit();
    };

    useEffect(() => {
        searchFormElencoCandidati.setValues({
            codiceFiscale: searchInput?.codiceFiscale || '',
            nome: searchInput?.nome || '',
            stato: searchInput?.stato || '',
            cognome: searchInput?.cognome || '',
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            sort: searchInput?.sort || '',
            direction: searchInput?.direction || '',
        })

        if (searchInput != undefined && (searchInput.codiceFiscale != '' || searchInput.cognome != '' || searchInput.stato != '' || searchInput.nome != '')) {
            setFormCollapseOpenElencoCandidati(true);
            dispatch(searchElencoCandidati(searchInput))
        }
    }, [searchInput])

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchFormElencoCandidati.setFieldValue("sort", columnName);
        searchFormElencoCandidati.setFieldValue("direction", sorting.direction);
        searchFormElencoCandidati.handleSubmit();
    };

    const nuovoCandidato = () => {
        navigate(HREF_INSERIMENTO_CANDIDATO);
    }

    const showModificaOperation = (stato: string) => 
        props.operations.elencoFunzionalita?.includes('ASSUNZIONI_DETTAGLIO')
        && props.operations.elencoFunzionalita?.includes('ASSUNZIONI_MODIFICA')
        && stato === ElencoNuoveAssunzioniDtoStatoCandidatoEnum.Inc

    const showVerificaDatiDocumentiOperation = (stato: string) => 
        props.operations.elencoFunzionalita?.includes('ASSUNZIONI_DETTAGLIO')
        && props.operations.elencoFunzionalita?.includes('ASSUNZIONI_RETTIFICA')
        && stato === ElencoNuoveAssunzioniDtoStatoCandidatoEnum.Reg

    const showComunicaConvocazioneSottOperation = (stato: string) => 
        props.operations.elencoFunzionalita?.includes('ASSUNZIONI_DETTAGLIO')
        && props.operations.elencoFunzionalita?.includes('ASSUNZIONI_ELENCO_CONVOCAZIONI')
        && props.operations.elencoFunzionalita?.includes('ASSUNZIONI_COMUNICA_CONVOCAZIONE')
        && stato === ElencoNuoveAssunzioniDtoStatoCandidatoEnum.Val
    
    const showInserisciCandidatoOperation = () => 
        props.operations.elencoFunzionalita?.includes('ASSUNZIONI_INSERT')

    const showAccettazioneContrattoOperation = (stato: string) =>
        props.operations.elencoFunzionalita?.includes('ASSUNZIONI_INSERT')
        && stato === ElencoNuoveAssunzioniDtoStatoCandidatoEnum.Acc

    const showCessazioneContrattoOperation = (stato: string) =>
        props.operations.elencoFunzionalita?.includes('ASSUNZIONI_CONVOCAZIONE_CESSAZIONE')
        && stato === ElencoNuoveAssunzioniDtoStatoCandidatoEnum.Rif

    const showVisualizzaOperation = () =>
        props.operations.elencoFunzionalita?.includes('ASSUNZIONI_DETTAGLIO')

    return (
        <div>
            <PageHeader showIcon={false} title={t("elenco candidati")!}
                buttons={[
                    {
                        buttonProperties: {outline: true},
                        title: 'Nuovo candidato',
                        buttonColor: 'primary',
                        action: nuovoCandidato,
                        showButtonIcon: true,
                        icon: 'it-plus',
                        iconColor: 'primary',
                        disabled: !showInserisciCandidatoOperation()
                    },
                    {
                        buttonProperties: { outline: true },
                        title: 'Ricerca',
                        buttonColor: 'primary',
                        showButtonIcon: true,
                        icon: 'it-search',
                        iconColor: 'primary',
                        action: () =>{formCollapseOpenElencoCandidati ? setFormCollapseOpenElencoCandidati(false) : setFormCollapseOpenElencoCandidati(true)},
                    }
                ]} 
                />
            <Accordion className="form-custom form-bg filtri-ricerca" background="active">
                <AccordionHeader
                    active={formCollapseOpenElencoCandidati}
                    onToggle={() => setFormCollapseOpenElencoCandidati(!formCollapseOpenElencoCandidati)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpenElencoCandidati}>
                    <div className="form-row mt-2">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchFormElencoCandidati.values.pageNum}
                            onChange={searchFormElencoCandidati.handleChange}
                        />
                        <Input
                            type="text"
                            id="input-nome"
                            placeholder="Inserisci un nome"
                            wrapperClassName="col col-md-6"
                            name="nome"
                            invalid={!!searchFormElencoCandidati.errors.nome}
                            infoText={t(searchFormElencoCandidati.errors.nome || "") || ""}
                            value={searchFormElencoCandidati.values.nome}
                            onChange={searchFormElencoCandidati.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            id="input-cognome"
                            placeholder="Inserisci un cognome"
                            wrapperClassName="col col-md-6"
                            name="cognome"
                            invalid={!!searchFormElencoCandidati.errors.cognome}
                            infoText={t(searchFormElencoCandidati.errors.cognome || "") || ""}
                            value={searchFormElencoCandidati.values.cognome}
                            onChange={searchFormElencoCandidati.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            id="input-codiceFiscale"
                            placeholder="Inserisci un codice fiscale"
                            wrapperClassName="col col-md-6"
                            name="codiceFiscale"
                            invalid={!!searchFormElencoCandidati.errors.codiceFiscale}
                            infoText={t(searchFormElencoCandidati.errors.codiceFiscale || "") || ""}
                            value={searchFormElencoCandidati.values.codiceFiscale}
                            onChange={searchFormElencoCandidati.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Col md="6">
                            <CustomSelect
                                options={statoOptions}
                                value={searchFormElencoCandidati.values.stato}
                                placeholder="Seleziona stato"
                                onChange={(e) => handleChange("stato", e)}
                                invalid={!!searchFormElencoCandidati.errors.stato}
                                infoText={t(searchFormElencoCandidati.errors.stato || "") || ""}
                                name="stato"
                            />
                        </Col>
                    </div>

                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchFormElencoCandidati.setFieldValue("pageNum", 0);
                                searchFormElencoCandidati.handleSubmit();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchFormElencoCandidati.handleReset}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>

            <div className="mt-4 position-relative">
                <div>
                    {searchStatus === STATUS_FULLFILLED && searchResult?.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
                    {searchStatus === STATUS_FULLFILLED && hasResults && (
                        <div className={searchResult.totalPages === 1 ? 'mb-5' : ''}>
                            <Table responsive striped>
                                <TableTheadCustom 
                                    colonne={[
                                        {titolo: 'Codice Fiscale', nomeColonna: 'codiceFiscale'},
                                        {titolo: 'Cognome', nomeColonna: 'cognome'},
                                        {titolo: 'Nome', nomeColonna: 'nome'},
                                        {titolo: 'Stato', nomeColonna: 'stato'},
                                        {titolo: 'Azioni'}
                                    ]} 
                                    datiOrdinamento={searchFormElencoCandidati.values}
                                    eseguiOrdinamento={ordinamento}/>

                                <tbody>
                                    {(searchResult.data || []).map((x, i) => (
                                        <tr key={x.codiceUnivoco}>
                                            <td>{x.anagraficaUnica?.codiceFiscale}</td>
                                            <td>{x.anagraficaUnica?.cognome}</td>
                                            <td>{x.anagraficaUnica?.nome}</td>
                                            <td>{x.statoCandidato != null && statoOptions.map((item) => item.value === x.statoCandidato ? item.label : '' )}</td>
                                            <td>
                                                <UncontrolledDropdown direction="left">
                                                    <DropdownToggle nav>
                                                        <Icon icon="it-settings" color="primary" size="sm" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="no-arrow">
                                                        <LinkList>
                                                            {showVisualizzaOperation() && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_VISUALIZZA_CANDIDATO}/${x.codiceUnivoco}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Visualizza</span>
                                                            </LinkListItem>}
                                                            {showModificaOperation(x.statoCandidato!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_MODIFICA_CANDIDATO}/${x.codiceUnivoco}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Modifica</span>
                                                            </LinkListItem>}
                                                            {showVerificaDatiDocumentiOperation(x.statoCandidato!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_VERIFICA_DATI_DOCUMENTI_CANDIDATO}/${x.codiceUnivoco}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Verifica dati</span>
                                                            </LinkListItem>}
                                                            {showComunicaConvocazioneSottOperation(x.statoCandidato!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_COM_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO}/${x.codiceUnivoco}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Sott. contratto</span>
                                                            </LinkListItem>}
                                                            {showAccettazioneContrattoOperation(x.statoCandidato!) &&
                                                            <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_GEST_ACCETTAZIONE_CONTRATTO}/${x.codiceUnivoco}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Accett. contratto</span>
                                                            </LinkListItem>}
                                                            {showCessazioneContrattoOperation(x.statoCandidato!) &&<LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_GEST_CESSAZIONE_CONTRATTO}/${x.codiceUnivoco}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Cess. contratto</span>
                                                            </LinkListItem>}
                                                        </LinkList>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Pagination
                                totalCount={searchResult.totalElements || 0}
                                siblingCount={1}
                                currentPage={searchFormElencoCandidati.values.pageNum + 1}
                                pageSize={searchResult.pageSize || 10}
                                onPageChange={(page: number) => {
                                    handlePageChange(page);
                                }}
                                className="justify-content-center"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ElencoCandidati