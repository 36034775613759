import React from 'react'
import svgError from '../../assets/images/wired-outline-1140-error1.gif'
import { Button } from 'design-react-kit'
import { useNavigate } from 'react-router-dom'
import { HREF_LOGIN, HREF_PRIMO_ACCESSO_CITTADINO, HREF_SCRIVANIA_VIRTUALE } from '../../components/layout/sidemenu/sidemenuConstants'
import { useAppSelector } from '../../hooks'
const PageNotFound = () => {
    const navigate = useNavigate();
    const { userInfoResult } = useAppSelector(
        (state) => state.user
    );

    const changePage = () => {
        if (!userInfoResult) {
            navigate(HREF_LOGIN)
        } else if (userInfoResult && userInfoResult.cittadinoEsterno) {
            navigate(HREF_PRIMO_ACCESSO_CITTADINO)
        } else {
            navigate(HREF_SCRIVANIA_VIRTUALE)
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '75vh' }}>
            <img src={svgError} alt="Errore 404" style={{ width: '200px' }} />
            <p style={{ color: 'gray', fontSize: '2rem' }}>
                Errore risorsa non trovata...
            </p>
            <Button onClick={changePage} color='primary' >Ritorna alla Home</Button>
        </div>
    )
}

export default PageNotFound