import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks'
import CustomAlert from '../common/custom-alert/CustomAlert'
import { useNavigate } from 'react-router-dom'
import { UtenteAutenticatoDtoCategoriaEnum } from '../../services/ms-anagrafica-unica/model/utente-autenticato-dto'
import { STATUS_FULLFILLED } from '../../store/store-constants'

const ProtectedRoute = (props: { children: React.ReactElement, perCittadino?: boolean, perCessato?: boolean, perCandidato?: boolean, toMatch?:Array<string>, mandatory?:boolean, controlloPartecipazioneBandiARF?: boolean}): JSX.Element => {

  const { userRoleActive,userInfoResult, userInfoStatus } = useAppSelector((state) => state.user)

  const [hasRequiredFeatures, setHasRequiredFeatures] = useState<boolean>(false)
  const navigate = useNavigate();

  const goToHome = () => {
    return navigate('/')
  }

  useEffect( () => {
      if (process.env.REACT_APP_MS_ACTIVATE_AUTH === 'true') {

        const userHasFuncs = !!userRoleActive && !!userRoleActive.elencoFunzionalita;
        
        if ((!!props.perCandidato || !!props.perCittadino || !!props.perCessato) && !userHasFuncs) {
          setHasRequiredFeatures(true);
          return; 
        }

        if (userHasFuncs) {
          const funzionalitaRichieste = props.toMatch && props.toMatch.length;

          if( funzionalitaRichieste === undefined) {
            return;
          }

          if (funzionalitaRichieste! > 0 && !props.mandatory) {
            //controllare se l'utente ha i permessi richiesti dalla funzionalità
            userRoleActive.elencoFunzionalita?.forEach( op => {
              // per ogni permesso dell'utente controllo se quel permesso è nelle requiredFeature definite per la rotta
              let inFunzionalitaUtente = props.toMatch?.includes(op);
              
              // se è inclusa, aggiungo un elemento "vero" nell'array contatore
              if (inFunzionalitaUtente) {
                setHasRequiredFeatures(true);
              }
            });
          }

          if (funzionalitaRichieste! > 0 && props.mandatory) {
            let funzionalitaVerificate = 0;
            //controllare se l'utente ha i permessi richiesti dalla funzionalità
            userRoleActive.elencoFunzionalita?.forEach( op => {
              // per ogni permesso dell'utente controllo se quel permesso è nelle requiredFeature definite per la rotta
              let inFunzionalitaUtente = props.toMatch?.includes(op);
              
              // se è inclusa, aggiungo un elemento "vero" nell'array contatore
              if (inFunzionalitaUtente) {
                funzionalitaVerificate += 1;
              }
            });
            
            if(funzionalitaRichieste === funzionalitaVerificate) {
              setHasRequiredFeatures(true);
            }
          }

          if (funzionalitaRichieste! === 0) {
            setHasRequiredFeatures(true);
          }

          if(props.controlloPartecipazioneBandiARF && userInfoResult?.categoria === "ARF" && !userInfoResult?.partecipazioneBandi)
            setHasRequiredFeatures(false);

        }
      } else {
        //autenticazione disabilitata. l'utente ha tutti i permessi
        setHasRequiredFeatures(true);
      }
  }, [props,userRoleActive])

  if ((userRoleActive && hasRequiredFeatures) || userInfoResult?.cittadinoEsterno || userInfoResult?.cittadino || userInfoResult?.dipendenteCessato || userInfoResult?.candidato) {
    return props.children;
  } else {
    return userInfoStatus === STATUS_FULLFILLED ? (
        <CustomAlert color="danger" message='Non hai il permesso per visualizzare questa pagina' showButton={true} btnText='Torna alla Home' callback={() => goToHome()} /> 
     ) : <></>
  }
}

export default ProtectedRoute
