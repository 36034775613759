import { PosizioneOrganizzativaDto } from '../../services/ms-anagrafica-unica'
import {
    ATTO_DI_ASSGENAZIONE,
    CODICE_CID_DIPENDENTE,
    CODICE_UNIVOCO_PO,
    DATA_FINE_VALIDITA_INCARICO,
    DATA_FINE_VALIDITA_PO,
    DATA_INIZIO_VALIDITA_INCARICO,
    DATA_INIZIO_VALIDITA_PO,
    DECLARATORIA_PO,
    DENOMINAZIONE_PO,
    NOME_E_COGNOME_DIPENDENTE,
    STRUTTURA_ORGANIZZATIVA_PO,
    TIPO_INCARICO,
    TIPO_PO
} from '../../pages/posizioni-organizzative/posizioniOrganizzativeConstants'
import { useTranslation } from "react-i18next";
import { formatDate } from 'utility/formatDate';

export interface PODetailsProps {
    value: PosizioneOrganizzativaDto,
    fields: Array<string>,
}

const PosizioniOrganizzativeDetailsComponent = (props: PODetailsProps) => {
    
    const {t} = useTranslation();

    return (
        <div className="dettaglio-po row">
            <div className="col-sm-12">
                <div className="info-box-light-po bg-light-gray">
                    <h5 className='pb-2 border-bottom font-weight-bold'>
                        Riepilogo
                    </h5>
                    <dl className="border-bottom py-2">
                        <div className="row">
                            <div className="col-12 col-xl-4">
                                <dt>{CODICE_UNIVOCO_PO}</dt>
                                <dd>{
                                    (props.fields.indexOf(CODICE_UNIVOCO_PO) !== -1 && !!props?.value?.codiceUnivoco) 
                                        ? props?.value?.codiceUnivoco : '-'}
                                </dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{DENOMINAZIONE_PO}</dt>
                                <dd>{ 
                                    (props.fields.indexOf(DENOMINAZIONE_PO) !== -1 && !!props?.value?.denominazione) 
                                        ? props?.value?.denominazione : '-'}
                                </dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{DECLARATORIA_PO}</dt>
                                <dd>{ (props.fields.indexOf(DECLARATORIA_PO) !== -1 && !!props?.value?.declaratoria) 
                                    ? props?.value?.declaratoria : '-'}</dd>
                            </div>
                        </div>
                    </dl>
                    
                    <dl className="col-sm-12 border-bottom py-2">
                        <div className="row">
                            <div className="col-12 col-xl-4">
                                <dt>{STRUTTURA_ORGANIZZATIVA_PO}</dt>
                                <dd>{ (props.fields.indexOf(STRUTTURA_ORGANIZZATIVA_PO) !== -1 && !!props?.value?.strutturaOrganizzativa) 
                                ? props?.value?.strutturaOrganizzativa : '-'}</dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{DATA_INIZIO_VALIDITA_PO}</dt>
                                <dd>{formatDate(props?.value?.dataInizio!, false, '-')}</dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{DATA_FINE_VALIDITA_PO}</dt>
                                <dd>{formatDate(props?.value?.dataFine!, false, '-')}</dd>
                            </div>
                        </div>
                    </dl>
                    
                    <dl className="col-sm-12 border-bottom py-2">
                        <div className="row">
                            <div className="col-sm-12">
                                <dt>{TIPO_PO}</dt>
                                <dd>{(props.fields.indexOf(TIPO_PO) !== -1 && !!props?.value?.tipo) 
                                    ? t( "tipoEQ_" + props?.value?.tipo) : '-'  }</dd>
                            </div>
                        </div>
                    </dl>
                    
                    <dl className="col-sm-12 border-bottom py-2">
                        <div className="row">
                            <div className="col-12 col-xl-4">
                                <dt>{CODICE_CID_DIPENDENTE}</dt>
                                <dd>{(props.fields.indexOf(CODICE_CID_DIPENDENTE) !== -1 && !!props?.value?.idCidDipendente) 
                                    ? props?.value?.idCidDipendente : '-'}</dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{NOME_E_COGNOME_DIPENDENTE}</dt>
                                <dd>{(props.fields.indexOf(NOME_E_COGNOME_DIPENDENTE) !== -1 && !!props?.value?.dipendenteRegionale) 
                                    ? props?.value?.dipendenteRegionale : '-'}</dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{TIPO_INCARICO}</dt>
                                <dd>{(props.fields.indexOf(TIPO_INCARICO) !== -1 && !!props?.value?.tipoIncarico) 
                                    ? props?.value?.tipoIncarico : '-'}</dd>
                            </div>
                        </div>
                    </dl>
                    <dl className="col-sm-12 py-2">
                        <div className="row">
                            <div className="col-12 col-xl-4">
                                <dt>{ATTO_DI_ASSGENAZIONE}</dt>
                                <dd>{(props.fields.indexOf(ATTO_DI_ASSGENAZIONE) !== -1 && !!props?.value?.estremiAttoConferimento) 
                                    ? props?.value?.estremiAttoConferimento : '-'}</dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{DATA_INIZIO_VALIDITA_INCARICO}</dt>
                                <dd>{(props.fields.indexOf(DATA_INIZIO_VALIDITA_INCARICO) !== -1 && !!props?.value?.dataInizioAssociazione) 
                                    ? formatDate(props?.value?.dataInizioAssociazione!, false, '-') : '-'}</dd>
                            </div>
                            <div className="col-12 col-xl-4">
                                <dt>{DATA_FINE_VALIDITA_INCARICO}</dt>
                                <dd>{formatDate(props?.value?.dataFineAssociazione!, false, '-')}</dd>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <dt>Declaratoria</dt>
                                <dd>{!!props?.value?.declaratoriaFunzioni ? props?.value?.declaratoriaFunzioni : '-'}</dd>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </div> 
    )
}

export default PosizioniOrganizzativeDetailsComponent