import { forwardRef, Ref, useEffect, useState } from 'react'
import CustomSelect, { CustomSelectItem } from '../../common/custom-select/CustomSelect';
import { Alert, Button, Callout, CalloutTitle, FormGroup, Icon, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'design-react-kit'
import { t } from 'i18next'
import { AttivitaIncarichiResponsabilitaRequestDto, CurriculumVitaeRequestDtoSezioneEnum, EsperienzeProfessionaliInRegioneDto, EsperienzeProfessionaliInRegioneRequestDto, EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum } from '../../../services/ms-anagrafica-unica';
import { creaNuovoCv, getAmbitoRuolo, getCvDetail, getGestioneCvOpts, getProfiliProfessionali } from '../../../store/curriculumVitaeSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import { formatDate } from '../../../utility/formatDate';
import TextareaInfoChars from '../../common/textarea-infochars/TextareaInfoChars';
import { useImperativeHandle } from 'react';
import { GestioneCvPageRef } from '../GestioneCvForm';

export interface EspProfessionaliInRegioneProps {
    disabled: boolean,
    continuaAction: Function,
    scrollAction: Function,
    disabilitaContinuaBtn: (disabilita: boolean) => void
}

const EspProfessionaliInRegioneTab = forwardRef((props: EspProfessionaliInRegioneProps, ref: Ref<GestioneCvPageRef>) => {

    const dispatch = useAppDispatch();
    const { detailStatus, detailResult, insertStatus} = useAppSelector((state) => state.curriculumVitae);
    const { userInfoResult } = useAppSelector((state) => state.user)

    const [areaInquadramentoOptions, setAreaInquadramentoOptions] = useState<CustomSelectItem[]>([]);
    const [profiloProfessionaleOptions, setProfiloProfessionaleOptions] = useState<CustomSelectItem[]>([]);
    const [ambitoDiRuoloOptions, setAmbitoDiRuoloOptions] = useState<CustomSelectItem[]>([]);
    const [, setTipologiaDipendenteOptions] = useState<CustomSelectItem[]>([]);

    const [elencoEsperienzeProfessionaliInRegione, setElencoEsperienzeProfessionaliInRegione] = useState<EsperienzeProfessionaliInRegioneRequestDto[]>([]);
    const [selectedElement, setSelectedElement] = useState<number|undefined>(undefined);
    const [saveOnClick, setSaveOnClick] = useState<boolean>(false);
    const [showFormInserimento, setShowFormInserimento] = useState(false);
    const [canAggiungiEsperienza, setCanAggiungiEsperienza] = useState(false);
    const [inModifica, setInModifica] = useState(false);
    const [disableAmbitoRuolo,setDisableAmbitoRuolo] = useState(true);
    const [dataMin, setDataMin] = useState<string>(new Date(1900, 0).toISOString().slice(0, 10));
    const [dataMax, setDataMax] = useState<string>(new Date().toISOString().slice(0, 10));
    const [isOpenModalDelete, toggleModalDelete] = useState(false);
    const [attivitaDaRimuovere, setAttivitaDaRimuovere] = useState<string | number | undefined>(undefined);
    const [showCheckInCorso, setShowCheckInCorso] = useState(true);

    var initialValues: EsperienzeProfessionaliInRegioneRequestDto = {
        da: '',
        al: '',
        inCorso: false,
        tipologiaDipendente: undefined,
        idAreaInquadramento: undefined,
        idProfiloProfessionale: undefined,
        idAmbitoDiRuolo: undefined,
        elencoAttivita: []
    };

    const schema = z.object({
        da: z.coerce.date({ required_error: 'Il campo DA è obbligatorio' }).min(new Date(1900, 0), "La data non può essere inferiore al 01-01-1900").refine((data) => (data < new Date()), { message: 'DA non può essere maggiore della data odierna' }),
        al: z.coerce.date({ required_error: 'Il campo AL è obbligatorio' }).refine((data) => (data < new Date()), { message: 'AL non può essere maggiore della data odierna' }).optional(),
        tipologiaDipendente: z.string({ required_error: "Selezionare una tipologia dipendente" }),
        idAreaInquadramento: z.number().optional(),
        idProfiloProfessionale: z.number().optional(),
        idAmbitoDiRuolo: z.number().optional(),
        inCorso: z.boolean(),
        elencoAttivita: z.array(z.object({
            descrizione: z.string({required_error: 'Il campo è obbligatorio'}).max(300),
            dal: z.coerce.date({ required_error: 'Il campo DA è obbligatorio' }).min(new Date(1900, 0), "La data non può essere inferiore al 01-01-1900").refine((data) => (data < new Date()), { message: 'DA non può essere maggiore della data odierna' }).refine((data) => (data >= new Date(form.values.da!)), { message: 'il campo DA non può essere minore della data di inizio' }),
            a: z.coerce.date({ required_error: 'Il campo A è obbligatorio' }).min(new Date(1900, 0), "La data non può essere inferiore al 01-01-1900").refine((data) => (data < new Date()), { message: 'AL non può essere maggiore della data odierna' }).refine((data) => (form.values.al === undefined ? data <= new Date() : data <= new Date(form.values.al!)), { message: 'Il campo A non può essere maggiore della data fine esperienza' }).optional(),
        })),
    }).superRefine((data, context) => {
        if (data.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Comparto && data.idAreaInquadramento == undefined) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Il campo è obbligatorio",
                path: ["idAreaInquadramento"]
            });
        }
    }).superRefine((data, context) => {
        if (data.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente && controlloNumeroEsperienzeDirigentiSuperato()) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "E' già stata inserita una esperienza di tipo dirigente",
                path: ["tipologiaDipendente"]
            });
        }
    }).superRefine((data, context) => {
        if (data.tipologiaDipendente !== undefined && data.idAreaInquadramento !== undefined && controlloNumeroEsperienzeCompartoSuperato(data.idAreaInquadramento)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "E' già stata inserita una esperienza di tipo comparto in questa area",
                path: ["tipologiaDipendente"]
            });
        }
    }).superRefine((data, context) => {
        if (!data.inCorso && !data.al) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Il campo è obbligatorio",
                path: ["al"]
            });
        }
    })/* .superRefine((data, context) => {
        if (!hasInCorso && !data.al) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Il campo è obbligatorio",
                path: ["al"]
            });
        }
    }) */.superRefine((data, context) => {
        if (data.da && data.al && data.al < data.da) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "La data non può essere minore della data DA",
                path: ["al"]
            });
        }
        
    }).superRefine((data, context) => {
        if (data.elencoAttivita.length > 0 ) {
            data.elencoAttivita.forEach( (att, i) => {
                if (((!!att.a && (att.a < data.da || att.a < att.dal))) || (att.dal  > data.al!)) {
                    context.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "L'attività deve essere in un periodo compreso nell'esperienza professionale riportata",
                        path: ['elencoAttivita', i, "dal"]
                    });
                }
            })
        }
    });

    const form: FormikProps<EsperienzeProfessionaliInRegioneRequestDto> = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        validateOnChange: false,
        onSubmit: values => {
            aggiungiEsperienza(values);
        },
        enableReinitialize: true,
    });

    useImperativeHandle(ref, () => ({
        submitForm() {
            props.continuaAction()
         }
    }) , []);

    useEffect(() => {
        if (elencoEsperienzeProfessionaliInRegione && elencoEsperienzeProfessionaliInRegione.length > 0) {
            setShowFormInserimento(false)
            setCanAggiungiEsperienza(true)
            props.disabilitaContinuaBtn(false)
        } else {
            setShowFormInserimento(true)
            props.disabilitaContinuaBtn(true)
        }
    }, [elencoEsperienzeProfessionaliInRegione])

    useEffect(() => {
        setSelectedElement(undefined);
        dispatch(getGestioneCvOpts()).unwrap().then((resp) => {
            if (resp) {
                var areaInquadramentoOpt: Array<CustomSelectItem> = [];
                resp.areaInquadramento &&
                    resp.areaInquadramento.forEach((f: any) => {
                        areaInquadramentoOpt.push({
                            value: f.valore,
                            label: f.label,
                        });
                    });
                setAreaInquadramentoOptions(areaInquadramentoOpt);

                var tipologiaDipendenteOpt: Array<CustomSelectItem> = [];
                resp.tipologiaDipendente &&
                    resp.tipologiaDipendente.forEach((f: any) => {
                        tipologiaDipendenteOpt.push({
                            value: f.valore,
                            label: f.label,
                        });
                    });

                setTipologiaDipendenteOptions(tipologiaDipendenteOpt);
            }
            dispatch(getCvDetail(userInfoResult?.entityId!))
        })
    }, [])

    useEffect( () => {
        !!detailResult && detailResult.elencoEsperienzeProfessionaliInRegione && detailResult.elencoEsperienzeProfessionaliInRegione.length > 0 && 
        fillElencoEsperienzeProfessionaliState(detailResult.elencoEsperienzeProfessionaliInRegione)
    }, [detailResult])

    useEffect(() => {
        if (elencoEsperienzeProfessionaliInRegione.length > 0 && saveOnClick === true) {
            dispatch(creaNuovoCv({
                elencoEsperienzeProfessionaliInRegione: elencoEsperienzeProfessionaliInRegione,
                sezione: CurriculumVitaeRequestDtoSezioneEnum.EsperienzeProfessionaliInRegione
            }))
            .unwrap()
            .then((resp) => {
                resp != null && dispatch(getCvDetail(userInfoResult?.entityId!))
            })
        }
        setSaveOnClick(false);
    }, [elencoEsperienzeProfessionaliInRegione])


    const fillElencoEsperienzeProfessionaliState = (elencoExp:EsperienzeProfessionaliInRegioneDto[]) => {
        setElencoEsperienzeProfessionaliInRegione(elencoExp.map((value, i) => {

            let valueToReturn: EsperienzeProfessionaliInRegioneRequestDto;
            valueToReturn = {
                da: value.da,
                al: value.al === null || value.al === undefined? '' : value.al,
                inCorso: !value.al,
                tipologiaDipendente: value.tipologiaDipendente,
                idAreaInquadramento: value.areaInquadramento?.id || undefined,
                areaInquadramentoLabel: value.areaInquadramento?.titoloArea || undefined,
                idProfiloProfessionale: value.profiloProfessionale?.id || undefined,
                idAmbitoDiRuolo: value.ambitoDiRuolo?.id || undefined,
                elencoAttivita: value.elencoAttivita?.map((a:AttivitaIncarichiResponsabilitaRequestDto) => ({
                    a: a.a === null || a.a === undefined? '' : a.a,
                    dal: a.dal === null || a.dal === undefined? '' : a.dal,
                    descrizione: a.descrizione
                } ))
            }
            value.areaInquadramento?.id && getProfiloProfessionaleOptions(value.areaInquadramento.id);
            value.profiloProfessionale?.id && getAmbitoRuoloOptions(value.profiloProfessionale.id.toString());

            return valueToReturn;
        }));

    }

    const handleSelectChange = (name: string, e: string | number, validate?:boolean) => {
        form.setFieldValue(name, e, validate);
    };

    const getFieldError = (form: FormikProps<EsperienzeProfessionaliInRegioneRequestDto>, fieldName: keyof EsperienzeProfessionaliInRegioneRequestDto): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    let aggiungiEsperienza = (values: EsperienzeProfessionaliInRegioneRequestDto) => {
        form.validateForm();

        setInModifica(false);
        
        if (form.isValid) {
            if (selectedElement!== undefined) {
                const newVal = {
                    ...values,
                    areaInquadramentoLabel: areaInquadramentoOptions.filter(i => i.value === values.idAreaInquadramento)[0]?.label 
                }
                let updatedData = newVal;
                var array = [...elencoEsperienzeProfessionaliInRegione];
                array[selectedElement] = updatedData;
                setElencoEsperienzeProfessionaliInRegione(array);
                setSelectedElement(undefined);
            } else {
                let dataDetail = [...elencoEsperienzeProfessionaliInRegione, values]
                setElencoEsperienzeProfessionaliInRegione(dataDetail);
            }
        }
        setSaveOnClick(true);
        form.resetForm({ values: initialValues });
    }

    const removeItem = (index: number) => {
        setElencoEsperienzeProfessionaliInRegione([
            ...elencoEsperienzeProfessionaliInRegione.slice(0, index),
            ...elencoEsperienzeProfessionaliInRegione.slice(index + 1)
        ]);
        setSaveOnClick(true);
        form.resetForm({ values: initialValues });

    }

    const modificaEsperienza = (index: number) => {
        var array = [...elencoEsperienzeProfessionaliInRegione];
        var esp = array[index];

        setInModifica(true)

        if (esp !== null && esp !== undefined) {
            if(!esp.inCorso && array.some((item, i) => i !== index && item.inCorso))
                setShowCheckInCorso(false)
            else 
                setShowCheckInCorso(true)

            setSelectedElement(index);
            form.resetForm({ values: initialValues });
            form.setValues(esp);

            esp.idAreaInquadramento && getProfiloProfessionaleOptions(esp.idAreaInquadramento, false);
            esp.idProfiloProfessionale && getAmbitoRuoloOptions(esp.idProfiloProfessionale.toString());
            esp.idAmbitoDiRuolo && handleSelectChange('idAmbitoDiRuolo', esp.idAmbitoDiRuolo)

            changeDataDa(esp.da!)
            changeDataAl(esp.al!)
            props.disabilitaContinuaBtn(true)
            setCanAggiungiEsperienza(false)
            setShowFormInserimento(true)
        }
    }

    const changeInCorso = () => {
        const inCorso = !form.values.inCorso;
        form.setFieldValue('inCorso', inCorso, true)
        inCorso && form.setFieldValue('al', new Date(), true)
    }

    const changeTipologiaDipendenteDirigente = () => {
        form.setFieldValue('tipologiaDipendente', EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente, true)
        form.setFieldValue('idAmbitoDiRuolo', undefined)
        form.setFieldValue('idProfiloProfessionale', undefined)
        form.setFieldValue('idAreaInquadramento', undefined)
    }

    const changeTipologiaDipendenteComparto = () => {
        form.setFieldValue('tipologiaDipendente', EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Comparto, true)
    }

    const isDirigente = () => {
        return form.values.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente;
    }

    const controlloNumeroEsperienzeDirigentiSuperato = (): boolean => {
        const esperienzeDirigenti = elencoEsperienzeProfessionaliInRegione
                .filter(x=> x.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente).length;

        if(esperienzeDirigenti === 0) {
            return false;
        }

        if(inModifica) {
            return selectedElement !== undefined && elencoEsperienzeProfessionaliInRegione[selectedElement].tipologiaDipendente !== form.values.tipologiaDipendente;
        }else{
            return esperienzeDirigenti > 0
        }    
    }

    const controlloNumeroEsperienzeCompartoSuperato = (idArea: number): boolean => {
        const esperienzeCompartoPerArea = elencoEsperienzeProfessionaliInRegione
                .filter(x=> x.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Comparto && x.idAreaInquadramento === idArea).length;

        if(esperienzeCompartoPerArea === 0) {
            return false;
        }

        if(inModifica) {
            return selectedElement !== undefined && elencoEsperienzeProfessionaliInRegione[selectedElement].idAreaInquadramento !== idArea;
        }else{
            return esperienzeCompartoPerArea > 0
        }    
    }

    const getFieldArrayError = (errors: any, arrayName: string, fieldName: keyof AttivitaIncarichiResponsabilitaRequestDto,index:number): string => {
        return ( Object.keys(errors).length > 0 && errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][fieldName]) || ''
    };

    const aggiungiAttivita = () => {
        const attivita: Array<AttivitaIncarichiResponsabilitaRequestDto> = form.values.elencoAttivita || [];
        const nuovaAttivita = [
            ...attivita,
            {
                da: '',
                al: '',
                descrizione: ''
            }
        ]
        form.setFieldValue('elencoAttivita', nuovaAttivita);
    }

    const rimuoviAttivita = (i:number) => {
        const attivita: Array<AttivitaIncarichiResponsabilitaRequestDto> = form.values.elencoAttivita || [];
        const nuovaAttivita = attivita.filter( (attivita,index) => i!==index )
        form.setFieldValue('elencoAttivita', nuovaAttivita, true);
    }

    const setCampoAttivita = (e:string, i: number, campo: string) => {
        var attivita = form.values.elencoAttivita || [];
        const obj = {
            ...attivita[i],
            [campo]: e
         }
        attivita = attivita.slice(0);
        attivita[i] = obj;
        form.setFieldValue('elencoAttivita', attivita, true);
    }

    const getProfiloProfessionaleOptions = (index: number|string, validate?:boolean) =>{
        handleSelectChange('idAreaInquadramento',index, validate);
        var idArea: number = 1;
        if(typeof index === "string"){
            idArea = Number(index);
        }else{
            idArea = index;
        }
        dispatch(getProfiliProfessionali(idArea)).unwrap().then((resp) => {
            if(resp){
                var profiliProfessionaliOpt: Array<CustomSelectItem> = [];
                resp.profiliProfessionali && resp.profiliProfessionali.forEach((f:any)=>{
                    profiliProfessionaliOpt.push({
                        value: f.id,
                        label: f.profiloProfessionale,
                    })
                })
                setProfiloProfessionaleOptions(profiliProfessionaliOpt);
            }
        })
    }

    const getAmbitoRuoloOptions = (index: string)=>{
        if(index === ''){
            form.setFieldValue('idProfiloProfessionale', undefined)
        }else if(!isNaN(Number(index))){
            handleSelectChange('idProfiloProfessionale', Number(index));
        }

        var idArea: number = Number(index);
        dispatch(getAmbitoRuolo(idArea)).unwrap().then((resp) =>{
            if(resp){
                var ambitoRuoloOpt: Array<CustomSelectItem> = [];
                resp.ambitoRuolo && resp.ambitoRuolo.forEach((f:any)=>{
                    ambitoRuoloOpt.push({
                        value: f.id,
                        label: f.descrizione
                    })
                })
                if(ambitoRuoloOpt.length > 0){
                    setDisableAmbitoRuolo(false);
                }else{
                    setDisableAmbitoRuolo(true);
                }
                setAmbitoDiRuoloOptions(ambitoRuoloOpt);
            }
        })
    }

    const checkEsperienzaInCorso = () => {
        const numExpInCorso = elencoEsperienzeProfessionaliInRegione?.filter( exp => exp.inCorso).length;
        if (numExpInCorso > 1 || 
            form.values.inCorso || 
            (numExpInCorso === 1 && !inModifica) || 
            (numExpInCorso === 1 && inModifica && (selectedElement !== undefined && form.values.inCorso === elencoEsperienzeProfessionaliInRegione[selectedElement!].inCorso))) {            
            return false;
        }
        return true;
    }

    const changeDataDa = (data:string) => {
        if (!!data) {
            setDataMin(new Date(data).toISOString().split('T')[0]);
            form.setFieldValue('da', data);
            return true;
        }
        form.setFieldValue('da', undefined);
    }

    const changeDataAl = (data:string) => {
        if (!!data) {    
            setDataMax(new Date(data).toISOString().split('T')[0]);
            form.setFieldValue('al', data);
            return true;
        }
        form.setFieldValue('al', new Date(), true);
    }

    const confermaRimuoviAttivita = (stato: boolean) => {
        if(stato) {
            removeItem(Number(attivitaDaRimuovere))
        }
        toggleModalDelete(!isOpenModalDelete)
        setAttivitaDaRimuovere(undefined)
    }

    return (
        <div className='esperienze-professionali-regione col-12'>
            <Callout className='au-callout au-callout-info col-md-12'>
                <CalloutTitle><Icon icon='it-info-circle' color='primary' size='sm'/> Nota</CalloutTitle>
                In questa sezione è necessario anagrafare uno o più inquadramenti ricoperti in Regione Puglia.<br/>
                Per ciascun inquadramento inserito è necessario indicare una o più attività, incarichi e/o responsabilità ricoperte.
            </Callout>
            <div className='font-italic font-weight-500 mb-3'>
                {t("campiObbligatoriAsterisco")}
            </div>
            {detailStatus === STATUS_FULLFILLED && elencoEsperienzeProfessionaliInRegione.length > 0 &&
                <>
                    <div className='d-flex align-items-center justify-content-between my-3'>
                        <div className='d-flex align-items-center'>
                            <h4>{t("Area di appartenenza e incarichi/esperienze lavorative")} </h4>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Button
                                color="primary"
                                className="mt-2"
                                disabled={!canAggiungiEsperienza}
                                outline
                                onClick={() => {
                                    setShowFormInserimento(true)
                                    setCanAggiungiEsperienza(false)
                                    props.disabilitaContinuaBtn(true)
                                    form.resetForm({ values: initialValues });

                                }} >
                                {t('Aggiungi esperienza')}
                            </Button>
                        </div>
                    </div>

                    <Table responsive striped>
                        <tbody>
                            {(insertStatus === STATUS_FULLFILLED) && elencoEsperienzeProfessionaliInRegione.map((data, index) =>
                                <tr key={index}>
                                    <td>
                                       <strong>#{index + 1}</strong> {data.tipologiaDipendente} - {data.areaInquadramentoLabel?.toUpperCase()} 
                                            {' '} dal {formatDate(data.da!, true, '-')} {!!data.al ? `al ${formatDate(data.al!, true, '-')}` : ''}
                                        {data.inCorso ? <span className='font-bold'>(IN CORSO)</span>: ''}
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-end mr-3'>
                                            <div className='btn-table' 
                                                onClick={() => {if(!showFormInserimento) {toggleModalDelete(true); setAttivitaDaRimuovere(index)} }} 
                                                title='Elimina voce'>
                                                <Icon color="primary" icon="it-delete" className={(showFormInserimento ? 'disabled' : '')}/>
                                            </div>
                                            <div className='btn-table ml-2' 
                                                onClick={() => {if(!showFormInserimento) modificaEsperienza(index)}} title='Modifica voce' >
                                                <Icon icon='it-pencil' color='primary' className={(showFormInserimento ? 'disabled' : '')}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>
            }

            {showFormInserimento && insertStatus === STATUS_FULLFILLED &&
                <Callout className='au-callout col-md-12 mt-5'>
                    <CalloutTitle>{inModifica ? 'Modifica esperienza #' + (selectedElement! + 1) : 'Nuova esperienza'}</CalloutTitle>

                    <span className='row font-bold mb-5 ml-1'>Area di appartenenza</span>

                    <div className='form-custom'>
                        <Input
                            label="Datore di lavoro"
                            type="text"
                            id="datoreLavoro"
                            wrapperClassName={"form-group col-md-12"}
                            value="ARPAL Puglia"
                            disabled={true}
                        />

                        <div className="form-row mt-5 border-bottom pb-md-3">
                            <div className='row col-12 mb-5'>
                                <Input
                                    label="Da"
                                    type="date"
                                    placeholder="Da"
                                    id="da"
                                    wrapperClassName="col-md-6 required mb-0"
                                    max={new Date().toISOString().slice(0, 10)}
                                    min={new Date(1900, 0).toISOString().slice(0, 10)}
                                    name="da"
                                    value={form.values.da}
                                    disabled={props.disabled}
                                    onChange={(e) => changeDataDa(e.target.value)}
                                    invalid={!!getFieldError(form, "da")}
                                    infoText={t(getFieldError(form, "da")) || ""}
                                />

                                <Input
                                    label="A"
                                    type="date"
                                    placeholder="A"
                                    id="al"
                                    wrapperClassName="col-md-6 mb-0"
                                    min={dataMin}
                                    max={new Date().toISOString().slice(0, 10)}
                                    name="al"
                                    value={form.values.al}
                                    disabled={form.values.inCorso}
                                    onChange={(e) => changeDataAl(e.target.value)}
                                    invalid={!!getFieldError(form, "al")}
                                    infoText={t(getFieldError(form, "al")) || ""}
                                />
                            </div>
                            <FormGroup check className="col-12 offset-md-6 col-md-6">
                                <Input
                                    type="checkbox"
                                    id="inCorso"
                                    name="inCorso"
                                    checked={form.values.inCorso}
                                    onChange={changeInCorso}
                                />
                                <Label for={"inCorso"} check>In corso</Label>
                            </FormGroup>
                           
                        </div>

                        <div className='border-bottom'>
                            <div className="form-row mt-1">
                                <FormGroup check className="text-left col-md-4">
                                    <Input
                                        label='Dirigente'
                                        type="checkbox"
                                        id="tipologiaDipendenteDirigente"
                                        name="tipologiaDipendenteDirigente"
                                        className='checkbox-tipo-dipendente'
                                        checked={form.values.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente}
                                        disabled={false}
                                        onChange={changeTipologiaDipendenteDirigente}
                                        invalid={!!getFieldError(form, "tipologiaDipendente")}
                                        infoText={t(getFieldError(form, "tipologiaDipendente")) || ""}
                                    />
                                </FormGroup>

                                <FormGroup check className="text-left col-md-6">
                                    <Input
                                        label='Comparto'
                                        type="checkbox"
                                        className='checkbox-tipo-dipendente'
                                        id="tipologiaDipendenteComparto"
                                        name="tipologiaDipendenteComparto"
                                        checked={form.values.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Comparto}
                                        disabled={false}
                                        onChange={changeTipologiaDipendenteComparto}
                                    />
                                </FormGroup>
                            </div>

                            <CustomSelect label="Area di inquadramento"
                                name="areaInquadramento"
                                placeholder="Seleziona Area di inquadramento"
                                wrapperClass={isDirigente()? "col-md-12" : "col-md-12 required"}
                                options={areaInquadramentoOptions}
                                onChange={(e) => {getProfiloProfessionaleOptions(e, true); form.setFieldValue('idProfiloProfessionale', undefined); form.setFieldValue('idAmbitoDiRuolo', undefined);}}
                                disabled={form.values.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente}
                                value={form.values.idAreaInquadramento}
                                invalid={!!getFieldError(form, "idAreaInquadramento")}
                                infoText={t(getFieldError(form, "idAreaInquadramento")) || ""}
                            />
                            <CustomSelect label="Profilo professionale ai sensi del DD n.301 del 16 Marzo 2021 e SS.MM.II"
                                name="profiloProfessionale"
                                placeholder="Seleziona profilo professionale"
                                wrapperClass="col-md-12"
                                options={profiloProfessionaleOptions}
                                onChange={(e) => {getAmbitoRuoloOptions(e); form.setFieldValue('idAmbitoDiRuolo', undefined);} }
                                disabled={form.values.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente}
                                value={form.values.idProfiloProfessionale}
                                invalid={!!getFieldError(form, "idProfiloProfessionale")}
                                infoText={t(getFieldError(form, "idProfiloProfessionale")) || ""}
                            />
                            <CustomSelect label="Ambito di ruolo"
                                name="ambitoDiRuolo"
                                placeholder="Seleziona ambito di ruolo"
                                wrapperClass="col-md-12"
                                options={ambitoDiRuoloOptions}
                                onChange={(e) => handleSelectChange('idAmbitoDiRuolo', e)}
                                disabled={form.values.tipologiaDipendente === EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum.Dirigente || !form.values.idProfiloProfessionale || disableAmbitoRuolo}
                                value={form.values.idAmbitoDiRuolo}
                                invalid={!!getFieldError(form, "idAmbitoDiRuolo")}
                                infoText={t(getFieldError(form, "idAmbitoDiRuolo")) || ""}
                            />
                    </div>

                    <div className='d-flex align-items-center justify-content-between my-3'>
                        <h4>Attività, incarichi, responsabilità</h4>
                        <Button
                            color="primary"
                            outline
                            onClick={aggiungiAttivita}
                        >
                            {t('Aggiungi attività')}
                        </Button>
                    </div>

                    {form.values.elencoAttivita && form.values.elencoAttivita.map( (l,i) => (
                        <div key={i} className='box-nuova-attivita'>
                            <span className='row font-bold mb-5'>#{i + 1} Nuova Attività </span>
                            <div className='form-row'>
                                <Input
                                    label="Da"
                                    type="date"
                                    placeholder="Da"
                                    id="da"
                                    wrapperClassName="col-md-6 required"
                                    max={dataMax}
                                    min={dataMin}
                                    name="da"
                                    value={(form.values.elencoAttivita && form.values.elencoAttivita[i].dal) || ''}
                                    disabled={props.disabled}
                                    onChange={(e)=> setCampoAttivita(e.target.value, i, "dal")}
                                    invalid={!!getFieldArrayError(form.errors, "elencoAttivita", "dal", i)}
                                    infoText={t(getFieldArrayError(form.errors, "elencoAttivita", "dal", i)) || ""}
                                />
                                <Input
                                    label="A"
                                    type="date"
                                    placeholder="A"
                                    id="al"
                                    wrapperClassName={"col-md-6"}
                                    min={dataMin}
                                    max={dataMax}
                                    name="al"
                                    value={(form.values.elencoAttivita && form.values.elencoAttivita[i].a) || ''}
                                    onChange={(e)=> setCampoAttivita(e.target.value, i, "a")}
                                    invalid={!!getFieldArrayError(form.errors,"elencoAttivita",  "a", i)}
                                    infoText={t(getFieldArrayError(form.errors,"elencoAttivita",  "a", i)) || ""}
                                />
                            </div>
                            <div className='container-textarea mt-3'>
                                <TextareaInfoChars
                                    className=""
                                    rows={3}
                                    label="Descrizione attività e responsabilità"
                                    placeholder="Indica le attività svolte durante l’attività lavorativa"
                                    wrapperClassName="required"
                                    name="attivitaResponsabilitaDescrizione"
                                    value={(form.values.elencoAttivita && form.values.elencoAttivita[i].descrizione) || ''}
                                    onChange={(e:any)=> setCampoAttivita(e.target.value, i, "descrizione")}
                                    invalid={!!getFieldArrayError(form.errors, "elencoAttivita", "descrizione", i)}
                                    infoText={t(getFieldArrayError(form.errors, "elencoAttivita", "descrizione", i)) ||
                                         t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                                    maxLength={300}
                                />
                            </div>
                            <Button color="secondary" onClick={() => {rimuoviAttivita(i)}}>
                                {t('Rimuovi attività')}
                            </Button>
                        </div>
                    ))}
                </div>

                {checkEsperienzaInCorso() && (
                    <Alert className="col-md-12 mt-2" color='warning'>
                        Non è possibile salvare l'esperienza lavorativa perchè è necessario indicarne almeno una <b>in corso</b>.
                    </Alert>
                )}
                <div className="d-flex align-items-center justify-content-end mt-5">
                    {(elencoEsperienzeProfessionaliInRegione && elencoEsperienzeProfessionaliInRegione.length > 0) && <Button
                        color="secondary"
                        className="mr-3"
                        onClick={() => {
                            setSelectedElement(undefined);
                            setShowFormInserimento(false)
                            setCanAggiungiEsperienza(true)
                            props.disabilitaContinuaBtn(false)
                            if(inModifica) {
                                setInModifica(false)
                            }
                            props.scrollAction()
                            form.resetForm();
                        }}
                    >
                        {t('Annulla')}
                    </Button>}
                    <Button
                        color="primary"
                        outline
                        disabled={checkEsperienzaInCorso() || !form.isValid}
                        onClick={() => {
                            props.scrollAction()
                            form.submitForm();
                        }}
                    >
                        {t('salva esperienza')}
                    </Button>
                </div>
            </Callout>
            }

            <Modal
                isOpen={isOpenModalDelete}
                toggle={() => toggleModalDelete(!isOpenModalDelete)}
                labelledBy='elimina-attivita'
                centered>
                <ModalHeader toggle={() => { toggleModalDelete(!isOpenModalDelete) }} id='elimina-attivita'>
                    Conferma rimozione
                </ModalHeader>
                <ModalBody>
                    <p>Sei sicuro di voler eliminare questa attività professionale?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { confermaRimuoviAttivita(false) }}>
                        No
                    </Button>
                    <Button color='primary' onClick={() => { confermaRimuoviAttivita(true) }}>
                        Si
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
})

export default EspProfessionaliInRegioneTab