/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CaricamentoMassivoRequest } from '../model';
// @ts-ignore
import { UploadBandiEqResponseDto } from '../model';
/**
 * CaricamentoMassivoBandiEqControllerApi - axios parameter creator
 * @export
 */
export const CaricamentoMassivoBandiEqControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CaricamentoMassivoRequest} caricamentoMassivoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caricamentoMassivo: async (caricamentoMassivoRequest: CaricamentoMassivoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caricamentoMassivoRequest' is not null or undefined
            assertParamExists('caricamentoMassivo', 'caricamentoMassivoRequest', caricamentoMassivoRequest)
            const localVarPath = `/v1/upload-bandi/caricamento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caricamentoMassivoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQ' | 'EQ_BANDI' | 'BANDI'} tipoCaricamento 
         * @param {'BA' | 'BV'} tipoEQ 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewEq: async (tipoCaricamento: 'EQ' | 'EQ_BANDI' | 'BANDI', tipoEQ: 'BA' | 'BV', file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoCaricamento' is not null or undefined
            assertParamExists('previewEq', 'tipoCaricamento', tipoCaricamento)
            // verify required parameter 'tipoEQ' is not null or undefined
            assertParamExists('previewEq', 'tipoEQ', tipoEQ)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('previewEq', 'file', file)
            const localVarPath = `/v1/upload-bandi/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tipoCaricamento !== undefined) {
                localVarQueryParameter['tipoCaricamento'] = tipoCaricamento;
            }

            if (tipoEQ !== undefined) {
                localVarQueryParameter['tipoEQ'] = tipoEQ;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaricamentoMassivoBandiEqControllerApi - functional programming interface
 * @export
 */
export const CaricamentoMassivoBandiEqControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaricamentoMassivoBandiEqControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CaricamentoMassivoRequest} caricamentoMassivoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caricamentoMassivo(caricamentoMassivoRequest: CaricamentoMassivoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caricamentoMassivo(caricamentoMassivoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQ' | 'EQ_BANDI' | 'BANDI'} tipoCaricamento 
         * @param {'BA' | 'BV'} tipoEQ 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewEq(tipoCaricamento: 'EQ' | 'EQ_BANDI' | 'BANDI', tipoEQ: 'BA' | 'BV', file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBandiEqResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewEq(tipoCaricamento, tipoEQ, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaricamentoMassivoBandiEqControllerApi - factory interface
 * @export
 */
export const CaricamentoMassivoBandiEqControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaricamentoMassivoBandiEqControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caricamentoMassivo(requestParameters: CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.caricamentoMassivo(requestParameters.caricamentoMassivoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaricamentoMassivoBandiEqControllerApiPreviewEqRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewEq(requestParameters: CaricamentoMassivoBandiEqControllerApiPreviewEqRequest, options?: AxiosRequestConfig): AxiosPromise<UploadBandiEqResponseDto> {
            return localVarFp.previewEq(requestParameters.tipoCaricamento, requestParameters.tipoEQ, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for caricamentoMassivo operation in CaricamentoMassivoBandiEqControllerApi.
 * @export
 * @interface CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest
 */
export interface CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest {
    /**
     * 
     * @type {CaricamentoMassivoRequest}
     * @memberof CaricamentoMassivoBandiEqControllerApiCaricamentoMassivo
     */
    readonly caricamentoMassivoRequest: CaricamentoMassivoRequest
}

/**
 * Request parameters for previewEq operation in CaricamentoMassivoBandiEqControllerApi.
 * @export
 * @interface CaricamentoMassivoBandiEqControllerApiPreviewEqRequest
 */
export interface CaricamentoMassivoBandiEqControllerApiPreviewEqRequest {
    /**
     * 
     * @type {'EQ' | 'EQ_BANDI' | 'BANDI'}
     * @memberof CaricamentoMassivoBandiEqControllerApiPreviewEq
     */
    readonly tipoCaricamento: 'EQ' | 'EQ_BANDI' | 'BANDI'

    /**
     * 
     * @type {'BA' | 'BV'}
     * @memberof CaricamentoMassivoBandiEqControllerApiPreviewEq
     */
    readonly tipoEQ: 'BA' | 'BV'

    /**
     * 
     * @type {File}
     * @memberof CaricamentoMassivoBandiEqControllerApiPreviewEq
     */
    readonly file: File
}

/**
 * CaricamentoMassivoBandiEqControllerApi - object-oriented interface
 * @export
 * @class CaricamentoMassivoBandiEqControllerApi
 * @extends {BaseAPI}
 */
export class CaricamentoMassivoBandiEqControllerApi extends BaseAPI {
    /**
     * 
     * @param {CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaricamentoMassivoBandiEqControllerApi
     */
    public caricamentoMassivo(requestParameters: CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest, options?: AxiosRequestConfig) {
        return CaricamentoMassivoBandiEqControllerApiFp(this.configuration).caricamentoMassivo(requestParameters.caricamentoMassivoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaricamentoMassivoBandiEqControllerApiPreviewEqRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaricamentoMassivoBandiEqControllerApi
     */
    public previewEq(requestParameters: CaricamentoMassivoBandiEqControllerApiPreviewEqRequest, options?: AxiosRequestConfig) {
        return CaricamentoMassivoBandiEqControllerApiFp(this.configuration).previewEq(requestParameters.tipoCaricamento, requestParameters.tipoEQ, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}
