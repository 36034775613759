import { useNavigate, useParams } from "react-router-dom";
import AbilitazioneDetailsComponent from "../../components/abilitazione-detail/AbilitazioneDetailsComponent";
import { RuoloUtenteAutenticatoDto } from "../../services/ms-anagrafica-unica";
import { useEffect, useState } from "react";
import { getDettaglioRichiesta, valutaRichiestaAbilitazioneAttivazione } from "../../store/funzionalitaApplicativeSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import PageLoader from "../../components/common/page-loader/PageLoader";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import NotificationManagerComponent from "../../components/notification/NotificationManagerComponent";
import PageHeader from "../../components/common/page-header/PageHeader";
import { useTranslation } from "react-i18next";
import { HREF_ABILITAZIONI_ELENCO_RICHIESTE } from "../../components/layout/sidemenu/sidemenuConstants";
import { Input, Table } from "design-react-kit";
import { Button } from "reactstrap";
import { useFormik } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

const ApprovaRichiestaAttivazione = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { dettaglioRichiestaResult, dettaglioRichiestaStatus, valutazioneRichiestaAttivazioneStatus } = useAppSelector((state) => state.abilitazioni)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const schema = z.object({
        noteGestore: z.string().max(1000, { message: 'lengthnotegestore' }).optional(),
        decisioneGestore: z.enum(['APPROVA','RIFIUTA'])
    }).refine((data) => {
        if (data.decisioneGestore === 'RIFIUTA' && !data.noteGestore)
            return false
        return true
    }, 
    {
        message: "Il campo note è obbligatorio",
        path: ["noteGestore"]
    })


    const valutazioneForm = useFormik({
        initialValues: {
            decisioneGestore: undefined,
            noteGestore: ''
        },
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('values >>> ', values);
            dispatch(valutaRichiestaAbilitazioneAttivazione({
                id: Number(id),
                valutazioneRichiestaAbilitazioneDto: {
                    ...values,
                decisioneGestore: values.decisioneGestore}})
            ).unwrap().then( resp => !!resp && navigate(HREF_ABILITAZIONI_ELENCO_RICHIESTE))
        },
    });

    useEffect(() => {
        dispatch(getDettaglioRichiesta(Number(id)))
    }, []);

    const submitApprova = () => {
        valutazioneForm.setFieldValue('decisioneGestore', 'APPROVA');
        setTimeout(() => {
            valutazioneForm.submitForm();
        }, 500); 
    }

    const submitRifiuta = () => {
        valutazioneForm.setFieldValue('decisioneGestore', 'RIFIUTA');
        setTimeout(() => {
            valutazioneForm.submitForm();
        }, 500); 
    }

    return (
        <div>
            <PageHeader showIcon={true}  urlBack={HREF_ABILITAZIONI_ELENCO_RICHIESTE} title={t("Valuta richiesta di attivazione")!}/>
        {(dettaglioRichiestaStatus === STATUS_REJECTED  || valutazioneRichiestaAttivazioneStatus === STATUS_REJECTED) &&
            <NotificationManagerComponent state='error' title='Errore'
                                            content='Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio'/>
        }
        {dettaglioRichiestaStatus === STATUS_FULLFILLED && !!dettaglioRichiestaResult &&
            <>
            <div className="form-row form-custom mt-5">
                <AbilitazioneDetailsComponent abilitazione={dettaglioRichiestaResult} except={[]}/>
                {dettaglioRichiestaResult?.elencoFunzionalitaRichieste && dettaglioRichiestaResult.elencoFunzionalitaRichieste.length > 0 &&
                <>
                <Table md={12}>
                    <thead>
                        <tr>
                            <th>Funzionalità</th>
                            <th>Attiva</th>
                            <th>Note dirigente</th>
                        </tr>
                    </thead>
                    <tbody>
                    {dettaglioRichiestaResult.elencoFunzionalitaRichieste.map( funz => (
                        <tr>
                            <td>{funz.funzionalita?.descrizione}</td>
                            <td>{funz.tipoAbilitazione}</td>
                            <td>{funz.noteDirigente}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Input type="text"
                    label="Note Gestore"
                    id="noteGestore"
                    placeholder="Inserisci nota"
                    wrapperClassName="col-md-12 mt-5"
                    name="noteGestore"
                    invalid={!!valutazioneForm.errors.noteGestore}
                    infoText={t(valutazioneForm.errors.noteGestore || "") || ""}
                    onChange={valutazioneForm.handleChange}
                />
                </>}
            </div>
            <div className="d-flex flex-row-reverse">
                <Button color='primary' className="mt-2 mr-2" onClick={() => submitApprova()}>Approva</Button>
                <Button color='primary' className="mt-2 mr-2" onClick={() =>  submitRifiuta()}>Rifiuta</Button>
                <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ABILITAZIONI_ELENCO_RICHIESTE}`) }} >{t('annulla')} </Button>
            </div>
            </>
        }
        </div>
    )
}

export default ApprovaRichiestaAttivazione;