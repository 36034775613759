import {Button, FormGroup, Input, Label, TextArea} from "design-react-kit";
import {FormikProps, useFormik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import {HREF_ELENCO_CONVENZIONI, HREF_VISUALIZZA_CONVENZIONE} from "../../components/layout/sidemenu/sidemenuConstants";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    detailsConvenzione,
    editConvenzione,
    fetchContatti,
    fetchEntiSottoscrittori,
    fetchStrutture,
    schema,
    SelectItem
} from "../../store/ConvenzioniSlice";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import CreatableSelect from 'react-select/creatable'

import {
    APPLICABILITA_CONVENZIONE,
    CONDIZIONI_DI_RINNOVO,
    CONTATTI,
    CONTATTO_AMMINISTRATIVO,
    CONTATTO_TECNICO,
    DATA_ALERT_1,
    DATA_ALERT_2,
    DATA_ALERT_3,
    DATA_RECESSO,
    DATI_AMMINISTRAZIONE_REGIONALE,
    DATI_ENTE_SOTTOSCRITTORE,
    DOCUMENTI_ALLEGATI,
    EMAIL_CONTATTO,
    ENTI_SOTTOSCRITTORI,
    MODALITA_RINNOVO,
    MOTIVO_RECESSO,
    NOME_CONVENZIONE,
    NOTIFICHE,
    RECESSO,
    regexEmail,
    RESPONSABILE,
    SCOPO_CONVENZIONE,
    STRUTTURA_REFERENTE,
    STRUTTURE_REGIONALI
} from "./convenzioniConstants";
import {STATUS_REJECTED} from "../../store/store-constants";
import {toFormikValidationSchema} from "zod-formik-adapter";
import Select from "react-select";
import {ConvenzioneInsertModifyRequestDto} from "../../services/ms-anagrafica-unica";
import CustomSelectItemComponent
    from "../../components/common/custom-select-item/CustomSelectItemComponent";
import PageHeader from "components/common/page-header/PageHeader";

const initialValues: ConvenzioneInsertModifyRequestDto = {
    nomeConvenzione: '',
    scopoConvenzione: '',
    entiSottoscrittori: [],
    struttureRegionali: [],
    note: '',
    // stato: undefined,
    dataDecorrenza: '',
    dataScadenza: '',
    contattoAmministrativo: '',
    contattoTecnico: '',
    responsabile: '',
    strutturaReferente: '',
    dataAlert1: '',
    dataAlert2: '',
    dataAlert3: '',
    condizioniRinnovo: '',
    emailContatto: '',
    allegato1: '',
    allegato2: '',
    allegato3: '',
    dataRecesso: '',
    motivoRecesso: '',
    contatti: [],
};

function Convenzione() {
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {
        detailsResult,
        listaEnti,
        listaContatti,
        listaStrutture,
        editStatus,
        errorText
    } = useAppSelector((state) => state.convenzioni);
    const [errorMsgEnte, setErrorMsgEnte] = useState<boolean>(false);
    const [errorMsgStrutture, setErrorMsgStrutture] = useState<boolean>(false);
    const [errorMsgContatto, setErrorMsgContatto] = useState<boolean>(false);
    const [errorMsgContattoNonValido, setErrorMsgContattoNonValido] = useState<boolean>(false);
    const [allRegion, setAllRegion] = useState<boolean>(true);
    const [loadedContatti, setLoadedContatti] = useState<SelectItem[]>([]);
    const [loadedEnti, setLoadedEnti] = useState<SelectItem[]>([]);
    const [loadedStrutture, setLoadedStrutture] = useState<SelectItem[]>([]);

    let entiSelect: SelectItem[] = [];
    let contattiSelect: SelectItem[] = [];
    let struttureSelect: SelectItem[] = [];

    let entiDaDettaglio: SelectItem[] = [];
    let contattiDaDettaglio: SelectItem[] = [];
    let struttureDaDettaglio: SelectItem[] = [];

    listaEnti.forEach((ente) => {
        entiSelect.push({label: ente.nomeEnte!, value: ente.id!});
    });
    listaContatti.forEach((contatto) => {
        contattiSelect.push({label: contatto.email!, value: contatto.id!});
    });
    listaStrutture.forEach((struttura) => {
        struttureSelect.push({label: struttura.descrizione!, value: struttura.codice!});
    });

    const doFetchEntiSottoscrittori = () => {
        dispatch(fetchEntiSottoscrittori());
    }
    const doFetchContatti = () => {
        dispatch(fetchContatti());
    }
    const doLoadDetails = (id: number) => {
        dispatch(detailsConvenzione(id));
    }

    const doFetchStrutture = () => {
        dispatch(fetchStrutture());
    }


    useEffect(() => {
        doLoadDetails(id as unknown as number);
        doFetchEntiSottoscrittori();
        doFetchContatti();
        doFetchStrutture();
    }, []);


    useEffect(() => {
        formik.setFieldValue('id', detailsResult?.id);
        formik.setFieldValue('nomeConvenzione', detailsResult?.nomeConvenzione);
        formik.setFieldValue('scopoConvenzione', detailsResult?.scopoConvenzione);
        formik.setFieldValue('dataDecorrenza', detailsResult?.dataDecorrenza);
        formik.setFieldValue('dataScadenza', detailsResult?.dataScadenza);

        formik.setFieldValue('note', detailsResult?.note);

        formik.setFieldValue('contattoAmministrativo', detailsResult?.contattoAmministrativo);
        formik.setFieldValue('contattoTecnico', detailsResult?.contattoTecnico);
        formik.setFieldValue('responsabile', detailsResult?.responsabile);
        formik.setFieldValue('strutturaReferente', detailsResult?.strutturaReferente);

        formik.setFieldValue('dataAlert1', detailsResult?.dataAlert1);
        if (detailsResult?.dataAlert2)
            formik.setFieldValue('dataAlert2', detailsResult?.dataAlert2);
        if (detailsResult?.dataAlert3)
            formik.setFieldValue('dataAlert3', detailsResult?.dataAlert3);
        formik.setFieldValue('condizioniRinnovo', detailsResult?.condizioniRinnovo);
        formik.setFieldValue('emailContatto', detailsResult?.emailContatto);

        if (detailsResult?.dataRecesso)
            formik.setFieldValue('dataRecesso', detailsResult?.dataRecesso);
        if (detailsResult?.motivoRecesso)
            formik.setFieldValue('motivoRecesso', detailsResult?.motivoRecesso);

        formik.setFieldValue('entiSottoscrittori', detailsResult?.entiConvenzioneList?.map(ente => ({id: ente.id})));
        formik.setFieldValue('contatti', detailsResult?.contattiConvenzioneList?.map(contatto => ({id: contatto.id})));
        formik.setFieldValue('struttureRegionali', detailsResult?.struttureConvenzioneList?.map(struttura => ({codice: struttura.codice})));

        detailsResult?.entiConvenzioneList?.forEach((ente) => {
            entiDaDettaglio.push({label: ente.nomeEnte!, value: (ente.id as any)});
        });
        detailsResult?.contattiConvenzioneList?.forEach((contatto) => {
            contattiDaDettaglio.push({label: contatto.email!, value: (contatto.id as any)});
        });
        detailsResult?.struttureConvenzioneList?.forEach((struttura) => {
            struttureDaDettaglio.push({label: struttura.descrizione!, value: (struttura.codice as any)});
        });

        setLoadedEnti(entiDaDettaglio);
        setLoadedContatti(contattiDaDettaglio);
        setLoadedStrutture(struttureDaDettaglio);

        setAllRegion(struttureDaDettaglio.length === 0)
    }, [detailsResult])


    const formik: FormikProps<ConvenzioneInsertModifyRequestDto> = useFormik<ConvenzioneInsertModifyRequestDto>({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            if ((values?.contatti?.length && values?.contatti?.length > 0)
                && (values?.entiSottoscrittori?.length && values?.entiSottoscrittori?.length > 0)
                && (allRegion || (values?.struttureRegionali?.length && values?.struttureRegionali?.length > 0))) {
                if (allRegion) {
                    values.struttureRegionali = [];
                    setLoadedStrutture([]);
                }
                dispatch(editConvenzione(values));
            }
        }
    });
    const submit = (): Promise<void> => {
        let values = formik.values;

        setErrorMsgContatto(values?.contatti?.length === 0);
        setErrorMsgEnte(values?.entiSottoscrittori?.length === 0);
        setErrorMsgStrutture(!allRegion && values?.struttureRegionali?.length === 0);

        return formik.submitForm();
    }
    const handleEntiChange = (selectedOption: any) => {
        setErrorMsgEnte(false);
        let enti = (selectedOption as SelectItem[]).map(opt => ({
            id: opt.__isNew__ ? undefined : opt.value,
            nomeEnte: opt.label
        }));
        formik.setFieldValue('entiSottoscrittori', enti);
    };

    const handleContattiChange = (selectedOption: any, actionMeta: any) => {
        if (actionMeta.action === 'create-option') {
            if (!regexEmail.test(actionMeta.option.label)) {
                setErrorMsgContattoNonValido(true);
                (selectedOption as SelectItem[]).pop()
                return;
            }
        }
        setErrorMsgContattoNonValido(false)
        setErrorMsgContatto(false);
        let contatti = (selectedOption as SelectItem[]).map(opt => ({
            id: opt.__isNew__ ? undefined : opt.value,
            nominativo: "",
            email: opt.label
        }));
        formik.setFieldValue('contatti', contatti);
    };
    const handleStruttureRegionali = (selectedOption: any) => {
        setErrorMsgStrutture(false);
        let enti = (selectedOption as SelectItem[]).map(opt => ({codice: opt.value}));
        formik.setFieldValue('struttureRegionali', enti);
    };

    const handleRadioStruttureRegionali = () => {
        setAllRegion(false);
    }
    const handleRadioRegione = () => {
        setAllRegion(true);
        setErrorMsgStrutture(false);
    }

    const getFieldError = (form: FormikProps<ConvenzioneInsertModifyRequestDto>, fieldName: keyof ConvenzioneInsertModifyRequestDto): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] as string || "" as string;
        } else return "";
    };


    return (
        <div className="modifica-convenzione">
            <PageHeader showIcon={true} 
                urlBack={HREF_ELENCO_CONVENZIONI} 
                title="Modifica Convenzione" buttons={[]} />
            {editStatus == STATUS_REJECTED && <p className={"mb-0"}
                                                 style={{color: "red"}}><b>- {errorText}</b></p>}

            <div className="pt-3">
                <div className={"col-12 titolo-sezione"}>
                    <span>{DATI_ENTE_SOTTOSCRITTORE}</span>
                </div>
                <div className={"form-row form-custom form-inserimento mt-2"}>
                    <Input
                        label={NOME_CONVENZIONE}
                        type='text'
                        placeholder='Inserisci il nome della convenzione'
                        id='input-nomeConvenzione'
                        wrapperClassName="form-group required col col-md-6"
                        name="nomeConvenzione"
                        invalid={!!getFieldError(formik, "nomeConvenzione")}
                        infoText={t(getFieldError(formik, "nomeConvenzione")) ?? ""}
                        value={formik.values.nomeConvenzione}
                        onChange={formik.handleChange}/>
                    <Input
                        label={SCOPO_CONVENZIONE}
                        type='text'
                        placeholder='Inserisci lo scopo della convenzione'
                        id='input-denominazione'
                        wrapperClassName="form-group required col col-md-6"
                        name="scopoConvenzione"
                        invalid={!!getFieldError(formik, "scopoConvenzione")}
                        infoText={t(getFieldError(formik, "scopoConvenzione")) ?? ""}
                        value={formik.values.scopoConvenzione}
                        onChange={formik.handleChange}/>
                    <Input type='date'
                           label='Data decorrenza'
                           placeholder="Data di decorrenza"
                           id='input-dataDecorrenza'
                           name="dataDecorrenza"
                           invalid={!!getFieldError(formik, "dataDecorrenza")}
                           infoText={t(getFieldError(formik, "dataDecorrenza")) ?? ""}
                           value={formik.values.dataDecorrenza}
                           onChange={formik.handleChange}
                           wrapperClassName="form-group required col-md-6"/>

                    <Input type='date'
                           label='Data scadenza'
                           placeholder="Inserisci la data di scadenza"
                           id='input-dataScadenza'
                           name="dataScadenza"
                        // disabled={new Date(formik.values.dataScadenza) < today}
                           invalid={!!getFieldError(formik, "dataScadenza")}
                           infoText={t(getFieldError(formik, "dataScadenza")) ?? ""}
                           value={formik.values.dataScadenza}
                           onChange={formik.handleChange}
                           wrapperClassName="form-group required col-md-6"/>

                    <div className={"col-auto"}>
                        <span style={{fontSize: ".777rem", paddingLeft: '0.5rem'}}><b>{ENTI_SOTTOSCRITTORI}</b></span>
                        <span style={{color: "red", fontSize: "22px"}}><b> *</b></span>
                    </div>
                    <CreatableSelect
                        key={"enti" + loadedEnti.length}
                        placeholder="Seleziona gli enti sottoscrittori"
                        options={entiSelect}
                        defaultValue={loadedEnti}
                        isMulti={true}
                        className="form-group col-12"
                        formatCreateLabel={(value) => CustomSelectItemComponent('Aggiungi '+ value)}
                        onChange={handleEntiChange}
                    />

                    <div className={"col"} style={{fontSize: ".777rem", transform: "translateY(-50px)"}}>
                        <span className={"text-info"}><b><i>Se
                            non trovi l'ente che cerchi, inseriscilo/i manualmente scrivendo il nominativo e premi
                            invio</i></b></span>
                        <div className="col">
                            {errorMsgEnte &&
                                <span className={"red"}
                                    style={{color: "red"}}>
                                    Campo obbligatorio
                                </span>}
                        </div>
                    </div>

                    <TextArea
                        label="Note"
                        placeholder="Inserisci delle note aggiuntive"
                        id="input-note"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        wrapperClassName="form-group col-md-12"
                        name="note"/>

                </div>

                <div className={"col-12 titolo-sezione"}>
                    <span>{DATI_AMMINISTRAZIONE_REGIONALE}</span>
                </div>
                <div className={"form-row form-custom form-inserimento mt-2"}>
                    <Input
                        label={CONTATTO_AMMINISTRATIVO}
                        type='text'
                        placeholder='Cognome Nome'
                        id='input-contattoAmministrativo'
                        wrapperClassName="form-group required col col-md-6"
                        name="contattoAmministrativo"
                        invalid={!!getFieldError(formik, "contattoAmministrativo")}
                        infoText={t(getFieldError(formik, "contattoAmministrativo")) ?? ""}
                        value={formik.values.contattoAmministrativo}
                        onChange={formik.handleChange}
                    />

                    <Input
                        label={CONTATTO_TECNICO}
                        type='text'
                        placeholder='Cognome Nome'
                        id='input-contattoTecnico'
                        wrapperClassName="form-group required col col-md-6"
                        name="contattoTecnico"
                        invalid={!!getFieldError(formik, "contattoTecnico")}
                        infoText={t(getFieldError(formik, "contattoTecnico")) ?? ""}
                        value={formik.values.contattoTecnico}
                        onChange={formik.handleChange}
                    />

                    <Input
                        label={RESPONSABILE}
                        type='text'
                        placeholder='Cognome Nome'
                        id='input-responsabile'
                        wrapperClassName="form-group required col col-md-6"
                        name="responsabile"
                        invalid={!!getFieldError(formik, "responsabile")}
                        infoText={t(getFieldError(formik, "responsabile")) ?? ""}
                        value={formik.values.responsabile}
                        onChange={formik.handleChange}
                    />

                    <Input
                        label={STRUTTURA_REFERENTE}
                        type='text'
                        placeholder='Struttura referente'
                        id='input-strutturaReferente'
                        wrapperClassName="form-group required col col-md-6"
                        name="strutturaReferente"
                        invalid={!!getFieldError(formik, "strutturaReferente")}
                        infoText={t(getFieldError(formik, "strutturaReferente")) ?? ""}
                        value={formik.values.strutturaReferente}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className={"col-12 titolo-sezione"}>
                    <span>{APPLICABILITA_CONVENZIONE}</span>
                </div>
                <div className={"form-row form-custom form-inserimento mt-2"}>
                    <FormGroup check className="form-group mb-3 col-md-3">
                        <Input id='regione' name="equip" onChange={handleRadioRegione} checked={allRegion}
                               type='radio'/>
                        <Label for='regione' check>
                            ARPAL Puglia
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-group mb-3 col-md-3">
                        <Input id='strutt' name="equiparata" onChange={handleRadioStruttureRegionali}
                               checked={!allRegion} type='radio'/>
                        <Label for='strutt' check>
                            Strutture Regionali
                        </Label>
                    </FormGroup>
                    <div className={"col-12"}>
                        <label style={{fontSize: ".777rem"}}><b>{STRUTTURE_REGIONALI}</b></label>
                        {!allRegion && <span style={{color: "red", fontSize: "18px"}}><b>*</b></span>}
                    </div>
                    <Select
                        key={"strutture" + loadedStrutture.length}
                        placeholder="Seleziona le strutture regionali"
                        name="contatti"
                        options={struttureSelect}
                        defaultValue={loadedStrutture}
                        isDisabled={allRegion}
                        isMulti={true}
                        className="form-group col-12"
                        onChange={handleStruttureRegionali}
                    />
                    <div className={"col-12"}>
                        <div className="col"
                             style={{fontSize: ".777rem", transform: "translateY(-50px)", color: "red"}}>
                            {errorMsgStrutture &&
                                <span style={{fontWeight: '600'}}>
                                    Campo obbligatorio
                                </span>}
                        </div>
                    </div>
                </div>

                <div className={"col-12 titolo-sezione"}>
                    <span>{MODALITA_RINNOVO}</span>
                </div>
                <div className={"form-row form-custom form-inserimento mt-2"}>
                    <Input type='date'
                           label={DATA_ALERT_1}
                           placeholder="Data alert 1"
                           id='input-dataAlert1'
                           name="dataAlert1"
                           invalid={!!getFieldError(formik, "dataAlert1")}
                           infoText={t(getFieldError(formik, "dataAlert1")) ?? ""}
                           value={formik.values.dataAlert1}
                           onChange={formik.handleChange}
                           wrapperClassName="form-group required col col-md-4"/>

                    <Input type='date'
                           label={DATA_ALERT_2}
                           placeholder="Data alert 2"
                           id='input-dataAlert2'
                           name="dataAlert2"
                           invalid={!!getFieldError(formik, "dataAlert2")}
                           infoText={t(getFieldError(formik, "dataAlert2")) ?? ""}
                           value={formik.values.dataAlert2}
                           onChange={formik.handleChange}
                           wrapperClassName="form-group col-md-4"/>

                    <Input type='date'
                           label={DATA_ALERT_3}
                           placeholder="Data alert 3"
                           id='input-dataAlert3'
                           name="dataAlert3"
                           invalid={!!getFieldError(formik, "dataAlert3")}
                           infoText={t(getFieldError(formik, "dataAlert3")) ?? ""}
                           value={formik.values.dataAlert3}
                           onChange={formik.handleChange}
                           wrapperClassName="form-group col-md-4"/>

                    <Input
                        label={CONDIZIONI_DI_RINNOVO}
                        type='text'
                        placeholder='Condizioni di rinnovo'
                        id='input-condizioniRinnovo'
                        wrapperClassName="form-group required col-md-12"
                        name="condizioniRinnovo"
                        invalid={!!getFieldError(formik, "condizioniRinnovo")}
                        infoText={t(getFieldError(formik, "condizioniRinnovo")) ?? ""}
                        value={formik.values.condizioniRinnovo}
                        onChange={formik.handleChange}
                    />

                    <Input
                        label={EMAIL_CONTATTO}
                        type='text'
                        placeholder='E-mail contatto'
                        id='input-emailContatto'
                        wrapperClassName="form-group required col-md-12"
                        name="emailContatto"
                        invalid={!!getFieldError(formik, "emailContatto")}
                        infoText={t(getFieldError(formik, "emailContatto")) ?? ""}
                        value={formik.values.emailContatto}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className={"col-12 mb-5 titolo-sezione"}>
                    <span>{DOCUMENTI_ALLEGATI}</span>
                </div>
                <div className={"col mb-5 ml-4"}>
                    <Button color='primary' disabled>Carica documento</Button>
                </div>
                <div className={"col-12 titolo-sezione"}>
                    <span>{RECESSO}</span>
                </div>
                <div className={"form-row form-custom form-inserimento mt-2"}>
                    <Input type='date'
                           label={DATA_RECESSO}
                           placeholder="Data recesso"
                           id='input-dataRecesso'
                           name="dataRecesso"
                           invalid={!!getFieldError(formik, "dataRecesso")}
                           infoText={t(getFieldError(formik, "dataRecesso")) ?? ""}
                           value={formik.values.dataRecesso}
                           onChange={formik.handleChange}
                           wrapperClassName="form-group col-4"/>
                    <Input
                        label={MOTIVO_RECESSO}
                        type='text'
                        placeholder='Motivo del recesso'
                        id='input-motivoRecesso'
                        wrapperClassName="form-group col-md-12"
                        name="motivoRecesso"
                        invalid={!!getFieldError(formik, "motivoRecesso")}
                        infoText={t(getFieldError(formik, "motivoRecesso")) ?? ""}
                        value={formik.values.motivoRecesso}
                        onChange={formik.handleChange}
                    />
                </div>

                <div className={"col-12 titolo-sezione"}>
                    <span>{NOTIFICHE}</span>
                </div>

                <div className={"form-row form-custom form-inserimento mt-2"}>
                    <div className={"col-auto"}>
                        <span style={{fontSize: ".777rem", paddingLeft: '0.5rem'}}><b>{CONTATTI}</b></span>
                        <span style={{color: "red", fontSize: "22px"}}><b> *</b></span>
                    </div>
                    <CreatableSelect
                        key={"contatti" + loadedContatti.length}
                        placeholder="Seleziona i contatti a cui inviare eventuali notifiche"
                        options={contattiSelect}
                        defaultValue={loadedContatti}
                        isMulti={true}
                        className="form-group col-12"
                        formatCreateLabel={(value) => CustomSelectItemComponent('Aggiungi '+ value)}
                        onChange={handleContattiChange}
                    />
                    <div className={"col-auto"} style={{fontSize: ".777rem", transform: "translateY(-50px)"}}>
                        <span className={"text-info"}>
                            <b>
                                <i>Se non trovi il contatto che cerchi, inseriscilo/i manualmente scrivendo l'email e premi
                                    invio</i>
                            </b>
                        </span>
                        <div className="col">
                            {errorMsgContatto &&
                                <span style={{color: "red", fontWeight: '600'}}>
                                    Campo obbligatorio
                                </span>}
                        </div>
                        <div className="col">
                            {errorMsgContattoNonValido &&
                                <span style={{color: "red", fontWeight: '600'}}>
                                    Hai inserito una email in un formato non corretto, riprova.
                                </span>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row-reverse pb-5">
                <Button color='primary' className="mt-2" onClick={submit}>Salva</Button>
                <Button outline color='primary' className="mt-2 mr-2"
                        onClick={() => navigate(`${HREF_VISUALIZZA_CONVENZIONE}/${id}`)}>Indietro</Button>
            </div>
        </div>
    )
}

export default Convenzione;