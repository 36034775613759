import { DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Table, UncontrolledDropdown } from "design-react-kit";
import { AssegnazioneBandoPoRequestDto, PagedDtoPosizioneOrganizzativaDto, PosizioneOrganizzativaDto } from "../../../services/ms-anagrafica-unica";
import Pagination from "../../common/pagination/Pagination";
import { formatDate } from "../../../utility/formatDate";
import TableTheadCustom from "components/common/custom-table/table-thead-custom";

interface TableResultProps {
    ricercaEqStrutturaOrganizzativa: Function,
    content: PagedDtoPosizioneOrganizzativaDto,
    selezioneEqPerInserimento: Function,
    EQInseriteABando: Array<AssegnazioneBandoPoRequestDto>
    idSo: string | undefined,
    sort?: string,
    direction?: Direction
}

type Direction = "ASC" | "DESC";

const TableResult = (props: TableResultProps) => {

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = props.direction === 'ASC' ? 'DESC' : 'ASC';
        props.ricercaEqStrutturaOrganizzativa({
            idSo: props.idSo,
            sort: columnName,
            direction: direction
        })
    };

    const handlePageChange = (pageNum: number) => {
        props.ricercaEqStrutturaOrganizzativa({
            pageNum: pageNum - 1,
            idSo: props.idSo
        }); 
    };

    const selezionaEqDaInserireABando = (eq: PosizioneOrganizzativaDto) => {
        props.selezioneEqPerInserimento(eq)
    }

    const showInserisciAction = (eq: PosizioneOrganizzativaDto) => 
        !props.EQInseriteABando.some( eqInserita => eqInserita.idPosizioneOrganizzativa === eq.codiceUnivoco)

    return (
        <div className={props.content.totalPages === 1 ? 'mb-5' : ''}>
            <Table responsive striped>
                <TableTheadCustom 
                    colonne={[
                        {titolo: 'Denominazione', nomeColonna: 'descrizione'},
                        {titolo: 'Struttura Organizzativa', nomeColonna: 'struttura_organizzativa'},
                        {titolo: 'Tipologia EQ', nomeColonna: 'tipo'},
                        {titolo: 'Associato', nomeColonna: 'associato_a'},
                        {titolo: 'Data fine associazione', nomeColonna: 'dataFineAssociazione'},
                        {titolo: 'Azioni'}
                    ]} 
                    datiOrdinamento={{ sort: props.sort, direction: props.direction}}
                    eseguiOrdinamento={ordinamento}/>

                <tbody>
                    {(props.content.data || []).map((eq, index) => (
                        <tr key={index} style={{height: '50px'}}>
                            <td>{eq.denominazione}</td>
                            <td>{eq.nomeStrutturaOrganizzativa}</td>
                            <td>{eq.tipoLabel || "-"}</td>
                            <td>{eq.dipendenteRegionale}</td>
                            <td>{formatDate(eq.dataFineAssociazione!, true)}</td>
                            <td>
                                {showInserisciAction(eq) && (
                                    <UncontrolledDropdown direction="left">
                                        <DropdownToggle nav>
                                            <Icon icon="it-settings" color="primary" size="sm" />
                                        </DropdownToggle>
                                        <DropdownMenu className="no-arrow">
                                            <LinkList>
                                                
                                                    <LinkListItem
                                                        size="medium"
                                                        onClick={() => selezionaEqDaInserireABando(eq)}
                                                    >
                                                        <span>Inserisci nel bando</span>
                                                    </LinkListItem>
                                                
                                            </LinkList>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination
                totalCount={props.content.totalElements || 0}
                siblingCount={1}
                currentPage={props.content.pageNum ? props.content.pageNum + 1 : 1}
                pageSize={props.content.pageSize || 10}
                onPageChange={(page: number) => {
                    handlePageChange(page);
                }}
                className="justify-content-center"
            />
        </div>
    )
}

export default TableResult;