import { Button, Icon } from "design-react-kit";
import { ButtonCustomProps } from "./ButtonCustomProps";


function ButtonDelete (props: ButtonCustomProps) {

    return ( 
        <>
        {props.showOnlyIcon ? 
            <div className="item-icon-menu" onClick={props.onCliclEvent} title={ props.titleBtn ?? "Elimina File" } >
                <Icon icon="it-delete" color="danger"/>
            </div>
            :
            <Button className={props.className} color="danger" outline size="xs" onClick={props.onCliclEvent} title={ props.titleBtn ?? "Elimina File" }>
                <Icon icon="it-delete" size="xs" color="danger" /> { props.testoBtn ?? "Elimina" } 
            </Button>
        }
        </>
     );
}

export default ButtonDelete;