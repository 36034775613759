import { Badge, Button, ButtonProps, Icon, IconProps } from 'design-react-kit';
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ButtonsInterface {
    buttonProperties?: ButtonProps,
    title: string,
    action: Function,
    buttonColor: string,
    showButtonIcon?: boolean,
    icon?: string,
    iconColor?: string,
    disabled?: boolean
}

export interface pageHeader {
    title?: string;
    urlBack?: string
    showIcon?: boolean
    titleClass?: string
    buttons?: ButtonsInterface[],
    info?: React.ReactNode
    state?: any
}


const PageHeader = (props: pageHeader) => {

    const navigate = useNavigate();
    const { t } = useTranslation()

    const toBack = () => {
        if (props.urlBack === 'INDIETRO') {
            navigate(-1)
        }
        if (props.urlBack) {
            if(!!props.state)
                navigate(props.urlBack, {state: props.state})
            else navigate(props.urlBack);
        }
    }

    return (
        <div className='page-header d-flex align-items-center justify-content-between mb-3'>
            <div className='d-flex align-items-center'>
                {props.showIcon && <Badge color='primary' style={{marginRight: '20px'}}><Icon style={{ cursor: 'pointer' }} color="white" onClick={() => toBack()} icon="it-arrow-left" size="" /></Badge> }
                <h2 className={props.titleClass || ''}>{t(props.title!)} <span className="h6" style={{verticalAlign: 'middle'}}>{props.info}</span></h2>
            </div>
            <div className='d-flex align-items-center'>
                {props.buttons && props.buttons.map((button, i) =>

                    <Button key={i}
                        {...button.buttonProperties}
                        disabled={button.disabled}
                        color={button.buttonColor || ''}
                        className={`d-flex align-items-center btn-xs ${props.buttons?.length! && i < props.buttons?.length! - 1 ? 'mr-2' : ''}`}
                        onClick={() => button.action()}>
                        {button.showButtonIcon && <Icon color={button.iconColor!} icon={button.icon!} className='icon-align mr-1' size='xs' />}
                        <span>{button.title}</span>

                    </Button>

                )}
            </div>
        </div>
    )
}

export default PageHeader