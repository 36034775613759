import { StrutturaOrganizzativaDto } from '../../services/ms-anagrafica-unica'
import { CITTA, CODICE, CODICE_CIFRA_DELIBERA, CODICE_CIFRA_DETERMINA, CODICE_DIPA_RAGIONERIA, CODICE_IPA, CODICE_SEZI_RAGIONERIA, DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, DIPARTIMENTO, EMAIL, FAX, INDIRIZZO, PEC, SEZIONE, TELEFONO, TIPOLOGIA_STRUTTURA } from '../../pages/strutture-organizzative/struttureOrganizzativeConstants'
import DocumentView from '../common/document-view/DocumentView'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utility/formatDate'

export interface SODetailsForm {
    value: StrutturaOrganizzativaDto,
    fields: Array<string>,
}

const StruttureOrganizzativeDetails = (strutturaOrganizzativa: SODetailsForm) => {

    const { t } = useTranslation();
    let soDipa, soSez;

    if (strutturaOrganizzativa.value.strutturaOrganizzativaPadre) {
        const soLiv1 = strutturaOrganizzativa.value.strutturaOrganizzativaPadre;

        switch (soLiv1.tipo) {
            case 'DIPARTIMENTO':
                soDipa = soLiv1;
                break;
            case 'SEZIONE':
                soSez = soLiv1;
                break
            default:
                break;
        }

        if (soLiv1.strutturaOrganizzativaPadre) {
            const soLiv2 = soLiv1.strutturaOrganizzativaPadre;
            switch (soLiv2.tipo) {
                case 'DIPARTIMENTO':
                    soDipa = soLiv2;
                    break;
                case 'SEZIONE':
                    soSez = soLiv2;
                    break
                default:
                    break;
            }
        }
    }

    const showField = (field : string, nome: string) => {
        let obj = Object.getOwnPropertyDescriptor(strutturaOrganizzativa.value, nome)
        return strutturaOrganizzativa.fields.indexOf(field) !== -1 && !!obj?.value ? obj?.value : ' - '
    }

    return (
            <div className="dettaglio-so row">
                <div className="col-sm-12">
                    <div className="info-box-light-so bg-light-gray">
                        <h5 className='pb-2 border-bottom font-weight-bold'>
                            Riepilogo
                        </h5>
                        <dl className="border-bottom py-2">
                            <div className="row">
                                <dl className="col-12">
                                    <dt className='col-sm-12'>{CODICE}</dt>
                                    <dd className='col-sm-12'>{showField(CODICE, "id")}</dd>
                                </dl>
                                <dl className="col-12">
                                    <dt className='col-sm-12'>{DENOMINAZIONE}</dt>
                                    <dd className='col-sm-12'>{showField(DENOMINAZIONE, "descrizioneBreve")}</dd>
                                </dl>
                                <dl className="col-12">
                                    <dt className='col-sm-12'>{DECLARATORIA}</dt>
                                    <dd className='col-sm-12'>{showField(DECLARATORIA, "descrizione")}</dd>
                                </dl>
                            </div>
                        </dl>
                        <dl className={strutturaOrganizzativa?.value.documenti?.length! > 0 ? 'border-bottom py-2' : 'py-2'}>
                            <div className="row">
                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{DATA_INIZIO_VALIDITA}</dt>
                                    <dd className='col-sm-12'>{showField(DATA_INIZIO_VALIDITA, "dataInizioValidita")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{TIPOLOGIA_STRUTTURA}</dt>
                                    <dd className='col-sm-12'>{showField(TIPOLOGIA_STRUTTURA, "tipo")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{DIPARTIMENTO}</dt>
                                    <dd className='col-sm-12'>{showField(DIPARTIMENTO, "soDipa")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{SEZIONE}</dt>
                                    <dd className='col-sm-12'>{showField(SEZIONE, "soSez")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{EMAIL}</dt>
                                    <dd className='col-sm-12'>{showField(EMAIL, "email")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{PEC}</dt>
                                    <dd className='col-sm-12'>{showField(PEC, "pec")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{TELEFONO}</dt>
                                    <dd className='col-sm-12'>{showField(TELEFONO, "telefono")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{FAX}</dt>
                                    <dd className='col-sm-12'>{showField(FAX, "fax")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{INDIRIZZO}</dt>
                                    <dd className='col-sm-12'>{showField(INDIRIZZO, "indirizzo")}</dd>
                                </div>

                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{CITTA}</dt>
                                    <dd className='col-sm-12'>{showField(CITTA, "citta")}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{CODICE_CIFRA_DETERMINA}</dt>
                                    <dd className='col-sm-12'>{showField(CODICE_CIFRA_DETERMINA, "codiceUfficioDetermine")}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{CODICE_CIFRA_DELIBERA}</dt>
                                    <dd className='col-sm-12'>{showField(CODICE_CIFRA_DELIBERA, "codiceUfficioDelibere")}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{CODICE_DIPA_RAGIONERIA}</dt>
                                    <dd className='col-sm-12'>{showField(CODICE_DIPA_RAGIONERIA, "codiceDipartimentoRagioneria")}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{CODICE_SEZI_RAGIONERIA}</dt>
                                    <dd className='col-sm-12'>{showField(CODICE_SEZI_RAGIONERIA, "codiceSezioneRagioneria")}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt className='col-sm-12'>{CODICE_IPA}</dt>
                                    <dd className='col-sm-12'>{showField(CODICE_IPA, "codiceIpa")}</dd>
                                </div>
                            </div>
                        </dl>
                        <dl className="col-sm-12">
                            {(strutturaOrganizzativa?.value.documenti?.length! > 0) && 
                                <div className="documentView pt-2">
                                    <h4>Documenti caricati</h4>
                                    <div className="form-row">
                                        {strutturaOrganizzativa?.value.documenti?.map((value, index) => (
                                            <div className="col-4" key={index}>
                                               <DocumentView documentName={ t(`tipo-documento.${value.tipo!}`) + ' (' + formatDate(value.id?.documento?.dataInserimento || '') + ')'} 
                                                            documento={value.id?.documento} download />
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            }
                        </dl>
                    </div>
                </div>

            </div >
    )
}

export default StruttureOrganizzativeDetails