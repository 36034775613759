/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AnagraficaUnicaDto } from './anagrafica-unica-dto';

/**
 * 
 * @export
 * @interface ElencoNuoveAssunzioniDto
 */
export interface ElencoNuoveAssunzioniDto {
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'codiceUnivoco'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'categoria'?: ElencoNuoveAssunzioniDtoCategoriaEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'stato'?: ElencoNuoveAssunzioniDtoStatoEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'cellulare'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'telefono'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'pec'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'dataInizioProcedura'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'dataIngressoEffettivo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'dataInvioDocumenti'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'notePersonali'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'modalitaSelezione'?: ElencoNuoveAssunzioniDtoModalitaSelezioneEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'statoCandidato'?: ElencoNuoveAssunzioniDtoStatoCandidatoEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'tipoContratto'?: ElencoNuoveAssunzioniDtoTipoContrattoEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'livello'?: ElencoNuoveAssunzioniDtoLivelloEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'titoloDiStudio'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'attoIndizione'?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'areeProfessionali'?: ElencoNuoveAssunzioniDtoAreeProfessionaliEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'codiceClasseConcorso'?: ElencoNuoveAssunzioniDtoCodiceClasseConcorsoEnum;
    /**
     * 
     * @type {string}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'dipendenteRegionale'?: string;
    /**
     * 
     * @type {AnagraficaUnicaDto}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'anagraficaUnica'?: AnagraficaUnicaDto;
    /**
     * 
     * @type {boolean}
     * @memberof ElencoNuoveAssunzioniDto
     */
    'cessabile'?: boolean;
}

export const ElencoNuoveAssunzioniDtoCategoriaEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    Aro: 'ARO',
    Are: 'ARE',
    Ari: 'ARI',
    Arf: 'ARF',
    Cd: 'CD',
    Sz: 'SZ',
    Sr: 'SR',
    Altro: 'ALTRO'
} as const;

export type ElencoNuoveAssunzioniDtoCategoriaEnum = typeof ElencoNuoveAssunzioniDtoCategoriaEnum[keyof typeof ElencoNuoveAssunzioniDtoCategoriaEnum];
export const ElencoNuoveAssunzioniDtoStatoEnum = {
    Iniziata: 'INIZIATA',
    Sospsesa: 'SOSPSESA',
    InAttesaDiIntegrazione: 'IN_ATTESA_DI_INTEGRAZIONE',
    Chiusa: 'CHIUSA'
} as const;

export type ElencoNuoveAssunzioniDtoStatoEnum = typeof ElencoNuoveAssunzioniDtoStatoEnum[keyof typeof ElencoNuoveAssunzioniDtoStatoEnum];
export const ElencoNuoveAssunzioniDtoModalitaSelezioneEnum = {
    Con: 'CON',
    Chd: 'CHD',
    Mob: 'MOB'
} as const;

export type ElencoNuoveAssunzioniDtoModalitaSelezioneEnum = typeof ElencoNuoveAssunzioniDtoModalitaSelezioneEnum[keyof typeof ElencoNuoveAssunzioniDtoModalitaSelezioneEnum];
export const ElencoNuoveAssunzioniDtoStatoCandidatoEnum = {
    Inc: 'INC',
    Com: 'COM',
    Reg: 'REG',
    Val: 'VAL',
    Rif: 'RIF',
    Acc: 'ACC',
    Ces: 'CES',
    Cen: 'CEN'
} as const;

export type ElencoNuoveAssunzioniDtoStatoCandidatoEnum = typeof ElencoNuoveAssunzioniDtoStatoCandidatoEnum[keyof typeof ElencoNuoveAssunzioniDtoStatoCandidatoEnum];
export const ElencoNuoveAssunzioniDtoTipoContrattoEnum = {
    Pt: 'PT',
    Ind: 'IND',
    Det: 'DET',
    IndPt: 'IND_PT',
    DetDg: 'DET_DG'
} as const;

export type ElencoNuoveAssunzioniDtoTipoContrattoEnum = typeof ElencoNuoveAssunzioniDtoTipoContrattoEnum[keyof typeof ElencoNuoveAssunzioniDtoTipoContrattoEnum];
export const ElencoNuoveAssunzioniDtoLivelloEnum = {
    A1: 'A1',
    A2: 'A2',
    A3: 'A3',
    A4: 'A4',
    A5: 'A5',
    A6: 'A6',
    B1: 'B1',
    B2: 'B2',
    B3: 'B3',
    B4: 'B4',
    B5: 'B5',
    B6: 'B6',
    B7: 'B7',
    B8: 'B8',
    C1: 'C1',
    C2: 'C2',
    C3: 'C3',
    C4: 'C4',
    C5: 'C5',
    C6: 'C6',
    D1: 'D1',
    D2: 'D2',
    D3: 'D3',
    D4: 'D4',
    D5: 'D5',
    D6: 'D6',
    D7: 'D7'
} as const;

export type ElencoNuoveAssunzioniDtoLivelloEnum = typeof ElencoNuoveAssunzioniDtoLivelloEnum[keyof typeof ElencoNuoveAssunzioniDtoLivelloEnum];
export const ElencoNuoveAssunzioniDtoAreeProfessionaliEnum = {
    Are: 'ARE',
    Arf: 'ARF',
    Ari: 'ARI',
    Aro: 'ARO'
} as const;

export type ElencoNuoveAssunzioniDtoAreeProfessionaliEnum = typeof ElencoNuoveAssunzioniDtoAreeProfessionaliEnum[keyof typeof ElencoNuoveAssunzioniDtoAreeProfessionaliEnum];
export const ElencoNuoveAssunzioniDtoCodiceClasseConcorsoEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;

export type ElencoNuoveAssunzioniDtoCodiceClasseConcorsoEnum = typeof ElencoNuoveAssunzioniDtoCodiceClasseConcorsoEnum[keyof typeof ElencoNuoveAssunzioniDtoCodiceClasseConcorsoEnum];


