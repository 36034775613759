import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { detailsStruttureOrganizzative, resetUpdateForm, updateStruttureOrganizzative } from '../../store/strutturaOrganizzativaSlice';
import { useTranslation } from 'react-i18next';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import { z } from 'zod'
import { RuoloUtenteAutenticatoDto, SOUpdateRequestDto } from '../../services/ms-anagrafica-unica';
import { toFormikValidationSchema } from "zod-formik-adapter";
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail/StruttureOrganizzativeDetails';
import { CODICE, DATA_INIZIO_VALIDITA, DENOMINAZIONE, DIPARTIMENTO, TIPOLOGIA_STRUTTURA } from './struttureOrganizzativeConstants';
import PageHeader from '../../components/common/page-header/PageHeader';


const schema = z
  .object({
    declaratoria: z.string().max(5000, 'lenghtdeclaratoria').optional(),
    email: z.string().regex(new RegExp(/^([a-zA-Z0-9-._]+)(@[a-z]+).[a-z]{2,3}$/), 'emailNonCorretta').trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec: z.string().regex(new RegExp(/\b[A-Za-z0-9-._]+@pec.+/), 'pecNonCorretta').trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    telefono: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    fax: z.string().regex(new RegExp(/^\d+$/), 'numberFax').trim().min(1).max(20, 'lenghtTelefono').optional(),
    indirizzo: z.string().trim().min(1).max(200, 'lenghtIndirizzo').optional(),
    citta: z.string().trim().min(1).max(100, 'lenghtCitta').optional(),
  }
  )

const initialValues: SOUpdateRequestDto = {
  codiceInca: undefined,
  denominazione: undefined,
  declaratoria: undefined,
  dataInizioValidita: undefined,
  tipo: undefined,
  dipartimento: undefined,
  email: undefined,
  pec: undefined,
  telefono: undefined,
  fax: undefined,
  indirizzo: undefined,
  citta: undefined,
  idFile: undefined,
  stato: undefined,
  requestType: 'UpdateEntity'
}

const ModificaStruttureOrganizzative = (props: {operations: RuoloUtenteAutenticatoDto}) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetUpdateForm())
    getDetailsFromApi(id!)
  }, [0])

  const strutturaOrganizzativa = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult)
  const updateResult = useAppSelector((state) => state.strutturaOrganizzativa.updateResult)
  const strutturaOrganizzativaResult = useAppSelector((state) => state.strutturaOrganizzativa.detailsStatus)
  const [idFileUpload, setIdFileUpload] = useState<number>()
  const fieldUsed: Array<string> = [CODICE, DENOMINAZIONE, DATA_INIZIO_VALIDITA, TIPOLOGIA_STRUTTURA, DIPARTIMENTO];

  const showModificaOperations = () => 
    (props.operations?.elencoFunzionalita?.includes('SO_MOD_DIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_MOD_SEZ')
    || props.operations?.elencoFunzionalita?.includes('SO_MOD_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_DISA_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_DISA_DIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_DISA_SEZ')
    || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODCIFRA')
    || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODBILA')
    || props.operations?.elencoFunzionalita?.includes('SO_INOLTRA')
    || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_DIP')
    || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SEZ')
    || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_DIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SEZ')
  )

  const handleIdChange = (id: number | undefined) => {
    if (id && id !== -1) {
      updateForm.setFieldValue('idFile', id);
      setIdFileUpload(id)
    } else {
      updateForm.setFieldValue('idFile', undefined);
      setIdFileUpload(undefined)
    }
  }

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  }

  const updateForm: FormikProps<SOUpdateRequestDto> = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: values => {
      doUpdate();
    },
  });

  useEffect(() => {
    updateForm.setValues({
      codiceInca: strutturaOrganizzativa?.id || '',
      denominazione: strutturaOrganizzativa?.descrizioneBreve || '',
      declaratoria: strutturaOrganizzativa?.descrizione || '',
      dataInizioValidita: strutturaOrganizzativa?.dataInizioValidita || '',
      tipo: strutturaOrganizzativa?.tipo || undefined,
      dipartimento: strutturaOrganizzativa?.codiceDipartimentoRagioneria || undefined,
      email: strutturaOrganizzativa?.email || '',
      pec: strutturaOrganizzativa?.pec || '',
      telefono: strutturaOrganizzativa?.telefono || '',
      fax: strutturaOrganizzativa?.fax || '',
      indirizzo: strutturaOrganizzativa?.indirizzo || '',
      citta: strutturaOrganizzativa?.citta || '',
      stato: 'SAVE',
      requestType: 'UpdateEntity'
    })
  }, [strutturaOrganizzativa])


  useEffect(() => {
    getDetailsFromApi(updateResult?.id!)
    setIdFileUpload(undefined)
  }, [updateResult])

  const doUpdate = () => {
    const body = {
      idStrutturaOrganizzativa: strutturaOrganizzativa?.id,
      bodyRequest: updateForm.values
    }
    dispatch(updateStruttureOrganizzative(body))
  }

  const getFieldError = (
    form: FormikProps<SOUpdateRequestDto>,
    fieldName: keyof SOUpdateRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || "";
    } else return "";
  };


  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} state={{form: true}} title={t("struttura-organizzativa.modifica-so")!}></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED &&
        <div className="postion-relative pb-5">
          <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
          <div className="mt-3 form-custom form-inserimento p-4">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <h6>{t("struttura-organizzativa.documento-da-allegare")}</h6>
                <DocumentUpload setDocumentId={handleIdChange} documentName="" className="my-3" />
              </div>
              <TextArea
                label="Declaratoria"
                placeholder="Inserisci la declaratoria"
                id="input-declaratoria"
                wrapperClassName="required col-md-12"
                name="declaratoria"
                value={updateForm.values.declaratoria}
                onChange={updateForm.handleChange}
                invalid={!!getFieldError(updateForm, "declaratoria")}
                infoText={t(getFieldError(updateForm, "declaratoria")) || ""}
                disabled={idFileUpload === undefined ? true : false}
              />
              <Input type='email'
                label='Email'
                placeholder="Inserisci email"
                id='input-email'
                name="email"
                invalid={!!getFieldError(updateForm, "email")}
                infoText={t(getFieldError(updateForm, "email")) || ""}
                value={updateForm.values.email}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />
              <Input type='email'
                label='Pec'
                placeholder="Inserisci Pec"
                id='input-pec'
                name="pec"
                invalid={!!getFieldError(updateForm, "pec")}
                infoText={t(getFieldError(updateForm, "pec")) || ""}
                value={updateForm.values.pec}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />
              <Input type='tel'
                label='Telefono'
                placeholder="Inserisci Telefono"
                id='input-telefono'
                name="telefono"
                invalid={!!getFieldError(updateForm, "telefono")}
                infoText={t(getFieldError(updateForm, "telefono")) || ""}
                value={updateForm.values.telefono}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />
              <Input type='tel'
                label='Fax'
                placeholder="Inserisci Fax"
                id='input-fax'
                name="fax"
                invalid={!!getFieldError(updateForm, "fax")}
                infoText={t(getFieldError(updateForm, "fax")) || ""}
                value={updateForm.values.fax}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />
              <Input type='text'
                label='Indirizzo'
                placeholder="Inserisci Indirizzo"
                id='input-indirizzo'
                name="indirizzo"
                invalid={!!getFieldError(updateForm, "indirizzo")}
                infoText={t(getFieldError(updateForm, "indirizzo")) || ""}
                value={updateForm.values.indirizzo}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />
              <Input type='text'
                label='Città'
                placeholder="Inserisci Città"
                id='input-citta'
                name="citta"
                invalid={!!getFieldError(updateForm, "citta")}
                infoText={t(getFieldError(updateForm, "citta")) || ""}
                value={updateForm.values.citta}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />

            </div>

          </div>
          <div className="d-flex flex-row-reverse">
            <Button outline color='primary' className="m-2" disabled={!showModificaOperations()} onClick={() => { showModificaOperations() && updateForm.submitForm(); }}>{t('salva')}</Button>
            <Button outline color='secondary' className="m-2" onClick={() => navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`, {state:{form: true}})}>{t('annulla')}</Button>
          </div>
        </div>
      }
    </div>
  )
}

export default ModificaStruttureOrganizzative