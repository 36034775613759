/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PosizioneOrganizzativaDto
 */
export interface PosizioneOrganizzativaDto {
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'codiceUnivoco'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'descrizione'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'denominazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'strutturaOrganizzativa'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dataInizio'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dataFine'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'tipo'?: PosizioneOrganizzativaDtoTipoEnum;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'tipoLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'stato'?: PosizioneOrganizzativaDtoStatoEnum;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dipendenteRegionale'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'idCidDipendente'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dataFineAssociazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dataInizioAssociazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'estremiAttoConferimento'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'estremiAttoDisassociazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'tipoIncarico'?: PosizioneOrganizzativaDtoTipoIncaricoEnum;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'tipoIncaricoLabel'?: string;
    /**
     * 
     * @type {number}
     * @memberof PosizioneOrganizzativaDto
     */
    'idNotaPropostaIstituzione'?: number;
    /**
     * 
     * @type {number}
     * @memberof PosizioneOrganizzativaDto
     */
    'idNotaDiUscita'?: number;
    /**
     * 
     * @type {number}
     * @memberof PosizioneOrganizzativaDto
     */
    'idNullaOsta'?: number;
    /**
     * 
     * @type {number}
     * @memberof PosizioneOrganizzativaDto
     */
    'idDeterminaIstituzioneAvviso'?: number;
    /**
     * 
     * @type {number}
     * @memberof PosizioneOrganizzativaDto
     */
    'idDeterminaDiNomina'?: number;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'decodificaCodiciTipoIncarico'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'declaratoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'nomeStrutturaOrganizzativa'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dirigenteStrutturaOrganizzativa'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dataInserimento'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dataModifica'?: string;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'dataCancellazione'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'equiparata'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PosizioneOrganizzativaDto
     */
    'declaratoriaFunzioni'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'nullaOstaScaricabile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'uploadDeterminaIstituzioneAvvisoPossibile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'protocollabile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'disattivabile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'caricamentoDeterminaDiNominaPossibile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'associabile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PosizioneOrganizzativaDto
     */
    'disassociabile'?: boolean;
}

export const PosizioneOrganizzativaDtoTipoEnum = {
    Po: 'PO',
    PoEquiparata: 'PO_EQUIPARATA'
} as const;

export type PosizioneOrganizzativaDtoTipoEnum = typeof PosizioneOrganizzativaDtoTipoEnum[keyof typeof PosizioneOrganizzativaDtoTipoEnum];
export const PosizioneOrganizzativaDtoStatoEnum = {
    Bozza: 'BOZZA',
    Inserita: 'INSERITA',
    InseritaEComunicata: 'INSERITA_E_COMUNICATA',
    Creata: 'CREATA',
    Associabile: 'ASSOCIABILE',
    Associata: 'ASSOCIATA',
    Disattivata: 'DISATTIVATA'
} as const;

export type PosizioneOrganizzativaDtoStatoEnum = typeof PosizioneOrganizzativaDtoStatoEnum[keyof typeof PosizioneOrganizzativaDtoStatoEnum];
export const PosizioneOrganizzativaDtoTipoIncaricoEnum = {
    P: 'P',
    I: 'I',
    V: 'V'
} as const;

export type PosizioneOrganizzativaDtoTipoIncaricoEnum = typeof PosizioneOrganizzativaDtoTipoIncaricoEnum[keyof typeof PosizioneOrganizzativaDtoTipoIncaricoEnum];


