import { Card, CardBody, Icon, CardText, CardTitle, Col, Row } from "design-react-kit";
import format from "date-fns/format";
import { ModalType, ScrivaniaVirtualeModale } from "../scrivania-virtuale-modal/ScrivaniaVirtualeModal";
import { useState } from "react";
import { parseISO } from "date-fns";
import { NotificaDtoStatoEnum, PagedDtoNotificaDto } from "../../services/ms-anagrafica-unica";
import { BsEnvelopeOpen} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { resetInputForm } from "../../store/notificheSlice";
import { HREF_FEEDBACK_NOTIFICA } from "../layout/sidemenu/sidemenuConstants";
import { downloadDocument } from "../../store/DocumentiSlice";
import { dettaglioDocumento } from "../../store/DocumentiSlice";
import letterDot from 'assets/icons/letter-dot.svg'
import { LeggiTutto } from "components/common/leggi-tutto/LeggiTutto";

export interface NotificationTableComponentProps {
    searchResult: PagedDtoNotificaDto
    showAggiornaStatoNotifica: boolean,
    showEliminaNotifica: boolean,
    showElencoFeedback: boolean,
    showFornisciFeedback: boolean,
}

const NotificationCardsComponent = ({ searchResult, showAggiornaStatoNotifica, showEliminaNotifica, showElencoFeedback, showFornisciFeedback }: NotificationTableComponentProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [id, setId] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [body, setBody] = useState<string>();
    const [actionType, setActionType] = useState<ModalType>();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const inoltra = (id?: number, actionType?: ModalType) => {
        setId(String(id));
        setIsOpen(true);
        switch (actionType) {
            case ModalType.SegnaDaLeggere:
                setTitle('Conferma Segna da leggere');
                setBody('Sei sicuro di voler cambiare lo stato della notifica in: Segna da leggere?');
                setActionType(actionType)

                break;
            case ModalType.SegnaComeLetto:
                setTitle('Conferma Segna come letto');
                setBody('Sei sicuro di voler cambiare lo stato della notifica in: Segna come letto?');
                setActionType(actionType)
                //dispatch(searchNotifiche({}));
                break;
            case ModalType.Archivia:
                setTitle('Conferma archiviazione');
                setBody('Sei sicuro di voler archiviare la notifica?');
                setActionType(actionType)
                break;
            case ModalType.Elimina:
                setTitle('Conferma eliminazione');
                setBody('Sei sicuro di voler eliminare la notifica?');
                setActionType(actionType)
                break;
            default:
                break;
        }

    }

    const resetModalProps = () => {
        setId('');
        setIsOpen(false);
        setTitle('');
        setBody('');
        setActionType(undefined);
    }

    const returnIconBusta = (id?: number, stato?: NotificaDtoStatoEnum) => {
        switch (stato) {
            case NotificaDtoStatoEnum.NonLetta:
                return (
                    <div className="item-icon-menu" onClick={() => inoltra(id!, ModalType.SegnaComeLetto)} title='Segna come letto'>
                        <div className="busta"><img src={letterDot} alt="" width={26}/></div>
                    </div>
                )
            case NotificaDtoStatoEnum.Letta:
                return (
                    <div className="item-icon-menu" onClick={() => inoltra(id!, ModalType.SegnaDaLeggere)} title='Segna da leggere'>
                       <div className="busta"><BsEnvelopeOpen title={"Notifica letta"} /></div>
                    </div>
                )
            case NotificaDtoStatoEnum.Archiviata:
                break;
            default:
                break;
        }
    }

    const vediFile = (id: number) => {
        dispatch(dettaglioDocumento(id))
            .unwrap()
            .then((doc) => {
                doc && doc.id && dispatch(downloadDocument({
                    idDocumento: doc.id
                })).unwrap().then(resp => {
                    if (!!resp) {
                        const blob = new Blob([resp], { type: doc.contentType })
                        const link = document.createElement('a');
                        let url = URL.createObjectURL(blob);
                        link.href = url;
                        link.download = doc.filename || '';
                        link.click()
                    }
                });
        })
      }

    return (
        <div className="card-custom">

            <Row >
                {(searchResult.data || []).map((x, i) =>
                    <Col key={x?.id} lg="6" className="p-2">
                        <Card className="item-card card-notifica card-bg card-big border-bottom-card" spacing >
                            <div className="card-header d-flex justify-align-content-between">
                                <div className="icon-menu">
                                    {(showAggiornaStatoNotifica || showEliminaNotifica || showFornisciFeedback || showElencoFeedback) &&
                                        <>
                                            {showAggiornaStatoNotifica && <>
                                                {returnIconBusta(x?.id, x.stato)}
                                                {x.stato != null && x.stato === NotificaDtoStatoEnum.Archiviata ?
                                                        ''
                                                        :
                                                        <div className="item-icon-menu" onClick={() => inoltra(x.id!, ModalType.Archivia)} title='Archivia'>
                                                            <Icon color="primary" icon="it-box" />
                                                        </div>
                                                    }
                                                </>}

                                            {showEliminaNotifica && 
                                                <div className="item-icon-menu" onClick={() => inoltra(x.id!, ModalType.Elimina)} title='Elimina'>
                                                    <Icon color="primary" icon="it-delete" />
                                                </div>}

                                            {showFornisciFeedback && x.feedbackInseribile &&(
                                                <div className="item-icon-menu" onClick={() => {
                                                        dispatch(resetInputForm());
                                                        navigate(`${HREF_FEEDBACK_NOTIFICA}/${x.id}`)
                                                    }} title='Fornisci feedback'>
                                                    <Icon color="primary" icon="it-horn" />
                                                </div>
                                            )}
                                            {showElencoFeedback && x.ultimoFeedback && (
                                                <div className="item-icon-menu" onClick={() => {
                                                    setTitle('Testo feedback');
                                                    setBody(x.ultimoFeedback?.testo);
                                                    setActionType(undefined);
                                                    setIsOpen(true);
                                                }} title='Visualizza feedback'>
                                                <Icon color="primary" icon="it-horn" />
                                                </div>
                                            )}
                                        </>
                                    }</div>

                                <div className="etichetta">
                                    <Icon icon="it-calendar" />
                                    <span>
                                        {x.dataInserimento != null ? format(parseISO(x.dataInserimento!), 'dd/MM/yyyy HH:mm') : ''}
                                    </span>
                                </div>
                            </div>
                            <CardBody>
                                <CardTitle tag="h5">
                                    {x?.oggetto}
                                </CardTitle>
                                <CardText>
                                    <LeggiTutto key={x?.id} id={'leggi-tutto-' + x.id} testo={x?.testo!}></LeggiTutto>
                                </CardText>

                                {x?.ultimoFeedback != null && (
                                    <Row className="card-feedback col-12">
                                        <div className="col-6 d-flex flex-column">
                                            <span>Feedback da:</span> 
                                            <span>{x?.ultimoFeedback != null ? x.ultimoFeedback.fattoDa : '-'}</span> 
                                        </div>
                                        <div className="col-6 d-flex flex-column">
                                            <span>Data e ora:</span> 
                                            <span>{x?.ultimoFeedback != null ? format(parseISO(x.ultimoFeedback?.dataInserimento!), 'dd/MM/yyyy HH:mm') : ''}</span> 
                                        </div>
                                    </Row>
                                )}
                                
                                <div className="card-footer">
                                    <div className="d-flex flex-wrap">
                                        {x.allegati && x.allegati.length > 0 && (
                                            <>
                                            {x.allegati.map( (a, i) => 
                                                <div className="item-allegato" onClick={() => vediFile(a.documentoDto?.id!)} title={a.documentoDto?.filename!}>
                                                    <Icon color="primary" icon="it-clip"/>
                                                    <span>{a.documentoDto?.filename! && a.documentoDto?.filename.length > 18 ? (a.documentoDto?.filename.substring(0,25) + '...' ): a.documentoDto?.filename} </span>
                                                </div>
                                            )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>

            <ScrivaniaVirtualeModale open={isOpen} resetModalProps={resetModalProps} id={id} title={title} body={body} action={actionType} />
        </div>)
}

export default NotificationCardsComponent;