import { useTranslation } from "react-i18next";
import PageHeader from "../../../components/common/page-header/PageHeader";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { aggiornaBando, elencoPoPerBando, getCreaBandoContestoForm, resetElencoPoPerBando, riepilogoBandoCompleto } from "../../../store/bandoSlice";
import { AssegnazioneBandoPoRequestDto, BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest, CreaAggiornaBandoPoRequestDto, CreaAggiornaBandoPoRequestDtoRequestTypeEnum, OptionDtoInteger, OptionDtoString, OptionDtoTipoPosizioneOrganizzativa, OptionDtoTipologiaBandoPo, PosizioneOrganizzativaDto, PosizioneOrganizzativaDtoTipoEnum } from "../../../services/ms-anagrafica-unica";
import TableResult from "../../../components/bandi/NuovoBando/TableResult";
import { STATUS_FULLFILLED } from "../../../store/store-constants";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "design-react-kit";
import PosizioniOrganizzativeForm from "../../../components/bandi/PosizioniOrganizzativeForm";
import { creaSelectOptions, creaSelectOptionsWithOther } from "../../../utility/formUtils";
import TableElencoEQABando from "../../../components/bandi/NuovoBando/TableElencoEQABando";
import PosizioniOrganizzativeDetail from "../../../components/bando-eq-detail-form/PosizioniOrganizzativeDetail";
import FormDatiEQ from "../../../components/bandi/NuovoBando/FormDatiEQ";
import { HREF_ELENCO_BANDI } from "../../../components/layout/sidemenu/sidemenuConstants";
import { useNavigate, useParams } from "react-router-dom";

const ModificaBando = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { idBando } = useParams();
  const { contestoResult, contestoStatus, elencoPoPerBandoResult, elencoPoPerBandoInput } = useAppSelector((state) => state.bando);

  const [idSo, setIdSo] = useState<string | undefined>(undefined);
  const [tipo, setTipo] = useState<PosizioneOrganizzativaDtoTipoEnum | undefined>(undefined);
  const [tipologiaBando, setTipologiaBando] = useState<OptionDtoTipologiaBandoPo[]>([]);
  const [durateIncarico, setDurateIncarico] = useState<OptionDtoInteger[]>([]);
  const [EQinseriteABando, setEQinseriteABando] = useState<Array<AssegnazioneBandoPoRequestDto>>([]);
  const [EQDaInserireABando, setEQDaInserireABando] = useState<PosizioneOrganizzativaDto | undefined>(undefined);
  const [EQDettaglioInseriteABando, setEQDettaglioInseriteABando] = useState<Array<PosizioneOrganizzativaDto>>([]);
  const [cifra, setCifra] = useState<string | undefined>(undefined)
  const [dataApertura, setDataApertura] = useState<string | undefined>(undefined)
  const [dataChiusura, setDataChiusura] = useState<string | undefined>(undefined)
  const [fase, setFase] = useState<string | undefined>(undefined)
  const [riepilogoEQ, setRiepilogoEQ] = useState<PosizioneOrganizzativaDto | undefined>(undefined);
  const [riepilogoAssegnazione, setRiepilogoAssegnazione] = useState<AssegnazioneBandoPoRequestDto | undefined>(undefined);
  const [isModalOpenAggiungiEQABando, setModalOpenAggiungiEQABando] = useState<boolean>(false);
  const [isModalOpenDettaglioEQABando, setModalOpenDettaglioEQABando] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCreaBandoContestoForm()).unwrap().then(resp => {
      if (resp) {
        resp.durataIncarico && setDurateIncarico(resp.durataIncarico);
        resp.tipologiaBandoPo && setTipologiaBando(resp.tipologiaBandoPo);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(resetElencoPoPerBando());
    if (!!idBando) {
      dispatch(riepilogoBandoCompleto({
        id: idBando
      })).unwrap().then(resp => {
        if (!!resp) {
          var tipoEQ = resp.elencoAssegnazioniPo?.[0]?.posizioneOrganizzativa?.tipo;
          setIdSo(resp.idStrutturaOrganizzativa)
          setTipo(tipoEQ)
          setCifra(resp.codiceCifraDeliberaDetermina!)
          setDataApertura(resp.dataApertura!)
          setDataChiusura(resp.dataChiusura!)
          setFase(resp.fase)
          dispatch(elencoPoPerBando({
            idSo: resp.idStrutturaOrganizzativa!,
            tipoPosizioneOrganizzativa: tipoEQ
          })); 
          setEQinseriteABando([]);
          setEQDettaglioInseriteABando([]);
          resp.elencoAssegnazioniPo && resp.elencoAssegnazioniPo.forEach(assegnazione => {
            setEQinseriteABando(prev => ([
              ...prev,
              {
                durataIncarico: assegnazione.durataIncarico,
                idPosizioneOrganizzativa: assegnazione.posizioneOrganizzativa?.codiceUnivoco,
                requisitiOggettivi: assegnazione.requisitiOggettivi,
                requisitiSoggettivi: assegnazione.requisitiSoggettivi,
                sedi: assegnazione.sedi,
                tipo: assegnazione.tipologiaBandoPo,
                progetto: assegnazione.progetto
              }
            ]))
            setEQDettaglioInseriteABando(prev => ([
              ...prev,
              assegnazione.posizioneOrganizzativa!
            ]))
          })
        }
      })
    }
  }, [idBando])

  const submitRicercaEqPerStrutturaOrganizzativa = (parametriRicerca: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest) => {
    const params = {
      ...elencoPoPerBandoInput,
      ...parametriRicerca,
      idSo: idSo!
    }
    dispatch(elencoPoPerBando(params));
  }

  const inserisciDettagliEq = (eq: PosizioneOrganizzativaDto) => {
    setEQDaInserireABando(eq)
    setModalOpenAggiungiEQABando(true);
  }

  const inserisciABando = (assegnazione: AssegnazioneBandoPoRequestDto) => {
    setModalOpenAggiungiEQABando(false);
    const EQinseriteABandoAggiornate = [
      ...EQinseriteABando,
      assegnazione
    ]
    setEQinseriteABando(EQinseriteABandoAggiornate)
    setEQDettaglioInseriteABando((prevEQDettaglioInseriteABando) => {
      return [
        ...prevEQDettaglioInseriteABando,
        EQDaInserireABando!
      ];
    });
    setEQDaInserireABando(undefined)
  }

  const apriVisualizzaDettaglioEQABando = (idEQ: string) => {
    const eq = EQDettaglioInseriteABando.filter(eq => eq.codiceUnivoco === idEQ).at(0)
    const assegnazione = EQinseriteABando.filter(eq => eq.idPosizioneOrganizzativa === idEQ).at(0)
    if (!!eq) {
      setRiepilogoEQ(eq);
      setRiepilogoAssegnazione(assegnazione);
      setModalOpenDettaglioEQABando(true);
    }
  }

  const chiudiVisualizzaDettaglioEQABando = () => {
    setRiepilogoEQ(undefined);
    setRiepilogoAssegnazione(undefined);
    setModalOpenDettaglioEQABando(false)
  }

  const rimuoviEQDaBando = (idEQ: string) => {
    setEQinseriteABando((prevEQinseriteABando) => {
      const EQinseriteABandoAggiornate = prevEQinseriteABando.filter(prevEq => prevEq.idPosizioneOrganizzativa !== idEQ)
      return EQinseriteABandoAggiornate
    })
    setEQDettaglioInseriteABando((prevEQDettaglioInseriteABando) => {
      const EQDaInserireABandoAggiornate = prevEQDettaglioInseriteABando.filter(prevEq => prevEq.codiceUnivoco !== idEQ)
      return EQDaInserireABandoAggiornate;
    });
    setEQDaInserireABando(undefined)
  }

  const modificaBando = (values: CreaAggiornaBandoPoRequestDto) => {
    dispatch(aggiornaBando({
      id: idBando!,
      creaAggiornaBandoPoRequestDto: values
    })).unwrap().then(rest => {
      if(values.requestType === CreaAggiornaBandoPoRequestDtoRequestTypeEnum.Conferma)
        navigate(HREF_ELENCO_BANDI)
      }
    )
  }

  const annullaInserimentoEQ = () => {
    setModalOpenAggiungiEQABando(false)
}

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_BANDI} title={t("Modifica Bando " + idBando) || ''} />
      {contestoStatus === STATUS_FULLFILLED && (
        <dl className="au-callout row mb-5 bg-light-gray">
          <dt className="col-md-2">Struttura organizzativa</dt>
          <dd className="col-md-10">{contestoResult?.struttureOrganizzative?.filter(so => so.valore === idSo).at(0)?.label}</dd>
          <dt className="col-md-2">Tipologia</dt>
          <dd className="col-md-10">{contestoResult?.tipi?.filter(so => so.valore === tipo).at(0)?.label}</dd>
        </dl>
      )}
      {elencoPoPerBandoResult && (
        <div>
          <h4>Elenco EQ inseribili a bando</h4>
          
            <TableResult
              ricercaEqStrutturaOrganizzativa={submitRicercaEqPerStrutturaOrganizzativa}
              content={elencoPoPerBandoResult}
              selezioneEqPerInserimento={inserisciDettagliEq}
              EQInseriteABando={EQinseriteABando}
              idSo={idSo}
              sort={elencoPoPerBandoInput?.sort}
              direction={elencoPoPerBandoInput?.direction}
            ></TableResult>
        </div>
      )}

      {EQinseriteABando.length > 0 && (
          <>
            <h4>Elenco EQ inserite a bando</h4>
            <TableElencoEQABando
              content={EQinseriteABando}
              EQInseriteABando={EQDettaglioInseriteABando}
              apriVisualizzaDettaglioEQABando={apriVisualizzaDettaglioEQABando}
              rimuoviEQDaBando={rimuoviEQDaBando}
            ></TableElencoEQABando>
        </>
      )}
        
      {riepilogoAssegnazione && riepilogoEQ && (
        <Modal
          isOpen={isModalOpenDettaglioEQABando}
          toggle={() => setModalOpenDettaglioEQABando(!isModalOpenDettaglioEQABando)}
          modalClassName="modal-custom"
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader />
          <ModalBody>
            <PosizioniOrganizzativeDetail
              assegnazione={riepilogoAssegnazione!}
              eq={riepilogoEQ!}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color='secondary'
              onClick={() => chiudiVisualizzaDettaglioEQABando()}
            >
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {EQDaInserireABando && (
        <Modal
            isOpen={isModalOpenAggiungiEQABando}
            toggle={() => { setModalOpenAggiungiEQABando(!isModalOpenAggiungiEQABando) }}
            modalClassName="modal-custom "
            size="lg"
          >
            <ModalHeader />
            <ModalBody>
              <PosizioniOrganizzativeForm
                po={EQDaInserireABando!}
                tipologieBandoOptions={creaSelectOptions(tipologiaBando)}
                durataIncaricoOptions={creaSelectOptionsWithOther(durateIncarico)}
                btnConferma={inserisciABando}
                btnAnnulla={annullaInserimentoEQ}
              />
            </ModalBody>
        </Modal>
        )}
        
      {!!idSo && <div className="mt-5">
        <h4>Si prega di compilare i seguenti campi</h4>
        <FormDatiEQ
          idSo={idSo!}
          elencoPo={EQinseriteABando}
          salvaDatiForm={modificaBando}
          cifra={cifra}
          dataApertura={dataApertura}
          dataChiusura={dataChiusura}
          fase={fase}
          nuovoBando={false}
        ></FormDatiEQ>
      </div>}
    </div>
  )
}

export default ModificaBando;