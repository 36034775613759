import { useEffect, useRef, useState } from 'react'
import InformazioniPersonaliTab from './gestione-cv-pages/InformazioniPersonaliTab'
import EspProfessionaliTab from './gestione-cv-pages/EsperienzeProfessionaliTab'
import IstruzioneFormazioneTab from './gestione-cv-pages/IstruzioneFormazioneTab'
import CapacitaCompetenzaTab from './gestione-cv-pages/CapacitaCompetenzaTab'
import PrivacyTab from './gestione-cv-pages/PrivacyTab'
import { useAppSelector } from '../../hooks'
import { formatDate } from '../../utility/formatDate'
import EspProfessionaliInRegioneTab from './gestione-cv-pages/EsperienzeProfessionaliInRegioneTab'
import './style.scss'
import { SteppersCustom, Stepper } from 'components/common/custom-steppers/SteppersCustom'
import { Button } from 'design-react-kit'
import { t } from 'i18next'
import { Tab, TabList, Tabs } from 'react-tabs'

export interface GestioneCvDto {
    id?: number,
    nome?: string,
    cognome?: string
}

export interface GestioneCvFormProps {
    disabled: boolean
    scrollAction: Function,
}

export type GestioneCvPageRef = {
    submitForm: () => void;
}

export const GestioneCvForm = (props: GestioneCvFormProps) => {
    const[currentTab,setCurrentTab] = useState(0);
    const { detailResult } = useAppSelector((state) => state.curriculumVitae);
    const [disabilitaContinuaBtb, setDisabilitaContinuaBtn] = useState(false);
    const [pubblicato, setPubblicato] = useState<boolean>(false);

    const refStepper = useRef<GestioneCvPageRef>(null);

    useEffect(() => {
        props.scrollAction();
    }, [currentTab]);

    const submitFormTab = () => {
        refStepper.current?.submitForm();
    }

    const incrementCurrentTab = () => {
        setCurrentTab(currentTab + 1);
    }

    const decrementCurrentTab = () => {
        setCurrentTab(currentTab - 1);
        setDisabilitaContinuaBtn(false);
    }

    return (
        <div className='gestione-cv'>
            <div className='content-page-cv'>
                {detailResult && (
                    <div className="mb-3 row border-bottom">
                        {detailResult.dataInserimento && detailResult.stato !== 'PUBBLICATO' &&
                            <dl className="col-12 col-xl-3">
                                <dt>Data inserimento</dt>
                                <dd>{formatDate(detailResult?.dataInserimento)}</dd>
                            </dl>}
                        {detailResult.dataPubblicazione && 
                            <dl className="col-12 col-xl-3">
                                <dt>Data pubblicazione</dt>
                                <dd>{formatDate(detailResult?.dataPubblicazione)}</dd>
                            </dl>}
                        {detailResult.dataModifica &&
                            <dl className="col-12 col-xl-3">
                                <dt>Data ultima modifica</dt>
                                <dd>{formatDate(detailResult?.dataModifica)}</dd>
                            </dl>}
                    </div>
                )}

                <SteppersCustom currentTab={currentTab}>
                    <Stepper label="01. Introduzione">
                        <InformazioniPersonaliTab 
                            disabled={props.disabled} 
                            continuaAction={() => {setCurrentTab(1)}} 
                            ref={refStepper} />
                    </Stepper>
                    <Stepper label="02. Esperienze professionali in Regione Puglia">
                        <EspProfessionaliInRegioneTab 
                            disabled={props.disabled} 
                            scrollAction={() => props.scrollAction()} 
                            continuaAction={incrementCurrentTab} 
                            disabilitaContinuaBtn={setDisabilitaContinuaBtn}
                            ref={refStepper} />
                    </Stepper>
                    <Stepper label="03. Esperienze professionali">
                        <EspProfessionaliTab 
                            disabled={props.disabled} 
                            scrollAction={() => props.scrollAction()} 
                            continuaAction={incrementCurrentTab} 
                            disabilitaContinuaBtn={setDisabilitaContinuaBtn}
                            ref={refStepper} />
                    </Stepper>
                    <Stepper label="04. Istruzione e formazione">
                        <IstruzioneFormazioneTab 
                            disabled={props.disabled} 
                            scrollAction={() => props.scrollAction()} 
                            continuaAction={incrementCurrentTab} 
                            disabilitaContinuaBtn={setDisabilitaContinuaBtn}
                            ref={refStepper}/>
                    </Stepper>
                    <Stepper label="05. Capacità e competenze personali">
                        <CapacitaCompetenzaTab 
                            disabled={props.disabled} scrollAction={() => props.scrollAction()} 
                            continuaAction={incrementCurrentTab} 
                            disabilitaContinuaBtn={setDisabilitaContinuaBtn}
                            ref={refStepper} />
                    </Stepper>
                    <Stepper label="06. Privacy e autocertificazione">
                        <PrivacyTab 
                            disabled={props.disabled} 
                            scrollAction={() => props.scrollAction()} 
                            continuaAction={() => setCurrentTab(0)}
                            disabilitaContinuaBtn={setDisabilitaContinuaBtn}
                            setPubblicato={setPubblicato}
                            ref={refStepper} />
                    </Stepper>
                </SteppersCustom>

            </div>

            <Tabs onSelect={(e) => console.log(e)} selectedIndex={currentTab}>
                <TabList>
                    <Tab tabIndex="0" disabled={currentTab !== 0}>{"Introduzione"}</Tab>
                    <Tab tabIndex="1" disabled={currentTab !== 1}>{"Esperienze professionali in ARPAL Puglia"}</Tab>
                    <Tab tabIndex="2" disabled={currentTab !== 2}>{"Esperienze professionali"}</Tab>
                    <Tab tabIndex="3" disabled={currentTab !== 3}>{"Istruzione e formazione"}</Tab>
                    <Tab tabIndex="4" disabled={currentTab !== 4}>{"Capacità e competenze personali"}</Tab>
                    {/*<Tab tabIndex="4" disabled={currentTab !== 4}>{"Allegati"}</Tab>*/}
                    <Tab tabIndex="5" disabled={currentTab !== 5}>{"Privacy e autocertificazione"}</Tab>
                </TabList>
            </Tabs>
            <div className="d-flex justify-content-end mt-3">
                <Button color="primary" outline className="mr-2" onClick={decrementCurrentTab}>
                    {t('indietro')}
                </Button>
                <Button color="primary" disabled={disabilitaContinuaBtb} onClick={() => submitFormTab()} >
                   { currentTab < 5 ? t('continua') : !pubblicato ? 'Inserisci CV' : t('conferma')}
                </Button>
            </div>

        </div>
    );
}

