import { getUnixTime } from "date-fns";
import { JWTModel } from "../components/auth/jwtModel";
import jwt_decode from "jwt-decode";
import { decodeJwt, jwtVerify, } from 'jose';
import { JWTVerifyOptions } from "jose/dist/types/jwt/verify";

export const getCookie = (cname: string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c !== name && c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function deleteJwtToken() {
    const jwtToken = getLocalStorageKey('au-user-token');
    const jsessionid = getCookie("JSESSIONID");
    const xsrfToken = getCookie("XSRF-TOKEN") ;
    if (!!jwtToken) {
        localStorage.removeItem('au-user-token');
    }
    if (!!jsessionid) {
        document.cookie = "JSESSIONID=";
    }
    if(!!xsrfToken){
        document.cookie = "XSRF-TOKEN=";
    }
}

export function getUserFromCookieAuUserToken(): JWTModel | null | undefined {
    const cookie = getLocalStorageKey('au-user-token');
    
    if (!!cookie) {
        const decoded: JWTModel = jwt_decode(cookie);
     //   verifyToken( decoded)
        
        const result = getUnixTime(new Date())
        if (decoded.exp) {
            if (Number(decoded.exp) < Number(result)) {
                deleteJwtToken();
                return null;
            } else {    
                return decoded;
            }
        } 
    }
}
//metodo di test per verificare il token jwt lato FE
const getPaddedSecret = (secret: Uint8Array, length: number): Uint8Array => {
    if (secret.length < length) {
        const paddedSecret = new Uint8Array(length);
        paddedSecret.set(secret);
        return paddedSecret;
    }
    return secret;
};

const verifyToken = async (token: any) => {

    const secret = 'AnagUnic2023JWT'; 
    const secretUint8Array = new TextEncoder().encode(secret);
    const paddedSecret = getPaddedSecret(secretUint8Array, 128); 

    try {
    

        console.log(token);
        const { payload, protectedHeader  } = await jwtVerify(token, paddedSecret, {
            algorithms: ['HS256']
        });
        console.log('Token verificato:', payload, protectedHeader);
    } catch (err) {
        console.error('Token non valido:', err);
    }
};




export function saveTokenFromUri() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('au-user-token');
    if(token) {
        localStorage.setItem("au-user-token", JSON.stringify(token));
        window.history.pushState({}, document.title, window.location.pathname);
    }
    return token;
}

export function saveTokenFromInput(token: string) {
    localStorage.setItem("au-user-token", JSON.stringify(token));
    window.history.pushState({}, document.title, window.location.pathname);
    return token;
}

export function getLocalStorageKey(key: string) {
    const saved = localStorage.getItem(key);
    return JSON.parse(saved!);
}