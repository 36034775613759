import {ITabOption} from "../components/common/custom-tab/CustomTab";

export class TabUtil {
    public static getTabOption(): ITabOption[] {
        return [{
            option: {
                description: 'Elenco',
                value: 'list'
            },
            icon: 'it-horn'
        },
            {
                option: {
                    description: 'Archiviate',
                    value: 'archived'
                },
                icon: 'it-folder'
            },
        ]
    }
}