import { useEffect, useState } from 'react'
import PageHeader from '../../components/common/page-header/PageHeader'
import { HREF_ELENCO_CANDIDATI } from '../../components/layout/sidemenu/sidemenuConstants'
import { Button, Col, Input, TextArea, } from 'design-react-kit'
import { FormikProps, useFormik } from 'formik'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { DocumentoPrimaConvocazioneRequestDto, NuoveAssunzioniInsertUpdateRequestDto, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica'
import { useTranslation } from 'react-i18next'
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { detailsCandidato, formSrachPopulate, generaDocumentoPrimaConvocazione, generaDocumentoRequest, insertCandidato, resetAll, resetGeneraDocumento, ricercaCandidato, updateCandidato } from '../../store/candidatoSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { STATUS_FULLFILLED } from '../../store/store-constants'
import CandidatoDetailsComponent from '../../components/candidato-detail-form/CandidatoDetailsComponent'
import { CAP, CITTADINANZA, CITTA_NASCITA, CODICE_FISCALE, COGNOME, CITTA_RESIDENZA, DATA_NASCITA, INDIRIZZO_RESIDENZA, NOME, NUM_CARTA_IDENTITA, PROVINCIA, SESSO, STATO_CIVILE } from './candidatiConstants'

const oneDay = (3600 * 1000 * 24);

const initialValuesCodiceFiscale = {
    codiceFiscale: '',
}

const validationSchemaCodiceFiscale = z.object({
    codiceFiscale: z.string({ required_error: 'required' }).max(16, 'lengthcodicefiscale')
})

let initialValuesUtenteRecuperato: NuoveAssunzioniInsertUpdateRequestDto = {
    codiceUnivoco: undefined,
    pec: '',
    email: '',
    telefono: '',
    cellulare: '',
    modalitaSelezione: undefined,
    attoIndizione: undefined,
    tipoContratto: undefined,
    areaProfessionale: undefined,
    categoria: undefined,
    livello: undefined,
    dataInvioDocumenti: '',
    note: '',
    requestType: 'CONFERMA'
}

const validationSchemaUtenteRecuperatoConfirm = z.object({
    email: z.string({ required_error: 'required' }).regex(new RegExp(/^([a-zA-Z0-9-._]+)(@[a-z]+).[a-z]{2,3}$/), "emailNonCorretta").trim().min(1).max(150, 'lenghtEmailCandidato').email({ message: "emailNonCorretta" }).optional(),
    pec: z.string().regex(new RegExp(/\b[A-Za-z0-9-._]+@pec.+/), "pecNonCorretta").trim().min(1).max(150, 'lenghtEmailCandidato').email({ message: "pecNonCorretta" }).optional(),
    telefono: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(15, 'lenghtTelefonoCandidato').optional(),
    cellulare: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(15, 'lenghtTelefonoCandidato').optional(),
    // modalitaSelezione: z.string({ required_error: 'required' }),
    attoIndizione: z.string({ required_error: 'required' }).optional(),
    tipoContratto: z.string({ required_error: 'required' }),
    areaProfessionale: z.string({ required_error: 'required' }),
    livello: z.string({ required_error: 'required' }),
    categoria: z.string({ required_error: 'required' }).optional(),
    dataInvioDocumenti: z.coerce.date(),
    note: z.string({ required_error: 'required' }).optional(),

})

const validationSchemaGeneraDocumento = z.object({
    tipoContratto: z.string({ required_error: 'required'}),
    //modalitaSelezione: z.string({ required_error: 'required' }),
    areaProfessionale: z.string({ required_error: 'required' }),
    livello: z.string({ required_error: 'required' }),
    dataInvioDocumenti: z.coerce.date().min(new Date(Date.now() - oneDay), {message: 'Il campo data invio documenti non deve essere inferiore alla data odierna'})
})


const validationSchemaUtenteRecuperatoSave = z.object({
    email: z.string({ required_error: 'required' }).regex(new RegExp(/^([a-zA-Z0-9-._]+)(@[a-z]+).[a-z]{2,3}$/), "emailNonCorretta").trim().min(1).max(150, 'lenghtEmailCandidato').email({ message: "emailNonCorretta" }).optional(),
    pec: z.string().regex(new RegExp(/\b[A-Za-z0-9-._]+@pec.+/), "pecNonCorretta").trim().min(1).max(150, 'lenghtEmailCandidato').email({ message: "pecNonCorretta" }).optional(),
    telefono: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(15, 'lenghtTelefonoCandidato').optional(),
    cellulare: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(15, 'lenghtTelefonoCandidato').optional(),
    // modalitaSelezione: z.string({ required_error: 'required' }).optional(),
    attoIndizione: z.string({ required_error: 'required' }).optional(),
    tipoContratto: z.string({ required_error: 'required' }).optional(),
    areaProfessionale: z.string({ required_error: 'required' }).optional(),
    livello: z.string({ required_error: 'required' }).optional(),
    categoria: z.string({ required_error: 'required' }).optional(),
    dataInvioDocumenti: z.coerce.date().optional(),
    note: z.string({ required_error: 'required' }).optional(),
})

const InserimentoCandidato = (props: {operations: RuoloUtenteAutenticatoDto}) => {

    const { t } = useTranslation()
    const [utenteRecuperato, setUtenteRecuperato] = useState(false)
    const dispatch = useAppDispatch();
    const { formSearchResult, formSearchStatus, detailsResult, generaDocumentoResult, generaDocumentoStatus, ricercaCandidatoStatus, ricercaCandidatoResult } = useAppSelector((state) => state.candidato)
    const { id } = useParams();
    const navigate = useNavigate()
    const [button, setButtonSubmit] = useState('');
    const [abilitaConferma, setAbilitaConferma] = useState<boolean>(false);
    const [abilitaGeneraDocumento, setAbilitaGeneraDocumento] = useState<boolean>(true);
    const validationSchema = button === "CONFERMA" ? toFormikValidationSchema(validationSchemaUtenteRecuperatoConfirm) : toFormikValidationSchema(validationSchemaUtenteRecuperatoSave);
    const fieldUsed: Array<string> = [NOME, COGNOME, SESSO, DATA_NASCITA, CITTA_NASCITA, CODICE_FISCALE, CITTADINANZA, CITTA_RESIDENZA, PROVINCIA, CAP, INDIRIZZO_RESIDENZA, STATO_CIVILE, NUM_CARTA_IDENTITA]

    const showSalvaOperation = () => 
        (id != null && id !== undefined && props.operations.elencoFunzionalita?.includes('ASSUNZIONI_MODIFICA'))
        || ((id == null || id === undefined) && props.operations.elencoFunzionalita?.includes('ASSUNZIONI_INSERT'))

    const showConfermaOperation = () => showSalvaOperation() && abilitaConferma
    
    const showRicercaANPROperation = () => 
        (props.operations.elencoFunzionalita?.includes('ASSUNZIONI_DETTAGLIO_ANPR'))

    const form = useFormik({
        initialValues: initialValuesCodiceFiscale,
        validationSchema: toFormikValidationSchema(validationSchemaCodiceFiscale),
        onSubmit: (values) => {
            console.log("Submit form", JSON.stringify(values, null, 2));
            setUtenteRecuperato(true)
            dispatch(ricercaCandidato({
                codiceFiscale: values.codiceFiscale
            }))
        },
    });
    
    const insertForm: FormikProps<NuoveAssunzioniInsertUpdateRequestDto> = useFormik({
        initialValues: initialValuesUtenteRecuperato,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("Submit form", JSON.stringify(values, null, 2));
            doInsert()
        },
    })

    
    const initialValuesGeneraDoc:DocumentoPrimaConvocazioneRequestDto = {
        tipoContratto: insertForm.values.tipoContratto,
        areaProfessionale: insertForm.values.areaProfessionale,
        // modalitaSelezione: insertForm.values.modalitaSelezione,
        livello: insertForm.values.livello,
        dataInvioDocumenti: insertForm.values.dataInvioDocumenti,
    }

    const generaDocumentoForm: FormikProps<DocumentoPrimaConvocazioneRequestDto> = useFormik({
        initialValues: initialValuesGeneraDoc,
        enableReinitialize: true,
        validationSchema: toFormikValidationSchema(validationSchemaGeneraDocumento),
        onSubmit: (values) => {
            console.log("Submit form", JSON.stringify(values, null, 2));
            generazioneDocumento()
        },
    })

    const doInsert = () => {
        if (id != null && id != undefined) {
            let body: updateCandidato = {
                id: Number.parseInt(id),
                bodyRequest: insertForm.values
            }
            dispatch(updateCandidato(body))
            .then(res => { if(body.bodyRequest.requestType === "CONFERMA" && res.payload)
                                navigate(`${HREF_ELENCO_CANDIDATI}`);
                          });

        } else {
            console.log(insertForm.values)
            dispatch(insertCandidato(insertForm.values))
            .then(res => { if(insertForm.values.requestType === "CONFERMA" && res.payload)
                               navigate(`${HREF_ELENCO_CANDIDATI}`);
                          });
        }

    }

    useEffect(() => {
        dispatch(resetGeneraDocumento());
        dispatch(formSrachPopulate());
        dispatch(resetAll());
        if (id != '' && id != undefined && id != null) {
            dispatch(detailsCandidato(Number.parseInt(id!)))

        }
    }, [])

    useEffect(() => {
        form.setValues({
            codiceFiscale: detailsResult?.anagraficaUnica?.codiceFiscale || ricercaCandidatoResult?.codiceFiscale || '',
        })
        insertForm.setValues({
            codiceUnivoco: Number(detailsResult?.codiceUnivoco!) || undefined,
            pec: detailsResult?.pec || ricercaCandidatoResult?.indirizzoPec || '',
            email: detailsResult?.email || '',
            telefono: detailsResult?.telefono || '',
            cellulare: detailsResult?.cellulare || '',
            // modalitaSelezione: detailsResult?.modalitaSelezione || undefined,
            attoIndizione: detailsResult?.attoIndizione || '',
            tipoContratto: detailsResult?.tipoContratto || undefined,
            areaProfessionale: detailsResult?.areeProfessionali || undefined,
            categoria: detailsResult?.categoria || undefined,
            livello: detailsResult?.livello || undefined,
            dataInvioDocumenti: detailsResult?.dataInvioDocumenti || '',
            note: detailsResult?.note || '',
        })
        if (detailsResult?.anagraficaUnica != null && detailsResult?.anagraficaUnica?.codiceFiscale != "") {
            setUtenteRecuperato(true)
        }
    }, [detailsResult, ricercaCandidatoResult])

    useEffect(() => {
        if (generaDocumentoResult != null) {
            const blob = new Blob([generaDocumentoResult], { type: 'application/pdf' })
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            console.log(url);
            link.href = url;
            link.download = 'Prima convocazione.pdf';
            link.click();
        }
    }, [generaDocumentoResult])

    // let modalitaSelezione: CustomSelectItem[] = [];
    // formSearchResult?.modalitaSelezione?.forEach((s) => {
    //     modalitaSelezione.push({ label: s.label, value: s.valore });
    // });

    let tipiContratti: CustomSelectItem[] = [];
    formSearchResult?.tipiContratti?.forEach((s) => {
        tipiContratti.push({ label: s.label, value: s.valore });
    });

    let categorie: CustomSelectItem[] = [];
    formSearchResult?.categorie?.forEach((s) => {
        categorie.push({ label: s.label, value: s.valore });
    });

    let livelli: CustomSelectItem[] = [];
    formSearchResult?.livelli?.forEach((s) => {
        livelli.push({ label: s.label, value: s.valore });
    });

    let areeProfessionali: CustomSelectItem[] = [];
    formSearchResult?.areeProfessionali?.forEach((s) => {
        areeProfessionali.push({ label: s.label, value: s.valore });
    });

    const getFieldError = (
        form: FormikProps<NuoveAssunzioniInsertUpdateRequestDto>,
        fieldName: keyof NuoveAssunzioniInsertUpdateRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const getFieldErrorDocumento = (
        form: FormikProps<DocumentoPrimaConvocazioneRequestDto>,
        fieldName: keyof DocumentoPrimaConvocazioneRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const getFieldErrorCodiceFiscale = (
        form: any,
        fieldName: keyof any
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const handleChange = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        if (selectedOption != "") {
            insertForm.setFieldValue(name, selectedOption);
        } else {
            insertForm.setFieldValue(name, undefined);
        }
    };

    const submitConfirm = () => {
        insertForm.setFieldValue("codiceUnivoco", ricercaCandidatoResult?.codiceUnivoco)
        insertForm.setFieldValue('requestType', 'CONFERMA');
        setTimeout(() => {
            insertForm.submitForm();
            setAbilitaConferma(false)
        }, 500);
    }

    const submitGeneraDocumento = () => {
        setTimeout(() => {
            generaDocumentoForm.submitForm();
        }, 500);
    }

    const submitSave = () => {
        insertForm.setFieldValue("codiceUnivoco", ricercaCandidatoResult?.codiceUnivoco)
        insertForm.setFieldValue('requestType', 'SALVA');
        setTimeout(() => {
            insertForm.submitForm();
        }, 500);
    }

    const generazioneDocumento = () => {
        const body: generaDocumentoRequest = {
            bodyRequest: {
                areaProfessionale: insertForm.values.areaProfessionale,
                attoIndizione: insertForm.values.attoIndizione,
                dataInvioDocumenti: insertForm.values.dataInvioDocumenti,
                livello: insertForm.values.livello,
                // modalitaSelezione: insertForm.values.modalitaSelezione,
                tipoContratto: insertForm.values.tipoContratto,
                tipoDocumentoNuoveAssunzioni: 'RICHIESTA_DOCUMENTAZIONE'
            }
        }

        dispatch(generaDocumentoPrimaConvocazione(body)).unwrap().then((resp) => {
            if (resp) {
                setAbilitaConferma(true)
            }
        });
    }

        // useEffect(() => {
        //     if(insertForm.values.modalitaSelezione !== "CON"){
        //         insertForm.values.attoIndizione = '';
        //         }

        //     }, [modalitaSelezione])

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={id != null ? 'Modifica candidato' : 'Inserimento candidato'} />
            {formSearchStatus === STATUS_FULLFILLED && (
                <div>
                    <div className="position-relative form-custom form-inserimento py-5">
                        <div className="row">
                            <div className="col-sm-8 mt-2">
                                <Input
                                    label="Codice fiscale"
                                    type="text"
                                    placeholder="Inserisci il codice fiscale"
                                    id="input-codiceFiscale"
                                    wrapperClassName="form-group required col-md-12 "
                                    name="codiceFiscale"
                                    disabled={detailsResult?.anagraficaUnica != null && detailsResult.anagraficaUnica.codiceFiscale !== null && detailsResult.anagraficaUnica.codiceFiscale != ""}
                                    value={form.values.codiceFiscale}
                                    onChange={form.handleChange}
                                    invalid={!!getFieldErrorCodiceFiscale(form, "codiceFiscale")}
                                    infoText={t(getFieldErrorCodiceFiscale(form, "codiceFiscale")) || ""}
                                />
                                <span></span>
                            </div>
                            <div className="col-sm-4">
                                <Button
                                    outline
                                    color="primary"
                                    className="mt-2 mr-2"
                                    onClick={() => { form.submitForm() }}
                                    disabled={!showRicercaANPROperation() || (detailsResult?.anagraficaUnica != null && detailsResult.anagraficaUnica.codiceFiscale !== null && detailsResult.anagraficaUnica.codiceFiscale != "")}
                                >
                                    Cerca
                                </Button>
                            </div>
                            <CandidatoDetailsComponent value={detailsResult! || {anagraficaUnica: ricercaCandidatoResult}} fileds={fieldUsed} />
                        </div>
                        {utenteRecuperato &&
                            <div>
                                <div className="position-relative py-5">
                                    <div className="row">
                                        <Input
                                            label="Pec"
                                            type="email"
                                            placeholder="Inserisci la pec"
                                            id="input-pec"
                                            wrapperClassName="form-group required col-md-6"
                                            name="pec"
                                            value={insertForm.values.pec}
                                            onChange={insertForm.handleChange}
                                            invalid={!!getFieldError(insertForm, "pec")}
                                            infoText={t(getFieldError(insertForm, "pec")) || ""}
                                        />
                                        <Input
                                            label="Email"
                                            type="email"
                                            placeholder="Inserisci l' email"
                                            id="input-email"
                                            wrapperClassName="form-group col-md-6"
                                            name="email"
                                            value={insertForm.values.email}
                                            onChange={insertForm.handleChange}
                                            invalid={!!getFieldError(insertForm, "email")}
                                            infoText={t(getFieldError(insertForm, "email")) || ""}
                                        />
                                        <Input
                                            label="Telefono"
                                            type="tel"
                                            placeholder="Inserisci il numero di telefono"
                                            id="input-telefono"
                                            wrapperClassName="form-group col-md-6 "
                                            name="telefono"
                                            value={insertForm.values.telefono}
                                            onChange={insertForm.handleChange}
                                            invalid={!!getFieldError(insertForm, "telefono")}
                                            infoText={t(getFieldError(insertForm, "telefono")) || ""}
                                        />
                                        <Input
                                            label="Cellulare"
                                            type="tel"
                                            placeholder="Inserisci il numero di cellulare"
                                            id="input-cellulare"
                                            wrapperClassName="form-group col-md-6 "
                                            name="cellulare"
                                            value={insertForm.values.cellulare}
                                            onChange={insertForm.handleChange}
                                            invalid={!!getFieldError(insertForm, "cellulare")}
                                            infoText={t(getFieldError(insertForm, "cellulare")) || ""}
                                        />
                                        <Col md="6">
                                            {/* <CustomSelect
                                                label="Modalità di selezione"
                                                placeholder="Seleziona la modalità di selezione"
                                                options={modalitaSelezione}
                                                name="modalitaSelezione"
                                                invalid={!!getFieldError(insertForm, "modalitaSelezione")|| !!getFieldErrorDocumento(generaDocumentoForm, "modalitaSelezione")}
                                                infoText={t(getFieldError(insertForm, "modalitaSelezione")) || t(getFieldErrorDocumento(generaDocumentoForm, "modalitaSelezione")) || ""}
                                                value={insertForm.values.modalitaSelezione}
                                                onChange={(e) => { handleChange("modalitaSelezione", e) }}
                                                wrapperClass="required"
                                            /> */}
                                        </Col>
                                        <Input
                                            label="Atto indizione"
                                            type="text"
                                            placeholder="Inserisci l'atto di indizione"
                                            id="input-attoIndizione"
                                            wrapperClassName="form-group col-md-6 "
                                            name="attoIndizione"
                                            value={insertForm.values.attoIndizione}
                                            onChange={insertForm.handleChange}
                                            invalid={!!getFieldError(insertForm, "attoIndizione")}
                                            infoText={t(getFieldError(insertForm, "attoIndizione")) || ""}
                                            disabled={
                                                insertForm.values.modalitaSelezione !== "CON"
                                            }
                                        />
                                        <Col md="6">
                                            <CustomSelect
                                                label="Tipologia contratto"
                                                placeholder="Seleziona la tipologia di contratto"
                                                options={tipiContratti}
                                                name="tipoContratto"
                                                invalid={!!getFieldError(insertForm, "tipoContratto") || !!getFieldErrorDocumento(generaDocumentoForm, "tipoContratto")}
                                                infoText={t(getFieldError(insertForm, "tipoContratto")) || t(getFieldErrorDocumento(generaDocumentoForm, "tipoContratto")) || ""}
                                                value={insertForm.values.tipoContratto}
                                                onChange={(e) => { handleChange("tipoContratto", e) }}
                                                wrapperClass="required"
                                            />
                                        </Col>
                                        {<Col md="6">
                                            <CustomSelect
                                                label="Area professionale"
                                                placeholder="Seleziona l'area professionale"
                                                options={areeProfessionali}
                                                name="areaProfessionale"
                                                invalid={!!getFieldError(insertForm, "areaProfessionale") || !!getFieldErrorDocumento(generaDocumentoForm, "areaProfessionale")}
                                                infoText={t(getFieldError(insertForm, "areaProfessionale")) || t(getFieldErrorDocumento(generaDocumentoForm, "areaProfessionale")) || ""}
                                                value={insertForm.values.areaProfessionale}
                                                onChange={(e) => { handleChange("areaProfessionale", e) }}
                                                wrapperClass="required"
                                            />
                                        </Col>}
                                        <Col md="6">
                                            <CustomSelect
                                                label="Categoria"
                                                placeholder="Seleziona la categoria"
                                                options={categorie}
                                                name="categoria"
                                                invalid={!!getFieldError(insertForm, "categoria")}
                                                infoText={t(getFieldError(insertForm, "categoria")) || ""}
                                                value={insertForm.values.categoria}
                                                onChange={(e) => { handleChange("categoria", e) }}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <CustomSelect
                                                label="Livello"
                                                placeholder="Seleziona il livello"
                                                options={livelli}
                                                name="livello"
                                                invalid={!!getFieldError(insertForm, "livello") || !!getFieldErrorDocumento(generaDocumentoForm, "livello")}
                                                infoText={t(getFieldError(insertForm, "livello")) || t(getFieldErrorDocumento(generaDocumentoForm, "livello")) || ""}
                                                value={insertForm.values.livello}
                                                onChange={(e) => { handleChange("livello", e) }}
                                                wrapperClass="required"
                                            />
                                        </Col>
                                        <Input
                                            label="Data invio documento"
                                            type="date"
                                            placeholder="Inserisci la data"
                                            id="input-dataInvioDocumenti"
                                            wrapperClassName="form-group required col-md-6"
                                            name="dataInvioDocumenti"
                                            min={new Date().toISOString().split("T")[0]}
                                            value={insertForm.values.dataInvioDocumenti}
                                            onChange={insertForm.handleChange}
                                            invalid={!!getFieldError(insertForm, "dataInvioDocumenti") || !!getFieldErrorDocumento(generaDocumentoForm, "dataInvioDocumenti")}
                                            infoText={t(getFieldError(insertForm, "dataInvioDocumenti")) || t(getFieldErrorDocumento(generaDocumentoForm, "dataInvioDocumenti")) || ""}
                                        />
                                        <TextArea
                                            label="Note personale"
                                            placeholder="Inserisci le note personali "
                                            id="input-note"
                                            wrapperClassName="form-group  col-md-12"
                                            name="note"
                                            value={insertForm.values.note}
                                            onChange={insertForm.handleChange}
                                            invalid={!!getFieldError(insertForm, "note")}
                                            infoText={t(getFieldError(insertForm, "note")) || ""}
                                        />
                                    </div>



                                    <div className="d-flex flex-row-reverse">
                                        <Button color='primary' className="mt-2 mr-2" disabled={!showConfermaOperation()} onClick={() => { submitConfirm(); setButtonSubmit('CONFERMA') }}>Conferma</Button>
                                        <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitGeneraDocumento() }}>Genera documento</Button>
                                        <Button outline color='primary' className="mt-2 mr-2" disabled={!showSalvaOperation()} onClick={() => { submitSave(); setButtonSubmit('SAVE') }}>Salva</Button>
                                        <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_CANDIDATI}`) }} >{t('annulla')} </Button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>)}
        </div>
    )
}

export default InserimentoCandidato