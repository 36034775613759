import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAreaDocumentiStakeholder, getDocumenti } from "../../store/stakeholderSlice";
import PageHeader from "../../components/common/page-header/PageHeader";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionBody, AccordionHeader, Alert, Button, Icon, Table } from "design-react-kit";
import CustomSelect, { CustomSelectItem } from "../../components/common/custom-select/CustomSelect";
import { z } from "zod";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import DocumentView from "../../components/common/document-view/DocumentView";
import { GestioneDocumentaleControllerApiGetElencoDocumentiRequest } from "../../services/ms-anagrafica-unica";
import { it } from 'date-fns/locale';
import { format } from "date-fns";
import Pagination from "../../components/common/pagination/Pagination";
import TableTheadCustom from "components/common/custom-table/table-thead-custom";

const DocumentiCittadino = () => {
    type Direction = 'ASC' | 'DESC';
    interface SortingState {
        direction: Direction;
    }

    const schema = z.object({
        nomeDocumento: z.string().optional()
    });

    const initialValues:GestioneDocumentaleControllerApiGetElencoDocumentiRequest = {};

    const [formCollapseOpen, setFormCollapseOpen] = useState<boolean>(false);
    const [tipoDocumenti, setTipoDocumenti] = useState<Array<any>>([])
    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
    const dispatch = useAppDispatch();
    const { documentiStatus, documentiResult } = useAppSelector((state) => state.stakeholder)
    const { userInfoResult} = useAppSelector((state) => state.user)
    const { t } = useTranslation()    

    useEffect(() => {
        if (!!userInfoResult) {   
            dispatch(getAreaDocumentiStakeholder()).unwrap().then(resp => {
                if (!!resp) {
                    userInfoResult.candidato && setTipoDocumenti(resp.data.tipiDocumentiCandidato || [])
                    userInfoResult.cittadino && setTipoDocumenti(resp.data.tipiDocumentiCittadino || [])
                    userInfoResult.dipendenteCessato && setTipoDocumenti(resp.data.tipiDocumentiDipendenteCessato || [])
                }
            })
            dispatch(getDocumenti(initialValues))
        }
    }, [userInfoResult]);


    //useEffect(() => console.log('documentiResult >>> ', documentiResult ), [documentiResult]);

    let tipiDocumentoOptions: CustomSelectItem[] = [];
    tipoDocumenti?.forEach((s) => {
        tipiDocumentoOptions.push({ label: s.label, value: s.valore });
    });

    let hasResults =
    !!documentiResult && documentiResult.data && documentiResult.data?.length > 0
        ? documentiResult.data.length > 0
        : false;
    
    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('SEARCHFORM >>> ', values);
            
            dispatch(getDocumenti(values));
        },
    });
    
    const handleChange = (name: string, selectedOption: any) => {
        searchForm.setFieldValue(name, selectedOption);
    };

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };

    const handlePageChange = (pageNum: number) => {
        // setCurrentPage(pageNum);
        searchForm.setFieldValue("pageNum", pageNum - 1);
        // doSearch();
        searchForm.handleSubmit();
    };
    return (
        <div>
            <PageHeader showIcon={false} title={t("Documenti")!} buttons={[]}/>
            <Accordion background="active" className="form-custom form-bg filtri-ricerca">
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-2">
                        <CustomSelect
                            label="Tipo documento"
                            placeholder="Seleziona tipologia"
                            wrapperClass="col-md-12"
                            options={tipiDocumentoOptions}
                            name="tipoDocumento"
                            // invalid={!!searchForm.errors.tipoIncarico}
                            // infoText={t(searchForm.errors.tipoIncarico || "") || ""}
                            value={searchForm.values.nomeDocumento}
                            onChange={(e) => handleChange("nomeDocumento", e)}
                        />
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchForm.setFieldValue("pageNum", 0);
                                searchForm.handleSubmit();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchForm.handleReset}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>
            <div className="mt-2 position-relative">
            {documentiStatus === STATUS_FULLFILLED && !hasResults && 
                <Alert color="info">Nessun risultato disponibile</Alert>}
            {documentiStatus === STATUS_FULLFILLED && hasResults && (<>
                <Table striped responsive size="sm">
                    <TableTheadCustom 
                        colonne={[
                            {titolo: 'Nome file', nomeColonna:'filename'},
                            {titolo: 'Data inserimento', nomeColonna: 'dataInserimento'},
                            {titolo: 'Tipo', nomeColonna: 'nomeDocumento'},
                            {titolo: 'Download'}
                        ]} 
                        datiOrdinamento={searchForm.values}
                        eseguiOrdinamento={ordinamento}/>
                        
                    <tbody>
                        {documentiResult!.data?.map( (d, i, dataset) => (
                            <Fragment key={i}>
                            <tr>
                                <td>{d.filename}</td>
                                <td>{format(new Date(d.dataInserimento!), 'dd/MM/Y', {locale: it}) || '01/01/1970'}</td>
                                <td>{d.nomeDocumento || '-'}</td>
                                <td>{<DocumentView documento={d} download/>}</td>
                            </tr>
                            {/* corregge il popup delle azioni in caso di 1 risultato */}
                            {dataset.length === 1 && <tr><td style={{height:100, border: 0}} colSpan={4}></td></tr>}
                            </Fragment>
                        ))}
                    </tbody>
                </Table>
                <Pagination
                    totalCount={documentiResult!.data?.length || 0}
                    siblingCount={1}
                    currentPage={searchForm.values.pageNum! + 1}
                    pageSize={10}
                    onPageChange={(page: number) => {
                        handlePageChange(page);
                    }}
                    className="justify-content-center"
                />
            </>)}
            </div>
        </div>
    )
}

export default DocumentiCittadino;