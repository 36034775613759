import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    CODICE_UNIVOCO_PO,
    DATA_FINE_VALIDITA_PO,
    DATA_INIZIO_VALIDITA_PO,
    DECLARATORIA_PO,
    DENOMINAZIONE_PO,
    STRUTTURA_ORGANIZZATIVA_PO,
    TIPO_PO
} from './posizioniOrganizzativeConstants';
import { detailsPosizioneOrganizzativa, disattivaPO } from '../../store/posizioneOrganizzativaSlice';
import { useFormik } from 'formik';
import { Button, Input } from 'design-react-kit';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import PosizioniOrganizzativeDetailsComponent
    from '../../components/posizioni-organizzative-detail/PosizioniOrganizzativeDetailsComponent';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import { HREF_ELENCO_POSIZIONI_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import {PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest} from "../../services/ms-anagrafica-unica";
import PageHeader from '../../components/common/page-header/PageHeader';
import {z} from "zod";
import { toFormikValidationSchema } from 'zod-formik-adapter';

const DisattivaPostazioneOrganizzativa = () => {
    const { t } = useTranslation()
    const { idCodiceIncarico } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fieldUsed: Array<string> = [CODICE_UNIVOCO_PO, DENOMINAZIONE_PO, DECLARATORIA_PO, STRUTTURA_ORGANIZZATIVA_PO, DATA_INIZIO_VALIDITA_PO, DATA_FINE_VALIDITA_PO, TIPO_PO];

    useEffect(() => {
        getDetailsFromApi(idCodiceIncarico!)
    }, [0])

    const getDetailsFromApi = (idCodiceIncarico: string) => {
        dispatch(detailsPosizioneOrganizzativa({ idCodiceIncarico }));
    }

    const posizioneOrganizzativa = useAppSelector((state) => state.posizioneOrganizzativa.detailsResult);
    const posizioneOrganizzativaResult = useAppSelector((state) => state.posizioneOrganizzativa.detailsStatus);

    const schema = z.object({
        dataFineValidita: z.coerce.date()
    }).refine( (data) => new Date(posizioneOrganizzativa?.dataInizio!) <= data.dataFineValidita, {
        message: 'La data di inizio validità deve essere precedente alla data di fine',
        path: ['dataFineValidita']
    })

    const formData = useFormik({
        initialValues: {
            dataFineValidita: ''
        },
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            const param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest = {
                idCodiceIncarico: idCodiceIncarico!,
                patchPosizioneOrganizzativaRequest: {
                    dataFineValidita: values.dataFineValidita,
                    requestType: 'Disattiva',
                    codiceUnivoco: idCodiceIncarico
                }
            }
            dispatch(disattivaPO(param));
        },
    })

    const setDocumentId = (name: string, id: number | undefined) => {
        if (id) {
            formData.setFieldValue('idNotaPropostaIstituzione', id);
        } else {
            console.error('errore durante la chiamata di caricamento documento');
        }
    }


    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE} state={{form: true}} title={t("disattivazione posizione organizzativa")!}></PageHeader>
             {posizioneOrganizzativaResult === STATUS_FULLFILLED && <div>
                <PosizioniOrganizzativeDetailsComponent fields={fieldUsed} value={posizioneOrganizzativa!} />
                <div className="row mt-5">
                    <div className="col-6">
                        <div className="mt-3">
                            <DocumentUpload documentName={t("disattivazione posizione organizzativa documento da allegare")} className="my-3"
                                setDocumentId={(id) => setDocumentId('idNotaPropostaIstituzione', id)} />
                        </div>
                    </div>
                    <div className="col-6 mt-4">
                        <div className="form-row form-custom">
                            <Input
                                label='Data Fine Validità'
                                type='date'
                                placeholder='Inserisci la Data Fine Validità'
                                id='input-dataFineValidità'
                                wrapperClassName="form-group required col-12 col-xl-6"
                                name="dataFineValidita"
                                value={formData.values.dataFineValidita}
                                onChange={formData.handleChange}
                                infoText={t(formData.errors.dataFineValidita || "") || ""}
                                invalid={!!formData.errors.dataFineValidita}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-row-reverse">

                    <Button color='primary' className="mt-2" onClick={formData.submitForm}>Conferma</Button>
                    <Button color='primary' outline className="mt-2 mr-2"
                        onClick={() => navigate(`${HREF_ELENCO_POSIZIONI_ORGANIZZATIVE}`, {state:{form: true}})}>Annulla </Button>
                </div>
            </div>}
        </div>
    )
}

export default DisattivaPostazioneOrganizzativa