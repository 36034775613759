import { Col, Container, Headers, Row} from "design-react-kit";
import { Outlet } from "react-router-dom";
import HeaderCenter from "./headers/HeaderCenter";
import HeaderSlim from "./headers/HeaderSlim";
import Sidemenu from "./sidemenu/Sidemenu";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUserInfo } from "../../store/userSlice";
import { getNotificheNonLette } from "../../store/notificheSlice";
import { NotificaDtoStatoEnum } from "../../services/ms-anagrafica-unica";
import Footer from "./footer/Footer";
import 'style/header.scss'

function Layout() {
    const dispatch = useAppDispatch()
    const { userInfoResult } = useAppSelector(
        (state) => state.user
    );

    useEffect(() => {
        !userInfoResult && dispatch(getUserInfo()).unwrap().then(resp => {
            if (resp && !resp.cittadinoEsterno) return dispatch(getNotificheNonLette({stato: NotificaDtoStatoEnum.NonLetta}))
        })
    }, [userInfoResult]);

    return (
        <div>
            <Headers>

                <HeaderSlim />
                <HeaderCenter />

            </Headers>

            <Container fluid>
                <Row className="p-0">
                    {!userInfoResult?.cittadinoEsterno ? 
                    <>
                        <div className="sidebar-menu p-0">
                            <Sidemenu />
                        </div>
                        <div className='content-page pt-5 pl-4 pb-5 pr-3'>
                            <Outlet />
                        </div>
                    </> : <Col xs="lg-12" className='p-5'>
                            <Outlet />
                        </Col>
                    }
                </Row>
            </Container>
            <Footer/>

        </div>
    )
}

export default Layout;