import { AssegnazioneBandiPoDto } from "../../services/ms-anagrafica-unica";

const EqDetail = (props: {bando: AssegnazioneBandiPoDto}) => {
    const {bando} = props;
    return (
        <div className="col-sm-12">
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light bg-light-gray">
                        <h5 className='pb-2 border-bottom font-weight-bold'>
                            Riepilogo Bando
                        </h5>
                        <dl className="border-bottom py-2">
                            <div className="row">
                                <div className="col-md-6 col-xl-4">
                                    <dt>Codice univoco</dt>
                                    <dd>{bando.posizioneOrganizzativa?.codiceUnivoco ? bando.posizioneOrganizzativa?.codiceUnivoco : '-'}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt>Tipo di EQ</dt>
                                    <dd>{bando.posizioneOrganizzativa?.tipoLabel ? bando.posizioneOrganizzativa?.tipoLabel : '-'}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt>Tipologia</dt>
                                    <dd>{bando.tipologiaBandoPoLabel ? bando.tipologiaBandoPoLabel : '-'}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt>Durata (mesi)</dt>
                                    <dd>{bando.durataIncarico ? bando.durataIncarico : '-'}</dd>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <dt>Sedi</dt>
                                    <dd>{bando.sedi && bando.sedi.length > 0 ? bando.sedi.join(', ') : '-'}</dd>
                                </div>
                            </div>
                        </dl>
                    
                        <dl className="border-bottom py-2">
                            <div className="row">
                                <div className="col-xl-6">
                                    <dt>Requisiti oggettivi</dt>
                                    <dd>{bando.requisitiOggettivi ? bando.requisitiOggettivi : '-'}</dd>
                                </div>
                                <div className="col-xl-6">
                                    <dt>Requisiti soggettivi</dt>
                                    <dd>{bando.requisitiSoggettivi ? bando.requisitiSoggettivi : '-'}</dd>
                                </div>

                                <div className="col-xl-6">
                                    <dt>Denominazione EQ</dt>
                                    <dd>
                                        {bando.posizioneOrganizzativa?.denominazione ? bando.posizioneOrganizzativa?.denominazione : '-'}<br/>
                                        {bando.posizioneOrganizzativa?.descrizione}
                                        </dd>
                                </div>
                                <div className="col-xl-6">
                                    <dt>Descrizione funzioni</dt>
                                    <dd>{bando.posizioneOrganizzativa?.declaratoriaFunzioni ? bando.posizioneOrganizzativa?.declaratoriaFunzioni : '-'}</dd>
                                </div>
                            </div>
                        </dl>
                    
                        <dl className="py-2">
                            <div className="row">
                                {bando.codiceCifra && (
                                    <div className="col-sm-12">
                                        <dt>Codice Cifra</dt>
                                        <dd>{bando.codiceCifra}</dd>
                                    </div>
                                )}
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EqDetail;