import { useEffect } from 'react'
import PageHeader from '../../components/common/page-header/PageHeader'
import { HREF_MOD_DOCUMENTI_CANDIDATO } from '../../components/layout/sidemenu/sidemenuConstants'
import { Alert, DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Table, UncontrolledDropdown } from 'design-react-kit'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getListaDocumenti } from '../../store/candidatoSlice'
import { useNavigate } from 'react-router-dom'
import { STATUS_FULLFILLED } from '../../store/store-constants'
import { RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica'
import { formatDate } from 'utility/formatDate'

const ConfigurazioneAreaDocumenti = (props: {operations: RuoloUtenteAutenticatoDto}) => {

    const { elencoDocumentiResult, elencoDocumentiStatus } = useAppSelector((state) => state.candidato);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getListaDocumenti())
    }, [])

    const showModificaModificaAreaDocumenti = () =>
        props.operations?.elencoFunzionalita?.includes('ASSUNZIONI_DETTAGLIO_DOCUMENTI')
        && props.operations?.elencoFunzionalita?.includes('ASSUNZIONI_PATCH_DOCUMENTOCANDIDATO')

    return (
        <div>
            <PageHeader title='Configurazione area documenti' />
            {elencoDocumentiStatus === STATUS_FULLFILLED && elencoDocumentiResult?.length === 0 && 
            (
                <Alert color="info">Nessun risultato disponibile</Alert>
            )}
            {elencoDocumentiResult && elencoDocumentiResult?.length > 0 && (
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th scope='col'>
                                <div className="table-order">
                                    Nome documento
                                </div>
                            </th>
                            <th scope='col'>
                                <div className="table-order">
                                    Data inizio validità

                                </div>
                            </th>
                            <th scope='col'>
                                <div className="table-order">
                                    Data fine validità

                                </div>
                            </th>
                            <th scope='col'>
                                <div className="table-order">
                                    Azioni
                                </div>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {(elencoDocumentiResult || []).map((x, i) => (
                            <tr key={i}>
                                <td>{x.descrizioneDocumento}</td>
                                <td>{formatDate(x.dataInizioValidita!, false, '-')}</td>
                                <td>{formatDate(x.dataFineValidita!, false, '-')}</td>
                                <td>
                                    {showModificaModificaAreaDocumenti() && <UncontrolledDropdown direction="left">
                                        <DropdownToggle nav>
                                            <Icon icon="it-settings" color="primary" size="sm" />
                                        </DropdownToggle>
                                        <DropdownMenu className="no-arrow">
                                            <LinkList>
                                                <LinkListItem
                                                    size="medium"
                                                    onClick={() =>
                                                        navigate(
                                                            `${HREF_MOD_DOCUMENTI_CANDIDATO}/${x.codiceUnivoco}`
                                                        )
                                                    }
                                                >
                                                    <span>Modifica</span>
                                                </LinkListItem>
                                            </LinkList>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>)}
        </div>
    )
}

export default ConfigurazioneAreaDocumenti