import { Col, Input, Row } from "design-react-kit";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import {
  dettaglioDocumento,
} from "../../../store/DocumentiSlice";
import { DocumentoDto } from "../../../services/ms-anagrafica-unica";
import { downloadDocument } from "../../../store/DocumentiSlice";
import { hideLoader, showLoader } from "../../../store/loaderSlice";
import ButtonDownload from "../button-custom/ButtonDownload";
import ButtonProtocolla from "../button-custom/ButtonProtocolla";

export interface DocumentUploadProps {
  documentName?: string | null;
  className?: string;
  idDocumento?: number | undefined;
  documento?: DocumentoDto;
  download?: boolean;
  view?: boolean;
  protocolla?: boolean;
  numProtocollo?: string;
  showFileName?: boolean
}

function DocumentView(props: DocumentUploadProps) {
  const dispatch = useAppDispatch();

  const [uploadedFile, setUploadedFile] = useState<DocumentoDto | undefined>(undefined);
  const [protocollo, setProtocollo] = useState<string | undefined>(undefined);
  const [getDocument, setGetDocument] = useState(false)

  useEffect(() => {
    if (props.idDocumento != null && props.idDocumento !== undefined) {
      dispatch(showLoader())
      dispatch(dettaglioDocumento(props.idDocumento!))
        .unwrap()
        .then((doc) => {
          if (!!doc) {
            setUploadedFile({ ...doc })
            setGetDocument(true)
            dispatch(hideLoader())
          }
      })
    }
  }, [props.idDocumento])


  useEffect(() => {
    if (props.documento != null && props.documento !== undefined) {
        setUploadedFile({ ...props.documento })
        setGetDocument(true)
    }
  }, [props.documento])


  const vediFile = (download: boolean = false) => {
    dispatch(dettaglioDocumento(uploadedFile?.id!))
      .unwrap()
      .then((doc) => {
        doc && doc.id && dispatch(downloadDocument({
          idDocumento: doc.id
        }))
        .unwrap()
        .then(resp => {
          if (!!resp) {
            const blob = new Blob([resp], { type: doc.contentType })
            let url;
            if (!download) {
              url = URL.createObjectURL(blob);
              window.open(url, '_blank', 'noreferrer');
            } else {
              const link = document.createElement('a');
              url = URL.createObjectURL(blob);
              link.href = url;
              link.download = doc.filename || '';
              link.click()
            }
          }
        });
    })
  }

  useEffect( () => setProtocollo(props.numProtocollo), [props.numProtocollo])

  const protocolla = () => {
    setProtocollo('XXX')
  }

  return (
    <div data-component="DocumentView" className={props.className}>

      {(props.documentName || props.showFileName) && (
          <Row className="mt-3">
            <Col md="12">
              {props.documentName && <><strong>{props.documentName}</strong><br /></>}
              {props.showFileName && <small>{uploadedFile?.filename}</small>}
            </Col>
          </Row>
      )}

      {getDocument && uploadedFile && <>
      
        {!!props.view && (
          <ButtonDownload className="mr-2" onCliclEvent={vediFile} testoBtn={'Apri file'} titleBtn={'Apri File "' + uploadedFile?.filename + '"'} iconVedi/>
        )}

        {!!props.download && (
          <ButtonDownload className="mr-2" onCliclEvent={() => vediFile(true)} testoBtn={'Scarica file'} titleBtn={'Download File "' + uploadedFile?.filename + '"'} />
        )}

        {!!props.protocolla && (
          <ButtonProtocolla disabled={!!protocollo} onCliclEvent={protocolla} />
        )}
      </>}

      {protocollo && !!props.numProtocollo &&
        <Input
          type="text"
          label="Numero protocollo uscita"
          name="protocollo"
          value={protocollo}
          readOnly={true}
          wrapperClassName="my-5"
        />
      }
      
     {/*  {!props.compact && !getDocument && (
        <Row className="mb-3">
          <Col>
            <DocumentUpload documentName=""></DocumentUpload>
          </Col>
        </Row>
      )} */}
    </div>
  );
}

export default DocumentView;
