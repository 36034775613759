import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getContestoNotifiche, resetPlaceholder, loadConfigurazione, updateConfigurazione, getPlaceholder } from "../../store/configurazioniNotificheSlice";
import { useParams } from "react-router-dom";
import { Badge, Button, Col, Icon, Input, Table, TextArea, Toggle } from "design-react-kit";
import CustomSelect, { CustomSelectItem } from "../../components/common/custom-select/CustomSelect";
import { useFormik } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { split } from "lodash";
import { ConfigNotificheInsertRequestDtoFunzionalitaEnum, RuoloUtenteAutenticatoDto } from "../../services/ms-anagrafica-unica";
import PageHeader from "../../components/common/page-header/PageHeader";
import { HREF_GESTIONE_NOTIFICHE } from "../../components/layout/sidemenu/sidemenuConstants";

interface FormikType {
    funzionalita?: ConfigNotificheInsertRequestDtoFunzionalitaEnum,
    ruoli: '',
    listaDistribuzione: string,
    oggetto: string,
    testo: string,
    filtro: boolean
}

const formikInitialValues: FormikType = {
    funzionalita: undefined,
    ruoli: '',
    listaDistribuzione: '',
    oggetto: '',
    testo: '',
    filtro: false
}

let schema:any = z.object({
    funzionalita: z.string({ required_error: 'required' }),
    ruoli: z.string({ required_error: 'required' }),
    listaDistribuzione: z.string({ required_error: 'required' }).optional(),
    oggetto: z.string({ required_error: 'required' }),
    testo: z.string({ required_error: 'required' })
});

function ModificaConfigurazioneNotifica(props: {operations: RuoloUtenteAutenticatoDto}) {
    const {funzionalita} = useParams();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {loadConfigurazioneResult, contestoResult, getPlaceholderResult} = useAppSelector((state) => state.configurazioniNotifiche);
    const {searchResult} = useAppSelector((state) => state.ruolo);

    const [funzionalitaSelect, setFunzionalitaSelect] = useState<CustomSelectItem[]>([]);
    const [ruoliSelect, setRuoliSelect] = useState<CustomSelectItem[]>([]);
    const [ruoloSelezionatoModifica, setRuoloSelezionatoModifica] = useState('');
    const [ruoliTableModifica, setRuoliTableModifica] = useState<Array<any>>([]);

    // LISTA DI DISTRIBUZIONE
    // email temporanea impostata nella input.
    const [emailSelezionataModifica, setEmailSelezionataModifica] = useState('');
    // email impostate che si visualizzano in tabella.
    const [emailsTable, setEmailsTable] = useState<Array<string>>([]);

    // FILTRO
    const [filtro, setFiltro] = useState<boolean | undefined>();

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            const ruoli:Array<any> = [];
            ruoliTableModifica.forEach( r => contestoResult && contestoResult.ruoli && contestoResult.ruoli?.filter( ruolo => (ruolo.label === r) &&
                ruoli.push({
                    valore: ruolo.valore,
                    label: ruolo.label
                })
            ));

            const params = {
                ...values,
                ruoli
            }

            params.funzionalita && dispatch(updateConfigurazione({
                idConfigurazioneNotifiche: params.funzionalita,
                configNotificheInsertRequestDto: params
            }))
        }
    });

    const createSelectItems = (obj: any, valoreLabel?: string, labelLabel?: string) => {
        const v = valoreLabel || 'valore';
        const l = labelLabel || 'label';
        const selectItems: Array<CustomSelectItem> = [];
        obj.forEach((f: any) => {
            selectItems.push({
                value: f[v],
                label: f[l]
            })
        })
        return selectItems;
    };

    const getFieldError = (
        form: any,
        fieldName: any
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return (typeof form.errors[fieldName] === 'string') ?
            form.errors[fieldName] : "";
        } else return "";
    };

    const addRuolo = () => {
        if (!ruoloSelezionatoModifica) {
            return false;
        }
        // aggiungo il ruolo alla tabella
        const ruoloSelezionatoMappatoNodifica = contestoResult && contestoResult?.ruoli && contestoResult.ruoli.filter( r => r.valore === Number(ruoloSelezionatoModifica)).at(0)?.label; 
        const r = [...ruoliTableModifica, ruoloSelezionatoMappatoNodifica];
        setRuoliTableModifica(r);

        // rimuovo il ruolo dai ruoli selezionabili
        const ruoliSelezionabiliModifica = [...ruoliSelect];
        ruoliSelezionabiliModifica.splice(ruoliSelect.findIndex(e => e.value === ruoloSelezionatoModifica), 1);
        setRuoliSelect(ruoliSelezionabiliModifica)

        formik.setFieldValue('ruoli', r.join(','))
        setRuoloSelezionatoModifica('')
    }

    const rimuoviRuoloModifica = (selectValue:string) => {
        // rimuovo il ruolo dalla tabella
        const r = [...ruoliTableModifica];
        r.splice(r.findIndex(e => e === selectValue), 1)
        setRuoliTableModifica(r)

        //recupero il ruolo dto completo per le informazioni mancanti
        const ruoloModifica = contestoResult && contestoResult.ruoli && contestoResult.ruoli?.filter( ruolo => ruolo.label === selectValue).at(0);

        // aggiungo il ruolo nei ruoli selezionabili
        const ruoliSelezionabili = [...ruoliSelect]
        ruoloModifica && ruoloModifica.label && ruoloModifica.valore && ruoliSelezionabili.push({
            label: ruoloModifica?.label,
            value: ruoloModifica?.valore
        })
        setRuoliSelect(ruoliSelezionabili);

        formik.setFieldValue('ruoli', r.join(','));

        // cancello il ruolo dalla select
        setRuoloSelezionatoModifica('')
    }

    const addEmailModificaConfigurazioneNotifica = () => {
        const emailsVisual = [...emailsTable, emailSelezionataModifica];
        const inputEmailRegExp = /^[A-Za-z0-9_.-]{1,50}@[A-Za-z0-9.-]{1,40}\.[A-Za-z0-9\-\.]{1,10}$/;
        if (emailsTable.includes(emailSelezionataModifica)) {
            formik.errors.listaDistribuzione = 'email repeated';
            setEmailSelezionataModifica('')
        } else if (!inputEmailRegExp.test(emailSelezionataModifica)) {
            formik.errors.listaDistribuzione = 'invalid email';
            setEmailSelezionataModifica('')
        } else {
            formik.errors.listaDistribuzione = '';
            setEmailsTable(emailsVisual)
            formik.setFieldValue('listaDistribuzione', emailsVisual.join(';'));
            setEmailSelezionataModifica('')
        }
    }

    const rimuoviEmailModificaConfigurazioneNotifica = (index: number, selectValue:string) => {
        const e = [...emailsTable];
        e.splice(e.findIndex(e => e === selectValue), 1)
        setEmailsTable(e)
        formik.setFieldValue('listaDistribuzione', e.join(';'));
    }


    const toggleFiltroModificaConfigurazioneNotifica = (e:any) => {
        formik.setFieldValue('filtro', e.target.checked);
    }

    const showSalvaConfigurazioneOperations = () => 
        props.operations?.elencoFunzionalita?.includes('CONFIGNOTIFICA_UPDATE')

    useEffect(() => {
        if (funzionalita) {
            dispatch(resetPlaceholder());
            dispatch(getContestoNotifiche()).unwrap().then(resp => {
                if (resp) {
                    dispatch(getPlaceholder({
                        idConfigurazioneNotifiche: funzionalita
                    }))
                    setFunzionalitaSelect(createSelectItems(resp.funzionalita))
                    setRuoliSelect(createSelectItems(resp.ruoli, 'value', 'label'))
                    dispatch(loadConfigurazione({idConfigurazioneNotifiche: funzionalita}))
                }
            })
        }
    },[dispatch, funzionalita]);

    useEffect(() => {
        if (loadConfigurazioneResult && loadConfigurazioneResult?.ruoli && contestoResult && contestoResult.ruoli && contestoResult.ruoli.length > 0) {

            formik.setFieldValue('funzionalita', loadConfigurazioneResult.funzionalita);

            const nomiRuoloDaServizio = loadConfigurazioneResult.ruoli;
            const ruoliSelezionabili = [...ruoliSelect];
            const ruoliDaVisualizzare:Array<string> = [];

            nomiRuoloDaServizio.forEach(ruolo => {
                const ruoloCompleto = contestoResult.ruoli && contestoResult.ruoli.filter( dbRuolo => dbRuolo.label === ruolo).at(0);
                if (!!ruoloCompleto && ruoloCompleto.valore && ruoloCompleto.label) {
                    ruoliDaVisualizzare.push(ruoloCompleto?.label);
                    ruoliSelezionabili.splice(ruoliSelect.findIndex(e => e.value === ruoloCompleto?.valore), 1);
                    setRuoliSelect(ruoliSelezionabili)
                }
            });
            formik.setFieldValue('ruoli', ruoliDaVisualizzare.join(','), false);
            setRuoliTableModifica(ruoliDaVisualizzare)

            if (loadConfigurazioneResult && loadConfigurazioneResult.listaDistribuzione) {
                formik.setFieldValue('listaDistribuzione', loadConfigurazioneResult.listaDistribuzione, true)
                setEmailsTable(split(loadConfigurazioneResult.listaDistribuzione,';'))
            }

            loadConfigurazioneResult.oggetto && formik.setFieldValue('oggetto', loadConfigurazioneResult.oggetto)
            loadConfigurazioneResult.testo && formik.setFieldValue('testo', loadConfigurazioneResult.testo)

            setFiltro(loadConfigurazioneResult.filtro)
            formik.setFieldValue('filtro', loadConfigurazioneResult.filtro)
        }
    }, [loadConfigurazioneResult, searchResult])

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_GESTIONE_NOTIFICHE} title={t("modifica notifica")!} />
            {loadConfigurazioneResult && (
            <div className="py-5">
                <div className="form-row mb-5">
                    <Col>
                        <CustomSelect label="Funzionalità"
                            placeholder="Seleziona funzionalità"
                            wrapperClass="required"
                            options={funzionalitaSelect}
                            name="funzionalita"
                            invalid={!!getFieldError(formik, "funzionalita")}
                            infoText={t(getFieldError(formik, "funzionalita")) || ""}
                            value={formik.values.funzionalita}
                            disabled={true}
                            onChange={formik.handleChange} />
                    </Col>
                </div>
                <div className="form-row mb-5">
                    <Col md={10}>
                        <CustomSelect
                            label="Ruoli"
                            wrapperClass="required"
                            options={ruoliSelect}
                            value={ruoloSelezionatoModifica}
                            placeholder={ruoliSelect.length > 0 ? "Seleziona ruoli" : "Nessun ruolo selezionabile"}
                            onChange={(e) => setRuoloSelezionatoModifica(e)}
                            invalid={!!getFieldError(formik, "ruoli")}
                            infoText={t(getFieldError(formik, "ruoli")) || ""}
                            name="ruoli"
                            disabled={ruoliSelect.length === 0}
                        />
                    </Col>
                    <div className="col-2">
                        <Button outline color='primary' onClick={() => addRuolo()}>
                            <Icon icon='it-plus-circle' aria-hidden size='sm' color="primary" />Aggiungi
                        </Button>
                    </div>
                    {ruoliTableModifica && ruoliTableModifica.length > 0 && (
                    <Table striped size='sm' className='col-md-11'>
                        <thead>
                            <tr>
                                <th></th>
                                <th className='col-md-10' >Ruoli</th>
                                <th className='col-md-2' ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {ruoliTableModifica?.map((value, index) =>
                            <tr key={value+ '_' + index}>
                                <td className='notSaveElement'>*</td>
                                <td> {contestoResult && contestoResult.ruoli && contestoResult.ruoli.map((ruolo) => ruolo.label === value ? ruolo.label : '')}</td>
                                <td> <Button size='xs' color='danger' outline onClick={() => rimuoviRuoloModifica (value)} >  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                    )}
                </div>
                <div className="form-row mb-5">
                    <Col md={10}>
                        <Input
                            type="text"
                            label="Lista di distribuzione"
                            id="input-emails"
                            placeholder="Inserisci una email"
                            wrapperClassName="col"
                            name="listaDistribuzione"
                            invalid={!!getFieldError(formik, 'listaDistribuzione')}
                            infoText={t(getFieldError(formik, "ruoli")) || ""}
                            value={emailSelezionataModifica}
                            onChange={(e:any) => setEmailSelezionataModifica(e.target.value)}
                        />
                    </Col>
                    <div className="col-2">
                        <Button outline color='primary' onClick={() => addEmailModificaConfigurazioneNotifica()}> <Icon icon='it-plus-circle' aria-hidden size='sm' color="primary" />Aggiungi</Button>
                    </div>
                    {emailsTable && emailsTable.length > 0 && (
                    <Table striped size='sm' className='col-md-11'>
                        <thead>
                            <tr>
                                <th></th>
                                <th className='col-md-10' >Lista di distribuzione</th>
                                <th className='col-md-2' ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailsTable?.map((value, index) =>
                            <tr key={index}>
                                <td className='notSaveElement'>*</td>
                                <td> {value}</td>
                                <td> <Button size='xs' color='danger' outline onClick={() => rimuoviEmailModificaConfigurazioneNotifica(index, value)}>  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                    )}
                </div>
                <div className="form-row mb-5">
                    <Col>
                        <Input
                            type="text"
                            label="Oggetto"
                            placeholder="Inserisci l'oggetto della mail"
                            id="input-oggetto"
                            name="oggetto"
                            value={formik.values.oggetto}
                            onChange={formik.handleChange}
                            wrapperClassName="form-group required col-md-12 modifica"
                            invalid={!!getFieldError(formik, "oggetto")}
                            infoText={t(getFieldError(formik, "oggetto")) || ""}
                        />
                    </Col>
                </div>
                {getPlaceholderResult && getPlaceholderResult?.length > 0 &&
                <div className="mb-5">
                    <label>Placeholder utilizzabili:</label><br />
                    {getPlaceholderResult?.map( (p,i) => <Badge style={{marginLeft: '5px', padding: '10px'}} key={p.codiceNotifica}>{p.placeholder!}</Badge>)}
                </div>
                }
                <div className="form-row mb-5">
                    <TextArea
                        label="Testo email"
                        placeholder="Inserisci il testo"
                        id="input-testo"
                        wrapperClassName="form-group required col-md-12 modifica"
                        name="testo"
                        value={formik.values.testo}
                        onChange={formik.handleChange}
                        invalid={!!getFieldError(formik, "testo")}
                        infoText={t(getFieldError(formik, "testo")) || ""}
                    />
                </div>
                <div className="form-row mb-5">
                    <Col md>
                        <Toggle
                            label="Invia notifica ai soli dipendenti della struttura organizzativa di riferimento"
                            defaultChecked={filtro}
                            disabled={false}
                            onClick={toggleFiltroModificaConfigurazioneNotifica}
                        />
                        <small className="text-description" color='muted'>
                            Se non abilitato, la notifica verrà inviata a tutti i dipendenti.
                        </small>
                    </Col>
                </div>
                <div className=" position-relative py-5">
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' disabled={!showSalvaConfigurazioneOperations()} className="mt-2 mr-2" onClick={formik.submitForm}>{t('Salva')}</Button>
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}

export default ModificaConfigurazioneNotifica;
