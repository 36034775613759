export const CODICE_UNIVOCO_PO = 'Codice Univoco EQ';
export const DENOMINAZIONE_PO = 'Denominazione EQ';
export const DECLARATORIA_PO = 'Declaratoria';
export const STRUTTURA_ORGANIZZATIVA_PO = 'Struttura Organizzativa';
export const DATA_INIZIO_VALIDITA_PO = 'Data inizio Validità';
export const DATA_FINE_VALIDITA_PO = 'Data Fine Validità'
export const TIPO_PO = 'Tipo EQ';
export const DIPENDENTE_REGIONALE = 'Dipendente Regionale';
export const CODICE_CID_DIPENDENTE = 'Codice CID Dipendente';
export const NOME_E_COGNOME_DIPENDENTE = 'Cognome e nome dipendente';
export const TIPO_INCARICO = 'Tipo incarico';
export const ATTO_DI_ASSGENAZIONE = 'Atto di assegnazione';
export const DATA_INIZIO_VALIDITA_INCARICO = 'Data inizio validità incarico';
export const DATA_FINE_VALIDITA_INCARICO = 'Data fine validità incarico';