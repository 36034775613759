import { FormGroup, Icon } from "design-react-kit";
import { Ref, forwardRef, useCallback, useImperativeHandle, useState } from "react";
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import './CustomAutocompleteStyle.scss';

export interface CustomAutocompleteProps {
  loadOptionsFn: any,
  label?: string,
  placeholder?: string
  id: string,
  value?: string,
  handleOptionSelect: any
  required?: boolean
  invalid?: boolean;
  infoText?: string;
  returnLabel?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
}

export type CustomAutocompleteRef = {
  resetAutocompleteForm: () => void;
}

const  CustomAutocomplete = forwardRef((props: CustomAutocompleteProps, ref: Ref<CustomAutocompleteRef>)=> { 

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<any>(props.value);

  const AutocompleteDropdownIndicator = useCallback(
    (props: any) => (
      <components.DropdownIndicator {...props}>
        <span style={{ padding: '0px 5px' }} aria-hidden='true'>
          <Icon icon='it-search' aria-hidden size='sm' />
        </span>
      </components.DropdownIndicator>
    ),
    []
  );
  const AutocompleteInput = useCallback((props: any) => {
    return <components.Input {...props} />;
  }, []);

  const resetAutocompleteForm = useCallback(() => {
      setInputValue('');
      setSelectedValue(null);
      handleValueChange(null);
  }, []);

  const setValueToForm = (input:string) =>{
    setSelectedValue(input)
  }

  useImperativeHandle(ref, () => ({
    resetAutocompleteForm,
    setValueToForm
  }),[resetAutocompleteForm])

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/[^A-Za-z0-9 ]/g, '');
    setInputValue(inputValue);
    return inputValue;
  };

  const handleValueChange = useCallback(
    (selection: { value: string; label: string } | null) => {
      setSelectedValue(selection);
      if (selection) {
        if (props.returnLabel) {
          props.handleOptionSelect(selection);
        } else {
          props.handleOptionSelect(selection.value);
        }
      } else {
        props.handleOptionSelect("");
      }
    },
    [props]
  );
  
  

  return (
    <FormGroup className={`${props.required ? 'required' : ''}  ${props.wrapperClassName || ''}`} >
      {props.label && 
        <label htmlFor={props.id} className="custom-autocomplete-label" >
          {props.label}
        </label>}
      <AsyncSelect
        isClearable={true}
        components={{
          DropdownIndicator: AutocompleteDropdownIndicator,
          Input: AutocompleteInput,
          IndicatorSeparator: null
        }}
        loadOptions={(inputValue, callback) => {
          props.loadOptionsFn(inputValue, callback);
        }}
        cacheOptions
        
        inputValue={inputValue}
        defaultOptions
        onInputChange={handleInputChange}
        onChange={(e: any) => { handleValueChange(e) }}
        classNamePrefix='react-autocomplete'
        placeholder={props.placeholder || 'Seleziona un valore'}
        value={selectedValue} 
        className={"opacity " + (props.invalid ? "is-invalid" : "") }
        isDisabled={props.disabled}
      />

      {!!props.infoText && (
        <small className="invalid-feedback form-text text-muted">{props.infoText}</small>
      )}
    </FormGroup>
  )
})

export default CustomAutocomplete;