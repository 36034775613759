import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAreaDocumentiStakeholder, getInfoStakeholder, modificaInfoStakeholder } from "../../store/stakeholderSlice";
import PageHeader from "../../components/common/page-header/PageHeader";
import { useTranslation } from "react-i18next";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import { Input } from "design-react-kit";
import { useNavigate } from "react-router-dom";
import { HREF_SH_AREA_PERSONALE } from "../../components/layout/sidemenu/sidemenuConstants";
import { Button } from "reactstrap";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useFormik } from "formik";
import { ScriviAreaPersonaleRequestDto } from "../../services/ms-anagrafica-unica";
import PageLoader from "../../components/common/page-loader/PageLoader";
import NotificationManagerComponent from "../../components/notification/NotificationManagerComponent";

const ModificaInformazioni = () => {
    const initialValues: ScriviAreaPersonaleRequestDto = {
        iban: '',
        telefono: '',
        cellulare: '',
        email: ''
    };

    const { searchStatus, searchResult, modificaStatus } = useAppSelector((state) => state.stakeholder)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(getInfoStakeholder()).unwrap().then( resp => {
            if (!!resp) {
                searchForm.setFieldValue('iban', resp.data.iban || '')
                searchForm.setFieldValue('telefono', resp.data.telefono || '')
                searchForm.setFieldValue('cellulare', resp.data.cellulare || '')
                searchForm.setFieldValue('email', resp.data.email || '')
            }
        })
    }, [])

    const schema = z.object({
        iban: z.string().max(27, { message: 'lengthIban' }).optional(),
        telefono: z.string().regex((/^\d+$/)).max(15, { message: 'lengthTelefonoCandidato' }).optional(),
        cellulare: z.string().regex((/^\d+$/)).max(15, { message: 'lenghtCellulare' }).optional(),
        email: z.string().trim().min(1).max(150, 'lenghtEmailCandidato').email({ message: "emailNonCorretta" }).optional(),
    });

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            dispatch(modificaInfoStakeholder(values)).unwrap().then( resp => resp && navigate(HREF_SH_AREA_PERSONALE));
        },
    });

    return (
        <div>
            <PageHeader showIcon={true}  urlBack={HREF_SH_AREA_PERSONALE} title={t("modifica informazioni")!} buttons={[]}/>
            <div className="mt-2 position-relative">
                {(searchStatus === STATUS_PENDING || modificaStatus === STATUS_PENDING) && (
                    <PageLoader loadingMessage="Ricerca in corso.." />
                )}
                {searchStatus === STATUS_REJECTED && (
                    <NotificationManagerComponent state='error' title='Errore'
                        content='Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio'/>
                )}
                {searchStatus === STATUS_FULLFILLED && !!searchResult && (
                    <>
                    <div className="form-row mt-5">
                        <Input
                            type="text"
                            label="IBAN"
                            id="iban"
                            placeholder="Inserisci IBAN"
                            wrapperClassName="col-md-12"
                            name="iban"
                            invalid={searchForm.touched && !!searchForm.errors.iban}
                            infoText={searchForm.touched && (t(searchForm.errors.iban || "") || "")}
                            value={searchForm.values.iban}
                            onChange={searchForm.handleChange}
                        />
                        <Input
                            type="text"
                            label="Telefono"
                            id="telefono"
                            placeholder="Inserisci telefono"
                            wrapperClassName="col-md-12"
                            name="telefono"
                            invalid={searchForm.touched && !!searchForm.errors.telefono}
                            infoText={searchForm.touched && (t(searchForm.errors.telefono || "") || "")}
                            value={searchForm.values.telefono}
                            onChange={searchForm.handleChange}
                        />
                        <Input
                            type="text"
                            label="Cellulare"
                            id="cellulare"
                            placeholder="Inserisci cellulare"
                            wrapperClassName="col-md-12"
                            name="cellulare"
                            invalid={searchForm.touched && !!searchForm.errors.cellulare}
                            infoText={searchForm.touched && (t(searchForm.errors.cellulare || "") || "")}
                            value={searchForm.values.cellulare}
                            onChange={searchForm.handleChange}
                        />
                        <Input
                            type="text"
                            label="Email"
                            id="email"
                            placeholder="Inserisci email"
                            wrapperClassName="col-md-12"
                            name="email"
                            invalid={!searchForm.touched && !searchForm.errors.email}
                            infoText={searchForm.touched && (t(searchForm.errors.email || "") || "")}
                            value={searchForm.values.email}
                            onChange={searchForm.handleChange}
                        />
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2 mr-2" onClick={() => searchForm.submitForm()}>Modifica</Button>
                        <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_SH_AREA_PERSONALE}`) }} >{t('annulla')} </Button>
                    </div></>
                )}
            </div>
        </div>
    )
}

export default ModificaInformazioni;