import { Accordion, AccordionBody, AccordionHeader, Button, Col, Input } from "design-react-kit";
import { FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest } from "../../../services/ms-anagrafica-unica";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import CustomSelect, { CustomSelectItem } from "../../common/custom-select/CustomSelect";

interface FormRicercaEQProps {
    idSo?: string,
    dominioTipiEQ: CustomSelectItem[]
    dominioStruttureOrganizzative: CustomSelectItem[]
    ricercaEqStrutturaOrganizzativa: Function,
    disabled?: boolean,
    elencoPoPerBandoInput: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest
}

const FormRicercaEQ = (props: FormRicercaEQProps) => {
    const { t } = useTranslation()
    const [formCollapseOpen, setFormCollapseOpen] = useState(true);

    enum TipoEQ {
        PO = 'PO',
        PO_EQUIPARATA = 'PO_EQUIPARATA'
    }

    const initialValues: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest = {
        idSo: "",
        tipoPosizioneOrganizzativa: undefined,
        dataFineAssociazioneDa: undefined,
        dataFineAssociazioneA: undefined,
        codiceEQ: undefined,
        descrizioneEQ: undefined,
        direction: undefined,
        pageNum: undefined,
        pageSize: undefined,
        q: undefined,
        sort: undefined
    }

    const searchSchema = z.object({
        idSo: z.string({required_error: 'Campo obbligatorio'}),
        tipoPosizioneOrganizzativa: z.nativeEnum(TipoEQ, {required_error: 'Campo obbligatorio'}),
        dataFineAssociazioneDa: z.coerce.date().optional(),
        dataFineAssociazioneA: z.coerce.date().optional(),
        codiceEQ: z.string().optional(),
        descrizioneEQ: z.string().optional(),
    })

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(searchSchema),
        onSubmit: (values) => {
            props.ricercaEqStrutturaOrganizzativa(values)
        },
    });

    useEffect(() => {
        searchForm.setValues({
            idSo: props.elencoPoPerBandoInput?.idSo || '',
            tipoPosizioneOrganizzativa: props.elencoPoPerBandoInput?.tipoPosizioneOrganizzativa || undefined,
            dataFineAssociazioneDa: props.elencoPoPerBandoInput?.dataFineAssociazioneDa || '',
            dataFineAssociazioneA: props.elencoPoPerBandoInput?.dataFineAssociazioneA || undefined,
            codiceEQ: props.elencoPoPerBandoInput?.codiceEQ || undefined,
            descrizioneEQ: props.elencoPoPerBandoInput?.descrizioneEQ || '',

        })
    }, [props.elencoPoPerBandoInput])

    const resetForm = () => {
        if(props.disabled){
            searchForm.setFieldValue("dataFineAssociazioneDa", "");
            searchForm.setFieldValue("dataFineAssociazioneA", "");
            searchForm.setFieldValue("codiceEQ", "");
            searchForm.setFieldValue("descrizioneEQ", "");
            props.ricercaEqStrutturaOrganizzativa({
                ...initialValues,idSo: 
                searchForm.getFieldMeta("idSo").value, 
                tipoPosizioneOrganizzativa: searchForm.getFieldMeta("tipoPosizioneOrganizzativa").value
            })
        } else{
            searchForm.resetForm();
            props.ricercaEqStrutturaOrganizzativa(initialValues)
        }
    }

    const getFieldErrorSearch = (
        searchForm: FormikProps<BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest>,
        fieldName: keyof BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest
    ): string => {
        if (searchForm.getFieldMeta(fieldName).touched) {
            return searchForm.errors[fieldName] || "";
        } else return "";
    };

    useEffect( () => {
        if (!!props.idSo) {
            searchForm.setFieldValue('idSo', props.idSo)
        }
    }, [props.idSo])

    return (
        <Accordion className='form-custom form-bg filtri-ricerca' background="active">
            <AccordionHeader
                active={formCollapseOpen}
                onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
            >
                {t("filtri di ricerca")}
            </AccordionHeader>
            <AccordionBody active={formCollapseOpen}>
                <div className="form-row mt-2">
                    <Input
                        type="hidden"
                        name="pageNum"
                        value={searchForm.values.pageNum}
                        onChange={searchForm.handleChange}
                    />
                    <Col md={6}>
                        <CustomSelect
                            options={props.dominioStruttureOrganizzative}
                            value={searchForm.values.idSo}
                            placeholder="Seleziona la struttura organizzativa"
                            invalid={!!getFieldErrorSearch(searchForm, "idSo")}
                            infoText={t(getFieldErrorSearch(searchForm, "idSo")) || ''}
                            onChange={(e) => searchForm.setFieldValue("idSo", e, true)}
                            name="idSo"
                            disabled={!!props.idSo || props.disabled}
                        />
                    </Col>
                    <Col md={6}>
                        <CustomSelect
                            options={props.dominioTipiEQ}
                            value={searchForm.values.tipoPosizioneOrganizzativa}
                            placeholder="Seleziona tipologia EQ"
                            invalid={!!getFieldErrorSearch(searchForm, "tipoPosizioneOrganizzativa")}
                            infoText={t(getFieldErrorSearch(searchForm, "tipoPosizioneOrganizzativa")) || ''}
                            onChange={(e) => searchForm.setFieldValue("tipoPosizioneOrganizzativa", e, true)}
                            name="tipi"
                            wrapperClass="col-md"
                            disabled={props.disabled}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            placeholder="Codici EQ (elementi lista separati da spazio o virgola)"
                            name="codiceEQ"
                            invalid={!!getFieldErrorSearch(searchForm, "codiceEQ")}
                            infoText={t(getFieldErrorSearch(searchForm, "codiceEQ")) || ''}
                            value={searchForm.values.codiceEQ || ''}
                            onChange={searchForm.handleChange}
                            wrapperClassName="form-group"
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            placeholder="Descrizione EQ"
                            name="descrizioneEQ"
                            invalid={!!getFieldErrorSearch(searchForm, "descrizioneEQ")}
                            infoText={t(getFieldErrorSearch(searchForm, "descrizioneEQ")) || ''}
                            value={searchForm.values.descrizioneEQ || ''}
                            onChange={searchForm.handleChange}
                            wrapperClassName="form-group"
                        />
                    </Col>
                    <div className="row col-12 mt-2">
                        <Input
                            type="date"
                            label="Data fine associazione da"
                            placeholder="Inserisci la data"
                            id="input-dataApertura"
                            name="dataFineAssociazioneDa"
                            invalid={!!getFieldErrorSearch(searchForm, "dataFineAssociazioneDa")}
                            infoText={t(getFieldErrorSearch(searchForm, "dataFineAssociazioneDa")) || ''}
                            value={searchForm.values.dataFineAssociazioneDa || ''}
                            onChange={searchForm.handleChange}
                            wrapperClassName="col-md-4"
                        />
                        <Input
                            type="date"
                            label="Data fine associazione a"
                            placeholder="Inserisci la data"
                            id="input-dataFineAssociazioneA"
                            name="dataFineAssociazioneA"
                            invalid={!!getFieldErrorSearch(searchForm, "dataFineAssociazioneA")}
                            infoText={t(getFieldErrorSearch(searchForm, "dataFineAssociazioneA")) || ''}
                            value={searchForm.values.dataFineAssociazioneA || ''}
                            onChange={searchForm.handleChange}
                            wrapperClassName="col-md-4"
                        />

                        <div className="d-flex flex-row-reverse col-4">
                            <Button
                                color="primary"
                                className="mt-2"
                                onClick={() => {
                                    searchForm.submitForm();
                                }}
                            >
                                Ricerca
                            </Button>
                            <Button
                                outline
                                color="primary"
                                className="mt-2 mr-2"
                                onClick={() => resetForm()}
                            >
                                Pulisci
                            </Button>
                        </div>
                    </div>
                </div>
            </AccordionBody>
        </Accordion>
    )
}

export default FormRicercaEQ;