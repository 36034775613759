import { Button, Col, FormGroup, Input, Label } from 'design-react-kit';
import  { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { HREF_ELENCO_DIPENDENTI_REG } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { detailsCesszioneDipendente, editCesszioneDipendente, loadMotiviCessazzioneSearch, resetEditStatus, resetInfoDecesso, resetInfoDettaglio } from '../../store/dipendentiRegionaliSlice';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { CessazioneDipendenteDtoMotivoCessazioneEnum, DatiAttoDto, DipendenteRegionaleCessaRequestDtoAllOf, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import CessazioneDipendenteLightDetails from '../../components/cessazione-dipendente-detail/CessazioneDipendenteLightDetails';
import InfoDecessoDipendenteComponent from '../../components/cessazione-dipendente-detail/InfoDecessoDipendenteComponent';
import { t } from 'i18next';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import PageHeader from '../../components/common/page-header/PageHeader';
import { showNotifica } from '../../store/loaderSlice';
import { NOTIFICA_STATO } from '../../utility/Notifica';
import DettaglioAtto from '../../components/dettaglio-atto-cifra/DettaglioAtto';
import { zodCifra } from '../../utility/formUtils';

const CessazioneDipendente = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const cessazioneDipendenteResult = useAppSelector((state) => state.dipendentiRegionali.detailsResult);
    const cessazioneDipendenteStatus = useAppSelector((state) => state.dipendentiRegionali.detailsStatus);
    const formSearchComponents = useAppSelector((state) => state.dipendentiRegionali.formSearchComponents);

    const [showInfoDecesso, setShowInfoDecesso] = useState<boolean>(false);
    const [datiAtto, setDatiAtto] = useState<DatiAttoDto>({});
    const [codiceCifra, setCodiceCifra] = useState<string>('');

    let motivoCessazioneOptions: CustomSelectItem[] = [];

    formSearchComponents?.stato?.forEach(s => {
        motivoCessazioneOptions.push({ label: s.label, value: s.valore });
    });

    useEffect(() => {
        dispatch(resetEditStatus());
        dispatch(resetInfoDettaglio());
        dispatch(loadMotiviCessazzioneSearch());
        resetInfoDecessoData();
        getDetailsFromApi();
    }, [0])

    const getId = () => {
        return Number(id!);
    }

    const getDetailsFromApi = () => {
        let idDipendente = getId();
        dispatch(detailsCesszioneDipendente({ idDipendente }));
    }

    const resetInfoDecessoData = () => {
        dispatch(resetInfoDecesso());
    }

    const initialValues: DipendenteRegionaleCessaRequestDtoAllOf = {
        dataFineRapporto: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.dataFineRapporto : undefined,
        collaboratore: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.collaboratore : false,
        motivoCessazione: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.motivoCessazione : undefined,
        numeroAttoCessazione: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.numeroAttoCessazione : undefined,
        idDocumento: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.idDocumento! : undefined,
        disattivazioneCredenzialiDominio: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.disattivazioneCredenzialiDominio : false,
        disattivazioneNumeroTelefono: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.disattivazioneNumeroTelefono : false,
        disattivazioneCasellaPosta: cessazioneDipendenteResult ? cessazioneDipendenteResult.cessazione?.disattivazioneCasellaPosta : false,
    };

    const schema = z.object({
        dataFineRapporto: z.coerce.date({ required_error: "Data fine rapporto è un campo obbligatorio" }),
        collaboratore: z.boolean().optional(),
        motivoCessazione: z.string({ required_error: "Motivo cessazione è un campo obbligatorio" }),
        numeroAttoCessazione: zodCifra(z).optional(),
        idDocumento: z.number().optional(),
        disattivazioneCredenzialiDominio: z.boolean({ required_error: "Selezionare Si o No" }),
        disattivazioneNumeroTelefono: z.boolean({ required_error: "Selezionare Si o No" }),
        disattivazioneCasellaPosta: z.boolean({ required_error: "Selezionare Si o No" }),
    })

    const formik: FormikProps<DipendenteRegionaleCessaRequestDtoAllOf> = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            if(!datiAtto.oggetto && values.numeroAttoCessazione){
                dispatch(showNotifica({
                    titolo: 'ATTENZIONE',
                    messaggio: 'Non è possibile proseguire: atto non presente. Caricare un atto valido!',
                    tipo: NOTIFICA_STATO.warning
                }))
            }else {
                console.log('Submit form', JSON.stringify(values, null, 2));
                values = {...values, datiAtto: {...datiAtto, codiceAtto: values.numeroAttoCessazione}}
                dispatch(editCesszioneDipendente({
                    idDipendente: cessazioneDipendenteResult?.id!,
                    patchDipendenteRegionaleRequest: { requestType: 'Cessazione', ...values }
                }));
        }
        }
    });

    const getFieldError = (
        form: FormikProps<DipendenteRegionaleCessaRequestDtoAllOf>,
        fieldName: keyof DipendenteRegionaleCessaRequestDtoAllOf
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] ?? "";
        } else return "";
    };

    const handleChange = (selectedOption: any) => {
        formik.setFieldValue('motivoCessazione', selectedOption);
        formik.handleChange(selectedOption);

        if (selectedOption === CessazioneDipendenteDtoMotivoCessazioneEnum.Decesso) {
            setShowInfoDecesso(true);
            formik.setFieldValue('disattivazioneCredenzialiDominio', true);
            formik.setFieldValue('disattivazioneNumeroTelefono', true);
            formik.setFieldValue('disattivazioneCasellaPosta', true);
        } else {
            resetInfoDecessoData();
            setShowInfoDecesso(false);
            formik.setFieldValue('disattivazioneCredenzialiDominio', false);
            formik.setFieldValue('disattivazioneNumeroTelefono', false);
            formik.setFieldValue('disattivazioneCasellaPosta', false);
        }
    };

    const setRadioButton = (fieldName: string, value: string | undefined) => {
        let val = value === 'true';
        formik.setFieldValue(fieldName, val, true);
        //formik.handleChange(fieldName);
    }

    const showInserisciCessazioneOperation = () => 
        props.operations?.elencoFunzionalita?.includes('DIPENDENTE_PATCH')

    const showInfoDecessoOperation = () => 
        props.operations?.elencoFunzionalita?.includes('DIPENDENTE_INFO_DECESSO')

    useEffect(() => {
        setDatiAtto(cessazioneDipendenteResult?.cessazione?.datiAtto || {});
        formik.setValues(initialValues)

        let isDecesso = !!(cessazioneDipendenteResult && cessazioneDipendenteResult.cessazione?.motivoCessazione === CessazioneDipendenteDtoMotivoCessazioneEnum.Decesso);
        console.log('isDecesso', isDecesso);
        setShowInfoDecesso(isDecesso)
    }, [cessazioneDipendenteResult]);

    const onChangeCodiceAtto = (el: any) => {
        formik.handleChange(el);
        setCodiceCifra(el.target.value)
        setDatiAtto({});
    }

    const notFoundDatiAtto = () => {
        dispatch(showNotifica({
            titolo: 'ATTENZIONE',
            messaggio: 'Nessun atto associato al codice inserito!',
            tipo: NOTIFICA_STATO.warning
        }))
    }

    return (
        <div className='cessazione-dipendente'>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_DIPENDENTI_REG} title='Gestione cessazione dipendente'></PageHeader>
            {cessazioneDipendenteStatus === STATUS_FULLFILLED &&
                <div>
                    <CessazioneDipendenteLightDetails detailsResult={cessazioneDipendenteResult!} showInfoDecesso={showInfoDecesso}></CessazioneDipendenteLightDetails>

                    <div className="row col-12 form-custom form-inserimento">
                        <Input
                            label="Data fine rapporto"
                            type="date"
                            placeholder="Inserisci la data"
                            id="input-dataFineRapporto"
                            wrapperClassName="form-group required col-12 mb-4"
                            name="dataFineRapporto"
                            value={formik.values.dataFineRapporto}
                            onChange={formik.handleChange}
                            invalid={!!getFieldError(formik, "dataFineRapporto")}
                            infoText={t(getFieldError(formik, "dataFineRapporto")) ?? ""}
                        />

                        <FormGroup check className="form-group col-12 ml-1">
                            <Input id='input-collaboratore' name="collaboratore" onChange={formik.handleChange} checked={formik.values.collaboratore} type='checkbox' />
                            <Label for='input-collaboratore' check>
                                Collaboratore
                            </Label>
                        </FormGroup>

                        <Col md="12">
                            <CustomSelect label="Motivo cessazione"
                                name="motivoCessazione"
                                placeholder="Seleziona motivo cessazione"
                                wrapperClass="form-group required"
                                options={motivoCessazioneOptions}
                                value={formik.values.motivoCessazione}
                                onChange={handleChange}
                                invalid={!!getFieldError(formik, "motivoCessazione")}
                                infoText={t(getFieldError(formik, "motivoCessazione")) ?? ""}
                            />
                        </Col>

                        <Col md="12">
                            {!showInfoDecesso || !showInfoDecessoOperation() ?
                                ''
                                :
                                <InfoDecessoDipendenteComponent id={cessazioneDipendenteResult!.id!} />
                            }
                        </Col>

                        <Input
                            label="Numero atto cessazione (formato <codiceAoo>/<anno>/<numeroProvvedimento>)"
                            type='text'
                            placeholder="Inserisci il numero atto cessazione"
                            id="input-numero-atto-cessazione"
                            wrapperClassName="form-group col-12 mb-1"
                            name="numeroAttoCessazione"
                            invalid={!!getFieldError(formik, "numeroAttoCessazione")}
                            infoText={t(getFieldError(formik, "numeroAttoCessazione")) || ""}
                            value={formik.values.numeroAttoCessazione}
                            onChange={(e) => onChangeCodiceAtto(e)}
                            disabled={showInfoDecesso && !showInfoDecessoOperation()}
                        />

                        {(formik.values.numeroAttoCessazione && !formik.errors.numeroAttoCessazione) && (
                            <Col md="12 mt-3 mb-4">
                                <DettaglioAtto 
                                    codiceCifra={codiceCifra}
                                    datiAtto={datiAtto} 
                                    titolo='Dati Atto Cessazione' 
                                    setDatiAtto={(e) => setDatiAtto(e)} 
                                    notFoundDatiAtto={() => notFoundDatiAtto()} 
                                ></DettaglioAtto>
                            </Col>
                        )}

                        <Col md="12 mt-3 mb-4">
                            <DocumentUpload documentName="Cessazione rapporto di lavoro" className="my-3" idDocumento={formik.values.idDocumento}
                                invalid={!!getFieldError(formik, "idDocumento")} infoText={t(getFieldError(formik, "idDocumento")) ?? ""}
                                setDocumentId={(id) => formik.setFieldValue('idDocumento', id)} />
                        </Col>

                        <Col md="12" className='required mb-3'>
                            <label className='font-weight-semibold col-12'>Disattivazione delle credenziali di dominio associate all'utente</label>
                            <FormGroup check inline className='my-0'>
                                <Input
                                    name='disattivazioneCredenzialiDominio'
                                    type='radio'
                                    id='disattivazioneCredenzialiDominio'
                                    value={'true'}
                                    checked={formik.values.disattivazioneCredenzialiDominio === true}
                                    onChange={(e) => setRadioButton('disattivazioneCredenzialiDominio', e.target.value)}
                                />
                                <Label check htmlFor='disattivazioneCredenzialiDominio'>
                                    Si
                                </Label>
                            </FormGroup>
                            <FormGroup check inline className='my-0'>
                                <Input
                                    name='disattivazioneCredenzialiDominio'
                                    type='radio'
                                    id='disattivazioneCredenzialiDominio1'
                                    value={'false'}
                                    checked={formik.values.disattivazioneCredenzialiDominio === false}
                                    onChange={(e) => setRadioButton('disattivazioneCredenzialiDominio', e.target.value)}
                                />
                                <Label check htmlFor='disattivazioneCredenzialiDominio1'>
                                    No
                                </Label>
                            </FormGroup>
                            {!!getFieldError(formik, "disattivazioneCredenzialiDominio") &&
                                <small className="invalid-feedback form-text text-muted">{t(getFieldError(formik, "disattivazioneCredenzialiDominio"))}</small>
                            }
                        </Col>
                        <Col md="12" className='required mb-3'>
                            <label className='font-weight-semibold col-12'>Disattivazione del numero di telefono</label>
                            <FormGroup check inline className='my-0'>
                                <Input
                                    name='disattivazioneNumeroTelefono'
                                    type='radio'
                                    id='disattivazioneNumeroTelefono'
                                    value={'true'}
                                    checked={formik.values.disattivazioneNumeroTelefono === true}
                                    onChange={(e) => setRadioButton('disattivazioneNumeroTelefono', e.target.value)}
                                />
                                <Label check htmlFor='disattivazioneNumeroTelefono'>
                                    Si
                                </Label>
                            </FormGroup>
                            <FormGroup check inline className='my-0'>
                                <Input
                                    name='disattivazioneNumeroTelefono'
                                    type='radio'
                                    id='disattivazioneNumeroTelefono1'
                                    value={'false'}
                                    checked={formik.values.disattivazioneNumeroTelefono === false}
                                    onChange={(e) => setRadioButton('disattivazioneNumeroTelefono', e.target.value)}
                                />
                                <Label check htmlFor='disattivazioneNumeroTelefono1'>
                                    No
                                </Label>
                            </FormGroup>
                            {!!getFieldError(formik, "disattivazioneNumeroTelefono") &&
                                <small className="invalid-feedback form-text text-muted">{t(getFieldError(formik, "disattivazioneNumeroTelefono"))}</small>
                            }
                        </Col>
                        <Col md="12" className='required mb-3'>
                            <label className='font-weight-semibold col-12'>Disattivazione della casella di posta elettronica</label>
                            <FormGroup check inline className='my-0'>
                                <Input
                                    name='disattivazioneCasellaPosta'
                                    type='radio'
                                    id='disattivazioneCasellaPosta'
                                    value={'true'}
                                    checked={formik.values.disattivazioneCasellaPosta === true}
                                    onChange={(e) => setRadioButton('disattivazioneCasellaPosta', e.target.value)}
                                />
                                <Label check htmlFor='disattivazioneCasellaPosta'>
                                    Si
                                </Label>
                            </FormGroup>
                            <FormGroup check inline className='my-0'>
                                <Input
                                    name='disattivazioneCasellaPosta'
                                    type='radio'
                                    id='disattivazioneCasellaPosta1'
                                    value={'false'}
                                    checked={formik.values.disattivazioneCasellaPosta === false}
                                    onChange={(e) => setRadioButton('disattivazioneCasellaPosta', e.target.value)}
                                />
                                <Label check htmlFor='disattivazioneCasellaPosta1'>
                                    No
                                </Label>
                            </FormGroup>
                            {!!getFieldError(formik, "disattivazioneCasellaPosta") &&
                                <small className="invalid-feedback form-text text-muted">{t(getFieldError(formik, "disattivazioneCasellaPosta"))}</small>
                            }

                        </Col>

                    </div>
                    <div className="postion-relative py-5">
                        <div className="d-flex flex-row-reverse pb-5">
                            <Button color='primary' disabled={!showInserisciCessazioneOperation()} className="mt-2" onClick={() => formik.submitForm()}>Conferma</Button>
                            <Button outline color='primary' className="mt-2 mr-2"
                                onClick={() => navigate(HREF_ELENCO_DIPENDENTI_REG)}>Indietro</Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CessazioneDipendente