import PageHeader from "../../components/common/page-header/PageHeader";
import { useTranslation } from "react-i18next";
import { HREF_RUBRICA_ELENCO_DIPENDENTI } from "../../components/layout/sidemenu/sidemenuConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { detailsDipendente, getNumeriTelefonoAssociabili, inserisciTelefonoCredenzialiEmail } from "../../store/rubricaSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import DipendenteDetailsComponent from "../../components/dipendente-detail-form/DipendenteDetailsComponent";
import { Button, Col, Input } from "design-react-kit";
import CustomSelect, { CustomSelectItem } from "../../components/common/custom-select/CustomSelect";
import { InserimentoInfoRubricaRequestDto } from "../../services/ms-anagrafica-unica";
import { FormikProps, useFormik } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

let initialValuesTelCred: InserimentoInfoRubricaRequestDto = {
    idNumeroTelefono: 0,
    credenzialiDominio: '', 
    passwordDominio: '',
    requestType: ''
}

const InserisciTelefonoCredenziali = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { dipendenteResult, dipendenteStatus, numeriAssociabiliResult, inserisciTelefonoCredenzialiEmailStatus} = useAppSelector((state) => state.rubrica)

    const validationSchemaTelCredConfirm = z.object({
        idNumeroTelefono: z.number({ required_error: 'required' }).gt(0, {message: 'required'}),
        credenzialiDominio: z.string({ required_error: 'required' }),
        passwordDominio: z.string({ required_error: 'required' })
    })

    let numerazioniAssociabili: CustomSelectItem[] = [];
    numeriAssociabiliResult?.forEach((s) => {
        numerazioniAssociabili.push({ label: s.label, value: s.valore });
    });
    
    const insertForm: FormikProps<any> = useFormik({
        initialValues: initialValuesTelCred,
        validationSchema: toFormikValidationSchema(validationSchemaTelCredConfirm),
        onSubmit: (values) => {
            dispatch(inserisciTelefonoCredenzialiEmail({
                id: Number(id),
                patchDatiRubricaDipendenteRequest: {
                    ...values,
                    requestType: 'Inserimento'
                }
            })).unwrap().then( resp => {
                if (resp) {
                    navigate(HREF_RUBRICA_ELENCO_DIPENDENTI)
                }})
        },
    })

    const getFieldError = (
        form: FormikProps<InserimentoInfoRubricaRequestDto>,
        fieldName: keyof InserimentoInfoRubricaRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const handleChange = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        if (selectedOption !== "") {
            insertForm.setFieldValue(name, selectedOption,false);
        } else {
            insertForm.setFieldValue(name, undefined,false);
        }
    };

    const submitConfirm = () => {
        setTimeout(() => {
            insertForm.submitForm();
        }, 500);
    }

    useEffect(() => {
        if (id !== '' && id !== undefined && id != null) {
            dispatch(detailsDipendente(Number.parseInt(id!))).unwrap().then( dip => {
                if (!dip.assegnazioneNumeroTelefono) {
                    dispatch(getNumeriTelefonoAssociabili());   
                } else {
                    insertForm.setFieldValue('idNumeroTelefono', dip.assegnazioneNumeroTelefono.codiceUnivoco)
                }
                if (!!dip.credenzialiDominio) {
                    insertForm.setFieldValue('credenzialiDominio', dip.credenzialiDominio)
                }
                if (!!dip.passwordDominio) {
                    insertForm.setFieldValue('passwordDominio', dip.credenzialiDominio)
                }
            })
        }
    }, [])

    return (
        <div>
            <PageHeader
                showIcon={true}
                urlBack={HREF_RUBRICA_ELENCO_DIPENDENTI} 
                title={t("Inserimento telefono e credenziali di dominio")!}
            />
            {dipendenteStatus === STATUS_FULLFILLED && (
            <div>
                <DipendenteDetailsComponent dipendente={dipendenteResult!} except={['email']}/>
                <div className="position-relative py-5">
                    <div className="row">
                        <Col>
                            <CustomSelect
                                label="Numerazioni libere"
                                placeholder="Seleziona un numero di telefono"
                                options={numerazioniAssociabili}
                                name="idNumeroTelefono"
                                invalid={!!getFieldError(insertForm, "idNumeroTelefono")}
                                infoText={t(getFieldError(insertForm, "idNumeroTelefono")) || ""}
                                value={insertForm.values.idNumeroTelefono}
                                onChange={(e) => { handleChange("idNumeroTelefono", e) }}
                                wrapperClass="required"
                            />
                        </Col>
                    </div>
                    <div className="row">
                        <Col>
                            <Input
                                label="Credenziali di dominio"
                                type="text"
                                placeholder="Inserisci le credenziali di dominio"
                                id="credenzialiDominio"
                                wrapperClassName="form-group required col-md-12 "
                                name="credenzialiDominio"
                                disabled={!!initialValuesTelCred.credenzialiDominio}
                                value={insertForm.values.credenzialiDominio}
                                onChange={(e) => handleChange("credenzialiDominio", e.target.value)}
                                invalid={!!getFieldError(insertForm, "credenzialiDominio")}
                                infoText={t(getFieldError(insertForm, "credenzialiDominio")) || ""}
                            />
                        </Col>
                        <Col>
                            <Input
                                label="Password di dominio"
                                type="password"
                                placeholder="Inserisci la password di dominio"
                                id="passwordDominio"
                                wrapperClassName="form-group required col-md-12 "
                                name="passwordDominio"
                                disabled={!!initialValuesTelCred.passwordDominio}
                                value={insertForm.values.passwordDominio}
                                onChange={(e) => handleChange("passwordDominio", e.target.value)}
                                invalid={!!getFieldError(insertForm, "passwordDominio")}
                                infoText={t(getFieldError(insertForm, "passwordDominio")) || ""}
                            />
                        </Col>
                    </div>
                </div>
                <div className="d-flex flex-row-reverse">
                    <Button color='primary' className="mt-2 mr-2" onClick={() => { submitConfirm(); }}>Conferma</Button>
                    <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_RUBRICA_ELENCO_DIPENDENTI}`) }} >{t('annulla')} </Button>
                </div>
            </div>
            )
            }
        </div>
    );
}

export default InserisciTelefonoCredenziali;