import { DipendenteRegionaleRubricaDto } from '../../services/ms-anagrafica-unica'

const DipendenteDetailsComponent = (props: {dipendente: DipendenteRegionaleRubricaDto, except: Array<string>}) => {
    return (
        <div className="col-sm-12">
            {props.dipendente != null &&
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light-candidato">
                        <h6 className="mb-3">
                            Riepilogo
                        </h6>
                        <hr />
                        <div className="row">
                            <dl className="col-sm-12">
                                <div className="row">
                                {!!props.dipendente.id && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Codice CID'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.id}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.cognome && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Cognome'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.cognome}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.nome && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Nome'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.nome}</dd>
                                    </div>
                                )}
                                </div>
                                <div className="row">
                                {!!props.dipendente.elencoStruttureOrganizzative?.at(0) && (
                                    <div className="col-sm-8">
                                        <dt className='col-sm-12'>{'Struttura organizzativa'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.elencoStruttureOrganizzative.at(0)?.descrizione}</dd>
                                    </div>
                                )}
                                </div>
                                <div className="row">
                                {!props.except.includes('assegnazioneNumeroTelefono') && !!props.dipendente.assegnazioneNumeroTelefono?.numeroTelefono && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Numero di telefono'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.assegnazioneNumeroTelefono.numeroTelefono}</dd>
                                    </div>
                                )}
                                {!props.except.includes('credenzialiDominio') && !!props.dipendente.credenzialiDominio && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Credenziali di dominio'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.credenzialiDominio}</dd>
                                    </div>
                                )}
                                {!props.except.includes('passwordDominio') && !!props.dipendente.passwordDominio && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Password di dominio'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.passwordDominio}</dd>
                                    </div>
                                )}
                                </div>
                                <div className="row">
                                {!props.except.includes('indirizzoEmail') && !!props.dipendente.email && (
                                    <div className="col-sm-12">
                                        <dt className='col-sm-12'>{'Email'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.email}</dd>
                                    </div>
                                )}
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default DipendenteDetailsComponent