import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CaricamentoMassivoBandiEqControllerApi, CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest, CaricamentoMassivoBandiEqControllerApiPreviewEqRequest, FormsControllerApi, UploadBandiEqContestoForm, UploadEqDto, } from "../services/ms-anagrafica-unica";
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED, } from "./store-constants";

interface GestioneMassivaState {
  contestoResult: UploadBandiEqContestoForm | null;
  contestoStatus: string;
  // status: "idle" | "loading" | "succeeded" | "failed";
  previewData: UploadEqDto[] | null;
  error: string | null;
  result: any | null;
  uploadResult:any;
  uploadStatus:string;
}

const initialState: GestioneMassivaState = {
  contestoResult: null,
  contestoStatus: "",
  // status: "idle",
  previewData: null,
  error: null,
  result: null,
  uploadResult: null,
  uploadStatus: "",
};

export const fetchUploadBandiEqContestoForm = createAsyncThunk(
  "gestioneMassiva/fetchUploadBandiEqContestoForm",
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getUploadBandiEqContestoForm();
    return response.data;
  }
);

export const previewEqData = createAsyncThunk(
  "gestioneMassiva/previewEq",
  async (resource: CaricamentoMassivoBandiEqControllerApiPreviewEqRequest) => {
    const api = new CaricamentoMassivoBandiEqControllerApi(MS_AU_CONFIG);
    const response = await api.previewEq(resource);
    return response.data;
  }
);

export const uploadEqData = createAsyncThunk(
  "gestioneMassiva/downloadLog",
  async (resource: CaricamentoMassivoBandiEqControllerApiCaricamentoMassivoRequest) => {
    const api = new CaricamentoMassivoBandiEqControllerApi(MS_AU_CONFIG);
    const response = await api.caricamentoMassivo(resource, {responseType: 'arraybuffer' }).then((response) => {
      if(response.data){
        return response;
      }else{
        return rejectWithValue("errore caricamento")
      }
    })
    return response.data;
  }
)

const gestioneMassivaSlice = createSlice({
  name: "gestioneMassiva",
  initialState,
  reducers: { 
    resetUploadStatus: (state) => {
    state.uploadStatus = '';
  }},
  extraReducers: (builder) => {
    builder.addCase(
      fetchUploadBandiEqContestoForm.pending,
      (state: any, action: any) => {
        state.contestoStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      fetchUploadBandiEqContestoForm.fulfilled,
      (state: GestioneMassivaState, action: any) => {
        state.contestoResult = action.payload;
        state.contestoStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      fetchUploadBandiEqContestoForm.rejected,
      (state: any, action: any) => {
        state.contestoStatus = STATUS_REJECTED;
      }
    );
    builder.addCase(
      previewEqData.pending,
      (state: any, action: any) => {
        state.contestoStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      previewEqData.fulfilled,
      (state: GestioneMassivaState, action: any) => {
        state.result = action.payload;
        state.contestoStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      previewEqData.rejected,
      (state: any, action: any) => {
        state.contestoStatus = STATUS_REJECTED;
      }
    );
    builder.addCase(
      uploadEqData.pending,
      (state: any, action: any) => {
        state.uploadStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      uploadEqData.fulfilled,
      (state: GestioneMassivaState, action: any) => {
        state.uploadResult = action.payload;
        state.uploadStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      uploadEqData.rejected,
      (state: any, action: any) => {
        state.uploadStatus = STATUS_REJECTED;
      }
    );
  },
});

export const {resetUploadStatus} = gestioneMassivaSlice.actions;

export default gestioneMassivaSlice.reducer;
function rejectWithValue(message: any): any {
  throw new Error("Function not implemented.");
}

