import CustomTab from "../../components/common/custom-tab/CustomTab";
import { useEffect, useState } from "react";
import { TabUtil } from "../../utility/TabUtil";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { getNotificheNonLette, loadStatiFormSearch, resetStatus, searchNotifiche } from "../../store/notificheSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { z } from "zod";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { CustomSelectItem } from "../../components/common/custom-select/CustomSelect";
import { NotificaDtoStatoEnum, RuoloUtenteAutenticatoDto } from "../../services/ms-anagrafica-unica";
import Pagination from "../../components/common/pagination/Pagination";
import PageTopHeader from "../../components/common/page-header/PageTopHeader";
import PageHeader from "../../components/common/page-header/PageHeader";
import imgScrivania from '../../assets/images/scrivania-foto.jpg'
import NotificationCardsComponent from "../../components/notification-card/NotificationCardsComponent";

const schema = z.object({
    stato: z.string().optional(),
});

const initialValues = {
    stato: "",
    notArchiviate: true,
    pageNum: 0,
    pageSize: 10,
    sort: "",
    direction: "",
};

const ScrivaniaVirtuale = (props: {operations: RuoloUtenteAutenticatoDto}) => {

    const [showAggiornaStatoNotifica, setShowAggiornaStatoNotifica] = useState<boolean>(false);
    const [showEliminaNotifica, setShowEliminaNotifica] = useState<boolean>(false);
    const [showElencoFeedback, setShowElencoFeedback] = useState<boolean>(false);
    const [showFornisciFeedback, setShowFornisciFeedback] = useState<boolean>(false);
    
    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });
    
    const [selectedTab, setSelectedTab] = useState<string>('Elenco');
    const tabOpt = TabUtil.getTabOption();
    const {
        searchResult,
        searchStatus,
        searchInput,
        updateStatus,
        deleteStatus,
        formSearchComponents,
        insertStatus
    } = useAppSelector((state) => state.notifiche);

    const { userInfoResult } = useAppSelector((state) => state.user)

    const showScrivaniaVirtualeItem = () => {
        (props.operations?.elencoFunzionalita?.includes('NOTIFICHE_PATCH') || userInfoResult?.candidato || userInfoResult?.dipendenteCessato) && setShowAggiornaStatoNotifica(true);
        (props.operations?.elencoFunzionalita?.includes('NOTIFICHE_DELETE') || userInfoResult?.cittadino || userInfoResult?.candidato || userInfoResult?.dipendenteCessato) && setShowEliminaNotifica(true);
        (props.operations?.elencoFunzionalita?.includes('NOTIFICHE_ELENCO_FEEDBACK') || userInfoResult?.cittadino || userInfoResult?.candidato || userInfoResult?.dipendenteCessato) && setShowElencoFeedback(true);
        (props.operations?.elencoFunzionalita?.includes('NOTIFICHE_INSERT_FEEDBACK') || userInfoResult?.cittadino || userInfoResult?.candidato || userInfoResult?.dipendenteCessato) && setShowFornisciFeedback(true);
    }

    const dispatch = useAppDispatch();

    useEffect(() => {
        showScrivaniaVirtualeItem();
        dispatch(resetStatus());
        dispatch(loadStatiFormSearch());
        dispatch(getNotificheNonLette({stato: NotificaDtoStatoEnum.NonLetta}));
        doSearch();
    }, []);

    let hasResults =
        searchResult && searchResult.totalElements
            ? searchResult.totalElements > 0
            : false;

    const handleChange = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        if (selectedOption === NotificaDtoStatoEnum.Archiviata) {
            searchForm.setFieldValue('notArchiviate', false);
        }
        searchForm.setFieldValue(name, selectedOption);
    };

    const handlePageChange = (pageNum: number) => {
        if (selectedTab === 'Archiviate') {
            searchForm.setFieldValue('notArchiviate', false);
        }
        searchForm.setFieldValue("pageNum", pageNum - 1);
        searchForm.handleSubmit();
    };

    useEffect(() => {
        searchForm.setValues({
            stato: searchInput?.stato || '',
            notArchiviate: searchInput?.notArchiviate || true,
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            sort: searchInput?.sort || 'data',
            direction: searchInput?.direction || 'DESC',
        })

        if (!!searchInput && !!searchInput.stato) {
            dispatch(searchNotifiche(searchInput));
        }

    }, [searchInput, updateStatus, deleteStatus]);

    let statoOptions: CustomSelectItem[] = [];
    formSearchComponents?.stato?.forEach((s) => {
        statoOptions.push({ label: s.label, value: s.valore });
    });

    const doSearch = () => {
        dispatch(searchNotifiche(searchForm.values));
    };

    const getList = (desc: string) => {
        setSelectedTab(desc);

        if (desc == 'Archiviate') {
            resetPageNumAndElement();
            handleChange('notArchiviate', false);
            searchForm.handleSubmit();
        } else {
            resetPageNumAndElement();
            handleChange('notArchiviate', true);
            searchForm.handleSubmit();
        }
        console.log('get: ', desc);
    }

    const resetPageNumAndElement = () => {
        searchForm.setFieldValue("pageNum", 0);
        searchForm.setFieldValue("pageSize", 10);
    }

    return (
        <div>
          
            <PageTopHeader title='Scrivania Virtuale di Anagrafica Unica' 
                descrizione="La Scrivania Virtuale è lo strumento atto a gestire lo scambio di notifiche interne tra gli utenti." 
              /*   subDescrizione="La Regione Puglia si articola in una segreteria generale, in posizioni dirigenziali
                    individuali e di funzione, in dipartimenti, sezioni e servizi che operano in modo coordinato con il Presidente della Giunta regionale" */
                    contextRight={<div className="image-scrivania"><img src={imgScrivania} title="" alt="" style={{width: '100%'}} /> </div>}  
            ></PageTopHeader>

            <div>
                <div className="m-3 "><PageHeader showIcon={false} title='Notifiche'></PageHeader></div>
                <CustomTab selectedTab={selectedTab} tabOptions={tabOpt} onItemClick={(desc) => getList(desc)}></CustomTab>

                <div className="mt-3 position-relative">
                    <div>
                        {searchStatus === STATUS_FULLFILLED && hasResults && (
                            <>
                                <div className={searchResult.totalPages === 1 ? 'mb-5' : ''}>
                                    <NotificationCardsComponent
                                        searchResult={searchResult}
                                        showAggiornaStatoNotifica={showAggiornaStatoNotifica}
                                        showEliminaNotifica={showEliminaNotifica}
                                        showElencoFeedback={showElencoFeedback}
                                        showFornisciFeedback={showFornisciFeedback}
                                    ></NotificationCardsComponent>

                                </div>
                                <Pagination
                                    totalCount={searchResult.totalElements || 0}
                                    siblingCount={1}
                                    currentPage={searchForm.values.pageNum + 1}
                                    pageSize={searchResult.pageSize || 10}
                                    onPageChange={(page: number) => {
                                        handlePageChange(page);
                                    }}
                                    className="justify-content-center"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ScrivaniaVirtuale;