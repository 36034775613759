import { useTranslation } from 'react-i18next'
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from '../../components/layout/sidemenu/sidemenuConstants'
import PageHeader from '../../components/common/page-header/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getContestoProposteIncarichi, saveStruttureOrganizzativeSelezionabili, saveDipendentiSelezionabili, getPropostaIncarico, reset, updatePropostaIncarico } from '../../store/proposteIncarichiSlice'
import { z } from 'zod'
import { DipendenteRegionaleEssenzialeDto, FormsControllerApi, IncaricoDirigenzialeDto, InsertUpdateIncarichiDirigenzialiRequestDto, InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum } from '../../services/ms-anagrafica-unica'
import { debounce } from 'lodash'
import { MS_AU_CONFIG, STATUS_FULLFILLED } from '../../store/store-constants'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useFormik } from 'formik'
import { Col, Input, Row, TextArea, Toggle } from 'design-react-kit'
import CustomAutocomplete from '../../components/common/custom-autocomplete/CustomAutocomplete'
import { Button } from 'reactstrap'
import DocumentUpload from '../../components/common/document-upload/DocumentUpload'
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect'
import './style.scss';
import { hideLoader, showLoader } from '../../store/loaderSlice'

const initialValues = {
    codiceIncaricoSo: '',
    candidatoInterno: true,
    inComando: false,
    tipoIncarico: '',
    note: '',
    nomeCognomeEsterno: '',
    codiceFiscaleEsterno: '',
    idDipendente: 0,
    idDocumento: 0,
};

const schemaConferma = z.object({
    codiceIncaricoSo: z.string(),
    candidatoInterno: z.boolean(),
    inComando: z.boolean(),
    tipoIncarico: z.string(),
    note: z.string().max(200).optional(),
    nomeCognomeEsterno: z.string().max(50).optional(),
    codiceFiscaleEsterno: z.string().max(16).optional(),
    idDipendente: z.number().gt(0).optional(),
    idDocumento: z.number().gt(0),
}).refine( data => !(data.candidatoInterno && data.idDipendente === undefined), 
    {
        message: "Selezionare il candidato interno",
        path: ["idDipendente"],
})
.refine( data => !(!data.candidatoInterno && data.nomeCognomeEsterno === undefined), 
    {
        message: "Inserire il nome e cognome del candidato esterno",
        path: ["nomeCognomeEsterno"],
})
.refine( data => !(!data.candidatoInterno && data.codiceFiscaleEsterno === undefined), 
    {
        message: "Inserire il codice fiscale del candidato esterno",
        path: ["codiceFiscaleEsterno"],
})

const schemaSalva = z.object({
    codiceIncaricoSo: z.string().optional(),
    candidatoInterno: z.boolean().optional(),
    inComando: z.boolean().optional(),
    tipoIncarico: z.string().optional(),
    note: z.string().optional(),
    nomeCognomeEsterno: z.string().max(50).optional(),
    codiceFiscaleEsterno: z.string().max(16).optional(),
    idDipendente: z.number().optional(),
    idDocumento: z.number().optional()
});

function ModificaPropostaIncarico() {
    const { t } = useTranslation()
    const { id } = useParams();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [tipoSalvataggio, setTipoSalvataggio] = useState('');
    const [tipoStruttura, setTipoStruttura] = useState('');
    const [codiceIncaricoSo, setCodiceIncaricoSo] = useState('');
    const [candidatoInterno, setCandidatoInterno] = useState<boolean>(false);
    const [inComando, setInComando] = useState<boolean>(false);
    const [tipoIncarico, setTipoIncarico] = useState('');
    const [protocolloUscita, setProtocolloUscita] = useState<string | undefined>('');
    const [note, setNote] = useState('');
    const [tipoIncaricoSelect, setTipoIncaricoSelect] = useState<CustomSelectItem[]>([]);
    const [dipRegionale, setDipRegionale] = useState<DipendenteRegionaleEssenzialeDto | undefined>()
    const [modificaStruttura, toggleModificaStruttura] = useState<boolean>(false);
    const [modificaCandidato, toggleModificaCandidato] = useState<boolean>(false);
    const { soSelezionabili, updateResult, updateStatus} = useAppSelector((state) => state.proposteIncarichi);
    const api = new FormsControllerApi(MS_AU_CONFIG);


    const populateData = (resp: IncaricoDirigenzialeDto) => {
        setCodiceIncaricoSo(resp.codiceIncaricoSo!)
        setTipoStruttura(resp.tipoStrutturaOrganizzativa!)
        setCandidatoInterno(resp.candidatoInterno!)
        setInComando(resp.inComando || false)
        setTipoIncarico(resp.tipoIncarico!)
        setProtocolloUscita(resp.nprotocolloUscita!)
        setNote(resp.note!)
        setDipRegionale(resp.dipendenteRegionale)

        propostaForm.setValues({
            codiceIncaricoSo: resp.codiceIncaricoSo!,
            candidatoInterno: resp.candidatoInterno!,
            inComando: resp.inComando || false,
            tipoIncarico: resp.tipoIncarico! || '',
            note:resp.note! || '',
            nomeCognomeEsterno: resp.nomeCognomeEsterno || '',
            codiceFiscaleEsterno: resp.codiceFiscaleEsterno || '',
            idDipendente: resp.dipendenteRegionale?.id!,
            idDocumento: resp.documenti?.at(0)?.documento?.id!
        })
    }

    useEffect( () => {
        dispatch(reset())
        caricaElencoSO();
        dispatch(getPropostaIncarico({id: Number(id)}))
            .unwrap()
            .then(resp => {
                populateData(resp);
        });
        dispatch(getContestoProposteIncarichi()).unwrap().then(resp => {
            if (resp) {
                const selectItems: Array<CustomSelectItem> = [];
                resp.tipiIncaricoDirigenziale && resp.tipiIncaricoDirigenziale.forEach((f: any) => {
                    selectItems.push({
                        value: f.valore,
                        label: f.label
                    })
                })
                setTipoIncaricoSelect(selectItems)
            }
        });
    }, [])

    const validationSchema = tipoSalvataggio === "CONFERMA" ? toFormikValidationSchema(schemaConferma) : toFormikValidationSchema(schemaSalva);

    const propostaForm = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            const tipoIncarico = values.tipoIncarico.charAt(0).toUpperCase() + values.tipoIncarico.slice(1).toLowerCase();
            const formInserimento: InsertUpdateIncarichiDirigenzialiRequestDto = {
                ...values,
                nomeCognomeEsterno: values.candidatoInterno ? undefined : values.nomeCognomeEsterno,
                codiceFiscaleEsterno: values.candidatoInterno ? undefined : values.codiceFiscaleEsterno,
                tipoIncarico: InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum[tipoIncarico as keyof typeof InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum],
                idDipendente: (values.candidatoInterno && values.idDipendente) ? values.idDipendente : undefined,
                idDocumento:  values.idDocumento || undefined,
            }
            dispatch(updatePropostaIncarico({
                id: Number(id),
                insertUpdateIncarichiDirigenzialiRequestDto: formInserimento
            })).then(resp => {
                if(tipoSalvataggio === "CONFERMA" && resp.payload)
                    navigate(HREF_ELENCO_INCARICHI_DIRIGENZIALI);
            })
        },
    });

    useEffect(() => {
        if(updateStatus === STATUS_FULLFILLED){
            toggleModificaStruttura(false);
            toggleModificaCandidato(false);
            populateData(updateResult!);
        }
    }, [updateResult]);

    const caricaElencoSO = async (inputValue: string = '') => {
        const response = await api.getElencoSO({
            descrizioneBreve: inputValue,
            tipoRichiesta: 'INSERIMENTO'
        });
        if (response.data.struttureOrganizzative)
            dispatch(saveStruttureOrganizzativeSelezionabili(response.data))
        return response.data.struttureOrganizzative;
    }

    const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
        let options: { value: string | undefined; label: string | undefined; }[] = []
        caricaElencoSO(inputValue)
            .then(listaSo => {
                listaSo?.forEach(d => {
                    options.push({ value: d.value, label: d.label });
                });
                callback(options);
        });
    }, 500)

    const autocompleteCandidatoInterno = debounce(async (inputValue: string, callback: any) => {
        dispatch(showLoader());
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoDipendenti({
            categorie: candidatoInterno ? ['CD','SZ','SR'] : [],
            nomeCompleto: inputValue
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (response.data.dipendenti) {
            dispatch(saveDipendentiSelezionabili(response.data))
            response.data.dipendenti.forEach(d => {
                options.push({ value: String(d.value), label: d.label });
            });
            dispatch(hideLoader());
        }
        callback(options);
    }, 500)

    const handleStrutturaOrganizzativaSelect = (value: string) => {
        propostaForm.setFieldValue('codiceIncaricoSo', value, false)
        const soSelezionata = soSelezionabili?.struttureOrganizzative && soSelezionabili?.struttureOrganizzative.filter( so => so.valore === value).at(0)
        const tipo = soSelezionata?.label.slice(0, soSelezionata?.label.indexOf(' - '))
        !!tipo && setTipoStruttura(tipo);
    }

    const impostaCandidatoInterno = () => {
        const candidatoInternoChanged = !candidatoInterno;
        setCandidatoInterno(candidatoInternoChanged);
        propostaForm.setFieldValue('candidatoInterno', candidatoInternoChanged, false)
        toggleModificaCandidato(false)
        propostaForm.setFieldValue('idDipendente', undefined, false)
    }
    
    const impostaInComando = () => {
        const inComandoChanged = !inComando;
        setInComando(inComandoChanged);
        propostaForm.setFieldValue('inComando', inComandoChanged, false)
    }

    const setDocumentId = (name: string, id: number | undefined) => {
        if (id) {
            propostaForm.setFieldValue(name, id, true);
        } else {
            console.error('errore durante la chiamata di caricamento documento');
        }
    }

    const submitConfirm = () => {
        propostaForm.setFieldValue('requestType', 'CONFERMA');
        propostaForm.values.idDipendente === 0 && propostaForm.setFieldValue('idDipendente', undefined, false);
        setTimeout(() => {
            propostaForm.submitForm();
        }, 500);
    }

    const submitSave = () => {
        propostaForm.setFieldValue('requestType', 'SALVA');
        setTimeout(() => {
            propostaForm.submitForm();
        }, 500);
    }

    const impostaTipoIncarico = (e:any) => {
        setTipoIncarico(e)
        propostaForm.setFieldValue('tipoIncarico', e, true)
    }

    const impostaNote = (e:any) => {
        setNote(e.target.value)
        propostaForm.setFieldValue('note', e.target.value, false)
    }

    const loadOptions = (soSelezionabili: any) => {
        if (!soSelezionabili || !soSelezionabili.struttureOrganizzative) return [];
        return soSelezionabili.struttureOrganizzative.map( (so:any) => ({
            value: so.valore,
            label: so.label
        }));
    }

    const changeModificaSo = () => {
        if(modificaStruttura)
            handleStrutturaOrganizzativaSelect(codiceIncaricoSo)
        toggleModificaStruttura(!modificaStruttura)
    }

    const changeModificaCandidato = () => {
        if(candidatoInterno && modificaCandidato)
            propostaForm.setFieldValue('idDipendente', dipRegionale?.id, false);
        toggleModificaCandidato(!modificaCandidato);
    }

    return (
        <div className='modifica-proposta-incarico'>
            <PageHeader showIcon={true}  urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI} title={t("modifica incarichi dirigenziali")!} />
            <div className='form-custom form-bg form-inserimento border'>
                <div className="form-row">
                    {!!modificaStruttura ? 
                    <Col style={{marginTop: '-10px'}}>
                        <div className='au-autocomplete'>
                            <CustomAutocomplete
                                required={true}
                                label="Denominazione SO"
                                placeholder="Seleziona un valore"
                                id="select-struttura-organizzativa"
                                invalid={!!propostaForm.errors.codiceIncaricoSo}
                                infoText={t(propostaForm.errors.codiceIncaricoSo || "") || ""}
                                loadOptionsFn={autocompleteStruttureOrganizzative}
                                handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelect(e)}
                            />
                            <Button className='form-group ml-1' type='button' onClick={() => changeModificaSo()}>
                                Annulla
                            </Button>
                        </div>
                    </Col>
                    : 
                    <>
                        <Col className='mt-4'>
                            <CustomSelect label="Denominazione SO"
                                placeholder="Seleziona tipologia"
                                wrapperClass="required"
                                options={loadOptions(soSelezionabili)}
                                name="tipologia-incarico"
                                disabled={true}
                                value={codiceIncaricoSo}
                                onChange={() => false} />
                        </Col>
                        <Button className='form-group mt-4' type='button' onClick={() => changeModificaSo()}>
                            Modifica struttura
                        </Button>
                    </> }
                </div>
                <div className="form-row">
                    <Col md="6">
                        <Input
                            type="text"
                            label="Codice struttura"
                            name="codice-struttura"
                            value={propostaForm.values.codiceIncaricoSo}
                            readOnly={true}
                            />
                    </Col>
                    <Col md="6">
                        <Input
                            type="text"
                            label="Tipo struttura"
                            name="tipo-struttura"
                            value={tipoStruttura}
                            readOnly={true}
                            />
                    </Col>
                </div> 
                <div className="form-row">
                        <div className='col-12 col-xl-4'>
                            {candidatoInterno ? 
                                <Toggle
                                    label="Candidato interno"
                                    disabled={false}
                                    defaultChecked={candidatoInterno}
                                    onClick={() => impostaCandidatoInterno()}
                            /> : 
                                <Toggle
                                    label="Candidato interno"
                                    disabled={false}
                                    onClick={() => impostaCandidatoInterno()}
                        />}
                        </div>
                    </div>
                <div className="form-row candidato-incarico">
                    {!!candidatoInterno ? 
                        modificaCandidato ? 
                            <Col style={{marginTop: '12px'}}>
                                <div className='au-autocomplete'>
                                    <CustomAutocomplete
                                        label="Candidato"
                                        placeholder="Seleziona un valore"
                                        id="select-candidato"
                                        value={String(propostaForm.values.idDipendente!)}
                                        invalid={!!propostaForm.errors.idDipendente}
                                        infoText={t(propostaForm.errors.idDipendente || "") || ""}
                                        loadOptionsFn={autocompleteCandidatoInterno}
                                        handleOptionSelect={(e: string) => propostaForm.setFieldValue('idDipendente', Number(e), true)}
                                        required={true}
                                    />
                                </div>
                            </Col> 
                            : 
                            <>
                                <Input
                                    type="text"
                                    label="Candidato"
                                    placeholder="Inserisci il Candidato"
                                    name="candidato"
                                    wrapperClassName="col col-md required mt-5"
                                    value={dipRegionale?.nomeCompleto || ''}
                                    invalid={!!propostaForm.errors.idDipendente}
                                    infoText={t(propostaForm.errors.idDipendente || "") || ""}
                                    readOnly={true}
                                />
                            </> 
                        : 
                        <>
                            <Input type="text"
                                label="Candidato"
                                id="nomeCognomeEsterno"
                                placeholder="Nome e cognome del candidato"
                                wrapperClassName="col mt-5 required"
                                name="nomeCognomeEsterno"
                                invalid={!!propostaForm.errors.nomeCognomeEsterno}
                                infoText={t(propostaForm.errors.nomeCognomeEsterno || "") || ""}
                                value={propostaForm.values.nomeCognomeEsterno}
                                onChange={(e) => propostaForm.setFieldValue('nomeCognomeEsterno', e.target.value, false)}
                                disabled={!modificaCandidato}
                            /> 
                            <Input type="text"
                                label="Codice Fiscale"
                                id="codiceFiscaleEsterno"
                                placeholder="Inserisci il codice fiscale del candidato"
                                wrapperClassName="col mt-5 required"
                                name="codiceFiscaleEsterno"
                                invalid={!!propostaForm.errors.codiceFiscaleEsterno}
                                infoText={t(propostaForm.errors.codiceFiscaleEsterno || "") || ""}
                                value={propostaForm.values.codiceFiscaleEsterno}
                                onChange={(e) => propostaForm.setFieldValue('codiceFiscaleEsterno', e.target.value, false)}
                                disabled={!modificaCandidato}
                            />
                        </>
                    }
                    <Button className='mt-5' type='button' onClick={() => changeModificaCandidato()}>
                        {!modificaCandidato ? 'Modifica candidato' : 'Annulla'}
                    </Button>
                </div>
                <div className="form-row">
                        <div className='col-12 col-xl-4'>
                            {propostaForm.values.inComando ? 
                                <Toggle
                                    label="In comando"
                                    disabled={false}
                                    defaultChecked  
                                    onClick={impostaInComando} 
                            /> : 
                                <Toggle
                                    label="In comando"
                                    disabled={false} 
                                    onClick={impostaInComando} 
                        />}  
                        </div>
                    </div>
                <div className="form-row mt-5">
                    <Col>
                        <CustomSelect label="Tipologia incarico"
                            placeholder="Seleziona tipologia"
                            wrapperClass="required"
                            options={tipoIncaricoSelect}
                            name="tipologia-incarico"
                            invalid={!!propostaForm.errors.tipoIncarico}
                            infoText={t(propostaForm.errors.tipoIncarico || "") || ""}
                            value={tipoIncarico}
                            onChange={(e) => impostaTipoIncarico(e)} />
                    </Col>
                </div>
                <TextArea
                    label="Note personale"
                    placeholder="Inserisci le note personali "
                    id="input-note"
                    name="note"
                    value={note}
                    onChange={(e) => impostaNote(e)}
                    invalid={!!propostaForm.errors.note}
                    infoText={t(propostaForm.errors.note || "") || ""}
                />
                {!!tipoStruttura && <div className="form-row">
                    <DocumentUpload
                        documentName={tipoStruttura === 'DIPARTIMENTO' ? t('dgr approvata') : t('proposta di incarico')}
                        className="col col-md-8 required my-3"
                        invalid={!!propostaForm.errors.idDocumento}
                        infoText={t(propostaForm.errors.idDocumento || "") || ""}
                        idDocumento={propostaForm.values.idDocumento}
                        setDocumentId={(idDocumento) => setDocumentId('idDocumento', idDocumento)}
                    />
                </div>}

                {protocolloUscita && (
                    <Input
                        type="text"
                        label="Numero protocollo"
                        wrapperClassName="col mt-5"
                        value={protocolloUscita}
                        readOnly={true}
                    />
                )}
            
                <div className="d-flex flex-row-reverse">
                    <Button color='primary' className="mt-2 mr-2" onClick={() => { submitConfirm(); setTipoSalvataggio('CONFERMA') }}>Conferma</Button>
                    <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSave(); setTipoSalvataggio('SALVA') }}>Salva</Button>
                    <Button color='secondary' className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`) }} >{t('annulla')} </Button>
                </div>
            </div>
        </div>
    )
}

export default ModificaPropostaIncarico