/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssegnazioneBandiPoDto } from '../model';
// @ts-ignore
import { AssegnazioniBandiPagedDtoAssegnazioneBandiPoEssenzialeDto } from '../model';
// @ts-ignore
import { AssegnazioniBandiPoPagedDtoAssegnazioneBandiPoDto } from '../model';
// @ts-ignore
import { AssociazioneBandiPoRequestDto } from '../model';
// @ts-ignore
import { BandoPoDto } from '../model';
// @ts-ignore
import { CandidaturaBandoPoDto } from '../model';
// @ts-ignore
import { CandidaturaBandoPoRequestDto } from '../model';
// @ts-ignore
import { CreaAggiornaBandoPoRequestDto } from '../model';
// @ts-ignore
import { DocumentoDto } from '../model';
// @ts-ignore
import { DocumentoRiepilogoCandidaturaRequestDto } from '../model';
// @ts-ignore
import { PagedDtoBandoPoDto } from '../model';
// @ts-ignore
import { PagedDtoPosizioneOrganizzativaDto } from '../model';
// @ts-ignore
import { ValutazioneBandoPoRequestDto } from '../model';
/**
 * BandiPosizioniOrganizzativeControllerApi - axios parameter creator
 * @export
 */
export const BandiPosizioniOrganizzativeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per l\'aggiornamento di un bando per posizioni organizzative
         * @summary Aggiornamento bando Posizione organizzativa
         * @param {string} id 
         * @param {CreaAggiornaBandoPoRequestDto} creaAggiornaBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggiornaBandoPo: async (id: string, creaAggiornaBandoPoRequestDto: CreaAggiornaBandoPoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggiornaBandoPo', 'id', id)
            // verify required parameter 'creaAggiornaBandoPoRequestDto' is not null or undefined
            assertParamExists('aggiornaBandoPo', 'creaAggiornaBandoPoRequestDto', creaAggiornaBandoPoRequestDto)
            const localVarPath = `/v1/bandi-po/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creaAggiornaBandoPoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'annullamento di una candidatura per un bando po
         * @summary Annullamento candidatura per bando po
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annullaCandidaturaBandoPo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annullaCandidaturaBandoPo', 'id', id)
            const localVarPath = `/v1/bandi-po/assegnazioni/{id}/candidatura`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'associazione tra gli incarichi e i dipendenti vincitori dei bandi
         * @summary Associazione dipendenti vincitori di un bando po agli incarichi
         * @param {string} id 
         * @param {AssociazioneBandiPoRequestDto} associazioneBandiPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associazioneBandiPo: async (id: string, associazioneBandiPoRequestDto: AssociazioneBandiPoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('associazioneBandiPo', 'id', id)
            // verify required parameter 'associazioneBandiPoRequestDto' is not null or undefined
            assertParamExists('associazioneBandiPo', 'associazioneBandiPoRequestDto', associazioneBandiPoRequestDto)
            const localVarPath = `/v1/bandi-po/{id}/associazione`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(associazioneBandiPoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la sottomissione di una nuova candidatura per un bando po
         * @summary Inserimento candidatura per bando po
         * @param {number} id 
         * @param {CandidaturaBandoPoRequestDto} candidaturaBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidaturaBandoPo: async (id: number, candidaturaBandoPoRequestDto: CandidaturaBandoPoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('candidaturaBandoPo', 'id', id)
            // verify required parameter 'candidaturaBandoPoRequestDto' is not null or undefined
            assertParamExists('candidaturaBandoPo', 'candidaturaBandoPoRequestDto', candidaturaBandoPoRequestDto)
            const localVarPath = `/v1/bandi-po/assegnazioni/{id}/candidatura`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidaturaBandoPoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'eliminazione di un bando per posizioni organizzative
         * @summary Eliminazione bando Posizione organizzativa
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBandoPo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBandoPo', 'id', id)
            const localVarPath = `/v1/bandi-po/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il dettaglio di un\'assegnazione
         * @summary Dettaglio assegnazione bando Posizione organizzativa
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioAssegnazioneBando: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dettaglioAssegnazioneBando', 'id', id)
            const localVarPath = `/v1/bandi-po/assegnazioni/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'elenco dei bandi per posizioni organizzative
         * @summary Dettaglio Bando Posizione organizzativa
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioBandoPo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dettaglioBandoPo', 'id', id)
            const localVarPath = `/v1/bandi-po/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il dettaglio candidature per assegnazione bando eq
         * @summary Dettaglio candidature per assegnazione bando eq
         * @param {number} id 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioCandidatureAssegnazioneBando: async (id: number, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dettaglioCandidatureAssegnazioneBando', 'id', id)
            const localVarPath = `/v1/bandi-po/assegnazioni/{id}/candidature`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il download dell\'xls di riepilogo bandi
         * @summary Download Export bandi
         * @param {'RIEPILOGO_ASSEGNAZIONI' | 'RIEPILOGO_CANDIDATURE'} tipo 
         * @param {string} [dataApertura] 
         * @param {string} [dataChiusura] 
         * @param {'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'} [stato] 
         * @param {string} [so] 
         * @param {string} [attoCifra] 
         * @param {string} [eq] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExportBandi: async (tipo: 'RIEPILOGO_ASSEGNAZIONI' | 'RIEPILOGO_CANDIDATURE', dataApertura?: string, dataChiusura?: string, stato?: 'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO', so?: string, attoCifra?: string, eq?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('downloadExportBandi', 'tipo', tipo)
            const localVarPath = `/v1/bandi-po/export/{tipo}`
                .replace(`{${"tipo"}}`, encodeURIComponent(String(tipo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataApertura !== undefined) {
                localVarQueryParameter['dataApertura'] = (dataApertura as any instanceof Date) ?
                    (dataApertura as any).toISOString().substr(0,10) :
                    dataApertura;
            }

            if (dataChiusura !== undefined) {
                localVarQueryParameter['dataChiusura'] = (dataChiusura as any instanceof Date) ?
                    (dataChiusura as any).toISOString().substr(0,10) :
                    dataChiusura;
            }

            if (stato !== undefined) {
                localVarQueryParameter['stato'] = stato;
            }

            if (so !== undefined) {
                localVarQueryParameter['so'] = so;
            }

            if (attoCifra !== undefined) {
                localVarQueryParameter['attoCifra'] = attoCifra;
            }

            if (eq !== undefined) {
                localVarQueryParameter['eq'] = eq;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'elenco delle po assegnate ai bandi
         * @summary Elenco assegnazioni bandi Posizioni organizzative
         * @param {string} [dataApertura] 
         * @param {string} [dataChiusura] 
         * @param {string} [dataFineAssociazioneDa] 
         * @param {string} [dataFineAssociazioneA] 
         * @param {'PO' | 'PO_EQUIPARATA'} [tipoPosizioneOrganizzativa] 
         * @param {string} [idSo] 
         * @param {string} [denominazionePo] 
         * @param {'APERTO' | 'CHIUSO'} [stato] 
         * @param {string} [codiceCifra] 
         * @param {string} [sede] 
         * @param {number} [durataIncarico] 
         * @param {'DA_18M' | 'TIPO_A' | 'TIPO_B' | 'TIPO_C' | 'TIPO_D' | 'TIPO_E'} [tipologiaBando] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoAssegnazioniBandiPo: async (dataApertura?: string, dataChiusura?: string, dataFineAssociazioneDa?: string, dataFineAssociazioneA?: string, tipoPosizioneOrganizzativa?: 'PO' | 'PO_EQUIPARATA', idSo?: string, denominazionePo?: string, stato?: 'APERTO' | 'CHIUSO', codiceCifra?: string, sede?: string, durataIncarico?: number, tipologiaBando?: 'DA_18M' | 'TIPO_A' | 'TIPO_B' | 'TIPO_C' | 'TIPO_D' | 'TIPO_E', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bandi-po/assegnazioni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataApertura !== undefined) {
                localVarQueryParameter['dataApertura'] = (dataApertura as any instanceof Date) ?
                    (dataApertura as any).toISOString().substr(0,10) :
                    dataApertura;
            }

            if (dataChiusura !== undefined) {
                localVarQueryParameter['dataChiusura'] = (dataChiusura as any instanceof Date) ?
                    (dataChiusura as any).toISOString().substr(0,10) :
                    dataChiusura;
            }

            if (dataFineAssociazioneDa !== undefined) {
                localVarQueryParameter['dataFineAssociazioneDa'] = (dataFineAssociazioneDa as any instanceof Date) ?
                    (dataFineAssociazioneDa as any).toISOString().substr(0,10) :
                    dataFineAssociazioneDa;
            }

            if (dataFineAssociazioneA !== undefined) {
                localVarQueryParameter['dataFineAssociazioneA'] = (dataFineAssociazioneA as any instanceof Date) ?
                    (dataFineAssociazioneA as any).toISOString().substr(0,10) :
                    dataFineAssociazioneA;
            }

            if (tipoPosizioneOrganizzativa !== undefined) {
                localVarQueryParameter['tipoPosizioneOrganizzativa'] = tipoPosizioneOrganizzativa;
            }

            if (idSo !== undefined) {
                localVarQueryParameter['idSo'] = idSo;
            }

            if (denominazionePo !== undefined) {
                localVarQueryParameter['denominazionePo'] = denominazionePo;
            }

            if (stato !== undefined) {
                localVarQueryParameter['stato'] = stato;
            }

            if (codiceCifra !== undefined) {
                localVarQueryParameter['codiceCifra'] = codiceCifra;
            }

            if (sede !== undefined) {
                localVarQueryParameter['sede'] = sede;
            }

            if (durataIncarico !== undefined) {
                localVarQueryParameter['durataIncarico'] = durataIncarico;
            }

            if (tipologiaBando !== undefined) {
                localVarQueryParameter['tipologiaBando'] = tipologiaBando;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'elenco delle po assegnate a un bando
         * @summary Elenco assegnazioni Posizioni organizzative di un bando
         * @param {string} idBando 
         * @param {string} [denominazionePo] 
         * @param {'S' | 'N'} [conteso] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoAssegnazioniPerBando: async (idBando: string, denominazionePo?: string, conteso?: 'S' | 'N', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idBando' is not null or undefined
            assertParamExists('elencoAssegnazioniPerBando', 'idBando', idBando)
            const localVarPath = `/v1/bandi-po/{idBando}/assegnazioni`
                .replace(`{${"idBando"}}`, encodeURIComponent(String(idBando)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (denominazionePo !== undefined) {
                localVarQueryParameter['denominazionePo'] = denominazionePo;
            }

            if (conteso !== undefined) {
                localVarQueryParameter['conteso'] = conteso;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'elenco dei bandi per posizioni organizzative
         * @summary Elenco bandi Posizioni organizzative
         * @param {string} [dataApertura] 
         * @param {string} [dataChiusura] 
         * @param {'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'} [stato] 
         * @param {string} [so] 
         * @param {string} [attoCifra] 
         * @param {string} [eq] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoBandiPo: async (dataApertura?: string, dataChiusura?: string, stato?: 'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO', so?: string, attoCifra?: string, eq?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bandi-po`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataApertura !== undefined) {
                localVarQueryParameter['dataApertura'] = (dataApertura as any instanceof Date) ?
                    (dataApertura as any).toISOString().substr(0,10) :
                    dataApertura;
            }

            if (dataChiusura !== undefined) {
                localVarQueryParameter['dataChiusura'] = (dataChiusura as any instanceof Date) ?
                    (dataChiusura as any).toISOString().substr(0,10) :
                    dataChiusura;
            }

            if (stato !== undefined) {
                localVarQueryParameter['stato'] = stato;
            }

            if (so !== undefined) {
                localVarQueryParameter['so'] = so;
            }

            if (attoCifra !== undefined) {
                localVarQueryParameter['attoCifra'] = attoCifra;
            }

            if (eq !== undefined) {
                localVarQueryParameter['eq'] = eq;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'elenco delle posizioni organizzative da includere in un bando
         * @summary Elenco posizioni organizzative da includere in un bando
         * @param {string} idSo 
         * @param {string} [dataFineAssociazioneDa] 
         * @param {string} [dataFineAssociazioneA] 
         * @param {'PO' | 'PO_EQUIPARATA'} [tipoPosizioneOrganizzativa] 
         * @param {string} [codiceEQ] 
         * @param {string} [descrizioneEQ] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoPoPerBando: async (idSo: string, dataFineAssociazioneDa?: string, dataFineAssociazioneA?: string, tipoPosizioneOrganizzativa?: 'PO' | 'PO_EQUIPARATA', codiceEQ?: string, descrizioneEQ?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idSo' is not null or undefined
            assertParamExists('elencoPoPerBando', 'idSo', idSo)
            const localVarPath = `/v1/bandi-po/posizioni-organizzative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataFineAssociazioneDa !== undefined) {
                localVarQueryParameter['dataFineAssociazioneDa'] = (dataFineAssociazioneDa as any instanceof Date) ?
                    (dataFineAssociazioneDa as any).toISOString().substr(0,10) :
                    dataFineAssociazioneDa;
            }

            if (dataFineAssociazioneA !== undefined) {
                localVarQueryParameter['dataFineAssociazioneA'] = (dataFineAssociazioneA as any instanceof Date) ?
                    (dataFineAssociazioneA as any).toISOString().substr(0,10) :
                    dataFineAssociazioneA;
            }

            if (tipoPosizioneOrganizzativa !== undefined) {
                localVarQueryParameter['tipoPosizioneOrganizzativa'] = tipoPosizioneOrganizzativa;
            }

            if (idSo !== undefined) {
                localVarQueryParameter['idSo'] = idSo;
            }

            if (codiceEQ !== undefined) {
                localVarQueryParameter['codiceEQ'] = codiceEQ;
            }

            if (descrizioneEQ !== undefined) {
                localVarQueryParameter['descrizioneEQ'] = descrizioneEQ;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo firmato con la candidatura
         * @summary Elimina l\'associazione del documento di riepilogo firmato con la candidatura
         * @param {number} id 
         * @param {number} idDocumentoFirmato 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminaDocumentoFirmato: async (id: number, idDocumentoFirmato: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eliminaDocumentoFirmato', 'id', id)
            // verify required parameter 'idDocumentoFirmato' is not null or undefined
            assertParamExists('eliminaDocumentoFirmato', 'idDocumentoFirmato', idDocumentoFirmato)
            const localVarPath = `/v1/bandi-po/candidatura/{id}/documento/firmato/{idDocumentoFirmato}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idDocumentoFirmato"}}`, encodeURIComponent(String(idDocumentoFirmato)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo con la candidatura
         * @summary Elimina l\'associazione del documento di riepilogo con la candidatura
         * @param {number} id 
         * @param {number} idDocumento 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminaDocumentoRiepilogo: async (id: number, idDocumento: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eliminaDocumentoRiepilogo', 'id', id)
            // verify required parameter 'idDocumento' is not null or undefined
            assertParamExists('eliminaDocumentoRiepilogo', 'idDocumento', idDocumento)
            const localVarPath = `/v1/bandi-po/candidatura/{id}/documento/{idDocumento}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idDocumento"}}`, encodeURIComponent(String(idDocumento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la generazione del documento di riepilogo di una candidatura ad un bando
         * @summary Generazione documento di riepilogo candidatura
         * @param {number} idAssegnazioneBando 
         * @param {DocumentoRiepilogoCandidaturaRequestDto} documentoRiepilogoCandidaturaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generaRiepilogo: async (idAssegnazioneBando: number, documentoRiepilogoCandidaturaRequestDto: DocumentoRiepilogoCandidaturaRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idAssegnazioneBando' is not null or undefined
            assertParamExists('generaRiepilogo', 'idAssegnazioneBando', idAssegnazioneBando)
            // verify required parameter 'documentoRiepilogoCandidaturaRequestDto' is not null or undefined
            assertParamExists('generaRiepilogo', 'documentoRiepilogoCandidaturaRequestDto', documentoRiepilogoCandidaturaRequestDto)
            const localVarPath = `/v1/bandi-po/genera-riepilogo/{idAssegnazioneBando}`
                .replace(`{${"idAssegnazioneBando"}}`, encodeURIComponent(String(idAssegnazioneBando)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoRiepilogoCandidaturaRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la generazione del documento firmato di riepilogo di una candidatura ad un bando
         * @summary Generazione documento firmato di riepilogo candidatura
         * @param {number} idAssegnazioneBando 
         * @param {string} password 
         * @param {string} otp 
         * @param {DocumentoRiepilogoCandidaturaRequestDto} documentoRiepilogoCandidaturaRequestDto 
         * @param {string} [codiceCertificato] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generaRiepilogoFirmato: async (idAssegnazioneBando: number, password: string, otp: string, documentoRiepilogoCandidaturaRequestDto: DocumentoRiepilogoCandidaturaRequestDto, codiceCertificato?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idAssegnazioneBando' is not null or undefined
            assertParamExists('generaRiepilogoFirmato', 'idAssegnazioneBando', idAssegnazioneBando)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('generaRiepilogoFirmato', 'password', password)
            // verify required parameter 'otp' is not null or undefined
            assertParamExists('generaRiepilogoFirmato', 'otp', otp)
            // verify required parameter 'documentoRiepilogoCandidaturaRequestDto' is not null or undefined
            assertParamExists('generaRiepilogoFirmato', 'documentoRiepilogoCandidaturaRequestDto', documentoRiepilogoCandidaturaRequestDto)
            const localVarPath = `/v1/bandi-po/genera-riepilogo-firmato/{idAssegnazioneBando}`
                .replace(`{${"idAssegnazioneBando"}}`, encodeURIComponent(String(idAssegnazioneBando)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codiceCertificato !== undefined) {
                localVarQueryParameter['codiceCertificato'] = codiceCertificato;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (otp !== undefined) {
                localVarQueryParameter['otp'] = otp;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoRiepilogoCandidaturaRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la creazione di un bando per posizioni organizzative
         * @summary Creazione bando Posizioni organizzativa
         * @param {CreaAggiornaBandoPoRequestDto} creaAggiornaBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nuovoBandoPo: async (creaAggiornaBandoPoRequestDto: CreaAggiornaBandoPoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creaAggiornaBandoPoRequestDto' is not null or undefined
            assertParamExists('nuovoBandoPo', 'creaAggiornaBandoPoRequestDto', creaAggiornaBandoPoRequestDto)
            const localVarPath = `/v1/bandi-po`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creaAggiornaBandoPoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la modifica dello stato di un bando per posizioni organizzative
         * @summary Riporta un bando Posizione organizzativa in lavorazione
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        riportaInLavorazione: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('riportaInLavorazione', 'id', id)
            const localVarPath = `/v1/bandi-po/riporta-in-lavorazione/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la valutazione delle candidatura per un bando po
         * @summary Valutazione candidature di un bando po
         * @param {number} id 
         * @param {ValutazioneBandoPoRequestDto} valutazioneBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneBandoPo: async (id: number, valutazioneBandoPoRequestDto: ValutazioneBandoPoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('valutazioneBandoPo', 'id', id)
            // verify required parameter 'valutazioneBandoPoRequestDto' is not null or undefined
            assertParamExists('valutazioneBandoPo', 'valutazioneBandoPoRequestDto', valutazioneBandoPoRequestDto)
            const localVarPath = `/v1/bandi-po/assegnazioni/{id}/valutazione`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valutazioneBandoPoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BandiPosizioniOrganizzativeControllerApi - functional programming interface
 * @export
 */
export const BandiPosizioniOrganizzativeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BandiPosizioniOrganizzativeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per l\'aggiornamento di un bando per posizioni organizzative
         * @summary Aggiornamento bando Posizione organizzativa
         * @param {string} id 
         * @param {CreaAggiornaBandoPoRequestDto} creaAggiornaBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggiornaBandoPo(id: string, creaAggiornaBandoPoRequestDto: CreaAggiornaBandoPoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BandoPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggiornaBandoPo(id, creaAggiornaBandoPoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'annullamento di una candidatura per un bando po
         * @summary Annullamento candidatura per bando po
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annullaCandidaturaBandoPo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annullaCandidaturaBandoPo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'associazione tra gli incarichi e i dipendenti vincitori dei bandi
         * @summary Associazione dipendenti vincitori di un bando po agli incarichi
         * @param {string} id 
         * @param {AssociazioneBandiPoRequestDto} associazioneBandiPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async associazioneBandiPo(id: string, associazioneBandiPoRequestDto: AssociazioneBandiPoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.associazioneBandiPo(id, associazioneBandiPoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la sottomissione di una nuova candidatura per un bando po
         * @summary Inserimento candidatura per bando po
         * @param {number} id 
         * @param {CandidaturaBandoPoRequestDto} candidaturaBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidaturaBandoPo(id: number, candidaturaBandoPoRequestDto: CandidaturaBandoPoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidaturaBandoPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidaturaBandoPo(id, candidaturaBandoPoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'eliminazione di un bando per posizioni organizzative
         * @summary Eliminazione bando Posizione organizzativa
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBandoPo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBandoPo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il dettaglio di un\'assegnazione
         * @summary Dettaglio assegnazione bando Posizione organizzativa
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dettaglioAssegnazioneBando(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssegnazioneBandiPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dettaglioAssegnazioneBando(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'elenco dei bandi per posizioni organizzative
         * @summary Dettaglio Bando Posizione organizzativa
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dettaglioBandoPo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BandoPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dettaglioBandoPo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il dettaglio candidature per assegnazione bando eq
         * @summary Dettaglio candidature per assegnazione bando eq
         * @param {number} id 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dettaglioCandidatureAssegnazioneBando(id: number, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidaturaBandoPoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dettaglioCandidatureAssegnazioneBando(id, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il download dell\'xls di riepilogo bandi
         * @summary Download Export bandi
         * @param {'RIEPILOGO_ASSEGNAZIONI' | 'RIEPILOGO_CANDIDATURE'} tipo 
         * @param {string} [dataApertura] 
         * @param {string} [dataChiusura] 
         * @param {'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'} [stato] 
         * @param {string} [so] 
         * @param {string} [attoCifra] 
         * @param {string} [eq] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadExportBandi(tipo: 'RIEPILOGO_ASSEGNAZIONI' | 'RIEPILOGO_CANDIDATURE', dataApertura?: string, dataChiusura?: string, stato?: 'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO', so?: string, attoCifra?: string, eq?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadExportBandi(tipo, dataApertura, dataChiusura, stato, so, attoCifra, eq, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'elenco delle po assegnate ai bandi
         * @summary Elenco assegnazioni bandi Posizioni organizzative
         * @param {string} [dataApertura] 
         * @param {string} [dataChiusura] 
         * @param {string} [dataFineAssociazioneDa] 
         * @param {string} [dataFineAssociazioneA] 
         * @param {'PO' | 'PO_EQUIPARATA'} [tipoPosizioneOrganizzativa] 
         * @param {string} [idSo] 
         * @param {string} [denominazionePo] 
         * @param {'APERTO' | 'CHIUSO'} [stato] 
         * @param {string} [codiceCifra] 
         * @param {string} [sede] 
         * @param {number} [durataIncarico] 
         * @param {'DA_18M' | 'TIPO_A' | 'TIPO_B' | 'TIPO_C' | 'TIPO_D' | 'TIPO_E'} [tipologiaBando] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elencoAssegnazioniBandiPo(dataApertura?: string, dataChiusura?: string, dataFineAssociazioneDa?: string, dataFineAssociazioneA?: string, tipoPosizioneOrganizzativa?: 'PO' | 'PO_EQUIPARATA', idSo?: string, denominazionePo?: string, stato?: 'APERTO' | 'CHIUSO', codiceCifra?: string, sede?: string, durataIncarico?: number, tipologiaBando?: 'DA_18M' | 'TIPO_A' | 'TIPO_B' | 'TIPO_C' | 'TIPO_D' | 'TIPO_E', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssegnazioniBandiPoPagedDtoAssegnazioneBandiPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elencoAssegnazioniBandiPo(dataApertura, dataChiusura, dataFineAssociazioneDa, dataFineAssociazioneA, tipoPosizioneOrganizzativa, idSo, denominazionePo, stato, codiceCifra, sede, durataIncarico, tipologiaBando, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'elenco delle po assegnate a un bando
         * @summary Elenco assegnazioni Posizioni organizzative di un bando
         * @param {string} idBando 
         * @param {string} [denominazionePo] 
         * @param {'S' | 'N'} [conteso] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elencoAssegnazioniPerBando(idBando: string, denominazionePo?: string, conteso?: 'S' | 'N', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssegnazioniBandiPagedDtoAssegnazioneBandiPoEssenzialeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elencoAssegnazioniPerBando(idBando, denominazionePo, conteso, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'elenco dei bandi per posizioni organizzative
         * @summary Elenco bandi Posizioni organizzative
         * @param {string} [dataApertura] 
         * @param {string} [dataChiusura] 
         * @param {'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'} [stato] 
         * @param {string} [so] 
         * @param {string} [attoCifra] 
         * @param {string} [eq] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elencoBandiPo(dataApertura?: string, dataChiusura?: string, stato?: 'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO', so?: string, attoCifra?: string, eq?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoBandoPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elencoBandiPo(dataApertura, dataChiusura, stato, so, attoCifra, eq, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'elenco delle posizioni organizzative da includere in un bando
         * @summary Elenco posizioni organizzative da includere in un bando
         * @param {string} idSo 
         * @param {string} [dataFineAssociazioneDa] 
         * @param {string} [dataFineAssociazioneA] 
         * @param {'PO' | 'PO_EQUIPARATA'} [tipoPosizioneOrganizzativa] 
         * @param {string} [codiceEQ] 
         * @param {string} [descrizioneEQ] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elencoPoPerBando(idSo: string, dataFineAssociazioneDa?: string, dataFineAssociazioneA?: string, tipoPosizioneOrganizzativa?: 'PO' | 'PO_EQUIPARATA', codiceEQ?: string, descrizioneEQ?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoPosizioneOrganizzativaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elencoPoPerBando(idSo, dataFineAssociazioneDa, dataFineAssociazioneA, tipoPosizioneOrganizzativa, codiceEQ, descrizioneEQ, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo firmato con la candidatura
         * @summary Elimina l\'associazione del documento di riepilogo firmato con la candidatura
         * @param {number} id 
         * @param {number} idDocumentoFirmato 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminaDocumentoFirmato(id: number, idDocumentoFirmato: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidaturaBandoPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminaDocumentoFirmato(id, idDocumentoFirmato, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo con la candidatura
         * @summary Elimina l\'associazione del documento di riepilogo con la candidatura
         * @param {number} id 
         * @param {number} idDocumento 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminaDocumentoRiepilogo(id: number, idDocumento: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidaturaBandoPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminaDocumentoRiepilogo(id, idDocumento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la generazione del documento di riepilogo di una candidatura ad un bando
         * @summary Generazione documento di riepilogo candidatura
         * @param {number} idAssegnazioneBando 
         * @param {DocumentoRiepilogoCandidaturaRequestDto} documentoRiepilogoCandidaturaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generaRiepilogo(idAssegnazioneBando: number, documentoRiepilogoCandidaturaRequestDto: DocumentoRiepilogoCandidaturaRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generaRiepilogo(idAssegnazioneBando, documentoRiepilogoCandidaturaRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la generazione del documento firmato di riepilogo di una candidatura ad un bando
         * @summary Generazione documento firmato di riepilogo candidatura
         * @param {number} idAssegnazioneBando 
         * @param {string} password 
         * @param {string} otp 
         * @param {DocumentoRiepilogoCandidaturaRequestDto} documentoRiepilogoCandidaturaRequestDto 
         * @param {string} [codiceCertificato] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generaRiepilogoFirmato(idAssegnazioneBando: number, password: string, otp: string, documentoRiepilogoCandidaturaRequestDto: DocumentoRiepilogoCandidaturaRequestDto, codiceCertificato?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generaRiepilogoFirmato(idAssegnazioneBando, password, otp, documentoRiepilogoCandidaturaRequestDto, codiceCertificato, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la creazione di un bando per posizioni organizzative
         * @summary Creazione bando Posizioni organizzativa
         * @param {CreaAggiornaBandoPoRequestDto} creaAggiornaBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nuovoBandoPo(creaAggiornaBandoPoRequestDto: CreaAggiornaBandoPoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BandoPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nuovoBandoPo(creaAggiornaBandoPoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la modifica dello stato di un bando per posizioni organizzative
         * @summary Riporta un bando Posizione organizzativa in lavorazione
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async riportaInLavorazione(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.riportaInLavorazione(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la valutazione delle candidatura per un bando po
         * @summary Valutazione candidature di un bando po
         * @param {number} id 
         * @param {ValutazioneBandoPoRequestDto} valutazioneBandoPoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valutazioneBandoPo(id: number, valutazioneBandoPoRequestDto: ValutazioneBandoPoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssegnazioneBandiPoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valutazioneBandoPo(id, valutazioneBandoPoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BandiPosizioniOrganizzativeControllerApi - factory interface
 * @export
 */
export const BandiPosizioniOrganizzativeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BandiPosizioniOrganizzativeControllerApiFp(configuration)
    return {
        /**
         * API Rest per l\'aggiornamento di un bando per posizioni organizzative
         * @summary Aggiornamento bando Posizione organizzativa
         * @param {BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggiornaBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest, options?: AxiosRequestConfig): AxiosPromise<BandoPoDto> {
            return localVarFp.aggiornaBandoPo(requestParameters.id, requestParameters.creaAggiornaBandoPoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'annullamento di una candidatura per un bando po
         * @summary Annullamento candidatura per bando po
         * @param {BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annullaCandidaturaBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.annullaCandidaturaBandoPo(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'associazione tra gli incarichi e i dipendenti vincitori dei bandi
         * @summary Associazione dipendenti vincitori di un bando po agli incarichi
         * @param {BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associazioneBandiPo(requestParameters: BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.associazioneBandiPo(requestParameters.id, requestParameters.associazioneBandiPoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la sottomissione di una nuova candidatura per un bando po
         * @summary Inserimento candidatura per bando po
         * @param {BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidaturaBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest, options?: AxiosRequestConfig): AxiosPromise<CandidaturaBandoPoDto> {
            return localVarFp.candidaturaBandoPo(requestParameters.id, requestParameters.candidaturaBandoPoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'eliminazione di un bando per posizioni organizzative
         * @summary Eliminazione bando Posizione organizzativa
         * @param {BandiPosizioniOrganizzativeControllerApiDeleteBandoPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiDeleteBandoPoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBandoPo(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il dettaglio di un\'assegnazione
         * @summary Dettaglio assegnazione bando Posizione organizzativa
         * @param {BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioAssegnazioneBando(requestParameters: BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest, options?: AxiosRequestConfig): AxiosPromise<AssegnazioneBandiPoDto> {
            return localVarFp.dettaglioAssegnazioneBando(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'elenco dei bandi per posizioni organizzative
         * @summary Dettaglio Bando Posizione organizzativa
         * @param {BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest, options?: AxiosRequestConfig): AxiosPromise<BandoPoDto> {
            return localVarFp.dettaglioBandoPo(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il dettaglio candidature per assegnazione bando eq
         * @summary Dettaglio candidature per assegnazione bando eq
         * @param {BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioCandidatureAssegnazioneBando(requestParameters: BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CandidaturaBandoPoDto>> {
            return localVarFp.dettaglioCandidatureAssegnazioneBando(requestParameters.id, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il download dell\'xls di riepilogo bandi
         * @summary Download Export bandi
         * @param {BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExportBandi(requestParameters: BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.downloadExportBandi(requestParameters.tipo, requestParameters.dataApertura, requestParameters.dataChiusura, requestParameters.stato, requestParameters.so, requestParameters.attoCifra, requestParameters.eq, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'elenco delle po assegnate ai bandi
         * @summary Elenco assegnazioni bandi Posizioni organizzative
         * @param {BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoAssegnazioniBandiPo(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AssegnazioniBandiPoPagedDtoAssegnazioneBandiPoDto> {
            return localVarFp.elencoAssegnazioniBandiPo(requestParameters.dataApertura, requestParameters.dataChiusura, requestParameters.dataFineAssociazioneDa, requestParameters.dataFineAssociazioneA, requestParameters.tipoPosizioneOrganizzativa, requestParameters.idSo, requestParameters.denominazionePo, requestParameters.stato, requestParameters.codiceCifra, requestParameters.sede, requestParameters.durataIncarico, requestParameters.tipologiaBando, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'elenco delle po assegnate a un bando
         * @summary Elenco assegnazioni Posizioni organizzative di un bando
         * @param {BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoAssegnazioniPerBando(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest, options?: AxiosRequestConfig): AxiosPromise<AssegnazioniBandiPagedDtoAssegnazioneBandiPoEssenzialeDto> {
            return localVarFp.elencoAssegnazioniPerBando(requestParameters.idBando, requestParameters.denominazionePo, requestParameters.conteso, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'elenco dei bandi per posizioni organizzative
         * @summary Elenco bandi Posizioni organizzative
         * @param {BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoBandiPo(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoBandoPoDto> {
            return localVarFp.elencoBandiPo(requestParameters.dataApertura, requestParameters.dataChiusura, requestParameters.stato, requestParameters.so, requestParameters.attoCifra, requestParameters.eq, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'elenco delle posizioni organizzative da includere in un bando
         * @summary Elenco posizioni organizzative da includere in un bando
         * @param {BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoPoPerBando(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest, options?: AxiosRequestConfig): AxiosPromise<PagedDtoPosizioneOrganizzativaDto> {
            return localVarFp.elencoPoPerBando(requestParameters.idSo, requestParameters.dataFineAssociazioneDa, requestParameters.dataFineAssociazioneA, requestParameters.tipoPosizioneOrganizzativa, requestParameters.codiceEQ, requestParameters.descrizioneEQ, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo firmato con la candidatura
         * @summary Elimina l\'associazione del documento di riepilogo firmato con la candidatura
         * @param {BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminaDocumentoFirmato(requestParameters: BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest, options?: AxiosRequestConfig): AxiosPromise<CandidaturaBandoPoDto> {
            return localVarFp.eliminaDocumentoFirmato(requestParameters.id, requestParameters.idDocumentoFirmato, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo con la candidatura
         * @summary Elimina l\'associazione del documento di riepilogo con la candidatura
         * @param {BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminaDocumentoRiepilogo(requestParameters: BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest, options?: AxiosRequestConfig): AxiosPromise<CandidaturaBandoPoDto> {
            return localVarFp.eliminaDocumentoRiepilogo(requestParameters.id, requestParameters.idDocumento, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la generazione del documento di riepilogo di una candidatura ad un bando
         * @summary Generazione documento di riepilogo candidatura
         * @param {BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generaRiepilogo(requestParameters: BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest, options?: AxiosRequestConfig): AxiosPromise<DocumentoDto> {
            return localVarFp.generaRiepilogo(requestParameters.idAssegnazioneBando, requestParameters.documentoRiepilogoCandidaturaRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la generazione del documento firmato di riepilogo di una candidatura ad un bando
         * @summary Generazione documento firmato di riepilogo candidatura
         * @param {BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generaRiepilogoFirmato(requestParameters: BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest, options?: AxiosRequestConfig): AxiosPromise<DocumentoDto> {
            return localVarFp.generaRiepilogoFirmato(requestParameters.idAssegnazioneBando, requestParameters.password, requestParameters.otp, requestParameters.documentoRiepilogoCandidaturaRequestDto, requestParameters.codiceCertificato, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la creazione di un bando per posizioni organizzative
         * @summary Creazione bando Posizioni organizzativa
         * @param {BandiPosizioniOrganizzativeControllerApiNuovoBandoPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nuovoBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiNuovoBandoPoRequest, options?: AxiosRequestConfig): AxiosPromise<BandoPoDto> {
            return localVarFp.nuovoBandoPo(requestParameters.creaAggiornaBandoPoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la modifica dello stato di un bando per posizioni organizzative
         * @summary Riporta un bando Posizione organizzativa in lavorazione
         * @param {BandiPosizioniOrganizzativeControllerApiRiportaInLavorazioneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        riportaInLavorazione(requestParameters: BandiPosizioniOrganizzativeControllerApiRiportaInLavorazioneRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.riportaInLavorazione(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la valutazione delle candidatura per un bando po
         * @summary Valutazione candidature di un bando po
         * @param {BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest, options?: AxiosRequestConfig): AxiosPromise<AssegnazioneBandiPoDto> {
            return localVarFp.valutazioneBandoPo(requestParameters.id, requestParameters.valutazioneBandoPoRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aggiornaBandoPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiAggiornaBandoPo
     */
    readonly id: string

    /**
     * 
     * @type {CreaAggiornaBandoPoRequestDto}
     * @memberof BandiPosizioniOrganizzativeControllerApiAggiornaBandoPo
     */
    readonly creaAggiornaBandoPoRequestDto: CreaAggiornaBandoPoRequestDto
}

/**
 * Request parameters for annullaCandidaturaBandoPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPo
     */
    readonly id: number
}

/**
 * Request parameters for associazioneBandiPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPo
     */
    readonly id: string

    /**
     * 
     * @type {AssociazioneBandiPoRequestDto}
     * @memberof BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPo
     */
    readonly associazioneBandiPoRequestDto: AssociazioneBandiPoRequestDto
}

/**
 * Request parameters for candidaturaBandoPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPo
     */
    readonly id: number

    /**
     * 
     * @type {CandidaturaBandoPoRequestDto}
     * @memberof BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPo
     */
    readonly candidaturaBandoPoRequestDto: CandidaturaBandoPoRequestDto
}

/**
 * Request parameters for deleteBandoPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiDeleteBandoPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiDeleteBandoPoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDeleteBandoPo
     */
    readonly id: string
}

/**
 * Request parameters for dettaglioAssegnazioneBando operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBando
     */
    readonly id: number
}

/**
 * Request parameters for dettaglioBandoPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioBandoPo
     */
    readonly id: string
}

/**
 * Request parameters for dettaglioCandidatureAssegnazioneBando operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBando
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBando
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBando
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBando
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBando
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBando
     */
    readonly q?: string
}

/**
 * Request parameters for downloadExportBandi operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest {
    /**
     * 
     * @type {'RIEPILOGO_ASSEGNAZIONI' | 'RIEPILOGO_CANDIDATURE'}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly tipo: 'RIEPILOGO_ASSEGNAZIONI' | 'RIEPILOGO_CANDIDATURE'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly dataApertura?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly dataChiusura?: string

    /**
     * 
     * @type {'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly stato?: 'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly so?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly attoCifra?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly eq?: string

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiDownloadExportBandi
     */
    readonly q?: string
}

/**
 * Request parameters for elencoAssegnazioniBandiPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly dataApertura?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly dataChiusura?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly dataFineAssociazioneDa?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly dataFineAssociazioneA?: string

    /**
     * 
     * @type {'PO' | 'PO_EQUIPARATA'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly tipoPosizioneOrganizzativa?: 'PO' | 'PO_EQUIPARATA'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly idSo?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly denominazionePo?: string

    /**
     * 
     * @type {'APERTO' | 'CHIUSO'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly stato?: 'APERTO' | 'CHIUSO'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly codiceCifra?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly sede?: string

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly durataIncarico?: number

    /**
     * 
     * @type {'DA_18M' | 'TIPO_A' | 'TIPO_B' | 'TIPO_C' | 'TIPO_D' | 'TIPO_E'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly tipologiaBando?: 'DA_18M' | 'TIPO_A' | 'TIPO_B' | 'TIPO_C' | 'TIPO_D' | 'TIPO_E'

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPo
     */
    readonly q?: string
}

/**
 * Request parameters for elencoAssegnazioniPerBando operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly idBando: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly denominazionePo?: string

    /**
     * 
     * @type {'S' | 'N'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly conteso?: 'S' | 'N'

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBando
     */
    readonly q?: string
}

/**
 * Request parameters for elencoBandiPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly dataApertura?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly dataChiusura?: string

    /**
     * 
     * @type {'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly stato?: 'IN_LAVORAZIONE' | 'IN_PUBBLICAZIONE' | 'APERTO' | 'CHIUSO' | 'TERMINATO'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly so?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly attoCifra?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly eq?: string

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoBandiPo
     */
    readonly q?: string
}

/**
 * Request parameters for elencoPoPerBando operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly idSo: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly dataFineAssociazioneDa?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly dataFineAssociazioneA?: string

    /**
     * 
     * @type {'PO' | 'PO_EQUIPARATA'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly tipoPosizioneOrganizzativa?: 'PO' | 'PO_EQUIPARATA'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly codiceEQ?: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly descrizioneEQ?: string

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiElencoPoPerBando
     */
    readonly q?: string
}

/**
 * Request parameters for eliminaDocumentoFirmato operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmato
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmato
     */
    readonly idDocumentoFirmato: number
}

/**
 * Request parameters for eliminaDocumentoRiepilogo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogo
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogo
     */
    readonly idDocumento: number
}

/**
 * Request parameters for generaRiepilogo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiGeneraRiepilogo
     */
    readonly idAssegnazioneBando: number

    /**
     * 
     * @type {DocumentoRiepilogoCandidaturaRequestDto}
     * @memberof BandiPosizioniOrganizzativeControllerApiGeneraRiepilogo
     */
    readonly documentoRiepilogoCandidaturaRequestDto: DocumentoRiepilogoCandidaturaRequestDto
}

/**
 * Request parameters for generaRiepilogoFirmato operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmato
     */
    readonly idAssegnazioneBando: number

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmato
     */
    readonly password: string

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmato
     */
    readonly otp: string

    /**
     * 
     * @type {DocumentoRiepilogoCandidaturaRequestDto}
     * @memberof BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmato
     */
    readonly documentoRiepilogoCandidaturaRequestDto: DocumentoRiepilogoCandidaturaRequestDto

    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmato
     */
    readonly codiceCertificato?: string
}

/**
 * Request parameters for nuovoBandoPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiNuovoBandoPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiNuovoBandoPoRequest {
    /**
     * 
     * @type {CreaAggiornaBandoPoRequestDto}
     * @memberof BandiPosizioniOrganizzativeControllerApiNuovoBandoPo
     */
    readonly creaAggiornaBandoPoRequestDto: CreaAggiornaBandoPoRequestDto
}

/**
 * Request parameters for riportaInLavorazione operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiRiportaInLavorazioneRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiRiportaInLavorazioneRequest {
    /**
     * 
     * @type {string}
     * @memberof BandiPosizioniOrganizzativeControllerApiRiportaInLavorazione
     */
    readonly id: string
}

/**
 * Request parameters for valutazioneBandoPo operation in BandiPosizioniOrganizzativeControllerApi.
 * @export
 * @interface BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest
 */
export interface BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest {
    /**
     * 
     * @type {number}
     * @memberof BandiPosizioniOrganizzativeControllerApiValutazioneBandoPo
     */
    readonly id: number

    /**
     * 
     * @type {ValutazioneBandoPoRequestDto}
     * @memberof BandiPosizioniOrganizzativeControllerApiValutazioneBandoPo
     */
    readonly valutazioneBandoPoRequestDto: ValutazioneBandoPoRequestDto
}

/**
 * BandiPosizioniOrganizzativeControllerApi - object-oriented interface
 * @export
 * @class BandiPosizioniOrganizzativeControllerApi
 * @extends {BaseAPI}
 */
export class BandiPosizioniOrganizzativeControllerApi extends BaseAPI {
    /**
     * API Rest per l\'aggiornamento di un bando per posizioni organizzative
     * @summary Aggiornamento bando Posizione organizzativa
     * @param {BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public aggiornaBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).aggiornaBandoPo(requestParameters.id, requestParameters.creaAggiornaBandoPoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'annullamento di una candidatura per un bando po
     * @summary Annullamento candidatura per bando po
     * @param {BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public annullaCandidaturaBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).annullaCandidaturaBandoPo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'associazione tra gli incarichi e i dipendenti vincitori dei bandi
     * @summary Associazione dipendenti vincitori di un bando po agli incarichi
     * @param {BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public associazioneBandiPo(requestParameters: BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).associazioneBandiPo(requestParameters.id, requestParameters.associazioneBandiPoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la sottomissione di una nuova candidatura per un bando po
     * @summary Inserimento candidatura per bando po
     * @param {BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public candidaturaBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).candidaturaBandoPo(requestParameters.id, requestParameters.candidaturaBandoPoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'eliminazione di un bando per posizioni organizzative
     * @summary Eliminazione bando Posizione organizzativa
     * @param {BandiPosizioniOrganizzativeControllerApiDeleteBandoPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public deleteBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiDeleteBandoPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).deleteBandoPo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il dettaglio di un\'assegnazione
     * @summary Dettaglio assegnazione bando Posizione organizzativa
     * @param {BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public dettaglioAssegnazioneBando(requestParameters: BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).dettaglioAssegnazioneBando(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'elenco dei bandi per posizioni organizzative
     * @summary Dettaglio Bando Posizione organizzativa
     * @param {BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public dettaglioBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).dettaglioBandoPo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il dettaglio candidature per assegnazione bando eq
     * @summary Dettaglio candidature per assegnazione bando eq
     * @param {BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public dettaglioCandidatureAssegnazioneBando(requestParameters: BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).dettaglioCandidatureAssegnazioneBando(requestParameters.id, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il download dell\'xls di riepilogo bandi
     * @summary Download Export bandi
     * @param {BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public downloadExportBandi(requestParameters: BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).downloadExportBandi(requestParameters.tipo, requestParameters.dataApertura, requestParameters.dataChiusura, requestParameters.stato, requestParameters.so, requestParameters.attoCifra, requestParameters.eq, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'elenco delle po assegnate ai bandi
     * @summary Elenco assegnazioni bandi Posizioni organizzative
     * @param {BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public elencoAssegnazioniBandiPo(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest = {}, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).elencoAssegnazioniBandiPo(requestParameters.dataApertura, requestParameters.dataChiusura, requestParameters.dataFineAssociazioneDa, requestParameters.dataFineAssociazioneA, requestParameters.tipoPosizioneOrganizzativa, requestParameters.idSo, requestParameters.denominazionePo, requestParameters.stato, requestParameters.codiceCifra, requestParameters.sede, requestParameters.durataIncarico, requestParameters.tipologiaBando, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'elenco delle po assegnate a un bando
     * @summary Elenco assegnazioni Posizioni organizzative di un bando
     * @param {BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public elencoAssegnazioniPerBando(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).elencoAssegnazioniPerBando(requestParameters.idBando, requestParameters.denominazionePo, requestParameters.conteso, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'elenco dei bandi per posizioni organizzative
     * @summary Elenco bandi Posizioni organizzative
     * @param {BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public elencoBandiPo(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest = {}, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).elencoBandiPo(requestParameters.dataApertura, requestParameters.dataChiusura, requestParameters.stato, requestParameters.so, requestParameters.attoCifra, requestParameters.eq, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'elenco delle posizioni organizzative da includere in un bando
     * @summary Elenco posizioni organizzative da includere in un bando
     * @param {BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public elencoPoPerBando(requestParameters: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).elencoPoPerBando(requestParameters.idSo, requestParameters.dataFineAssociazioneDa, requestParameters.dataFineAssociazioneA, requestParameters.tipoPosizioneOrganizzativa, requestParameters.codiceEQ, requestParameters.descrizioneEQ, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo firmato con la candidatura
     * @summary Elimina l\'associazione del documento di riepilogo firmato con la candidatura
     * @param {BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public eliminaDocumentoFirmato(requestParameters: BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).eliminaDocumentoFirmato(requestParameters.id, requestParameters.idDocumentoFirmato, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'eliminazione dell\'associazione del documento di riepilogo con la candidatura
     * @summary Elimina l\'associazione del documento di riepilogo con la candidatura
     * @param {BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public eliminaDocumentoRiepilogo(requestParameters: BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).eliminaDocumentoRiepilogo(requestParameters.id, requestParameters.idDocumento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la generazione del documento di riepilogo di una candidatura ad un bando
     * @summary Generazione documento di riepilogo candidatura
     * @param {BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public generaRiepilogo(requestParameters: BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).generaRiepilogo(requestParameters.idAssegnazioneBando, requestParameters.documentoRiepilogoCandidaturaRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la generazione del documento firmato di riepilogo di una candidatura ad un bando
     * @summary Generazione documento firmato di riepilogo candidatura
     * @param {BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public generaRiepilogoFirmato(requestParameters: BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).generaRiepilogoFirmato(requestParameters.idAssegnazioneBando, requestParameters.password, requestParameters.otp, requestParameters.documentoRiepilogoCandidaturaRequestDto, requestParameters.codiceCertificato, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la creazione di un bando per posizioni organizzative
     * @summary Creazione bando Posizioni organizzativa
     * @param {BandiPosizioniOrganizzativeControllerApiNuovoBandoPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public nuovoBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiNuovoBandoPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).nuovoBandoPo(requestParameters.creaAggiornaBandoPoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la modifica dello stato di un bando per posizioni organizzative
     * @summary Riporta un bando Posizione organizzativa in lavorazione
     * @param {BandiPosizioniOrganizzativeControllerApiRiportaInLavorazioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public riportaInLavorazione(requestParameters: BandiPosizioniOrganizzativeControllerApiRiportaInLavorazioneRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).riportaInLavorazione(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la valutazione delle candidatura per un bando po
     * @summary Valutazione candidature di un bando po
     * @param {BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandiPosizioniOrganizzativeControllerApi
     */
    public valutazioneBandoPo(requestParameters: BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest, options?: AxiosRequestConfig) {
        return BandiPosizioniOrganizzativeControllerApiFp(this.configuration).valutazioneBandoPo(requestParameters.id, requestParameters.valutazioneBandoPoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
