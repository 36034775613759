import { Alert, Button, Col, FormGroup, Input, Row } from "design-react-kit";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { AssegnazioneBandoPoRequestDto, CreaAggiornaBandoPoRequestDto, CreaAggiornaBandoPoRequestDtoFaseEnum, DatiAttoDto } from "../../../services/ms-anagrafica-unica";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { t } from 'i18next';
import DettaglioAtto from "../../dettaglio-atto-cifra/DettaglioAtto";
import { showNotifica } from "../../../store/loaderSlice";
import { NOTIFICA_STATO } from "../../../utility/Notifica";
import { useAppDispatch } from "../../../hooks";
import { zodCifra } from "../../../utility/formUtils";

interface FormRicercaEQProps {
    salvaDatiForm: Function
    idSo: string,
    elencoPo: Array<AssegnazioneBandoPoRequestDto>,
    cifra?: string,
    dataApertura?: string,
    dataChiusura?: string
    fase?: string,
    nuovoBando? :boolean
}


const FormDatiEQ = (props: FormRicercaEQProps) => {   
    const dispatch = useAppDispatch(); 
    const [tipoSalvataggio, setTipoSalvataggio] = useState<'SALVA' | 'CONFERMA' | undefined>(undefined);
    const [ datiAtto, setDatiAtto ] = useState<DatiAttoDto>({});
    const [ codiceCifra, setCodiceCifra ] = useState<string>('');
    const [ fase, setFase ] = useState<string>('');

    const initialValues: CreaAggiornaBandoPoRequestDto = {
        codiceCifraDeliberaDetermina: "",
        dataApertura: undefined,
        dataChiusura: undefined,
        idSo: undefined,
        elencoPo: [],
        requestType: undefined,
        fase: undefined
    }

    const insertSchemaSalva = z.object({
        codiceCifraDeliberaDetermina: zodCifra(z).optional(),
        dataApertura: z.coerce.date().optional(),
        dataChiusura: z.coerce.date().optional(),
        idSo: z.string().optional(),
        elencoPo: z.object({
            idPosizioneOrganizzativa: z.string()
        }).array().min(1, {message: 'Per il salvataggio in bozza del bando è necessario assegnare almeno una EQ'}),
        fase: z.string().optional()
    })

    const insertSchemaConferma = z.object({
        codiceCifraDeliberaDetermina: zodCifra(z, true),

        dataApertura: z.coerce.date({
            errorMap: (issue, { defaultError }) => ({
              message: issue.code === "invalid_date" ? "La data di apertura è un campo obbligatorio" : defaultError,
            }),
        }),
        dataChiusura: z.coerce.date({
            errorMap: (issue, { defaultError }) => ({
              message: issue.code === "invalid_date" ? "La data di chiusura è un campo obbligatorio" : defaultError,
            }),
        }),
        idSo: z.string({required_error: 'La struttura organizzativa è un campo obbligatorio'}),
        elencoPo: z.object({
            idPosizioneOrganizzativa: z.string()
        }).array().min(1, {message: 'Per la pubblicazione del bando è necessario assegnare almeno una EQ'}),
        fase: z.string({required_error: 'Selezionare la fase'})
    }).superRefine((data, context) => {
        if (data.dataChiusura <= data.dataApertura) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "La data di chiusura del bando deve essere maggiore della data di apertura",
                path: ["dataChiusura"]
            });
        }
    })

    let validationSchema = tipoSalvataggio && tipoSalvataggio === 'CONFERMA' ? toFormikValidationSchema(insertSchemaConferma) : toFormikValidationSchema(insertSchemaSalva)

    const insertForm = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(!datiAtto.oggetto && tipoSalvataggio === 'CONFERMA'){
                dispatch(showNotifica({
                    titolo: 'ATTENZIONE',
                    messaggio: 'Non è possibile proseguire: atto non presente. Caricare un atto valido!',
                    tipo: NOTIFICA_STATO.warning
                }))
            }else {
                const request:CreaAggiornaBandoPoRequestDto = {
                    ...values,
                    requestType: tipoSalvataggio,
                    datiAtto: {...datiAtto, codiceAtto: values.codiceCifraDeliberaDetermina}
                }
                props.salvaDatiForm(request)
            }
        },
    });

    useEffect( () => {
        initForm();
    }, []);

    useEffect( () => {
        insertForm.setFieldValue('elencoPo', props.elencoPo);
    },[props.elencoPo])

    useEffect( () => {
        if (tipoSalvataggio === 'SALVA') {
            insertForm.submitForm()
        }
        if (tipoSalvataggio === 'CONFERMA') {
            insertForm.submitForm()
        }
    }, [tipoSalvataggio])

    const initForm = () => {
        if(insertForm.getFieldMeta("codiceCifraDeliberaDetermina").value !== props.cifra){
            setDatiAtto({});
            setCodiceCifra(props.cifra!)
            insertForm.setFieldValue('codiceCifraDeliberaDetermina', props.cifra || undefined)
        }
        insertForm.setFieldValue('idSo', props.idSo)
        insertForm.setFieldValue('dataApertura', props.dataApertura)
        insertForm.setFieldValue('dataChiusura', props.dataChiusura)
        insertForm.setFieldValue('fase', props.fase || undefined)
        setFase(props.fase!)
    }

    const submitConfirm = () => {
        if(tipoSalvataggio === 'CONFERMA') {
            insertForm.submitForm()
        } else {
            setTipoSalvataggio('CONFERMA')
        }
    }

    const submitSave = () => {
        if(tipoSalvataggio === 'SALVA') {
            insertForm.submitForm()
        } else {
            setTipoSalvataggio('SALVA')
        }
    }

    const submitAzzera = () => {
        if(props.nuovoBando){
            insertForm.setFieldValue('codiceCifraDeliberaDetermina', undefined)
            insertForm.setFieldValue('dataApertura', undefined)
            insertForm.setFieldValue('dataChiusura', undefined)
            insertForm.setFieldValue('fase', undefined)
            setDatiAtto({});
            setCodiceCifra("")
            setFase("")
        } else {
            initForm();
        }
        setTipoSalvataggio(undefined)
    }

    const onChangeCodiceAtto = (el: any) => {
        insertForm.handleChange(el);
        setDatiAtto({});
        setCodiceCifra(el.target.value)
    }

    const notFoundDatiAtto = () => {
        dispatch(showNotifica({
            titolo: 'ATTENZIONE',
            messaggio: 'Nessun atto associato al codice CIFRA inserito!',
            tipo: NOTIFICA_STATO.warning
        }))
    }

    const checkFasePrimaApplicazione = () => {
        setFase(CreaAggiornaBandoPoRequestDtoFaseEnum.PrimaApplicazione);
        insertForm.setFieldValue('fase', CreaAggiornaBandoPoRequestDtoFaseEnum.PrimaApplicazione)
    }
    const checkFaseARegime = () => {
        setFase(CreaAggiornaBandoPoRequestDtoFaseEnum.Regime);
        insertForm.setFieldValue('fase', CreaAggiornaBandoPoRequestDtoFaseEnum.Regime);
    }

    return (
        <>
            <div className="form-custom form-bg form-inserimento border pb-0">
                <Row className="mt-3">
                    <Col xl={6} className="px-1">
                        <Input
                            label="Codice CIFRA atto (formato <codiceAoo>/<anno>/<numeroProvvedimento>)"
                            placeholder="Codice CIFRA atto"
                            type="text"
                            id="input-codice-cifra-atto"
                            name="codiceCifraDeliberaDetermina"
                            invalid={!!insertForm.errors.codiceCifraDeliberaDetermina}
                            infoText={t(insertForm.errors.codiceCifraDeliberaDetermina!) || ""}
                            value={insertForm.values.codiceCifraDeliberaDetermina || ''}
                            onChange={(e) => onChangeCodiceAtto(e)}
                            className="required"
                        />
                    </Col>
                    <Col xl={3} className="px-1">
                        <Input
                            type="date"
                            label="Data Apertura"
                            placeholder="Inserisci la data"
                            id="input-dataApertura"
                            name="dataApertura"
                            invalid={!!insertForm.errors.dataApertura}
                            infoText={insertForm.errors.dataApertura}
                            value={insertForm.values.dataApertura || ''}
                            onChange={(e) => insertForm.setFieldValue('dataApertura', e.target.value)}
                            wrapperClassName="form-group required"
                        />
                    </Col>
                    <Col xl={3} className="px-1">
                        <Input
                            type="date"
                            label="Data Chiusura"
                            placeholder="Inserisci la data"
                            id="input-dataChiusura"
                            name="dataChiusura"
                            invalid={!!insertForm.errors.dataChiusura}
                            infoText={insertForm.errors.dataChiusura}
                            value={insertForm.values.dataChiusura || ''}
                            onChange={(e) => insertForm.setFieldValue('dataChiusura', e.target.value)}
                            wrapperClassName="form-group required"
                        />
                    </Col>
                    <Col xl={12} className="px-1">
                        <FormGroup check inline className="">
                            <Input
                                type="checkbox"
                                label="Prima Applicazione"
                                id="prima_applicazione"
                                checked={fase === CreaAggiornaBandoPoRequestDtoFaseEnum.PrimaApplicazione}
                                name="prima_applicazione"
                                onChange={checkFasePrimaApplicazione}
                                wrapperClassName="mb-0"
                            />
                            <Input
                                type="checkbox"
                                label="A Regime"
                                checked={fase === CreaAggiornaBandoPoRequestDtoFaseEnum.Regime}
                                id="regime"
                                name="regime"
                                onChange={checkFaseARegime}
                                wrapperClassName="mb-0 ml-4"
                            />
                        </FormGroup>
                        <small className="invalid-feedback form-text text-muted mt-0">{insertForm.errors.fase || ""}</small>
                    </Col>
                </Row>
                <Row>
                    {(insertForm.values.codiceCifraDeliberaDetermina && !insertForm.errors.codiceCifraDeliberaDetermina) && (
                        <Col md="12 mb-4">
                            <DettaglioAtto 
                                codiceCifra={codiceCifra}
                                datiAtto={datiAtto} 
                                setDatiAtto={(e) => setDatiAtto(e)} 
                                notFoundDatiAtto={() => notFoundDatiAtto()} 
                            ></DettaglioAtto>
                        </Col>
                    )}
                </Row>
                {tipoSalvataggio && insertForm.errors && insertForm.errors.elencoPo && (
                    <Alert color="warning">
                        { insertForm.errors.elencoPo}
                    </Alert>
                )}
            </div>
            <div className="d-flex flex-row-reverse mt-2">
                <Button color='primary' className="mt-2 mr-2" onClick={() => submitConfirm()}>Conferma</Button>
                <Button color='primary' className="mt-2 mr-2" onClick={() => submitSave()}>Salva</Button>
                <Button color='primary' outline className="mt-2 mr-2" onClick={() => submitAzzera()}>Annulla</Button>
            </div>
        </>
    )
}

export default FormDatiEQ;