import { NotificationManager, notify } from "design-react-kit";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks";
import { NOTIFICA_STATO } from "../../utility/Notifica";
import './style.scss';

const NotificaComponent = () => {
    const { notifica} = useAppSelector(
        (state) => state.loader
    );

    useEffect(() => {
        !!notifica && notify(
            notifica.titolo,
            notifica.messaggio && <p>{notifica.messaggio}</p>,
            { state: NOTIFICA_STATO[notifica.tipo as keyof typeof NOTIFICA_STATO ],
            dismissable: true}
        )
    }, [notifica])
    

    return (
        <NotificationManager containerId='notification-container'/>
    );
  
}

export default NotificaComponent;