import { Button, Icon } from "design-react-kit";
import { ButtonCustomProps } from "./ButtonCustomProps";


function ButtonUpload (props: ButtonCustomProps) {

    return ( 
        <>
        {props.showOnlyIcon ? 
            <div className="item-icon-menu" onClick={props.onCliclEvent} title={ props.titleBtn ?? 'Carica File' } >
                <Icon icon="it-upload" color="primary"></Icon>
            </div>
            :
            <Button className={props.className} color="primary" size="xs" onClick={props.onCliclEvent} title={ props.titleBtn ?? 'Carica File' }>
                <Icon icon="it-upload" size="xs" color="white"></Icon> { props.testoBtn ?? "Carica" } 
            </Button>
        }
    </>
     );
}

export default ButtonUpload;