import { FormikValues } from "formik";

 /**
 * Cerca se nell'oggetto ci sono dei campi valorizzati, escludendo i campi relativi alla paginazione
 *
 * @param {(Object | null)} obj
 * @returns {boolean}
 */
export const checkCampiRicercaValorizzati = (obj: Object | null) : boolean => {
    if(obj){
        let ricercaAttiva = false;
        Object.keys(obj!).forEach(item => {
            let value = Object.getOwnPropertyDescriptor(obj, item)?.value;
            if(!['pageNum', 'pageSize', 'sort', 'direction'].includes(item) && !!value){
                ricercaAttiva = true;
                return;
            }
        })
        return ricercaAttiva;
    }
    return false;
}

/**
 * Restituisci le classe css che rappresenta l'ordinamento della colonna
 * 
 * @param form
 * @returns 
 */
export const getClassNameOrdinamentoColonna = (colonna: string, form: FormikValues) => {
    return 'th-header-col-order ' + (form.sort === colonna ? ('attivo_' + form.direction) : '');
}

/**
 * Mostra il campo solo se non è null, altrimenti ritorna una stringa di default
 * 
 * @param field 
 * @param defaultValueIfNull 
 * @returns 
 */
export const showFieldIfNotNull = (field: string | number | undefined, defaultValueIfNull: string = '-') => {
    return !!field ? field : defaultValueIfNull;
}