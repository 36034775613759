import { Button, Col, FormGroup, Input, Label, TextArea } from "design-react-kit";
import { FormikProps, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CustomSelect, {
  CustomSelectItem,
} from "../../components/common/custom-select/CustomSelect";
import DocumentUpload, { DocumentUploadRef } from "../../components/common/document-upload/DocumentUpload";
import PageLoader from "../../components/common/page-loader/PageLoader";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { detailsStruttureOrganizzative, formSelectPopulateCreate, insertStruttureOrganizzative, resetInputForm, resetSelectSezioni, resetUpdateForm, selectCreateSOSezioni, updateSoBozza } from "../../store/strutturaOrganizzativaSlice";
import { OptionDtoStatoStrutturaOrganizzativaValoreEnum, SOInsertRequestDto, SOInsertRequestDtoTipoRichiestaEnum } from "../../services/ms-anagrafica-unica";
import { HREF_BOZZA_STRUTTURA_ORGANIZZATIVA, HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from "../../components/layout/sidemenu/sidemenuConstants";
import { useNavigate, useParams } from "react-router-dom";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import PageHeader from "../../components/common/page-header/PageHeader";
import TextareaInfoChars from "components/common/textarea-infochars/TextareaInfoChars";


const initialValues: SOInsertRequestDto = {
  denominazione: '',
  declaratoria: '',
  dataInizioValidita: '',
  tipo: undefined,
  dipartimento: undefined,
  sezione: '',
  email: '',
  pec: '',
  telefono: '',
  fax: '',
  indirizzo: '',
  citta: '',
  tipoRichiesta: undefined,
  stato: undefined,
  idFile: undefined,
  equiparata: false,
};

const schemaConfirm = z
  .object({
    denominazione: z.string({ required_error: 'required' }).trim().min(1).max(50, 'lenghtdenominazione'),
    declaratoria: z.string({ required_error: 'required' }).trim().min(1).max(5000, 'lenghtdeclaratoria'),
    dataInizioValidita: z.coerce.date(),
    tipo: z.string({ required_error: 'required' }),
    dipartimento: z.string().optional(),
    sezione: z.string().optional(),
    email: z.string().regex(new RegExp(/^([a-zA-Z0-9-._]+)(@[a-z]+).[a-z]{2,3}$/), "emailNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec: z.string().regex(new RegExp(/\b[A-Za-z0-9-._]+@pec.+/), "pecNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "pecNonCorretta" }).optional(),
    telefono: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    fax: z.string().regex(new RegExp(/^\d+$/), 'numberFax').trim().min(1).max(20, 'lenghtFax').optional(),
    indirizzo: z.string().trim().min(1).max(200, 'lenghtIndirizzo').optional(),
    citta: z.string().trim().min(1).max(100, 'lenghtCitta').optional(),
    idFile: z.number({ required_error: 'required' }),
    equiparata: z.boolean().optional(),
  })
  .refine(
    (data) => {
      // obbligatorio se tipoStruttura è SEZIONE o SERVIZIO
      // ritorno false se è vuoto e se tipoStruttura è SEZIONE o SERVIZIO
      // ma la validazione scatta solo DOPO che sono ok le validazioni normali ???
      if ((data.tipo === "SEZIONE" || data.tipo === "SERVIZIO") && !data.dipartimento)
        return false;
      return true;
    },
    {
      message: "required",
      path: ["dipartimento"], // path of error
    }
  );

const schemaSave = z
  .object({
    denominazione: z.string().max(50, 'lenghtdenominazione').optional(),
    declaratoria: z.string().max(5000, 'lenghtdeclaratoria').optional(),
    dataInizioValidita: z.coerce.date().optional(),
    tipo: z.string({ required_error: 'required' }),
    dipartimento: z.string().optional(),
    sezione: z.string().optional(),
    email: z.string().regex(new RegExp(/^([a-zA-Z0-9-._]+)(@[a-z]+).[a-z]{2,3}$/), "emailNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec: z.string().regex(new RegExp(/\b[A-Za-z0-9-._]+@pec.+/), "pecNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "pecNonCorretta" }).optional(),
    telefono: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    fax: z.string().regex(new RegExp(/^\d+$/), 'numberFax').trim().min(1).max(20, 'lenghtFax').optional(),
    indirizzo: z.string().trim().min(1).max(200, 'lenghtIndirizzo').optional(),
    citta: z.string().trim().min(1).max(100, 'lenghtCitta').optional(),
    idFile: z.number().optional(),
    equiparata: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if ((data.tipo === "SEZIONE" || data.tipo === "SERVIZIO") && !data.dipartimento)
        return false;
      return true;
    },
    {
      message: "required",
      path: ["dipartimento"], // path of error
    }
  );

function NuovaStrutturaOrganizzativa() {

  const { t } = useTranslation();
  const formResult = useAppSelector((state) => state.strutturaOrganizzativa.selectCreateFormResult)
  const formStatus = useAppSelector((state) => state.strutturaOrganizzativa.selectCreateFormStatus)
  const { id } = useParams();
  const [visualizzaDocumento, setVisualizzazioneDocumento] = useState<boolean | null>(null)
  const [button, setButtonSubmit] = useState('')
  const navigate = useNavigate();
  //const validationSchema = button === "BTN_CONFERMA" ? toFormikValidationSchema(schemaConfirm) : toFormikValidationSchema(schemaSave);
  const [tipiStruttura, setTipiStruttura] = useState<CustomSelectItem[]>([]);
  const [dipartimentoOptions, setDipartimentoStruttura] = useState<CustomSelectItem[]>([]);
  const [sezioneOptions, setSezioneStruttura] = useState<CustomSelectItem[]>([]);
  const dispatch = useAppDispatch();
  const ref = useRef<DocumentUploadRef>(null);
  const insertResult = useAppSelector((state) => state.strutturaOrganizzativa.inputResult);
  const dettaglioSoBozza = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult);
  const sezioniCreaStrutturaOrganizzativaResult = useAppSelector ((state)=> state.strutturaOrganizzativa.sezioniCreaStrutturaOrganizzativaResult);

  const handleIdChange = (id: number | undefined) => {
    if (id && id !== -1) {
      formSO.setFieldValue('idFile', id);
    } else {
      formSO.setFieldValue('idFile', undefined);
    }
  }

  useEffect(() => {
    dispatch(formSelectPopulateCreate());
    dispatch(resetUpdateForm())
  }, [])

  useEffect(() => {
    let customSelectArrayTipo: CustomSelectItem[] = [];
    formResult?.tipo?.forEach((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label !== undefined ? value.label : '',
        value: value.valore !== undefined ? value.valore : ''
      };
      customSelectArrayTipo.push(customSelect);
    });
    setTipiStruttura(customSelectArrayTipo)

    let customSelectArrayDipartimento: CustomSelectItem[] = [];
    formResult?.dipartimento?.forEach((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label !== undefined ? value.label : '',
        value: value.valore !== undefined ? value.valore : ''
      };
      customSelectArrayDipartimento.push(customSelect);
    });
    setDipartimentoStruttura(customSelectArrayDipartimento)

  }, [formResult]);

  useEffect(() => {
    if(id !== null && id !== undefined){
      dispatch(detailsStruttureOrganizzative(id));
    }else {
      dispatch(formSelectPopulateCreate());
      dispatch(resetUpdateForm())
    }
  }, [id])


  useEffect(() => {
    if(dettaglioSoBozza && dettaglioSoBozza.stato !== OptionDtoStatoStrutturaOrganizzativaValoreEnum.Compilata)
      navigate(HREF_ELENCO_STRUTTURE_ORGANIZZATIVE);

    let soDipa, soSez;
    handleSelectTipoStruttura(dettaglioSoBozza?.tipo);
    if(dettaglioSoBozza?.strutturaOrganizzativaPadre) {
      const soLiv1 = dettaglioSoBozza?.strutturaOrganizzativaPadre;

      switch (soLiv1.tipo) {
          case 'DIPARTIMENTO':
              soDipa = soLiv1;
              break;
          case 'SEZIONE':
              soSez = soLiv1;
              break
          default:
              break;
      }

      if(soLiv1.strutturaOrganizzativaPadre) {
          const soLiv2 = soLiv1.strutturaOrganizzativaPadre;
          switch (soLiv2.tipo) {
              case 'DIPARTIMENTO':
                  soDipa = soLiv2;
                  break;
              case 'SEZIONE':
                  soSez = soLiv2;
                  break
              default:
                  break;
          }
      }
    }

    formSO.setValues({
      codiceInca: dettaglioSoBozza?.id || '',
      denominazione: dettaglioSoBozza?.descrizioneBreve || '',
      declaratoria: dettaglioSoBozza?.descrizione || '',
      dataInizioValidita: dettaglioSoBozza?.dataInizioValidita || '',
      tipo: dettaglioSoBozza?.tipo || undefined,
      dipartimento: soDipa?.id || undefined,
      sezione: soSez?.id || undefined,
      email: dettaglioSoBozza?.email || '',
      pec: dettaglioSoBozza?.pec || '',
      telefono: dettaglioSoBozza?.telefono || '',
      fax: dettaglioSoBozza?.fax || '',
      indirizzo: dettaglioSoBozza?.indirizzo || '',
      citta: dettaglioSoBozza?.citta || '',
      idFile: dettaglioSoBozza?.documenti != null && dettaglioSoBozza?.documenti.length > 0
      ? dettaglioSoBozza?.documenti[0].id?.documento?.id
      : undefined,
    })

  }, [dettaglioSoBozza])


  const formSO: FormikProps<SOInsertRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: button === "BTN_CONFERMA" ? toFormikValidationSchema(schemaConfirm) : toFormikValidationSchema(schemaSave),
    onSubmit: (values) => {
      doInsert();
    },
  });

  useEffect(() => {
    if (insertResult !== undefined && insertResult !== null) {
      if(button === 'BTN_CONFERMA'){
        navigate(HREF_ELENCO_STRUTTURE_ORGANIZZATIVE);
        return;
      } else
        if(id === null || id === undefined){
          navigate(`${HREF_BOZZA_STRUTTURA_ORGANIZZATIVA}/${insertResult.id}`);
          return;
        }

      handleSelectTipoStruttura(insertResult.tipo);
      
      formSO.setValues({
        codiceInca: insertResult?.id || '',
        denominazione: insertResult?.descrizioneBreve || '',
        declaratoria: insertResult?.descrizione || '',
        dataInizioValidita: insertResult?.dataInizioValidita || '',
        tipo: insertResult?.tipo || undefined,
        dipartimento: formSO.values.dipartimento,
        sezione: formSO.values.sezione|| undefined,
        email: insertResult?.email || '',
        pec: insertResult?.pec || '',
        telefono: insertResult?.telefono || '',
        fax: insertResult?.fax || '',
        indirizzo: insertResult?.indirizzo || '',
        citta: insertResult?.citta || '',
        idFile:  insertResult?.documenti != null && insertResult?.documenti.length > 0
        ? insertResult?.documenti[0].id?.documento?.id
        : undefined,
      })
    }
  }, [insertResult])

  const doInsert = () => {
    let body = {
      ...formSO.values,
      tipoRichiesta: button === 'BTN_CONFERMA' ? SOInsertRequestDtoTipoRichiestaEnum.Confirm : SOInsertRequestDtoTipoRichiestaEnum.Save,
    }
    if(id !== null && id !== undefined){
      dispatch(updateSoBozza(body))
    }else{
      dispatch(insertStruttureOrganizzative(body))
    }
  };

  const handleSelectChange = (value: any, name: string) => {
    if (value === "DIPARTIMENTO") {
      formSO.setFieldValue('dipartimento', undefined);
      formSO.setFieldValue('sezione', undefined);
    }
      else if (value === "SEZIONE") {
        formSO.setFieldValue('sezione', undefined);
      }
        else if (value === '') {
          formSO.setFieldValue('dipartimento', undefined);
          formSO.setFieldValue('sezione', undefined);
        }
    formSO.setFieldValue(name, value);
  };

  const handleSelectTipoStruttura = (tipoSo: any) => {
    if (tipoSo === "DIPARTIMENTO" || tipoSo === "SEZIONE") {
      setVisualizzazioneDocumento(true);
    } else if (tipoSo === "SERVIZIO") {
      setVisualizzazioneDocumento(false);
    } else {
      setVisualizzazioneDocumento(null);
    }
  }

  useEffect(() => {
    let customSelectArraySezione: CustomSelectItem[] = [];
    sezioniCreaStrutturaOrganizzativaResult?.map((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label !== undefined ? value.label : '',
        value: value.valore !== undefined ? value.valore : ''
      };
      customSelectArraySezione.push(customSelect);
    });
    setSezioneStruttura(customSelectArraySezione)
  }, [sezioniCreaStrutturaOrganizzativaResult])

  const populateSezioneSelect = (e: any, name: string) =>{
    if(e === ""){
        dispatch(resetSelectSezioni())
    }else {
      dispatch(selectCreateSOSezioni(e))
    }
  }

  const getFieldError = (
    form: FormikProps<SOInsertRequestDto>,
    fieldName: keyof SOInsertRequestDto
  ): string => {
    if (formSO.getFieldMeta(fieldName).touched) {
      return formSO.errors[fieldName] || "";
    } else return "";
  };

  const submitConfirm = () => {
    setButtonSubmit('BTN_CONFERMA');
    setTimeout(() => {
      formSO.submitForm();
    }, 0);
  }

  const submitSave = () => {
    setButtonSubmit('BTN_SALVA');
    setTimeout(() => {
      formSO.submitForm();
    }, 0);
  }

  const resetForm = () => {
    formSO.resetForm();
  }

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} title={`${id == null ? 'Nuova' : 'Bozza'} Struttura Organizzativa ${id == null ? '' : (' - ' + id)}`}></PageHeader>
      {formStatus === STATUS_FULLFILLED &&
      <>
        <div className="form-custom form-bg form-inserimento border">
          {false && <PageLoader />}


          <div className="form-row">
            <Input
              label="Denominazione"
              type="text"
              placeholder="Inserisci la denominazione"
              id="input-denominazione"
              wrapperClassName="required col-md-12"
              name="denominazione"
              value={formSO.values.denominazione}
              onChange={formSO.handleChange}
              invalid={!!getFieldError(formSO, "denominazione")}
              infoText={t(getFieldError(formSO, "denominazione")) || ""}
            />

            <TextareaInfoChars
              label="Declaratoria"
              placeholder="Inserisci la declaratoria"
              id="input-declaratoria"
              wrapperClassName="required col-md-12"
              name="declaratoria"
              value={formSO.values.declaratoria}
              onChange={formSO.handleChange}
              invalid={!!getFieldError(formSO, "declaratoria")}
              infoText={t(getFieldError(formSO, "declaratoria")) || ""}
              maxLength={500}
              rows={6}
            />

            <Input
              type="date"
              label="Data inizio validità"
              placeholder="Inserisci la data di inizio Validità"
              id="input-dataInizioValidita"
              name="dataInizioValidita"
              value={formSO.values.dataInizioValidita}
              onChange={formSO.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(formSO, "dataInizioValidita")}
              infoText={t(getFieldError(formSO, "dataInizioValidita")) || ""}
            />

            <Col xl="6">
              <CustomSelect
                label="Tipo struttura"
                placeholder="Seleziona il tipo della struttura"
                options={tipiStruttura}
                name="tipoStruttura"
                invalid={!!getFieldError(formSO, "tipo")}
                infoText={t(getFieldError(formSO, "tipo")) || ""}
                value={formSO.values.tipo}
                onChange={(e) => { handleSelectChange(e, "tipo"); handleSelectTipoStruttura(e) }}
                wrapperClass="required"
                disabled={id !== undefined}
              />
            </Col>

            <Col xl="6">
              <CustomSelect
                label="Dipartimento"
                name="dipartimento"
                disabled={
                  (formSO.values.tipo !== "SEZIONE" &&
                  formSO.values.tipo !== "SERVIZIO") || id !== undefined
                }
                options={dipartimentoOptions}
                invalid={!!getFieldError(formSO, "dipartimento")}
                infoText={t(getFieldError(formSO, "dipartimento")) || ""}
                value={formSO.values.dipartimento}
                onChange={(e) => {handleSelectChange(e, "dipartimento"); populateSezioneSelect(e, "dipartimento")}}
              />
            </Col>

            <Col xl="6">
              <CustomSelect
                label="Sezione"
                name="sezione"
                disabled={formSO.values.tipo !== "SERVIZIO"}
                options={sezioneOptions}
                invalid={!!getFieldError(formSO, "sezione")}
                infoText={t(getFieldError(formSO, "sezione")) || ""}
                value={formSO.values.sezione}
                onChange={(e) => handleSelectChange(e, "sezione")}
              />
            </Col>

            <Input
              type="email"
              label="Email"
              placeholder="Inserisci email"
              id="input-email"
              name="email"
              value={formSO.values.email}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "email")}
              infoText={t(getFieldError(formSO, "email")) || ""}
            />

            <Input
              type="email"
              label="Pec"
              placeholder="Inserisci PEC"
              id="input-pec"
              name="pec"
              value={formSO.values.pec}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "pec")}
              infoText={t(getFieldError(formSO, "pec")) || ""}
            />

            <Input
              type="tel"
              label="Telefono"
              placeholder="Inserisci il numero di telefono"
              id="input-telefono"
              name="telefono"
              value={formSO.values.telefono}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "telefono")}
              infoText={t(getFieldError(formSO, "telefono")) || ""}
            />

            <Input
              type="tel"
              label="Fax"
              placeholder="Inserisci il fax"
              id="input-fax"
              name="fax"
              value={formSO.values.fax}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "fax")}
              infoText={t(getFieldError(formSO, "fax")) || ""}
            />

            <Input
              type="text"
              label="Indirizzo"
              placeholder="Inserisci indirizzo"
              id="input-indirizzo"
              name="indirizzo"
              value={formSO.values.indirizzo}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "indirizzo")}
              infoText={t(getFieldError(formSO, "indirizzo")) || ""}
            />

            <Input
              type="text"
              label="Città"
              placeholder="Inserisci città"
              id="input-citta"
              name="citta"
              value={formSO.values.citta}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "citta")}
              infoText={t(getFieldError(formSO, "citta")) || ""}
            />

            <FormGroup check className="mb-2">
              <Input id='strutturaEquiparata' name="equiparata" onChange={formSO.handleChange} checked={formSO.values.equiparata} type='checkbox' />
              <Label for='strutturaEquiparata' check>
                Struttura equiparata
              </Label>
            </FormGroup>

          </div>
          {visualizzaDocumento !== null && (
            <DocumentUpload ref={ref} 
              idDocumento={formSO.values.idFile} 
              invalid={!!formSO.errors.idFile} 
              infoText={t(getFieldError(formSO, "idFile")) || ""} 
              setDocumentId={handleIdChange} 
              documentName={t(`struttura-organizzativa.${visualizzaDocumento ? 'doc-provvedimento-giunta' : 'doc-determina-dirigenziale'}`)} 
              className="my-3 required" />
          )}
          
        </div>
        <div className="d-flex flex-row-reverse mt-2">
          <Button color='primary' className="mt-2" onClick={() => { submitConfirm(); }}> {t('conferma')}</Button>
          <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSave(); }}>{t('salva_bozza')}</Button>
          <Button color='secondary' className="mt-2 mr-2"  onClick={() => {resetForm() }} >{t('reset')} </Button>
          <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`); dispatch(resetInputForm()) }} >{t('annulla')} </Button>
        </div>
        </>
      }
    </div>
  );
}

export default NuovaStrutturaOrganizzativa;
