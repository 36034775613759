import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import PageHeader from "../../../components/common/page-header/PageHeader";
import { Fragment, useEffect, useState } from "react";
import { HREF_ELENCO_BANDI, HREF_ELENCO_CANDIDATURE } from "../../../components/layout/sidemenu/sidemenuConstants";
import { Accordion, AccordionBody, AccordionHeader, Alert, Button, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown, UncontrolledTooltip } from "design-react-kit";
import CustomSelect, { CustomSelectItem } from "../../../components/common/custom-select/CustomSelect";
import { AssegnazioneBandiPoEssenzialeDto, AssociazioneAssegnazioneBandoPoRequestDto, AssociazioneBandiPoRequestDtoRequestTypeEnum, BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest, BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest, DatiAttoDto, OptionDtoSiNoValueEnum, RuoloUtenteAutenticatoDto } from "../../../services/ms-anagrafica-unica";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { getElencoAssegnazioniPerBando, insertAssociazioneBandiPo } from "../../../store/bandoSlice";
import { STATUS_FULLFILLED } from "../../../store/store-constants";
import { formatDate } from "../../../utility/formatDate";
import { RiepilogoBandoDetailInterface } from "../../../models/RiepilogoBandoDetailInterface";
import RiepilogoBandoDetail from "../../../components/bando-eq-detail-form/RiepilogoBandoDetail";
import DettaglioAtto from "../../../components/dettaglio-atto-cifra/DettaglioAtto";
import { showNotifica } from "../../../store/loaderSlice";
import { NOTIFICA_STATO } from "../../../utility/Notifica";
import { controlliCodiceCifra } from "../../../utility/formUtils";
import Pagination from "../../../components/common/pagination/Pagination";
import TableTheadCustom from "components/common/custom-table/table-thead-custom";

const ElencoAssegnazioni = (props: { operations: RuoloUtenteAutenticatoDto }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {idBando} = useParams();
    const {searchElencoAssegnazioniPerBandoResult, searchElencoAssegnazioniPerBandoStatus } = useAppSelector(state => state.bando)

    const [formCollapseOpen, setFormCollapseOpen] = useState<boolean>(false);
    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
    const [statiOptions, setStatiOptions] = useState<CustomSelectItem[]>([]);
    const [elencoAssegnazioni, setElencoAssegnazioni] = useState<Array<AssegnazioneBandiPoEssenzialeDto>>([]);
    const [elencoAssociazioni, setelencoAssociazioni] = useState<Array<AssociazioneAssegnazioneBandoPoRequestDto>>([]);
    const [elencoAssociazioniPerSalva, setElencoAssociazioniPerSalva] = useState<Array<AssociazioneAssegnazioneBandoPoRequestDto>>([]);
    const [isOpenAttoConferimento, toggleModalAttoConferimento] = useState(false);
    const [attoConferimento, setAttoConferimento] = useState('');
    const [dataInizioIncarico, setDataInizioIncarico] = useState('');
    const [assegnazioneInLavorazione, setAssegnazioneInLavorazione] = useState<AssegnazioneBandiPoEssenzialeDto>();
    const [riepilogoBando, setRiepilogoBando] = useState<RiepilogoBandoDetailInterface|undefined>();
    const [ datiAtto, setDatiAtto ] = useState<DatiAttoDto>({});
    
    const initialValuesAssociazione:BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest = {
        id: '',
        associazioneBandiPoRequestDto: {
            elencoAssegnazioni: elencoAssociazioni,
            requestType: AssociazioneBandiPoRequestDtoRequestTypeEnum.Conferma
        }
    };

    const initialValuesAssociazioneSalva:BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest = {
        id: '',
        associazioneBandiPoRequestDto: {
            elencoAssegnazioni: elencoAssociazioniPerSalva,
            requestType: AssociazioneBandiPoRequestDtoRequestTypeEnum.Salva
        }
    };

    useEffect( () => {

        doSearch()

        const selectItems: Array<CustomSelectItem> = [];

        selectItems.push({
            value: OptionDtoSiNoValueEnum.S,
            label: "SI"
        })
        selectItems.push({
            value: OptionDtoSiNoValueEnum.N,
            label: "NO"
        })
        setStatiOptions(selectItems)
    }, []);
    
    const initialValues:BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest = {
        idBando: idBando!,
        denominazionePo: '',
        pageNum: 0,
        pageSize: 10,
        sort: '',
        direction: 'ASC',
        q: '',
    };

    type Direction = 'ASC' | 'DESC';
    interface SortingState {
        direction: Direction;
    }

    const schema = z.object({
        denominazionePo: z.string().optional()
    })

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: (values) => {
            console.log('values >>> ',values);
            
            doSearch();
        },
    });

    const doSearch = () => {
        dispatch(getElencoAssegnazioniPerBando(searchForm.values)).unwrap().then( resp => {
            if (!!resp) {
                setRiepilogoBando({
                    idBando: resp.idBando || '',
                    nomeBando: resp.nomeBando || '',
                    dataApertura: resp.dataApertura || '',
                    dataChiusura: resp.dataChiusura || '',
                    strutturaOrganizzativa: resp.strutturaOrganizzativa || '',
                    codiceCifraDeliberaDetermina: resp.codiceCifraDeliberaDetermina || ''
                })
                setElencoAssegnazioni(resp.data!);
            }
        });
    };

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };

    const handleToggleModalAttoConferimento = (assegnazioneX: any) => {
        
        toggleModalAttoConferimento(!isOpenAttoConferimento)

        const attoConferimentoInserito = elencoAssegnazioni[elencoAssegnazioni.findIndex(x=>x.codiceUnivoco===assegnazioneX.codiceUnivoco)]?.attoConferimento
        const dataInizioIncaricoInserita = elencoAssegnazioni[elencoAssegnazioni.findIndex(x=>x.codiceUnivoco===assegnazioneX.codiceUnivoco)]?.dataInizioIncarico

        if(attoConferimentoInserito)
            setAttoConferimento(attoConferimentoInserito)
        if(dataInizioIncaricoInserita)
            setDataInizioIncarico(dataInizioIncaricoInserita)

        setAssegnazioneInLavorazione(assegnazioneX)
    };

    
    const handleChangeConteso = (selectedOption: any) => {
        searchForm.setFieldValue('conteso', selectedOption);
        searchForm.handleChange(selectedOption);
    };

    const modificaAssociazione = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto) => {
        const assegnazioneInLavorazione = elencoAssegnazioni.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        const indiceAssociazioneDaAggiornare = elencoAssociazioni.findIndex( (associazione) => associazione.codiceUnivoco === assegnazioneInLavorazione.codiceUnivoco)
        if (indiceAssociazioneDaAggiornare === -1) {
            // aggiungo un elemento di tipo AssociazioneAssegnazioneBandoPoRequestDto da inviare al servizio
            const elencoAssociazioniAggiornato = [
                ...elencoAssociazioni,
                {
                    codiceUnivoco: assegnazioneInLavorazione.codiceUnivoco,
                    attoConferimento: assegnazioneInLavorazione.attoConferimento,
                    dataInizioIncarico: assegnazioneInLavorazione.dataInizioIncarico,
                    datiAttoConferimento: datiAtto
                }
            ]
            setelencoAssociazioni(elencoAssociazioniAggiornato)
        } else {
            const elencoAssociazioniAggiornato = elencoAssociazioni.filter( (assegnazione, indice) => indice !== indiceAssociazioneDaAggiornare)
            setelencoAssociazioni(elencoAssociazioniAggiornato)
        }
    }

    const modificaAssociazionePerSalva = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto) => {
        const assegnazioneInLavorazione = elencoAssegnazioni.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        const indiceAssociazioneDaAggiornare = elencoAssociazioniPerSalva.findIndex( (associazione) => associazione.codiceUnivoco === assegnazioneInLavorazione.codiceUnivoco)
        if (indiceAssociazioneDaAggiornare === -1) {
            // aggiungo un elemento di tipo AssociazioneAssegnazioneBandoPoRequestDto da inviare al servizio
            const elencoAssociazioniAggiornato = [
                ...elencoAssociazioniPerSalva,
                {
                    codiceUnivoco: assegnazioneInLavorazione.codiceUnivoco,
                    attoConferimento: assegnazioneForm.attoConferimento,
                    dataInizioIncarico: assegnazioneForm.dataInizioIncarico,
                    datiAttoConferimento: datiAtto
                }
            ]
            setElencoAssociazioniPerSalva(elencoAssociazioniAggiornato)
        } else {
            elencoAssociazioniPerSalva[indiceAssociazioneDaAggiornare].attoConferimento = assegnazioneForm.attoConferimento
            elencoAssociazioniPerSalva[indiceAssociazioneDaAggiornare].dataInizioIncarico = assegnazioneForm.dataInizioIncarico
        }
    }

    const consentiAssociazione = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto) => {
        const assegnazioneInLavorazione = elencoAssegnazioni.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        const assegnazioneGiaAssociata = searchElencoAssegnazioniPerBandoResult?.data?.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        if (
            (assegnazioneInLavorazione && assegnazioneInLavorazione.attoConferimento && assegnazioneInLavorazione.dataInizioIncarico) &&
            !assegnazioneInLavorazione.conteso && 
            (assegnazioneInLavorazione && assegnazioneInLavorazione.scelta) && 
            assegnazioneInLavorazione.statoBando === 'CHIUSO' && 
            !assegnazioneGiaAssociata?.assegnato
        )
            return true

        return false
    }

    const showInserisciAtto = () =>
        (props?.operations.elencoFunzionalita?.includes('BANDO_PO_ASSOCIAZIONE'))

    const consentiInserimentoAttoConferimento = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto) => {
        const assegnazioneGiaAssociata = searchElencoAssegnazioniPerBandoResult?.data?.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        if (!assegnazioneGiaAssociata?.assegnato && !assegnazioneForm.conteso && !!assegnazioneForm.scelta && assegnazioneForm.statoBando === 'CHIUSO') {
            return true;
        }
        return false;
    }
    
    const aggiornaAttoConferimento = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto, attoConferimento: string, dataInizioIncarico: string) => {

        const assegnazioneInLavorazione = elencoAssegnazioni.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        const indiceAssegnazioneDaAggiornare = elencoAssegnazioni.findIndex( (associazione) => associazione.codiceUnivoco === assegnazioneInLavorazione.codiceUnivoco)
        const assegnazioneInLavorazioneAggiornata = {
            ...assegnazioneInLavorazione,
            attoConferimento: attoConferimento,
            dataInizioIncarico: dataInizioIncarico,
            datiAtto
        }
        const elencoAssegnazioniAggiornato = [...elencoAssegnazioni]

        //const attoConferimentoInRequestDaAggiornare = elencoAssociazioni[elencoAssociazioni.findIndex(x=>x.codiceUnivoco===assegnazioneForm.codiceUnivoco)]?.attoConferimento

        if(elencoAssociazioni[elencoAssociazioni.findIndex(x=>x.codiceUnivoco===assegnazioneForm.codiceUnivoco)]){
            elencoAssociazioni[elencoAssociazioni.findIndex(x=>x.codiceUnivoco===assegnazioneForm.codiceUnivoco)].attoConferimento = attoConferimento
            elencoAssociazioni[elencoAssociazioni.findIndex(x=>x.codiceUnivoco===assegnazioneForm.codiceUnivoco)].datiAttoConferimento = datiAtto
            elencoAssociazioni[elencoAssociazioni.findIndex(x=>x.codiceUnivoco===assegnazioneForm.codiceUnivoco)].dataInizioIncarico = dataInizioIncarico
        }

        elencoAssegnazioniAggiornato.splice(indiceAssegnazioneDaAggiornare, 1, assegnazioneInLavorazioneAggiornata)
        setElencoAssegnazioni(elencoAssegnazioniAggiornato);
        modificaAssociazionePerSalva(assegnazioneInLavorazioneAggiornata)
        setAttoConferimento('')
        setDatiAtto({})
        setDataInizioIncarico('')
        toggleModalAttoConferimento(!isOpenAttoConferimento)
    }

    const mostraAttoConferimento = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto) => {
        const assegnazioneInLavorazione = elencoAssegnazioni.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        return (assegnazioneInLavorazione && assegnazioneInLavorazione.attoConferimento) || '-';
    }

    const mostraDataInizioIncarico = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto) => {
        const assegnazioneInLavorazione = elencoAssegnazioni.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm.codiceUnivoco)[0]
        return (assegnazioneInLavorazione && assegnazioneInLavorazione.dataInizioIncarico && formatDate(assegnazioneInLavorazione.dataInizioIncarico, true)) || '-';
    }

    const mostraScelta = (assegnazioneForm: AssegnazioneBandiPoEssenzialeDto) => {
        const assegnazioneInLavorazione = elencoAssegnazioni.filter( assegnazione => assegnazione.codiceUnivoco === assegnazioneForm?.codiceUnivoco)[0]
        return (assegnazioneInLavorazione && assegnazioneInLavorazione.scelta) || '-';
    }

    const submitAssociazione = (requestType: AssociazioneBandiPoRequestDtoRequestTypeEnum) => {        
        let req;

        if(requestType === AssociazioneBandiPoRequestDtoRequestTypeEnum.Conferma){
            req = {
                ...initialValuesAssociazione,
                id: idBando!
            }
        }else {
            req = {
                ...initialValuesAssociazioneSalva,
                id: idBando!
            }
        }
                
        dispatch(insertAssociazioneBandiPo(req)).unwrap().then( () => {
           // setelencoAssociazioni([])
           // doSearch()
        })
    }

    const onChangeCodiceAtto = (e: any) => {
        setDatiAtto({});
        setAttoConferimento(e.target.value)
    }

    const notFoundDatiAtto = () => {
        dispatch(showNotifica({
            titolo: 'ATTENZIONE',
            messaggio: 'Nessun atto associato al codice inserito!',
            tipo: NOTIFICA_STATO.warning
        }))
    }

    const isValidCodiceAtto = () => {
        return attoConferimento.length >= controlliCodiceCifra.min && 
            attoConferimento.length < controlliCodiceCifra.max && 
            attoConferimento.match(new RegExp(controlliCodiceCifra.regExp))
    }

    const handlePageChange = (pageNum: number) => {
        console.log("Handle page change", pageNum - 1);
        searchForm.setFieldValue("pageNum", pageNum - 1);
        searchForm.handleSubmit();
    };

    return (
        <div>
            <PageHeader
                showIcon={true}
                urlBack={HREF_ELENCO_BANDI}
                state={{form: true}} 
                title={`${t("Assegnazioni per il bando ")!} ${idBando}`}
                buttons={[
                    {
                        buttonProperties: { outline: true },
                        title: 'Ricerca',
                        buttonColor: 'primary',
                        showButtonIcon: true,
                        icon: 'it-search',
                        iconColor: 'primary',
                        action: () =>{formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true)},
                    }
                ]}
            />

            {!!riepilogoBando && <RiepilogoBandoDetail bando={riepilogoBando} />}

            <Accordion className='form-custom form-bg filtri-ricerca mt-3' background="active">
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-2">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchForm.values.pageNum}
                            onChange={searchForm.handleChange}
                        />
                        <Input
                            type="text"
                            id="input-denominazione-po"
                            placeholder="Denominazione EQ"
                            wrapperClassName="col col-md-6"
                            name="denominazionePo"
                            invalid={!!searchForm.errors.denominazionePo}
                            infoText={t(searchForm.errors.denominazionePo || "") || ""}
                            value={searchForm.values.denominazionePo}
                            onChange={searchForm.handleChange}
                        />
                        <CustomSelect
                            placeholder="Conteso"
                            options={statiOptions}
                            value={searchForm.values.conteso}
                            onChange={handleChangeConteso}
                            name="conteso"
                            wrapperClass="form-group col-md-6"
                        />
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchForm.setFieldValue("pageNum", 0);
                                searchForm.submitForm();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchForm.handleReset}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>

            <div className="mt-4 position-relative">
                {searchElencoAssegnazioniPerBandoStatus === STATUS_FULLFILLED &&
                    searchElencoAssegnazioniPerBandoResult?.totalElements === 0 && (
                        <Alert color="info">Nessun risultato disponibile</Alert>
                    )
                }
                {searchElencoAssegnazioniPerBandoStatus === STATUS_FULLFILLED && searchElencoAssegnazioniPerBandoResult?.totalElements! > 0 && (
                    <div className={searchElencoAssegnazioniPerBandoResult?.totalPages === 1 ? 'mb-5' : ''}>
                        <Table striped responsive>
                            <TableTheadCustom 
                                colonne={[
                                    {titolo: 'Denominazione', nomeColonna: 'posizioneOrganizzativa'},
                                    {titolo: 'Tipologia EQ', nomeColonna: 'tipoEq'},
                                    {titolo: 'Associato', nomeColonna: 'associato'},
                                    {titolo: 'Fine associazione', nomeColonna: 'dataFineAssociazione'},
                                    {titolo: 'Conteso'},
                                    {titolo: 'Scelta'},
                                    {titolo: 'Atto di conferimento'},
                                    {titolo: 'Data inizio incarico'},
                                    {titolo: 'Associa'},
                                    {titolo: 'Azioni'}
                                ]} 
                                datiOrdinamento={searchForm.values}
                                eseguiOrdinamento={ordinamento}/>
                            <tbody>
                                {(searchElencoAssegnazioniPerBandoResult?.data || []).map((x, i) => (
                                    <Fragment key={i}>
                                        <tr>
                                            <td>{x.posizioneOrganizzativa?.denominazione || ' - '}</td>
                                            <td>{x.posizioneOrganizzativa?.tipoLabel || ' - '}</td>
                                            <td>{x.posizioneOrganizzativa?.dipendenteRegionale || ' - '}</td>
                                            <td>{formatDate(x.posizioneOrganizzativa?.dataFineAssociazione!) || ' - '}</td>
                                            <td style={{textAlign: 'center'}}>{
                                            x.conteso ? (
                                                <>
                                                    <Button
                                                        className="m-3"
                                                        id="conteso"
                                                    >
                                                        <Icon style={{float: 'left'}} icon={'it-warning-circle'} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target={'conteso'}>
                                                        Bando conteso. Risolvere la contesa per poter assegnare il vincitore.
                                                    </UncontrolledTooltip>
                                                </>
                                            ) : <>NO</>
                                            }</td>
                                            <td>{x.scelta || ' - '}</td>
                                            {/*<td>{test(x.codiceUnivoco!)}</td>*/}
                                            <td>{mostraAttoConferimento(x)}</td>
                                            <td>{mostraDataInizioIncarico(x)}</td>
                                            <td className="checkbox-scelta">
                                                <Input
                                                    type="checkbox"
                                                    id="scelta"
                                                    disabled={!consentiAssociazione(x)}
                                                    defaultChecked={x?.assegnato}
                                                    wrapperClassName="col col-md-6"
                                                    name="scelta"
                                                    onChange={() => {modificaAssociazione(x)}}
                                                />
                                            </td>
                                            <td>
                                                <UncontrolledDropdown direction="left">
                                                    <DropdownToggle nav>
                                                        <Icon icon="it-settings" color="primary" size="sm" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="no-arrow">
                                                        <LinkList>
                                                            <LinkListItem
                                                                size="medium"
                                                                onClick={() => navigate(`${HREF_ELENCO_CANDIDATURE}/${x.codiceUnivoco}`)}
                                                            >
                                                                <span>{t('Visualizza candidature')}</span>
                                                            </LinkListItem>
                                                            {showInserisciAtto() && consentiInserimentoAttoConferimento(x) && <LinkListItem
                                                                size="medium"
                                                                onClick={() => handleToggleModalAttoConferimento(x)}
                                                            >
                                                                <span>{t('Inserisci atto di conferimento e data inizio incarico')}</span>
                                                            </LinkListItem>}
                                                        </LinkList>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                        
                                    </Fragment>
                                ))}
                                <Modal
                                        isOpen={isOpenAttoConferimento}
                                        toggle={() => toggleModalAttoConferimento(!isOpenAttoConferimento)}
                                        labelledBy={`attoConferimento`}
                                        centered
                                        size="lg"
                                    >
                                        <ModalHeader toggle={() => toggleModalAttoConferimento(!isOpenAttoConferimento)} id={`attoConferimento`}>
                                            {`Atto di conferimento per ${assegnazioneInLavorazione?.posizioneOrganizzativa?.codiceUnivoco}`}
                                        </ModalHeader>
                                        <ModalBody>
                                            <dl className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <dt className='col-sm-12'>Scelta</dt>
                                                        <dd className='col-sm-12'>{mostraScelta(assegnazioneInLavorazione!)}</dd>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <dt className='col-sm-12'>{t('Atto di conferimento') + ' (formato <codiceAoo>/<anno>/<numeroProvvedimento>)'}</dt>
                                                        <dd className='col-sm-12'>
                                                            <Input
                                                                type="text"
                                                                id="attoConferimento"
                                                                placeholder="Inserisci gli estremi"
                                                                wrapperClassName="col col-md-6 no-form-group"
                                                                name="attoConferimento"
                                                                value={attoConferimento}
                                                                onChange={(e) => onChangeCodiceAtto(e)}
                                                            />
                                                        </dd>
                                                        {isValidCodiceAtto() && (
                                                            <dd className='col-sm-12'>
                                                                <DettaglioAtto 
                                                                    codiceCifra={attoConferimento}
                                                                    datiAtto={datiAtto} 
                                                                    showLoader={true}
                                                                    setDatiAtto={(e) => setDatiAtto(e)} 
                                                                    notFoundDatiAtto={() => notFoundDatiAtto()} 
                                                                ></DettaglioAtto>
                                                             </dd>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <dt className='col-sm-12'>{t('Data inizio incarico')}</dt>
                                                        <dd className='col-sm-12'>
                                                            <Input
                                                                type="date"
                                                                id="dataInizioIncarico"
                                                                placeholder="Inserisci la data di inzio incarico"
                                                                wrapperClassName="col col-md-6 no-form-group"
                                                                name="dataInizioIncarico"
                                                                value={dataInizioIncarico}
                                                                onChange={(e) => setDataInizioIncarico(e.target.value)}
                                                            />
                                                        </dd>
                                                    </div>
                                                </div>
                                            </dl>
                                        </ModalBody>
                                        <ModalFooter>
                                            {/* <Button color='secondary' onClick={() => {changeAttoConferimento(codiceUnivoco!, attoConferimento);toggleModalAttoConferimento(!isOpenAttoConferimento)}}>
                                                Salva
                                            </Button> */}
                                            <Button color='secondary' disabled={!!!Object.keys(datiAtto).length || !dataInizioIncarico} 
                                                onClick={() => aggiornaAttoConferimento(assegnazioneInLavorazione!, attoConferimento, dataInizioIncarico)}>
                                                Inserisci
                                            </Button>
                                            <Button color='secondary' onClick={() => {setAttoConferimento(''); setDataInizioIncarico(''); toggleModalAttoConferimento(!isOpenAttoConferimento)}}>
                                                Annulla
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                            </tbody>
                        </Table>
                        <Pagination
                            totalCount={searchElencoAssegnazioniPerBandoResult?.totalElements || 0}
                            siblingCount={1}
                            currentPage={searchForm.values.pageNum! + 1}
                            pageSize={searchElencoAssegnazioniPerBandoResult?.pageSize || 10}
                            onPageChange={(page: number) => {
                                handlePageChange(page);
                            }}
                            className="justify-content-center"
                        />
                    </div>
                )}
            </div>
            <div className="d-flex flex-row-reverse">
                <Button color='primary' className="mt-2 mr-2" disabled={elencoAssociazioni.length === 0} onClick={()=>submitAssociazione(AssociazioneBandiPoRequestDtoRequestTypeEnum.Conferma)}>Conferma</Button>
                <Button color='primary' className="mt-2 mr-2" disabled={elencoAssociazioniPerSalva.length === 0} onClick={()=>submitAssociazione(AssociazioneBandiPoRequestDtoRequestTypeEnum.Salva)}>Salva Atti Inseriti</Button>
                <Button color='secondary' outline className="mt-2 mr-2" onClick={() => navigate(HREF_ELENCO_BANDI, {state: {form: true}})}>{t('annulla')} </Button>
            </div>
        </div>
    );
}

export default ElencoAssegnazioni;