import { useEffect } from 'react'
import PageHeader from '../../components/common/page-header/PageHeader'
import { HREF_CONF_AREA_DOCUMENTI } from '../../components/layout/sidemenu/sidemenuConstants'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Button, Input } from 'design-react-kit'
import { FormikProps, useFormik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'
import { AreaDocumentiCandidatoRequestDto } from '../../services/ms-anagrafica-unica'
import { dettaglioConfigDocumento, modificaDocumento, patchDocumento } from '../../store/candidatoSlice'
import { STATUS_FULLFILLED } from '../../store/store-constants'

const initialValues: AreaDocumentiCandidatoRequestDto = {
    dataInizioValidita: '',
    dataFineValidita: '',
}


const schema = z.object({
    dataInizioValidita: z.coerce.date(),
    dataFineValidita: z.coerce.date().optional(),

});

const ModificaAreaDocumenti = () => {

    const { id } = useParams();
    const { modificaDocumentoStatus, detailsDocumentoResult, detailsDocumentoStatus } = useAppSelector((state) => state.candidato);
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(dettaglioConfigDocumento(Number.parseInt(id!)));
    }, [])

    const updateValue: FormikProps<AreaDocumentiCandidatoRequestDto> = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            doUpdate();
        },
    });

    useEffect(() => {
        updateValue.setValues({
            dataInizioValidita: detailsDocumentoResult?.dataInizioValidita || '',
            dataFineValidita: detailsDocumentoResult?.dataFineValidita || '',
        })
    }, [detailsDocumentoResult])

    const doUpdate = () => {
        const body: patchDocumento = {
            id: Number.parseInt(id!),
            bodyRequest: updateValue.values,
        }
        dispatch(modificaDocumento(body));
    }

    const getFieldError = (
        form: FormikProps<AreaDocumentiCandidatoRequestDto>,
        fieldName: keyof AreaDocumentiCandidatoRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_CONF_AREA_DOCUMENTI} title='Modifica documento' />
            {detailsDocumentoStatus === STATUS_FULLFILLED &&
                <div className="mt-5 position-relative">

                    <Input type='text'
                        disabled={true}
                        label='Nome documento'
                        placeholder=""
                        id='input-nomeDocumento'
                        name="nomeDocumento"
                        value={detailsDocumentoResult?.nomeDocumento}
                        wrapperClassName="form-group col-md-12 " />

                    <Input type='date'
                        label='Data inizio'
                        placeholder="Inserisci la data di inizio"
                        id='input-dataInizioValidita'
                        name="dataInizioValidita"
                        value={updateValue.values.dataInizioValidita}
                        onChange={updateValue.handleChange}
                        max={updateValue.values.dataFineValidita}
                        invalid={!!getFieldError(updateValue, "dataInizioValidita")}
                        infoText={t(getFieldError(updateValue, "dataInizioValidita")) || ""}
                        wrapperClassName="form-group col-md-12 required" />

                    <Input type='date'
                        label='Data fine'
                        placeholder="Inserisci la data di fine"
                        id='input-dataFineValidita'
                        name="dataFineValidita"
                        value={updateValue.values.dataFineValidita}
                        onChange={updateValue.handleChange}
                        min={updateValue.values.dataInizioValidita}
                        invalid={!!getFieldError(updateValue, "dataFineValidita")}
                        infoText={t(getFieldError(updateValue, "dataFineValidita")) || ""}
                        wrapperClassName="form-group col-md-12" />


                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2 mr-2" onClick={() => { updateValue.submitForm() }}>Salva</Button>
                        <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_CONF_AREA_DOCUMENTI}`) }} >{'Indietro'} </Button>
                    </div>
                </div>}
        </div>
    )
}

export default ModificaAreaDocumenti