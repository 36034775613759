import { t } from "i18next";
import { Fragment } from "react";
import { getClassNameOrdinamentoColonna } from "utility/utility";

interface ItemCol {
    titolo: string, 
    nomeColonna?: string,
    style?: {}
}
interface SearchObj {
    sort? :string, 
    direction? : string
}
interface Props {
    colonne: Array<ItemCol>
    datiOrdinamento: SearchObj,
    eseguiOrdinamento: Function
}

function TableTheadCustom({ colonne, datiOrdinamento, eseguiOrdinamento } : Props) {

    return (   
        <thead>
            <tr>
                {(colonne.length > 0) && colonne.map(colonna => 
                    <Fragment key={colonna.titolo}>
                        {colonna.nomeColonna! ? 
                            <th key={colonna.titolo} scope="col" onClick={() => eseguiOrdinamento(colonna.nomeColonna!)} style={colonna.style}>
                                <div className={getClassNameOrdinamentoColonna(colonna.nomeColonna!, datiOrdinamento)} 
                                        title={'Ordinamento ' + (datiOrdinamento.direction === 'ASC' ? 'Decrescente' : 'Ascendente') + ' della colonna ' + colonna.titolo }>
                                    <span>{t(colonna.titolo)}</span>
                                    <span className="icon-direction"></span>
                                </div>
                            </th> 
                            : 
                            <th key={colonna.nomeColonna} scope='col'>
                                {t(colonna.titolo)}
                            </th>           
                        }
                    </Fragment>
                )}
            </tr>
        </thead>);
}

export default TableTheadCustom;