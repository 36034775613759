import { Button } from 'design-react-kit';
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { infoDecessoDipendente, resetInfoDecesso } from '../../store/dipendentiRegionaliSlice';
import { STATUS_REJECTED, STATUS_PENDING, STATUS_FULLFILLED } from '../../store/store-constants';
import NotificationManagerComponent from '../notification/NotificationManagerComponent';
import PageLoader from '../common/page-loader/PageLoader';

export interface InfoDecessoDipendenteInterface {
    id: number
}

const InfoDecessoDipendenteComponent = (props: InfoDecessoDipendenteInterface) => {

    const dispatch = useAppDispatch();

    const infoDecessoResult = useAppSelector((state) => state.dipendentiRegionali.infoDecessoResult);
    const infoDecessoStatus = useAppSelector((state) => state.dipendentiRegionali.infoDecessoStatus);

    const [numeroAtto, setNumeroAtto] = useState<string>('');
    const [dataDecesso, setDataDecesso] = useState<string>('');
    const [nomeComune, setNomeComune] = useState<string>('');
    const [codiceIstat, setCodiceIstat] = useState<string>('');
    const [siglaPrIstat, setSiglaPrIstat] = useState<string>('');

    const getInfoDecesso = () => {
        dispatch(infoDecessoDipendente({
            idDipendente: props.id!
        }));
    }

    const resetInfoDecessoData = () => {
        dispatch(resetInfoDecesso());
        console.log(infoDecessoResult)
    }

    useEffect(() => {
        setNumeroAtto(infoDecessoResult?.numeroAttoDecesso!);
        setDataDecesso(infoDecessoResult?.dataDecesso!);
        setNomeComune(infoDecessoResult?.comune!);
        setCodiceIstat(infoDecessoResult?.codiceIstat!);
        setSiglaPrIstat(infoDecessoResult?.provinciaIstat!);
    }, [infoDecessoResult])


    return (
        <>
            {
                infoDecessoStatus == STATUS_REJECTED &&
                <NotificationManagerComponent state='error' title='Errore' content='Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio' />
            }
            {
                infoDecessoStatus == STATUS_PENDING &&
                <PageLoader loadingMessage='Caricamento...' />
            }
            {
                infoDecessoStatus == STATUS_FULLFILLED &&
                <NotificationManagerComponent state='success' title='Success' content='Dati recuperati con successo' />
            }
            <div className='mt-3'>
                <div className="card-div">
                    <div className="info-box-light bg-light-gray">
                        <h5 className='pb-2 border-bottom font-weight-bold'>
                            Dati decesso
                        </h5>
                        <div className='row'>

                            <dl className='col-sm-4'>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <dt>Numero atto decesso</dt>
                                        <dd>{numeroAtto}</dd>

                                    </div>
                                </div>
                            </dl>
                            <dl className='col-sm-4'>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <dt>Data del decesso</dt>
                                        <dd>{dataDecesso}</dd>

                                    </div>
                                </div>
                            </dl>
                            <dl className='col-sm-4'>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <dt>Nome comune</dt>
                                        <dd>{nomeComune}</dd>

                                    </div>
                                </div>
                            </dl>
                            <dl className='col-sm-4'>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <dt>Codice istat</dt>
                                        <dd>{codiceIstat}</dd>

                                    </div>
                                </div>
                            </dl>
                            <dl className='col-sm-4'>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <dt>Codice provincia istat</dt>
                                        <dd>{siglaPrIstat}</dd>

                                    </div>
                                </div>
                            </dl>
                        </div>

                        <div className=" postion-relative d-flex flex-row-reverse pb-2">
                            <Button color='primary' className="mt-2 mr-2"
                                onClick={() => getInfoDecesso()}>Recupera Dati</Button>
                            <Button outline color='primary' className="mt-2 mr-2"
                                onClick={() => resetInfoDecessoData()}>Reset</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoDecessoDipendenteComponent