import { NuoveAssunzioniDto } from '../../services/ms-anagrafica-unica'
import { CAP, CELLULARE, CITTADINANZA, CITTA_NASCITA, CLASSE_CONCORSO, CODICE_FISCALE, COGNOME, CITTA_RESIDENZA, DATA_NASCITA, EMAIL, INDIRIZZO_RESIDENZA, NOME, NOTE_CANDIDATO, NUM_CARTA_IDENTITA, PEC, PROVINCIA, SESSO, STATO_CIVILE, TELEFONO, TITOLO_STUDIO, CODICE_CID } from '../../pages/gestione-candidati/candidatiConstants'
import { formatDate } from 'utility/formatDate'

export interface CandidatoDetailsForm {
    value: NuoveAssunzioniDto,
    fileds: Array<string>
}

const CandidatoDetailsComponent = (candidato: CandidatoDetailsForm) => {

    const showFieldAu = (field : string, nome: string) => {
        let obj = Object.getOwnPropertyDescriptor(candidato.value.anagraficaUnica, nome)
        return candidato.fileds.indexOf(field) !== -1 && !!obj?.value ? obj?.value : ' - '
    }
    const showField = (field : string, nome: string) => {
        let obj = Object.getOwnPropertyDescriptor(candidato.value, nome)
        return candidato.fileds.indexOf(field) !== -1 && !!obj?.value ? obj?.value : ' - '
    }

    return (
        <div className="col-sm-12">
            {candidato.value?.anagraficaUnica &&
                <div className="card-div row">
                    <div className="col-sm-12">
                        <div className="info-box-light-candidato bg-light-gray">
                            <h5 className='pb-2 border-bottom font-weight-bold'>
                                Riepilogo
                            </h5>
                            <div className="row py-2">
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <dt>{COGNOME}</dt>
                                            <dd>{showFieldAu(COGNOME, "cognome")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{NOME}</dt>
                                            <dd>{showFieldAu(NOME, "nome")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{SESSO}</dt>
                                            <dd>{showFieldAu(SESSO, "genere")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{DATA_NASCITA}</dt>
                                            <dd>{formatDate(candidato.value.anagraficaUnica.dataNascita)}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{CITTA_NASCITA}</dt>
                                            <dd>{showFieldAu(CITTA_NASCITA, "cittaNascita")}</dd>
                                        </div>
                                            <div className="col-sm-4">
                                            <dt>{CODICE_FISCALE}</dt>
                                            <dd>{showFieldAu(CODICE_FISCALE, "codiceFiscale")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{CITTADINANZA}</dt>
                                            <dd>{showFieldAu(CITTADINANZA, "cittadinanza")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{CITTA_RESIDENZA}</dt>
                                            <dd>{showFieldAu(CITTA_RESIDENZA, "cittaResidenza")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{PROVINCIA}</dt>
                                            <dd>{showFieldAu(PROVINCIA, "provinciaResidenza")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{CAP}</dt>
                                            <dd>{showFieldAu(CAP, "capResidenza")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{INDIRIZZO_RESIDENZA}</dt>
                                            <dd>{showFieldAu(INDIRIZZO_RESIDENZA, "indirizzoResidenza")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{STATO_CIVILE}</dt>
                                            <dd>{showFieldAu(STATO_CIVILE, "statoCivile")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{NUM_CARTA_IDENTITA}</dt>
                                            <dd>{showFieldAu(NUM_CARTA_IDENTITA, "numeroCartaIdentita")}</dd>
                                        </div>

                                        <div className="col-sm-4">
                                            <dt>{CELLULARE}</dt>
                                            <dd>{showField(CELLULARE, "cellulare")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{TELEFONO}</dt>
                                            <dd>{showField(CELLULARE, "telefono")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{EMAIL}</dt>
                                            <dd>{showField(CELLULARE, "email")}</dd>
                                        </div>
                                        <div className="col-sm-4">
                                            <dt>{PEC}</dt>
                                            <dd>{showField(CELLULARE, "pec")}</dd>
                                        </div>
                                        {(candidato.fileds.indexOf(CLASSE_CONCORSO) != -1 && !!candidato.value.codiceClasseConcorso) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt>{CLASSE_CONCORSO}</dt>
                                                    <dd>{candidato.value.codiceClasseConcorso}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(TITOLO_STUDIO) != -1 && !!candidato.value.titoloDiStudio) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt>{TITOLO_STUDIO}</dt>
                                                    <dd>{candidato.value.titoloDiStudio}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(NOTE_CANDIDATO) != -1 && !!candidato.value.note) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt>{NOTE_CANDIDATO}</dt>
                                                    <dd>{candidato.value.note}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(CODICE_CID) != -1 && !!candidato.value.idCid) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt>{CODICE_CID}</dt>
                                                    <dd>{candidato.value.idCid}</dd>
                                                </>
                                            </div>
                                        }


                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CandidatoDetailsComponent