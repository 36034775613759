import arpalLogoWhite from 'assets/images/Arpal_puglia_white.png';
import pugliafesr from 'assets/images/puglia-fesr.png'
import { Col, Container, Row } from 'design-react-kit';
import './Footer.scss'

function Footer () {
    return (
      <footer className="it-footer">
        <div className="it-footer-main">
          <Container>
            <section>
              <Row className="clearfix">
                <Col sm={12}>
                  <div className="it-brand-wrapper d-flex justify-content-between align-items-center">
                    <a href="https://www.regione.puglia.it/" title="Regione Puglia" target="_blank"  rel="noopener noreferrer" className="footer-logo">
                      <img src={arpalLogoWhite} alt="logo regione Puglia" className="img-fluid" style={{width:184, height:100}}/>
                    </a>
                    <div className="ml-5">
                      <img src={pugliafesr} alt="Logo Regione Puglia fesr" className="img-fesr" />
                    </div>
                    <div className="d-flex align-items-center iniziativa">
                      Iniziativa finanziata con risorse del POR Puglia 2014/2020 - Asse II, Azione 2.3
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
            <section className="py-4 border-white border-top">
              {/* <Row>
                <Col className="item-section" lg={4} md={4} >
                  <span className="">Ufficio RTD</span>
                  <p>
                    Bari - Lungomare N. Sauro, 31-33
                  </p>
                </Col>
                <Col className="item-section" lg={4} md={4} >
                  <span className="size-7">Assistenza e Contatti</span>
                  <p>
                    Telefono: +39 080 2016800
                  <br/>
                    Email: <a href="mailto:helpdesk.au@regione.puglia.it">helpdesk.au@regione.puglia.it</a>
                  </p>
                </Col>
                <Col className="item-section" lg={4} md={4} >
                   <span className="size-7" title="Ufficio Relazioni con il Pubblico">URP</span>
                    <p>
                      Telefono: 800 713939<br />
                      Scrivici: <a href="mailto:quiregione@regione.puglia.it" target="_blank" rel="noopener noreferrer">quiregione@regione.puglia.it</a>
                    </p>
                </Col>
              </Row> */}
            </section>
          </Container>
        </div>
        <div className="it-footer-small-prints clearfix it-footer-bottom">
          <Container>
            <div className="float-end">
              <div className="py-2">
                <a href="https://www.regione.puglia.it/" title="Regione Puglia" style={{textDecoration: 'none'}}> © Regione Puglia</a>
              </div>
            </div>
          </Container>
        </div>
      </footer>
    )
}

export default Footer;