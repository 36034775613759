import { Button } from 'design-react-kit';
import  { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import PageLoader from '../../components/common/page-loader/PageLoader';
import { HREF_CONSULENTE_ESTERNO_ELENCO } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { detailConsulenteEsterno, resetInfoDettaglio } from '../../store/consulentiEsterniSlice';
import { STATUS_PENDING, STATUS_REJECTED, STATUS_FULLFILLED } from '../../store/store-constants';
import { RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import PageHeader from '../../components/common/page-header/PageHeader';
import NotificationManagerComponent from '../../components/notification/NotificationManagerComponent';
import ConsulenteEsternoDetailsComponent from '../../components/consulente-esterno/ConsulenteEsternoDetailsComponent';

const DettaglioConsulenteEsterno = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const detailResult = useAppSelector((state) => state.consulentiEsterni.detailResult);
    const detailStatus = useAppSelector((state) => state.consulentiEsterni.detailStatus);

    useEffect(() => {
        dispatch(resetInfoDettaglio());
        getDetailsFromApi();
    }, [0])

    const getId = () => {
        return Number(id!);
    }

    const getDetailsFromApi = () => {
        let idDipendente = getId();
        dispatch(detailConsulenteEsterno(idDipendente));
    }

    useEffect(() => {

    }, [detailResult]);

    return (<>
        <div>
            <PageHeader showIcon={true} urlBack={HREF_CONSULENTE_ESTERNO_ELENCO} title='Dettaglio consulente esterno'></PageHeader>
            {
                detailStatus == STATUS_REJECTED &&
                <NotificationManagerComponent state='error' title='Errore' content='Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio' />
            }
            {
                detailStatus == STATUS_PENDING &&
                <PageLoader loadingMessage='Caricamento...' />
            }

            {detailStatus === STATUS_FULLFILLED &&
                <div>
                    <div className="row">
                        <ConsulenteEsternoDetailsComponent dipendente={detailResult!} except={[]}></ConsulenteEsternoDetailsComponent>
                    </div>
                    <div className="postion-relative py-5">
                        <div className="d-flex flex-row-reverse pb-5">
                            <Button outline color='primary' className="mt-2 mr-2"
                                onClick={() => navigate(HREF_CONSULENTE_ESTERNO_ELENCO)}>Indietro</Button>
                        </div>
                    </div>
                </div>
            }
        </div>

    </>)
}

export default DettaglioConsulenteEsterno