import { showFieldIfNotNull } from 'utility/utility';
import { DipendenteRegionaleDto } from '../../services/ms-anagrafica-unica';
import DettaglioAtto from '../dettaglio-atto-cifra/DettaglioAtto';
import { formatDate } from 'utility/formatDate';

export interface CessazioneDipendenteProps {
  detailsResult: DipendenteRegionaleDto,
  showInfoDecesso?: boolean
}

const CessazioneDipendenteDetailsComponent = (props: CessazioneDipendenteProps) => {

  return (
    <div className='col-sm-12'>
      {(props.detailsResult?.id || props.detailsResult?.nome || props.detailsResult?.cognome || props.detailsResult?.codiceFiscale || props.detailsResult?.elencoStruttureOrganizzative || props.detailsResult?.tipoContratto || props.detailsResult?.elencoIncarichiInCorso || props.detailsResult?.dataInizioRapporto || props.detailsResult?.cessazione?.dataFineRapporto || props.detailsResult?.cessazione?.collaboratore || props.detailsResult?.cessazione?.numeroAttoCessazione) &&
        <div className="card-div col-sm-12 mt-2">
            <div className="info-box-light bg-light-gray">
              <h5 className='pb-2 border-bottom font-weight-bold'>
                  Riepilogo
              </h5>
              <div className="row p-2">
                <div className="col-12 col-xl-3">
                    <dt>Codice CID</dt>
                    <dd>{showFieldIfNotNull(props.detailsResult?.id)}</dd>
                </div>
                <dl className='col-12 col-xl-3'>
                    <dt>Nome</dt>
                    <dd>{showFieldIfNotNull(props.detailsResult?.nome)}</dd>
                </dl>
                <dl className='col-12 col-xl-3'>
                    <dt>Cognome</dt>
                    <dd>{showFieldIfNotNull(props.detailsResult?.cognome)}</dd>
                </dl>
                <dl className='col-12 col-xl-3'>
                    <dt>Codice fiscale</dt>
                    <dd>{showFieldIfNotNull(props.detailsResult?.codiceFiscale)}</dd>
                </dl>

                {props.detailsResult?.numeroSO! > 0 &&
                    <dl className='col-sm-12'>
                        <dt>Strutture organizzative di appartenenza</dt>
                        <dd>{props.detailsResult?.numeroSO === 1 ? `${props.detailsResult?.elencoStruttureOrganizzative![0].descrizione}` : <ul>{props.detailsResult?.elencoStruttureOrganizzative!.map((data) => <li key={data.descrizione}>{data.descrizione}</li>)} </ul>}</dd>
                    </dl>
                }

                {props.detailsResult?.tipoContratto &&
                    <dl className='col-sm-12'>
                        <dt>Tipologia contrattuale</dt>
                        <dd>{props.detailsResult?.tipoContratto}</dd>
                    </dl>
                }
                {props.detailsResult?.elencoIncarichiInCorso && props.detailsResult?.elencoIncarichiInCorso.length > 0 &&
                    <dl className='col-sm-12'>
                        <dt>Incarichi in corso</dt>
                        <dd>{props.detailsResult?.numeroIncarichiInCorso === 1 ? 
                            `${props.detailsResult?.elencoIncarichiInCorso[0]}` 
                            : 
                            <ul key={props.detailsResult?.id}>{props.detailsResult?.elencoIncarichiInCorso!.map((data, i) => <li key={i}>{data}</li>)} </ul>}
                        </dd>
                    </dl>
                }

              <dl className='col-12 col-xl-6'>
                  <dt>Data inizio rapporto</dt>
                  <dd>{formatDate(props.detailsResult?.dataInizioRapporto, false, '-')}</dd>
              </dl>

              <dl className='col-12 col-xl-6'>
                <dt>Data fine rapporto</dt>
                <dd>{formatDate(props.detailsResult?.cessazione?.dataFineRapporto, false, '-')}</dd>
              </dl>

              {props.detailsResult?.cessazione?.collaboratore &&
                <dl className='col-sm-12'>
                  <dt>Collaboratore</dt>
                  <dd >{props.detailsResult?.cessazione.collaboratore ? 'SI' : 'NO'}</dd>
                </dl>
              }
              
              <dl className='col-12 col-xl-6'>
                <dt>Motivo cessazione</dt>
                <dd>{showFieldIfNotNull(props.detailsResult?.cessazione?.motivoCessazione)}</dd>
              </dl>

              <dl className='col-12 col-xl-6'>
                <dt>Numero atto cessazione</dt>
                <dd>{showFieldIfNotNull(props.detailsResult?.cessazione?.numeroAttoCessazione)}</dd>
              </dl>

              <dl className='col-sm-12 mb-1'>
                <div className="row">
                  <div className="col-sm-12">
                    {props.detailsResult?.cessazione?.datiAtto &&
                      <DettaglioAtto datiAtto={props.detailsResult?.cessazione?.datiAtto} titolo='Dati Atto Cessazione'></DettaglioAtto>
                    }
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CessazioneDipendenteDetailsComponent;