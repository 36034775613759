import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ELENCO_RUOLI } from '../../components/layout/sidemenu/sidemenuConstants';
import { deleteDipendenti, detailsRuolo, disassociaDipendente, insertRuoloDipendenti, listDipendenti, resetAssociazioneStatus } from '../../store/ruoloSlice';
import { Button, Col, Icon, Table, Toggle } from 'design-react-kit';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { AssegnaRuoloConDipendenteRequestDto, AssegnazioneRuoloDipendentiControllerApi, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import RuoloDetailsComponent from '../../components/ruolo-details/RuoloDetailsComponent';
import CustomAutocomplete, { CustomAutocompleteRef } from '../../components/common/custom-autocomplete/CustomAutocomplete';
import { MS_AU_CONFIG, STATUS_FULLFILLED } from '../../store/store-constants';
import { debounce } from 'lodash';

const initialValues = {
  idDipendente: { value: '', label: '' },
}

const AssociazioneRuoliUtenti = (props: {operations: RuoloUtenteAutenticatoDto}) => {

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { detailsResult, dipendentiRuoloListResult, dipendentiRuoloListStatus, insertDipendentiRuoloStauts } = useAppSelector((state) => state.ruolo)
  const [tableVisual, setVisualTable] = useState(false)
  const [dipendentiArray, setDipendentiArray] = useState<{ value: number; label: string }[]>([]);
  const [dipendenti, setDipendenti] = useState<AssegnaRuoloConDipendenteRequestDto>()
  const ref = useRef<CustomAutocompleteRef>(null);
  const [dipendente, setDipendente] = useState<Boolean>(true);
  const [keyState, setKeyState] = useState<any>();

  useEffect(() => {
    getList(id!)
    dispatch(resetAssociazioneStatus())
    dispatch(detailsRuolo(Number.parseInt(id!)))
  }, [])

  useEffect(() => {
    getList(id!)
  }, [insertDipendentiRuoloStauts])

  const getList = (id: string) => {
    dispatch(listDipendenti(Number.parseInt(id!)))
  }

  const schemaSave = z.object({
    elencoDipendenti: z.array(z.number()).nonempty("Inserire almeno un dipendente")
  })

  const handleDipendenteConsulente = () => {
    console.log('handleDipendenteConsulente');
    setDipendente(!dipendente)
    setKeyState(!keyState)
}

  const schema = z.object({
    idDipendente: z.object({
      value: z.number({ required_error: 'required' }),
      label: z.string({ required_error: 'required' })
    },
      { required_error: 'required', invalid_type_error: 'required', description: 'required' }
    )
  }).refine((data) => {
    if (data.idDipendente.value) {
      if(dipendentiArray.find(val => val.value == data.idDipendente.value)){
        return false
      }else{
        return true
      }
    }
    return true
  },
    {
      message: "Questo dipendente è stato già inserito",
      path: ["idDipendente"],
    })


  useEffect(() => {
    if (dipendentiRuoloListResult?.data?.length || dipendentiArray.length) {
      setVisualTable(true)
    } else {
      setVisualTable(false)
    }
  }, [dipendentiRuoloListResult, dipendentiArray])

  const handleChange = (name: string, selectedOption: any) => {
    console.log("selected options", selectedOption);
    form.setFieldValue(name, selectedOption);
  };

  const getFieldError = (
    form: any,
    fieldName: any
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      if (form.errors[fieldName] instanceof Object) {
        return form.errors[fieldName].value || "";
      }
      return form.errors[fieldName] || "";
    } else return "";
  };

  const eliminaFunzionalita = (idDipendente: number) => {
    const body: deleteDipendenti = {
      idRuolo: Number.parseInt(id!),
      idDipendente: idDipendente
    }
    dispatch(disassociaDipendente(body))
  }

  const formSave = useFormik({
    initialValues: {elencoDipendenti: []},
    validationSchema: toFormikValidationSchema(schemaSave),
    onSubmit: (values) => {
      dispatch(insertRuoloDipendenti(dipendenti!))
      formSave.handleReset(values);
      setDipendentiArray([])
    }
  })

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      console.log("Submit form", JSON.stringify(values, null, 2));
      const newDipendentiArray = [
        ...dipendentiArray,
        { value: Number.parseInt(values.idDipendente.value), label: values.idDipendente.label }
      ];
      setDipendentiArray(newDipendentiArray);
      setDipendenti({ idRuolo: Number.parseInt(id!), elencoDipendenti: newDipendentiArray.map(val => val.value) });
      formSave.setFieldValue("elencoDipendenti", newDipendentiArray.map(val => val.value) );
      form.handleReset(values);
      ref.current?.resetAutocompleteForm();
      form.setFieldValue('idDipendente', '');
      if (newDipendentiArray.length > 0) {
        setVisualTable(true)
      }

    },
  })

  const submitForm = () => {
    form.submitForm();
  }

  const autocompleteDipendenti = debounce(async (inputValue: string, callback: any) => {
    if (showDipendentiAssociabiliOperations()) {
      
      const api = new AssegnazioneRuoloDipendentiControllerApi(MS_AU_CONFIG);
      const response = await api.getAssociabili({
        idRuolo: Number.parseInt(id!),
        consulentiEsterni: !dipendente,
        chiave: inputValue
      });
      let options: { value: number | undefined; label: string | undefined }[] = [];
      if (response.data.data) {
        response.data?.data.forEach(value => {
          options.push({ value: value.id, label: value.nomeCompleto });
        })
      }
      callback(options)
    }
  }, 500)

  const rimuoviDipendentiArray = (index: number) => {

    const newDipendentiArray = dipendentiArray.filter((toDel, i) => i !== index);
    setDipendentiArray(newDipendentiArray);
    setDipendenti({ idRuolo: Number.parseInt(id!), elencoDipendenti: newDipendentiArray.map(val => val.value) });
    if (newDipendentiArray.length <= 0 && dipendentiRuoloListResult?.data?.length! <= 0) {
      setVisualTable(false)
    }
  }

  const showDipendentiAssociabiliOperations = () => props.operations?.elencoFunzionalita?.includes('ASSEGNARUOLODIPE_ELENCO_DIPEASSOCIABILI')

  const showDipendentiInsertOperations = () => props.operations?.elencoFunzionalita?.includes('ASSEGNARUOLODIPE_INSERT')

  const showDipendentiDeleteOperations = () => props.operations?.elencoFunzionalita?.includes('ASSEGNARUOLODIPE_DELETE')

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_RUOLI} title={`${detailsResult?.nome}: Gestione ruolo dipendenti`}></PageHeader>
      {dipendentiRuoloListStatus === STATUS_FULLFILLED &&
        <div>
          <div className='row'>
            <RuoloDetailsComponent detailsResult={detailsResult!} />
            <div className="col-md-12">
              <div className="mt-5 position-relative">
              {!!getFieldError(formSave, "elencoDipendenti") &&
                    <small className="invalid-feedback form-text text-muted">{t(getFieldError(formSave, "elencoDipendenti"))}</small>}
              <div className="form-row">

                <Col md={3}>
                    {dipendente ?
                    <Toggle
                    label="Dipendente"
                    disabled={false}
                    defaultChecked
                    onClick={() => handleDipendenteConsulente()}
                    /> : <Toggle
                    label="Dipendente"
                    disabled={false}
                    onClick={() => handleDipendenteConsulente()}
                    />}
                </Col>
              </div>
                <div className="row d-flex align-items-center">
                  <Col md="10">
                    <CustomAutocomplete
                      key={keyState}
                      ref={ref}
                      returnLabel={true}
                      required={true}
                      label={dipendente ? 'Dipendente' : 'Consulente esterno'}
                      placeholder={dipendente ? 'Cerca un dipendente' : 'Cerca un consulente esterno'}
                      id="select-idDipendente"
                      loadOptionsFn={autocompleteDipendenti}
                      handleOptionSelect={(e: any) => handleChange("idDipendente", e)}
                      invalid={!!getFieldError(form, "idDipendente")}
                      infoText={t(getFieldError(form, "idDipendente")) || ""}
                      disabled={!showDipendentiAssociabiliOperations()}
                    />
                  </Col>
                  <div className="col-2">
                    <Button outline color='primary' disabled={!showDipendentiInsertOperations()} onClick={() => submitForm()}> <Icon icon='it-plus-circle' aria-hidden size='sm' color="primary" />Aggiungi</Button>
                  </div>
                </div>


              </div>
              {tableVisual && <Table striped size='sm' className='col-md-11'>
                <thead>
                  <tr>
                    <th></th>
                    <th className='col-md-10' >Dipendenti/Consulenti</th>
                    <th className='col-md-2' ></th>
                  </tr>
                </thead>
                <tbody>
                  {(dipendentiRuoloListResult?.data || []).map((value, index) =>
                    <tr key={index}>
                      <td></td>
                      <td> {value.dipendenteRegionale}</td>
                      <td> <Button size='xs' color='danger' disabled={!showDipendentiDeleteOperations()} outline onClick={() => eliminaFunzionalita(value.idDipendenteRegionale!)} >  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                    </tr>
                  )}
                  {dipendentiArray?.map((value, index) =>
                    <tr key={index}>
                      <td className='notSaveElement'>*</td>
                      <td> {value.label}</td>
                      <td> <Button size='xs' color='danger' disabled={!showDipendentiDeleteOperations()} outline onClick={() => rimuoviDipendentiArray(index)} >  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                    </tr>
                  )}
                </tbody>
              </Table>}
              {dipendentiArray.length > 0 && <span>I campi contrassegnati da * non sono ancora stati salvati</span>}
            </div>
          </div>
          <div className=" position-relative py-5">
            <div className="d-flex flex-row-reverse">
              <Button color='primary' className="mt-2 mr-2" disabled={dipendentiArray.length === 0 } onClick={() => formSave.submitForm()}>Salva</Button>
              <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_RUOLI}`) }} >{t('annulla')} </Button>
            </div>

          </div>
        </div>}
    </div>
  )
}

export default AssociazioneRuoliUtenti