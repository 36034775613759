import { Accordion, AccordionBody, AccordionHeader, Badge, Button, Col, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown } from "design-react-kit"
import PageHeader from "../../components/common/page-header/PageHeader"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { z } from "zod";
import CustomAutocomplete from "../../components/common/custom-autocomplete/CustomAutocomplete";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getDecodificaCodiTipoInca, searchDipendentiRegionali } from "../../store/rubricaSlice";
import { debounce } from "lodash";
import { DipendenteRegionaleRubricaDto, RuoloUtenteAutenticatoDto, StrutturaOrganizzativaControllerApi, StrutturaOrganizzativaDto } from "../../services/ms-anagrafica-unica";
import { MS_AU_CONFIG, STATUS_FULLFILLED } from "../../store/store-constants";
import CustomAlert from "../../components/common/custom-alert/CustomAlert";
import { useNavigate } from "react-router-dom";
import { HREF_RUBRICA_INSERISCI_EMAIL_DIPENDENTE, HREF_RUBRICA_INSERISCI_TEL_CRED, HREF_RUBRICA_MODIFICA_UTENZE, HREF_RUBRICA_VISUALIZZA_UTENZE } from "../../components/layout/sidemenu/sidemenuConstants";
import Pagination from "../../components/common/pagination/Pagination";
import CustomSelect, { CustomSelectItem } from "../../components/common/custom-select/CustomSelect";
import { loadSOFormSearch } from "../../store/strutturaOrganizzativaSlice";
import TableTheadCustom from "components/common/custom-table/table-thead-custom";

const initialValues = {
    pageNum: 0,
    pageSize: 10,
    direction: 'ASC',
    sort: 'nomeCompleto',
    id: 0,
    nome: '',
    cognome: '',
    strutturaOrganizzativa: '',
    idTipoIncarico: '',
    tipoStrutturaOrganizzativa: ''

};

const schema = z.object({
    cognome: z.string().max(200, { message: 'lengthcognomecandidato' }).optional(),
    nome: z.string().max(200, { message: 'lengthnomecandidato' }).optional(),
    cid: z.number().max(16).optional(),
    strutturaOrganizzativa: z.string().optional(),
    tipoStrutturaOrganizzativa: z.string().optional()

})

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

const Rubrica = (props: { operations: RuoloUtenteAutenticatoDto }) => {
    const navigate = useNavigate();
    const [isOpenListDetails, toggleModalListDetails] = useState(false);
    const [elencoDetails, setElencoDetails] = useState<any[] | undefined>([]);
    const formSearchComponents = useAppSelector((state) => state.strutturaOrganizzativa.formSearchComponents);

    useEffect(() => {
        dispatch(getDecodificaCodiTipoInca())
        dispatch(loadSOFormSearch());
        doSearch()
    },[])

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    const [formCollapseOpen, setFormCollapseOpen] = useState<boolean>(false);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {
        searchResult,
        searchStatus,
        decodificaCodTipoResult,
        decodificaCodTipoStatus
    } = useAppSelector((state) => state.rubrica);
    
    const doSearch = () => {
        dispatch(searchDipendentiRegionali(searchForm.values));
    };


    let decodificaTipoIncarico: CustomSelectItem[] = [];
    decodificaCodTipoResult?.forEach((s) => {
        decodificaTipoIncarico.push({ label: s.label, value: s.valore });
    });

    let tipoStrutturaOrganizzativaOptions: CustomSelectItem[] = [];
    formSearchComponents?.tipo?.forEach((s) => {
        tipoStrutturaOrganizzativaOptions.push({ label: s.label, value: s.valore });
    });

    const searchStartKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            searchForm.setFieldValue("pageNum", 0);
            searchForm.handleSubmit();
        }
    }

    const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
        const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
        const response = await api.searchStrutturaOrganizzativa({
            denominazione: inputValue
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (response.data.data) {
            response.data.data.forEach(d => {
                options.push({ value: d.id, label: d.descrizione });
            });
        }
        callback(options);
    }, 500)

    const handleStrutturaOrganizzativaSelect = (value: string) => {
        searchForm.setFieldValue('strutturaOrganizzativa', value);
        searchForm.handleChange(value);
    }

    // const retryButtonCallback = () => {
    //     searchForm.setFieldValue("pageNum", 0);
    //     searchForm.handleSubmit();
    // }

    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };

    let hasResults =
    searchResult && searchResult.totalElements
        ? searchResult.totalElements > 0
        : false;

    const modalViewDetails = (list: any) => {
        if (list.length > 1) {
            toggleModalListDetails(!isOpenListDetails);
            setElencoDetails(list);
        }
    }

    const returnStruttureAssociate = (elencoStruttureOrganizzative: StrutturaOrganizzativaDto[]) => {

        if (elencoStruttureOrganizzative.length == 0) {
            return (
                'Nessuna struttura organizzativa'
            )
        } else if (elencoStruttureOrganizzative.length == 1) {
            return (
                elencoStruttureOrganizzative[0].descrizione
            )
        } else if (elencoStruttureOrganizzative.length > 1) {
            return (
                elencoStruttureOrganizzative?.length! + ' strutture organizzativa/e'
            )
        }
    }

    const handlePageChange = (pageNum: number) => {
        // setCurrentPage(pageNum);
        searchForm.setFieldValue("pageNum", pageNum - 1);
        // doSearch();
        searchForm.handleSubmit();
    };

    const showAggiungiTelefonoCredenziali = (dipendente:DipendenteRegionaleRubricaDto ) => {
        if (
            ((!dipendente.assegnazioneNumeroTelefono?.numeroTelefono || !dipendente.credenzialiDominio) && !dipendente.email)
            && props.operations?.elencoFunzionalita?.includes('RUBRICA_PATCH_DIPENDENTE'))
            {return true;}
        return false;
    }

    const showModificaUtenze = (dipendente:DipendenteRegionaleRubricaDto ) => {
        if ((!!dipendente.assegnazioneNumeroTelefono || !!dipendente.credenzialiDominio || !!dipendente.email) && props.operations?.elencoFunzionalita?.includes('RUBRICA_PATCH_DIPENDENTE'))
            {return true;}
        return false;
    }

    const showAggiungiEmail = (dipendente:DipendenteRegionaleRubricaDto ) => {
        if (!dipendente.email && props.operations?.elencoFunzionalita?.includes('RUBRICA_PATCH_DIPENDENTE'))
            {return true;}
        return false;
    }

    const showDettaglioDipendente = (dipendente:DipendenteRegionaleRubricaDto ) => {
        if ((!!dipendente.email
            || !!dipendente.assegnazioneNumeroTelefono?.numeroTelefono
            || !!dipendente.credenzialiDominio) && 
            props.operations?.elencoFunzionalita?.includes('RUBRICA_DETTAGLIO_DIPENDENTE'))
            {return true;}
        return false;
    }

    const handleChange = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        if (selectedOption !== "") {
            searchForm.setFieldValue(name, selectedOption,false);
        } else {
            searchForm.setFieldValue(name, undefined,false);
        }
    };

    const handleChangeOptions = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        searchForm.setFieldValue(name, selectedOption);
    };

    return (
    <div>
        <PageHeader
            showIcon={false}
            title={t("Rubrica")!}
            buttons={[
                {
                    buttonProperties: { outline: true },
                    title: 'Ricerca',
                    buttonColor: 'primary',
                    showButtonIcon: true,
                    icon: 'it-search',
                    iconColor: 'primary',
                    action: () =>{formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true)},
                }
            ]}
        />

        <Accordion className="form-custom form-bg filtri-ricerca" background="active">
            <AccordionHeader
                active={formCollapseOpen}
                onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
            >
                {t("filtri di ricerca")}
            </AccordionHeader>
            <AccordionBody active={formCollapseOpen}>
                <div className="form-row mt-2">
                    <Input
                        type="hidden"
                        name="pageNum"
                        value={searchForm.values.pageNum}
                        onChange={searchForm.handleChange}
                    />
                    <Input
                        type="number"
                        id="input-codice"
                        placeholder="Inserisci un codice"
                        wrapperClassName="col col-md-6"
                        name="id"
                        invalid={!!searchForm.errors.id}
                        infoText={t(searchForm.errors.id || "") || ""}
                        value={searchForm.values.id == 0 ? '' : searchForm.values.id}
                        onChange={searchForm.handleChange}
                        onKeyUp={searchStartKeyPress}
                    />

                    <Input
                        type="text"
                        id="input-nome"
                        placeholder="Inserisci un nome"
                        wrapperClassName="col col-md-6"
                        name="nome"
                        invalid={!!searchForm.errors.nome}
                        infoText={t(searchForm.errors.nome || "") || ""}
                        value={searchForm.values.nome}
                        onChange={searchForm.handleChange}
                        onKeyUp={searchStartKeyPress}
                    />

                    <Input
                        type="text"
                        id="input-cognome"
                        placeholder="Inserisci un cognome"
                        wrapperClassName="col col-md-6"
                        name="cognome"
                        invalid={!!searchForm.errors.cognome}
                        infoText={t(searchForm.errors.cognome || "") || ""}
                        value={searchForm.values.cognome}
                        onChange={searchForm.handleChange}
                        onKeyUp={searchStartKeyPress}
                    />
                    <Col>
                        <CustomSelect
                            placeholder="Seleziona un tipo incarico"
                            options={decodificaTipoIncarico}
                            name="idTipoIncarico"
                            invalid={!!searchForm.errors.idTipoIncarico}
                            infoText={t(searchForm.errors.idTipoIncarico || "") || ""}
                            value={searchForm.values.idTipoIncarico}
                            onChange={(e) => handleChange("idTipoIncarico", e)}
                        />
                    </Col>
                </div>
                <div className="form-row">
                    <Col md="6">
                        <CustomAutocomplete
                            placeholder="Seleziona una Struttura organizzativa"
                            id="select-struttura-organizzativa"
                            value={searchForm.values.strutturaOrganizzativa}
                            loadOptionsFn={autocompleteStruttureOrganizzative}
                            handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelect(e)}
                        />
                    </Col>
                    <Col md="6">
                        <CustomSelect
                            options={tipoStrutturaOrganizzativaOptions}
                            value={searchForm.values.tipoStrutturaOrganizzativa}
                            placeholder="Seleziona tipo struttura"
                            invalid={!!searchForm.errors.tipoStrutturaOrganizzativa}
                            infoText={t(searchForm.errors.tipoStrutturaOrganizzativa || "") || ""}
                            onChange={(e) => handleChangeOptions('tipoStrutturaOrganizzativa', e)}
                            name="statoDipendente"
                        />
                    </Col>
                </div>
                <div className="d-flex flex-row-reverse">
                    <Button
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                            searchForm.setFieldValue("pageNum", 0);
                            searchForm.handleSubmit();
                        }}
                    >
                        Applica
                    </Button>
                    <Button
                        outline
                        color="primary"
                        className="mt-2 mr-2"
                        onClick={searchForm.handleReset}
                    >
                        Pulisci
                    </Button>
                </div>
            </AccordionBody>
        </Accordion>
        <div className="mt-4 position-relative">
            <div>
            {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && 
                <CustomAlert color='info' message='Nessun risultato disponibile' showButton={false} />
            }
            {searchStatus === STATUS_FULLFILLED && hasResults && (<>
                <>
                    <Table striped responsive size="sm" className={searchResult.totalPages! === 1 ? 'mb-5 mt-5' : ''}>
                        <TableTheadCustom 
                            colonne={[
                                {titolo: 'Codice CID', nomeColonna: 'id', style:{ minWidth: '20%', maxWidth: '20%' }},
                                {titolo: 'Cognome e Nome', nomeColonna: 'nomeCompleto', style:{ minWidth: '20%', maxWidth: '20%' }},
                                {titolo: 'Struttura organizzativa', style:{  width: '25%' }},
                                {titolo: 'Stato'},
                                {titolo: 'Azioni'}
                            ]} 
                            datiOrdinamento={searchForm.values}
                            eseguiOrdinamento={ordinamento}/>

                        <tbody>
                        {(searchResult.data || []).map((x, i) =>
                            <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.cognome} {x.nome}</td>
                                <td>
                                    <Badge
                                        color={ x.elencoStruttureOrganizzative?.length! > 1 ? 'primary' : ''}
                                        className=" col-sm-12"
                                        style={x.elencoStruttureOrganizzative?.length! > 1 ? { cursor: 'pointer', padding: '10px', fontSize: '15px' } : { padding: '10px', fontSize: '15px' }}
                                        onClick={() => modalViewDetails(x.elencoStruttureOrganizzative)}>
                                        {`${returnStruttureAssociate(x.elencoStruttureOrganizzative!)}`}
                                    </Badge>
                                </td>

                                <td title={x.stato} >
                                    {x.stato}
                                </td>
                                <td>
                                    <UncontrolledDropdown direction="left">
                                        <DropdownToggle nav>
                                            <Icon icon="it-settings" color="primary" size="sm" />
                                        </DropdownToggle>
                                        <DropdownMenu className="no-arrow">
                                            <LinkList>
                                                {showAggiungiTelefonoCredenziali(x) && 
                                                <LinkListItem size="medium" onClick={() => { navigate(`${HREF_RUBRICA_INSERISCI_TEL_CRED}/${x.id}`) }}>
                                                    <span>Aggiungi utenze</span>
                                                </LinkListItem>}
                                                {showAggiungiEmail(x) &&
                                                <LinkListItem size="medium" onClick={() => { navigate(`${HREF_RUBRICA_INSERISCI_EMAIL_DIPENDENTE}/${x.id}`) }}>
                                                    <span>Crea email</span>
                                                </LinkListItem>}
                                                {showModificaUtenze (x) && 
                                                <LinkListItem size="medium" onClick={() => { navigate(`${HREF_RUBRICA_MODIFICA_UTENZE}/${x.id}`) }}>
                                                    <span>Modifica utenze</span>
                                                </LinkListItem>}
                                                {showDettaglioDipendente(x) && 
                                                <LinkListItem size="medium" onClick={() => { navigate(`${HREF_RUBRICA_VISUALIZZA_UTENZE}/${x.id}`) }}>
                                                    <span>Visualizza</span>
                                                </LinkListItem>}
                                            </LinkList>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <Modal
                        isOpen={isOpenListDetails}
                        toggle={() => toggleModalListDetails(!isOpenListDetails)}
                        labelledBy='listDetails'
                        centered
                    >
                        <ModalHeader toggle={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }} id='listDetails'>
                            Elenco
                        </ModalHeader>
                        <ModalBody>
                            {elencoDetails?.map((value, index) => (
                                <ul key={index}>
                                    <li>{`${value != null ? `[${value.id}]` : ''} ${value.descrizione != null ? `${value.descrizione}` : ''}`}</li>
                                </ul>
                            ))}
                        </ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }}>
                                Annulla
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
                <Pagination
                    totalCount={searchResult.totalElements || 0}
                    siblingCount={1}
                    currentPage={searchForm.values.pageNum + 1}
                    pageSize={searchResult.pageSize || 10}
                    onPageChange={(page: number) => {
                        handlePageChange(page);
                    }}
                    className="justify-content-center"
                />
            </>)}
            </div>
        </div>
    </div>
    )
}

export default Rubrica;