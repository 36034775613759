import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect } from "react";
import { detailsCandidato } from "../../store/candidatoSlice";
import PageHeader from "../../components/common/page-header/PageHeader";
import { HREF_ELENCO_CANDIDATI } from "../../components/layout/sidemenu/sidemenuConstants";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import CandidatoDetailsComponent from "../../components/candidato-detail-form/CandidatoDetailsComponent";
import { CAP, CITTADINANZA, CITTA_NASCITA, CODICE_FISCALE, COGNOME, CITTA_RESIDENZA, DATA_NASCITA, INDIRIZZO_RESIDENZA, NOME, NUM_CARTA_IDENTITA, PROVINCIA, SESSO, STATO_CIVILE } from './candidatiConstants'
import { Button } from "design-react-kit";


const VisualizzaCandidato = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { detailsStatus,detailsResult } = useAppSelector((state) => state.candidato)
    const fieldUsed: Array<string> = [NOME, COGNOME, SESSO, DATA_NASCITA, CITTA_NASCITA, CODICE_FISCALE, CITTADINANZA, CITTA_RESIDENZA, PROVINCIA, CAP, INDIRIZZO_RESIDENZA, STATO_CIVILE, NUM_CARTA_IDENTITA]
    
    useEffect(() => {
        dispatch(detailsCandidato(Number.parseInt(id!)))
    }, []);

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={t("Dettaglio Candidato")!}/>
            {detailsStatus === STATUS_FULLFILLED && <div>
                <CandidatoDetailsComponent value={detailsResult!} fileds={fieldUsed} />
            </div>}
            <div className="d-flex flex-row-reverse">
                <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_CANDIDATI}`) }} >{t('indietro')} </Button>
            </div>
        </div>

    )
}

export default VisualizzaCandidato