import React from 'react'
import {
    APPLICABILITA_CONVENZIONE,
    CONDIZIONI_DI_RINNOVO,
    CONTATTI,
    CONTATTO_AMMINISTRATIVO,
    CONTATTO_TECNICO,
    DATA_ALERT_1,
    DATA_ALERT_2,
    DATA_ALERT_3,
    DATA_DECORRENZA,
    DATA_RECESSO,
    DATA_SCADENZA,
    DATI_AMMINISTRAZIONE_REGIONALE,
    DATI_ENTE_SOTTOSCRITTORE,
    DOCUMENTI_ALLEGATI,
    EMAIL_CONTATTO,
    ENTI_SOTTOSCRITTORI,
    MODALITA_RINNOVO,
    MOTIVO_RECESSO,
    NOME_CONVENZIONE,
    NOTE,
    NOTIFICHE,
    RECESSO,
    RESPONSABILE,
    SCOPO_CONVENZIONE,
    STATO,
    STRUTTURA_REFERENTE,
    STRUTTURE_REGIONALI,
} from '../../pages/convenzioni/convenzioniConstants'
import CreatableSelect from "react-select/creatable";
import {Button, TextArea} from "design-react-kit";
import Select from "react-select";
import {ConvenzioneDto} from "../../services/ms-anagrafica-unica";
import { formatDate } from 'utility/formatDate';


export interface ConvenzioneDetailsComponent {
    value: ConvenzioneDto,
    fields: Array<string>,
}


const ConvenzioniDetailsComponent = (convenzione: ConvenzioneDetailsComponent) => {

    const entiSelect = convenzione?.value?.entiConvenzioneList?.map((ente) => ({
        label: ente.nomeEnte,
        value: ente.id
    }));
    const contattiSelect = convenzione?.value?.contattiConvenzioneList?.map((contatti) => ({
        label: contatti.email,
        value: contatti.id
    }));

    const struttureSelect = convenzione?.value?.struttureConvenzioneList?.map((struttura) => ({
        label: struttura.descrizione,
        value: struttura.codice
    }));

    const contattiHref = "mailto:" + contattiSelect?.map(x => x.label).join(";");

    return (
        <div className="position-relative pb-5 form-custom">

            <div className={"col-12 mb-2 titolo-sezione"}>
                <span>{DATI_ENTE_SOTTOSCRITTORE}</span>
            </div>

            <div className={"row p-3"}>
                <div className={"col-6"}>
                    <div className={"col-12 mb-4"}>
                        <b>{NOME_CONVENZIONE}:</b> {convenzione?.value?.nomeConvenzione}
                    </div>
                    <div className={"col-12 mb-4"}>
                        <b>{SCOPO_CONVENZIONE}:</b> {convenzione?.value?.scopoConvenzione}
                    </div>
                    <div className={"col-12 mb-4"}>
                        <b>{STATO}:</b> {convenzione?.value?.statoConvenzione?.descrizione}
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"col-12 mb-4"}>
                        <b>{DATA_DECORRENZA}:</b> {formatDate(convenzione?.value?.dataDecorrenza)}
                    </div>
                    <div className={"col-12 mb-4"}>
                        <b>{DATA_SCADENZA}:</b> {formatDate(convenzione?.value?.dataScadenza)}
                    </div>
                </div>
                <div className={"col"}>
                    <div className={"col-auto"}>
                        <b>{ENTI_SOTTOSCRITTORI}:</b>
                        <CreatableSelect
                            id="id-enti-select"
                            name="enti"
                            placeholder="Nessun ente sottoscrittore"
                            options={entiSelect}
                            defaultValue={entiSelect}
                            isMulti={true}
                            isDisabled={true}
                            className="form-group  mb-4 col required"
                        />
                    </div>
                </div>
                <div className={"col-12"}>
                    <div className={"col-auto"}>
                        <b>{NOTE}:</b>
                        <TextArea
                            placeholder="Inserisci delle note aggiuntive"
                            id="input-note"
                            disabled={true}
                            value={convenzione?.value?.note}
                            wrapperClassName="form-group col-md-12"
                            name="note"/>
                    </div>
                </div>
            </div>

            <div className={"col-12 mb-2 titolo-sezione"}>
                <span>{DATI_AMMINISTRAZIONE_REGIONALE}</span>
            </div>
            <div className={"row p-3"}>
                <div className={"col-6"}>
                    <div className={"col-12 mb-4"}>
                        <b>{CONTATTO_AMMINISTRATIVO}:</b> {convenzione?.value?.contattoAmministrativo}
                    </div>
                    <div className={"col-12 mb-4"}>
                        <b>{RESPONSABILE}:</b> {convenzione?.value?.responsabile}
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"col-12 mb-4"}>
                        <b>{CONTATTO_TECNICO}:</b> {convenzione?.value?.contattoTecnico}
                    </div>
                    <div className={"col-12 mb-4"}>
                        <b>{STRUTTURA_REFERENTE}:</b> {convenzione?.value?.strutturaReferente}
                    </div>
                </div>
            </div>

            <div className={"col-12 mb-2 titolo-sezione"}>
                <span>{APPLICABILITA_CONVENZIONE}</span>
            </div>
            <div className={"row p-3 mb-5"}>
                <div className={"col-12"}>
                    <b className='ml-3'>{STRUTTURE_REGIONALI}:</b>
                    {struttureSelect?.length === 0
                        ? <> Regione Puglia</>
                        : <Select
                            placeholder="Seleziona le strutture regionali"
                            name="strutture"
                            options={struttureSelect}
                            defaultValue={struttureSelect}
                            isMulti={true}
                            isDisabled={true}
                            className="col-12"
                        />}
                </div>
            </div>

            <div className={"col-12 mb-2 titolo-sezione"}>
                <span>{MODALITA_RINNOVO}</span>
            </div>
            <div className={"row p-3"}>
                <div className={"col-4 mb-4"}>
                    <b>{DATA_ALERT_1}:</b> {formatDate(convenzione?.value?.dataAlert1)}
                </div>
                {convenzione?.value?.dataAlert2 && <div className={"col-4 mb-4"}>
                    <b>{DATA_ALERT_2}:</b> {formatDate(convenzione?.value?.dataAlert2)}
                </div>}
                {convenzione?.value?.dataAlert3 && <div className={"col-4 mb-4"}>
                    <b>{DATA_ALERT_3}:</b> {formatDate(convenzione?.value?.dataAlert3)}
                </div>}
                <div className={"col-12 mb-4"}>
                    <b>{CONDIZIONI_DI_RINNOVO}:</b> {convenzione?.value?.condizioniRinnovo}
                </div>
                <div className={"col-12 mb-5"}>
                    <b>{EMAIL_CONTATTO}:</b> {convenzione?.value?.emailContatto}
                </div>
            </div>

            <div className={"col-12 mb-3 titolo-sezione"}>
                <span>{DOCUMENTI_ALLEGATI}</span>
            </div>

            <div className={"col mb-5 ml-4"}>
                <i><b>Nessun documento allegato</b></i>
            </div>
            {convenzione?.value?.dataRecesso &&
                <div>
                    <div className={"col-12 mb-5 titolo-sezione"}>
                        <span>{RECESSO}</span>
                    </div>
                    <div className={"row mx-1"}>
                        <div className={"col-12 mb-4"}>
                            <b>{DATA_RECESSO}:</b> {formatDate(convenzione?.value?.dataRecesso)}
                        </div>
                        <div className={"col-12 mb-5"}>
                            <b>{MOTIVO_RECESSO}:</b> {convenzione?.value?.motivoRecesso}
                        </div>
                    </div>
                </div>
            }
            <div className={"col-12 mb-2 titolo-sezione"}>
                <span>{NOTIFICHE}</span>
            </div>
            <div className={"row p-3"}>
                <div className='col-12 ml-3'>
                    <b>{CONTATTI}:</b>
                </div>
                <div className="col-10">
                    <CreatableSelect
                        placeholder="Seleziona i contatti a cui inviare eventuali notifiche"
                        name="contatti"
                        options={contattiSelect}
                        defaultValue={contattiSelect}
                        isMulti={true}
                        isDisabled={true}
                        className="form-group col"
                    />
                </div>
                <div className="col-2">
                    <Button color='primary' href={contattiHref} className="mt-2">Invia e-mail</Button>
                </div>
            </div>

        </div>
    )
}
export default ConvenzioniDetailsComponent