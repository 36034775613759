import { Button, Col, Icon, Row } from "design-react-kit";
import React, { Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import {
  cancellaDocumento,
  dettaglioDocumento
} from "../../../store/DocumentiSlice";
import {
  uploadDocumento
} from "../../../store/DocumentiSlice";
import { downloadDocument } from "../../../store/DocumentiSlice";
import { DocumentoDto } from "../../../services/ms-anagrafica-unica";
import { showNotifica } from "../../../store/loaderSlice";
import { NOTIFICA_STATO } from "../../../utility/Notifica";
import ButtonUpload from "../button-custom/ButtonUpload";
import ButtonDelete from "../button-custom/ButtonDelete";
import ButtonDownload from "../button-custom/ButtonDownload";


export interface DocumentUploadProps {
  maxSize?: number;
  types?: string[];
  documentName: string;
  className?: string;
  setDocumentId?: (id: number | undefined) => void;
  invalid?: boolean,
  infoText?: string,
  idDocumento?: number | undefined;
  documento?: DocumentoDto;
  hiddenDeleteButton?: boolean;
  hiddenTitle?: boolean;
  setDocumentIdNameFile?: (id: number | undefined, name: string | undefined) => void;
  returnDocumentName?: boolean;
  disabled?: boolean,
  isSigned?: boolean,
  resetUpload?: boolean,
  removedFile?: Function,
  emitRimozioneFile?: () => void;
  testoBtnScarica?: string,
  disabilitaDeleteFile?: boolean,
  /**
   * Mosta solo le icone per il Download ed Elimina
   */
  showOnlyIcon?: boolean
}

export type DocumentUploadRef = {
  resetUploadFile: () => void;
}

const DocumentUpload = forwardRef((props: DocumentUploadProps, ref: Ref<DocumentUploadRef>) => {
  const dispatch = useAppDispatch();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined | { size: number, type: string, name: string }>(undefined);
  const [isUploaded, setUploaded] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<DocumentoDto | undefined>(
    undefined
  );
  const [getDocument, setGetDocument] = useState(false)
  
  useEffect(() => {
    if (!!props.idDocumento && props.idDocumento !== -1) {
      dispatch(dettaglioDocumento(props.idDocumento!)).unwrap().then((a) => {
        setGetDocument(true)
        setUploaded(true)
        setUploadedFile({ ...a })
      })
    }
  }, [props.idDocumento])

  useEffect(() => {
    if (props.documento != null && props.documento !== undefined) {
        setUploadedFile({ ...props.documento })
        setGetDocument(true)
        setUploaded(true)
    }
  }, [props.documento])

  const resetUploadFile = useCallback(() => {
    setSelectedFile(undefined);
    setUploaded(false);
    setUploadedFile(undefined);
    setGetDocument(false)
  }, []);

  const maxSize = props.maxSize || 5000000;
  const types = props.types || [
    'application/pdf',
    'image/png',
    'image/jpeg'
  ]

  useImperativeHandle(ref, () => ({
    resetUploadFile
  }), [resetUploadFile]);


  const uploadFile = () => {
    if (selectedFile) {
      dispatch(uploadDocumento({
        file: selectedFile as File,
        signed: selectedFile.type !== 'application/pdf' ? false : props.isSigned
      }))
        .unwrap()
        .then(data => {
          if(!!data){
            setUploadedFile(data);
            setUploaded(true)
            setGetDocument(true)
            if (props.setDocumentId) {
              props.setDocumentId(data.id);
            }
            if (props.returnDocumentName && props.setDocumentIdNameFile) {
              props.setDocumentIdNameFile(data.id, props.documentName);
            }
          }
        });
    }
  };

  const handleSelectClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const fileSelected = (event.target as HTMLInputElement)?.files?.[0];
    if (fileSelected) {
      if (fileSelected.size > maxSize) {
        dispatch(showNotifica({
            titolo: 'FORMATO FILE NON CONSENTITO',
            stato: 0,
            messaggio: 'La dimensione del file deve essere inferiore a 5MB',
            tipo: NOTIFICA_STATO.error
        }))
        return false
      }
      if (!types.includes(fileSelected.type)) {
            dispatch(showNotifica({
                titolo: 'FORMATO FILE NON CONSENTITO',
                stato: 0,
                messaggio: 'Il formato del file non è del tipo ' + types.join(' oppure '),
                tipo: NOTIFICA_STATO.error
            }))
            return false
      }
      setUploaded(false);
      setSelectedFile(fileSelected);
    }
    // props.handleFile(fileUploaded);
  };

  const handleClear = () => {
    setSelectedFile(undefined);
    // @ts-ignore: Object is possibly 'null'.
    hiddenFileInput.current.value = null;
  };

  const vediFile = () => {
    if(uploadedFile && uploadedFile?.id)
     dispatch(downloadDocument({
        idDocumento: uploadedFile?.id
      })).unwrap().then(resp => {
        if (!!resp) {
          const blob = new Blob([resp], { type: uploadedFile.contentType })
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = uploadedFile.filename || '';
          link.click();
        }
      });
  }

  const handleDeleteFile = () => {
   if(props.emitRimozioneFile)
      props.emitRimozioneFile();
    else{
      if(!!!props.disabilitaDeleteFile)
        dispatch(cancellaDocumento(uploadedFile?.id!));
    }
    setSelectedFile(undefined);
    setUploaded(false);
    setUploadedFile(undefined);
    setGetDocument(false)

    if (props.setDocumentId) {
      props.setDocumentId!(-1);
    }
    if (props.returnDocumentName) {
      if (props.setDocumentIdNameFile) {
        props.setDocumentIdNameFile(-1, props.documentName);
      }
    }
    
    // @ts-ignore: Object is possibly 'null'.
    hiddenFileInput.current.value = null;
    props.removedFile && props.removedFile(true);
  };

  const getFileName = () => {
    return selectedFile?.name != null ? selectedFile?.name : uploadedFile?.filename;
  }

  return (
    <div className={'document-upload ' + props.className}>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />

      {!props.hiddenTitle && <Row className="mt-3">
        {props.documentName && <Col md="12">
          <label><span className="font-italic font-weight-semibold">{props.documentName}</span></label>
        </Col>}
      </Row>}

      {(selectedFile || getDocument) && (
        <Row>
          <Col md="8" className="mb-2 d-flex align-items-center">
            {isUploaded && <Icon icon="it-check-circle" className="mr-2" color="primary" />}
            <span className="font-italic overflow-hidden w-100" title={getFileName()}>{getFileName()}</span>
          </Col>

          {!isUploaded && (
            <Col className="d-flex">
              <ButtonUpload onCliclEvent={uploadFile}/>
              <ButtonDelete className="ml-1" onCliclEvent={handleClear} testoBtn="Rimuovi"/>
            </Col>
          )}

          {getDocument && (<>
            {uploadedFile && (
              <Col className="d-flex">
                <ButtonDownload className="mr-1" onCliclEvent={vediFile} showOnlyIcon={props.showOnlyIcon} titleBtn={'Download File "' + getFileName() + '"'} />
                {!props.hiddenDeleteButton && 
                   <ButtonDelete onCliclEvent={handleDeleteFile} showOnlyIcon={props.showOnlyIcon} titleBtn={'Elimina File "' + getFileName() + '"'}/>
                }
              </Col>
            )}
          </>
          )}
        </Row>
      )}
      {!getDocument && (
        <Row className="mb-2">
          <Col>
            <Button className="mx-1 d-flex align-items-center" outline size="xs" color="primary" onClick={handleSelectClick} disabled={props.disabled}>
              <Icon icon="it-files" size="xs" color="primary" className="mr-2"/> Seleziona file
            </Button>
            {props.resetUpload && isUploaded && (
              <ButtonDelete className="mx-1" onCliclEvent={handleDeleteFile} />
            )}
          </Col>
        </Row>
      )}
      {props.infoText && <small className={(props.invalid ? 'invalid-feedback' : '') + " form-text text-muted"}> Il campo {props.documentName} è obbligatorio</small>}
    </div>
  );
})

export default DocumentUpload;
