import { useEffect, useState } from 'react'
import CandidatoDetailsComponent from '../../components/candidato-detail-form/CandidatoDetailsComponent'
import { CAP, CELLULARE, CITTADINANZA, CITTA_NASCITA, CLASSE_CONCORSO, CODICE_FISCALE, COGNOME, CITTA_RESIDENZA, DATA_NASCITA, EMAIL, INDIRIZZO_RESIDENZA, NOME, NOTE_CANDIDATO, NUM_CARTA_IDENTITA, PEC, PROVINCIA, SESSO, STATO_CIVILE, TELEFONO, TITOLO_STUDIO } from './candidatiConstants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, TextArea } from 'design-react-kit'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { z } from 'zod'
import { HREF_ELENCO_CANDIDATI } from '../../components/layout/sidemenu/sidemenuConstants'
import PageHeader from '../../components/common/page-header/PageHeader'
import { detailsCandidato, resetRettificaStatus, rettificaCandidato } from '../../store/candidatoSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { GestioneAssunzioniControllerApiRettificaCandidatoRequest } from '../../services/ms-anagrafica-unica'
import { STATUS_FULLFILLED } from '../../store/store-constants'
import DocumentUpload from '../../components/common/document-upload/DocumentUpload'

const initialValues = {
  descrizione: ''
}

const validationSchema = z.object({
  descrizione: z.string({ required_error: 'required' }).max(5000, 'lenghtDescrizioneRettifica')
})

const VerificaDatiDocumentiCandidato = () => {

  const fieldUsed: Array<string> = [NOME, COGNOME, SESSO, DATA_NASCITA, CITTA_NASCITA, CODICE_FISCALE, CITTADINANZA, CITTA_RESIDENZA, PROVINCIA, CAP, INDIRIZZO_RESIDENZA, STATO_CIVILE, NUM_CARTA_IDENTITA, CELLULARE, TELEFONO, EMAIL, PEC, CLASSE_CONCORSO, TITOLO_STUDIO, NOTE_CANDIDATO]
  const { detailsResult, detailsStatus } = useAppSelector((state) => state.candidato);
  const { t } = useTranslation();
  const [isOpen, toggleModal] = useState(false);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetRettificaStatus())
    dispatch(detailsCandidato(Number.parseInt(id!)))
  }, [])

  const insertForm = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: (values) => {
      console.log("Submit form", JSON.stringify(values, null, 2));
      doInsert()
    },
  })

  const doInsert = () => {
    console.log(insertForm.values)
    const body: GestioneAssunzioniControllerApiRettificaCandidatoRequest = {
      id: Number.parseInt(id!),
      rettificaCandidatoDto: insertForm.values
    }
    dispatch(rettificaCandidato(body)).unwrap().then( resp => !!resp && navigate(HREF_ELENCO_CANDIDATI))
  }

  const getFieldError = (
    form: any,
    fieldName: keyof any
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || "";
    } else return "";
  };

  const confermaInvioRettifica = () => {
    insertForm.setFieldValue('requestType', 'RETTIFICA');
    insertForm.handleSubmit();
    toggleModal(!isOpen);
  }

  const confermaVerifica = () => {
    const body: GestioneAssunzioniControllerApiRettificaCandidatoRequest = {
      id: Number.parseInt(id!),
      rettificaCandidatoDto: { descrizione: insertForm.values.descrizione, requestType: 'CONFERMA' }
    }
    dispatch(rettificaCandidato(body)).unwrap().then( resp => !!resp && navigate(HREF_ELENCO_CANDIDATI))
  }

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={"Verifica dati e documenti candidato"}></PageHeader>
      {detailsStatus === STATUS_FULLFILLED && <div>
        <div className="row">
          <CandidatoDetailsComponent value={detailsResult!} fileds={fieldUsed} />

          <div className="col-md-12">
            {detailsResult?.elencoDocumenti != null && detailsResult.elencoDocumenti.length > 0 && <div>
              {detailsResult?.elencoDocumenti!.map((item, index) => <div key={index} className="p-3">
                <DocumentUpload hiddenDeleteButton={true} documentName={`${item.tipo}`} idDocumento={item.documentoDto?.id} />
              </div>)}
            </div>}
          </div>

          <div className="col-md-12">
            <div className='position-relative py-5'>
              <div className="d-flex flex-row-reverse">
                <Button color='primary' className="mt-2" onClick={() => { confermaVerifica() }}>Conferma</Button>
                <Button outline color='primary' className="mt-2 mr-2" onClick={() => { toggleModal(!isOpen) }}>Rettifica</Button>
                <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(HREF_ELENCO_CANDIDATI) }} >Indietro</Button>
              </div>
              <div>
                <Modal
                  isOpen={isOpen}
                  toggle={() => toggleModal(!isOpen)}
                  labelledBy='esempio1'
                  centered
                >
                  <ModalHeader toggle={() => { toggleModal(!isOpen) }} id='esempio1'>
                    Rettifica candidatura
                  </ModalHeader>
                  <ModalBody>
                    <TextArea
                      label="Descrizione"
                      placeholder="Inserisci la descrizione"
                      id="input-descrizione"
                      wrapperClassName="form-group required mt-5 col-md-12"
                      name="descrizione"
                      value={insertForm.values.descrizione}
                      onChange={insertForm.handleChange}
                      invalid={!!getFieldError(insertForm, "descrizione")}
                      infoText={t(getFieldError(insertForm, "descrizione")) || ""}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color='secondary' onClick={() => { toggleModal(!isOpen); insertForm.setValues(initialValues) }}>
                      Chiudi
                    </Button>
                    <Button color='primary' disabled={insertForm.values.descrizione === ""} onClick={() => { confermaInvioRettifica() }}>
                      Invia notifica
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default VerificaDatiDocumentiCandidato