import { formatDate } from 'utility/formatDate'
import { DipendenteRegionaleDto } from '../../services/ms-anagrafica-unica'
import { showFieldIfNotNull } from 'utility/utility'

const ConsulenteEsternoDetailsComponent = (props: {dipendente: DipendenteRegionaleDto, except: Array<string>}) => {

    return (
        <div className="col-sm-12">
            {props.dipendente != null &&
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light-candidato">
                        <h6 className="mb-3">
                            Riepilogo
                        </h6>
                        <hr />
                        <div className="row">
                            <dl className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Codice CID'}</dt>
                                        <dd className='col-sm-12'>{showFieldIfNotNull(props.dipendente?.id)}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Cognome'}</dt>
                                        <dd className='col-sm-12'>{showFieldIfNotNull(props.dipendente.cognome)}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Nome'}</dt>
                                        <dd className='col-sm-12'>{showFieldIfNotNull(props.dipendente.nome)}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Codice Fiscale'}</dt>
                                        <dd className='col-sm-12'>{showFieldIfNotNull(props.dipendente.codiceFiscale)}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Telefono'}</dt>
                                        <dd className='col-sm-12'>{showFieldIfNotNull(props.dipendente.telefono)}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Cellulare'}</dt>
                                        <dd className='col-sm-12'>{showFieldIfNotNull(props.dipendente.cellulare)}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Data Inizio'}</dt>
                                        <dd className='col-sm-12'>{formatDate(props.dipendente.dataInizio!, false, ' - ')}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Data Fine'}</dt>
                                        <dd className='col-sm-12'>{formatDate(props.dipendente.dataFine!, false, ' - ')}</dd>
                                    </div>
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Stato'}</dt>
                                        <dd className='col-sm-12'>{showFieldIfNotNull(props.dipendente.stato)}</dd>
                                    </div>
                                </div>
                                <div className="row">
                                    {!props.except.includes('indirizzoEmail') && !!props.dipendente.email && (
                                        <div className="col-sm-12 consul-esterno">
                                            <dt className='col-sm-12'>{'Email'}</dt>
                                            <dd className='col-sm-12'>{props.dipendente.email}</dd>
                                        </div>
                                    )}
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default ConsulenteEsternoDetailsComponent