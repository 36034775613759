import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react'
import CustomSelect, { CustomSelectItem } from '../../common/custom-select/CustomSelect';
import { Button, Callout, CalloutTitle, FormGroup, Icon, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'design-react-kit'
import { t } from 'i18next'
import { CurriculumVitaeRequestDtoSezioneEnum, ElencoAmbitoEsperienzaForm, EsperienzeProfessionaliDto, EsperienzeProfessionaliRequestDto, EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum, TipologiaContrattoForm } from '../../../services/ms-anagrafica-unica';
import { creaNuovoCv, getCvDetail, getElencoAmbitoEsperienza, getElencoTipologiaContratto, getGestioneCvOpts } from '../../../store/curriculumVitaeSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import { formatDate } from '../../../utility/formatDate';
import TextareaInfoChars from '../../common/textarea-infochars/TextareaInfoChars';
import { GestioneCvPageRef } from '../GestioneCvForm';

export interface EspProfessionaliProps {
    disabled: boolean,
    continuaAction: Function,
    scrollAction: Function,
    disabilitaContinuaBtn: (disabilita: boolean) => void
}

const EspProfessionaliTab = forwardRef((props: EspProfessionaliProps, ref: Ref<GestioneCvPageRef>) => {

    const dispatch = useAppDispatch();
    const { detailStatus, detailResult, insertStatus} = useAppSelector((state) => state.curriculumVitae);
    const { userInfoResult } = useAppSelector((state) => state.user)

    const [settoreOptions, setSettoreOptions] = useState<CustomSelectItem[]>([]);
    const [elencoEsperienzeProfessionali, setElencoEsperienzeProfessionali] = useState<EsperienzeProfessionaliRequestDto[]>([]);
    const [selectedElement, setSelectedElement] = useState<number|undefined>();
    const [saveOnClick, setSaveOnClick] = useState<boolean>(false);
    const [showFormInserimentoExpPRO, setShowFormInserimentoExpPRO] = useState(false);
    const [canAggiungiEsperienza, setCanAggiungiEsperienza] = useState(false);
    const [inModifica, setInModifica] = useState(false);
    const [ambitoEsperienza, setElencoAmbitoEsperienza] = useState<CustomSelectItem[]>([]);
    const [tipologiaContrattoList, setElencoTipologiaContratto] = useState<CustomSelectItem[]>([]);
    const [listTipologgiaContrattoFiltered, setListTipologgiaContrattoFiltered] = useState<CustomSelectItem[]>([]);
    const [isOpenExpPRO, toggleModal] = useState(false);
    const [esperienzaDaRimuovere, setEsperienzaDaRimuovere] = useState<string | number | undefined>(undefined);

    const initialValues: EsperienzeProfessionaliRequestDto = {
        da: '',
        al: '',
        inCorso: false,
        lavoroPosizioneRicoperta: '',
        principaliAttivitaResponsabilita: '',
        nomeIndirizzoLavoro: '',
        tipoAttivitaSettore: undefined,
        specificare: '',
        ambito: undefined,
        tipologiaContratto:undefined
    };
    

    const schema = z.object({
        da: z.coerce.date({ required_error: 'Il campo DA è obbligatorio' }).refine((data) => (data < new Date()), { message: 'DA non può essere maggiore della data odierna' }),
        al: z.coerce.date({ required_error: 'Il campo AL è obbligatorio' }).refine((data) => (data < new Date()), { message: 'AL non può essere maggiore della data odierna' }).optional(),
        lavoroPosizioneRicoperta: z.string().max(300),
        principaliAttivitaResponsabilita: z.string().max(300),
        nomeIndirizzoLavoro: z.string().max(300).optional(),
        tipoAttivitaSettore: z.string({ required_error: "Tipo attività o settore è un campo obbligatorio" }),
        specificare: z.string().max(300).optional(),
        ambito: z.number(),
        tipologiaContratto: z.number()
    }).superRefine((data, context) => {
        if (data.tipoAttivitaSettore == EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum.Altro && data.specificare == undefined) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Il campo è obbligatorio",
                path: ["specificare"]
            });
        }
    }).superRefine((data, context) => {
        if (data.al! < data.da) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "La data non può essere inferiore a quella inziale",
                path: ["al"]
            });
        }
    }).superRefine((data, context) => {
        if (data.ambito !== 3 && !data.nomeIndirizzoLavoro) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Il campo è obbligatorio",
                path: ["nomeIndirizzoLavoro"]
            });
        }
    });

    const formExpPRO: FormikProps<EsperienzeProfessionaliRequestDto> = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            aggiungiEsperienza(values);
        },
        enableReinitialize: true,
    });

    useImperativeHandle(ref, () => ({
        submitForm() {
            props.continuaAction()
         }
    }) , []);

    useEffect(() => {
        if (elencoEsperienzeProfessionali && elencoEsperienzeProfessionali.length > 0) {
            setShowFormInserimentoExpPRO(false)
            setCanAggiungiEsperienza(true)
            props.disabilitaContinuaBtn(false)
        } else {
            setShowFormInserimentoExpPRO(true)
        }

        if (saveOnClick === true) {
            dispatch(creaNuovoCv({
                elencoEsperienzeProfessionali: elencoEsperienzeProfessionali,
                sezione: CurriculumVitaeRequestDtoSezioneEnum.EsperienzeProfessionali
            }))
            .unwrap()
            .then((resp) => {
                resp != null && dispatch(getCvDetail(userInfoResult?.entityId!))
            })
        }
        setSaveOnClick(false);
    }, [elencoEsperienzeProfessionali])

    useEffect(() => {
        dispatch(getGestioneCvOpts()).unwrap().then((resp) => {
            if (resp) {
                const settoreOpt: Array<CustomSelectItem> = [];
                resp.tipoAttivita &&
                    resp.tipoAttivita.forEach((f: any) => {
                        settoreOpt.push({
                            value: f.valore,
                            label: f.label,
                        });
                    });

                setSettoreOptions(settoreOpt);
            }
            dispatch(getCvDetail(userInfoResult?.entityId!))
        })

        dispatch(getElencoAmbitoEsperienza()).then(res => {
            const getElencoAmbitoEsperienzaOpt: Array<CustomSelectItem> = [];
            const resp = res.payload as ElencoAmbitoEsperienzaForm;
            resp &&
                resp.elencoAmbitoEsperienza && resp.elencoAmbitoEsperienza.forEach((f: any) => {
                    getElencoAmbitoEsperienzaOpt.push({
                        value: f.id,
                        label: f.ambitoEsperienza,
                    });
                });
            resp.elencoAmbitoEsperienza && setElencoAmbitoEsperienza(getElencoAmbitoEsperienzaOpt);
        });

        dispatch(getElencoTipologiaContratto()).then(res => {
            const getElencoTipologiaContrattoOpt: Array<CustomSelectItem> = [];
            const resp = res.payload as TipologiaContrattoForm;
            resp &&
                resp.elencoTipologiaContratto && resp.elencoTipologiaContratto.forEach((f: any) => {
                    getElencoTipologiaContrattoOpt.push({
                        value: f.id,
                        label: f.descrizione,
                    });
                });
            resp.elencoTipologiaContratto && setElencoTipologiaContratto(getElencoTipologiaContrattoOpt);
        });
    }, [])

    useEffect( () => {
        !!detailResult && detailResult.elencoEsperienzeProfessionali && detailResult.elencoEsperienzeProfessionali.length > 0 && 
        fillElencoEsperienzeProfessionaliState(detailResult.elencoEsperienzeProfessionali)
    }, [detailResult])

    const fillElencoEsperienzeProfessionaliState = (elencoExp:EsperienzeProfessionaliDto[]) => {
        setElencoEsperienzeProfessionali(elencoExp.map((value, i) => {

            let valueToReturn: EsperienzeProfessionaliRequestDto;
            valueToReturn = {
                da: value.da,
                al: value.al || '',
                inCorso: !value.al,
                lavoroPosizioneRicoperta: value.lavoroPosizioneRicoperta,
                principaliAttivitaResponsabilita: value.principaliAttivitaResponsabilita,
                nomeIndirizzoLavoro: value.nomeIndirizzoLavoro,
                tipoAttivitaSettore: value.tipoAttivitaSettore,
                specificare: value.specificare,
                ambito: value.ambito,
                tipologiaContratto: value.tipologiaContratto
            }
            
            if (value.ambito && value.tipologiaContratto) {
                handleSelectChangeElencoAmbitoEsperienza('ambito', value.ambito, true)
                handleSelectChangeElencoTipologiaContratto('tipologiaContratto', value.tipologiaContratto)
            }
            return valueToReturn;
        }));
    }

    const handleSelectChange = (name: string, e: string | number) => {
        formExpPRO.setFieldValue(name, e);
    };

    const getFieldError = (form: FormikProps<EsperienzeProfessionaliRequestDto>, fieldName: keyof EsperienzeProfessionaliRequestDto): string => {
        if (formExpPRO.getFieldMeta(fieldName).touched) {
            return formExpPRO.errors[fieldName] || "";
        } else return "";
    };

    let aggiungiEsperienza = (values: EsperienzeProfessionaliRequestDto) => {
        formExpPRO.validateForm();
        if (formExpPRO.isValid) {
            if (selectedElement!== undefined) {
                let updatedData = values;
                const array = [...elencoEsperienzeProfessionali];
                array[selectedElement] = updatedData;
                setElencoEsperienzeProfessionali(array);
                setSelectedElement(undefined);
            } else {
                let dataDetail = [...elencoEsperienzeProfessionali, values]
                setElencoEsperienzeProfessionali(dataDetail);
            }
        }
        setSaveOnClick(true);
        formExpPRO.resetForm({ values: initialValues });
    }

    const removeItem = (index: number) => {
        setElencoEsperienzeProfessionali(prevState => prevState.filter( (el, i) => i !== index))
        setSaveOnClick(true);
        formExpPRO.resetForm({ values: initialValues });
    }

    const modificaEsperienza = (index: number) => {
        const array = [...elencoEsperienzeProfessionali];
        const esp = array[index];
        if (esp != null && esp != undefined) {
            setSelectedElement(index);
            formExpPRO.resetForm({ values: initialValues });
            formExpPRO.setValues(esp);
            props.disabilitaContinuaBtn(true)
            setCanAggiungiEsperienza(false)
            setShowFormInserimentoExpPRO(true)
            setInModifica(true)
            if (esp.ambito && esp.tipologiaContratto) {
                handleSelectChangeElencoAmbitoEsperienza('ambito', esp.ambito, true)
                handleSelectChangeElencoTipologiaContratto('tipologiaContratto', esp.tipologiaContratto)
            }
        }
    }

    const changeInCorso = () => {
        const inCorso = !formExpPRO.values.inCorso;
        formExpPRO.setFieldValue('inCorso', inCorso)
        inCorso && formExpPRO.setFieldValue('al', '')
    }
    
    const handleSelectChangeElencoAmbitoEsperienza = (name: string, e: string | number, forceValidation?: boolean) => {
        formExpPRO.setFieldValue(name, e, forceValidation); 

        formExpPRO.setFieldValue("tipologiaContratto", '', forceValidation); //reset tipo contratto

        setListTipologgiaContrattoFiltered((e===1 || e===2) ? tipologiaContrattoList.filter((f) => f.value === 1 || f.value === 2)
            : tipologiaContrattoList.filter((f) => f.value === 3 || f.value === 4));       

    };

    const handleSelectChangeElencoTipologiaContratto = (name: string, e: string | number, forceValidation?: boolean) => {
        formExpPRO.setFieldValue(name, e, forceValidation);
    };

    const confermaRimuoviAttivita = (stato: boolean) => {
        if(stato) {
            removeItem(Number(esperienzaDaRimuovere))
        }
        toggleModal(!isOpenExpPRO)
        setEsperienzaDaRimuovere(undefined)
    }

    return (
        <div className='esperienze-professionali col-12'>
             <div className='font-italic font-weight-500 mb-3'>
                {t("campiObbligatoriAsterisco")}
            </div>
            {detailStatus === STATUS_FULLFILLED && elencoEsperienzeProfessionali.length > 0 &&
                <>
                    <div className='d-flex align-items-center justify-content-between my-3'>
                        <div className='d-flex align-items-center'>
                            <h4>{t("Esperienze aggiunte")} </h4>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Button
                                color="primary"
                                className="mt-2"
                                outline
                                disabled={!canAggiungiEsperienza}
                                onClick={() => {
                                    setShowFormInserimentoExpPRO(true)
                                    setCanAggiungiEsperienza(false)
                                    props.disabilitaContinuaBtn(true)
                                    formExpPRO.resetForm({ values: initialValues });
                                }}
                            >
                                {t('Aggiungi esperienza')}
                            </Button>
                        </div>
                    </div>

                    <Table responsive striped>
                        <tbody>
                            {(insertStatus === STATUS_FULLFILLED) && elencoEsperienzeProfessionali.map((data, index) =>
                                <tr key={data.da + '_' + data.ambito}>
                                    <td>#{index + 1} {data.lavoroPosizioneRicoperta} dal {formatDate(data.da!, true, '-')} {!!data.al ? `al ${formatDate(data.al, true, '-')}` : ''}
                                        {data.inCorso ? <span className='font-bold'>(IN CORSO)</span>: ''}
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-end mr-3'>
                                            <div className='btn-table' 
                                                onClick={() => {if(!showFormInserimentoExpPRO) {toggleModal(true); setEsperienzaDaRimuovere(index)}}}
                                                title='Elimina voce'>
                                                <Icon color="primary" icon="it-delete" className={(showFormInserimentoExpPRO ? 'disabled' : '')}/>
                                            </div>

                                            <div className='btn-table ml-2' 
                                                onClick={() => {if(!showFormInserimentoExpPRO) modificaEsperienza(index)}} title='Modifica voce' >
                                                <Icon icon='it-pencil' color='primary' className={(showFormInserimentoExpPRO ? 'disabled' : '')}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>
            }

            {showFormInserimentoExpPRO && insertStatus === STATUS_FULLFILLED &&
            <Callout className='au-callout col-md-12 mt-5'>
                <CalloutTitle>{inModifica ? 'Modifica esperienza #' + (selectedElement! + 1) : 'Esperienza lavorativa'}</CalloutTitle>
                <div className='form-custom form-inserimento'>
                    <div className="form-row">
                        <CustomSelect label="Tipologia esperienza"
                            name="ambito"
                            placeholder="Indica l'ambito dell'esperienza lavorativa"
                            wrapperClass="col-md-12 required"
                            options={ambitoEsperienza}
                            value={formExpPRO.values.ambito}
                            onChange={(e) => handleSelectChangeElencoAmbitoEsperienza('ambito', e, true)}
                            invalid={!!getFieldError(formExpPRO, "ambito")}
                            infoText={t(getFieldError(formExpPRO, "ambito")) || ""}
                        />
                        <CustomSelect label="Tipologia contratto"
                            name="tipologiaContratto"
                            placeholder="Tipologia contratto"
                            wrapperClass="col-md-12 required"
                            options={(listTipologgiaContrattoFiltered)}
                            value={formExpPRO.values.tipologiaContratto}
                            onChange={(e) => handleSelectChangeElencoTipologiaContratto('tipologiaContratto', e,true)}
                            disabled={!formExpPRO.values.ambito}
                            invalid={!!getFieldError(formExpPRO, "tipologiaContratto")}
                            infoText={t(getFieldError(formExpPRO, "tipologiaContratto")) || ""}
                        />
                    </div> 

                    <div className="form-row mt-3">
                        <Input
                            label="Da"
                            type="date"
                            placeholder="Da"
                            id="da"
                            wrapperClassName="col-md-5 required"
                            max={new Date().toISOString().slice(0, 10)}
                            name="da"
                            value={formExpPRO.values.da}
                            disabled={props.disabled}
                            onChange={formExpPRO.handleChange}
                            invalid={!!getFieldError(formExpPRO, "da")}
                            infoText={t(getFieldError(formExpPRO, "da")) || ""}
                        />

                        <FormGroup check className="text-center col-md-2">
                            <Input
                                type="checkbox"
                                id="inCorso"
                                name="inCorso"
                                checked={formExpPRO.values.inCorso}
                                disabled={false}
                                onChange={changeInCorso}
                            />
                            <Label for={"inCorso"} check>In corso</Label>
                        </FormGroup>

                        <Input
                            label="A"
                            type="date"
                            placeholder="A"
                            id="al"
                            wrapperClassName={"col-md-5"}
                            min={formExpPRO.values.da == '' ? '' : formExpPRO.values.da}
                            max={new Date().toISOString().slice(0, 10)}
                            name="al"
                            value={formExpPRO.values.al}
                            disabled={formExpPRO.values.inCorso}
                            onChange={formExpPRO.handleChange}
                            invalid={!!getFieldError(formExpPRO, "al")}
                            infoText={t(getFieldError(formExpPRO, "al")) || ""}
                        />
                    </div>
                    <div className="form-row">
                        <TextareaInfoChars
                            className=""
                            label="Lavoro o posizione ricoperta"
                            placeholder="Indica la posizione professionale o il ruolo lavorativo ricoperto"
                            id="lavoroPosizioneRicoperta"
                            wrapperClassName="required col-md-12"
                            name="lavoroPosizioneRicoperta"
                            maxLength={300}
                            value={formExpPRO.values.lavoroPosizioneRicoperta}
                            onChange={formExpPRO.handleChange}
                            invalid={!!getFieldError(formExpPRO, "lavoroPosizioneRicoperta")}
                            infoText={t(getFieldError(formExpPRO, "lavoroPosizioneRicoperta")) || t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                        />
                    </div>

                    <div className="form-row">
                        <TextareaInfoChars
                            className=""
                            label="Principali attività e responsabilità"
                            placeholder="Indica le principali attività svolte durante l’attività lavorativa"
                            id="principaliAttivitaResponsabilita"
                            wrapperClassName="required col-md-12"
                            name="principaliAttivitaResponsabilita"
                            value={formExpPRO.values.principaliAttivitaResponsabilita}
                            onChange={formExpPRO.handleChange}
                            invalid={!!getFieldError(formExpPRO, "principaliAttivitaResponsabilita")}
                            infoText={t(getFieldError(formExpPRO, "principaliAttivitaResponsabilita")) || t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                            maxLength={300}
                        />
                    </div>

                    <div className="form-row">
                        <TextareaInfoChars
                            className=""
                            label={formExpPRO.values.ambito === 3 ? "Nome e indirizzo del committente" : "Nome e indirizzo del datore di lavoro"}
                            placeholder="Indica il nome dell’azienda e la sede di lavoro dell’azienda"
                            id="nomeIndirizzoLavoro"
                            wrapperClassName={formExpPRO.values.ambito === 3 ? "col-md-12" : "required col-md-12"}
                            name="nomeIndirizzoLavoro"
                            maxLength={300}
                            value={formExpPRO.values.nomeIndirizzoLavoro}
                            onChange={formExpPRO.handleChange}
                            invalid={!!getFieldError(formExpPRO, "nomeIndirizzoLavoro")}
                            infoText={t(getFieldError(formExpPRO, "nomeIndirizzoLavoro")) || t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                        />
                    </div>

                    <div className="form-row">
                        <CustomSelect label="Tipo di attività o settore"
                            name="tipoAttivitaSettore"
                            placeholder="Seleziona settore"
                            wrapperClass="col-md-12 required"
                            options={settoreOptions}
                            onChange={(e) => handleSelectChange('tipoAttivitaSettore', e)}
                            value={formExpPRO.values.tipoAttivitaSettore}
                            invalid={!!getFieldError(formExpPRO, "tipoAttivitaSettore")}
                            infoText={t(getFieldError(formExpPRO, "tipoAttivitaSettore")) || ""}
                        />
                        <TextareaInfoChars
                            className=""
                            label="Specificare"
                            placeholder="Indica la specifica del campo tipo di attività o settore"
                            id="specificare"
                            wrapperClassName={"col-md-12 " + (formExpPRO.values.tipoAttivitaSettore == EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum.Altro && 'required')}
                            name="specificare"
                            maxLength={300}
                            value={formExpPRO.values.specificare}
                            onChange={formExpPRO.handleChange}
                            invalid={!!getFieldError(formExpPRO, "specificare")}
                            infoText={t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                        />
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-end mb-2">
                    {(elencoEsperienzeProfessionali && elencoEsperienzeProfessionali.length > 0) && <Button
                        color="secondary"
                        className="mr-3"
                        onClick={() => {
                            setSelectedElement(undefined);
                            setShowFormInserimentoExpPRO(false)
                            setCanAggiungiEsperienza(true)
                            props.disabilitaContinuaBtn(false)
                            if(inModifica) {
                                setInModifica(false)
                            }
                            props.scrollAction()
                            formExpPRO.resetForm();
                        }}
                    >
                        {t('Annulla')}
                    </Button>}
                    <Button
                        color="primary"
                        outline
                        onClick={() => {
                            props.scrollAction()
                            formExpPRO.submitForm();
                        }}
                    >
                        {t('salva esperienza')}
                    </Button>
                </div>
            </Callout>}
            <Modal
                isOpen={isOpenExpPRO}
                toggle={() => toggleModal(!isOpenExpPRO)}
                labelledBy='elimina-attivita'
                centered
            >
                <ModalHeader toggle={() => { toggleModal(!isOpenExpPRO) }} id='elimina-attivita'>
                    Conferma rimozione
                </ModalHeader>
                <ModalBody>
                    <p>Sei sicuro di voler eliminare questa esperienza professionale?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { confermaRimuoviAttivita(false) }}>
                        No
                    </Button>
                    <Button color='primary' onClick={() => { confermaRimuoviAttivita(true) }}>
                        Si
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
})

export default EspProfessionaliTab