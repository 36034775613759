import { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Accordion, AccordionBody, AccordionHeader, Button, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown } from 'design-react-kit';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { resetEditStatus, resetInfoDecesso, resetInfoDettaglio } from '../../store/dipendentiRegionaliSlice';
import { z } from 'zod';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useNavigate } from 'react-router-dom';
import { loadSOFormSearch } from '../../store/strutturaOrganizzativaSlice';
import { t } from 'i18next';
import Pagination from '../../components/common/pagination/Pagination';
import { DipendenteRegionaleDtoStatoEnum, RuoloUtenteAutenticatoDto} from '../../services/ms-anagrafica-unica';
import { HREF_CONSULENTE_ESTERNO_DETTAGLIO, HREF_CONSULENTE_ESTERNO_INSERISCI, HREF_CONSULENTE_ESTERNO_MODIFICA} from '../../components/layout/sidemenu/sidemenuConstants';
import CustomAlert from '../../components/common/custom-alert/CustomAlert';
import PageHeader from '../../components/common/page-header/PageHeader';
import { deleteConsulente, searchConsulentiEsterni } from '../../store/consulentiEsterniSlice';
import { format } from 'date-fns';
import { it } from 'date-fns/locale'
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';
import { formatDate } from 'utility/formatDate';

const schema = z.object({
    id: z.number().optional(),
    nome: z.string().max(200, { message: "lenghtnome" }).optional(),
    cognome: z.string().max(200, { message: "lenghtcognome" }).optional(),
    strutturaOrganizzativa: z.string().optional()
});

const initialValues = {
    pageNum: 0,
    pageSize: 10,
    direction: 'ASC',
    sort: 'nomeCompleto',
    id: 0,
    nome: '',
    cognome: '',
    strutturaOrganizzativa: '',
};

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

const ElencoConsulentiEsterni = (props: { operations: RuoloUtenteAutenticatoDto }) => {

    const searchFormElencoCosulentiExt = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    const {
        searchResult,
        searchStatus,
        searchInput
    } = useAppSelector((state) => state.consulentiEsterni);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [formCollapseOpen, setFormCollapseOpen] = useState(false);
    const [consulente, setConsulente] = useState<number>();
    const [isOpenListDetails, toggleModalListDetails] = useState(false);
    const [elencoDetails, setElencoDetails] = useState<any[] | undefined>([]);
    const [isOpenElimininaRichiesta, toggleModalElimininaRichiesta] = useState(false);

    useEffect(() => {
        dispatch(resetEditStatus());
        dispatch(resetInfoDettaglio());
        dispatch(resetInfoDecesso());
        dispatch(loadSOFormSearch());
        if (searchInput == undefined || (searchInput?.id == 0 && searchInput?.nome == '' && searchInput?.cognome == '' && searchInput?.strutturaOrganizzativa == '' && searchInput?.sort == '' && searchInput?.direction == '')) {
            doSearch();
        }
    }, [0])

    useEffect(() => {
        searchFormElencoCosulentiExt.setValues({
            id: searchInput?.id || 0,
            nome: searchInput?.nome || '',
            cognome: searchInput?.cognome || '',
            strutturaOrganizzativa: searchInput?.strutturaOrganizzativa || '',
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            sort: searchInput?.sort || '',
            direction: searchInput?.direction || '',
        })

        if (searchInput != undefined && (searchInput.id != 0 || searchInput.nome != '' || searchInput.cognome != '' || searchInput.strutturaOrganizzativa != '' || searchInput?.sort != '' || searchInput?.direction != '')) {
            if (searchInput?.sort == '' && searchInput?.direction == '') {
                setFormCollapseOpen(true);
            }
            dispatch(searchConsulentiEsterni(searchInput))
        }

    }, [searchInput])

    let hasResults =
        searchResult && searchResult.totalElements
            ? searchResult.totalElements > 0
            : false;

    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    const doSearch = () => {
        dispatch(searchConsulentiEsterni(searchFormElencoCosulentiExt.values));
    };

    const searchStartKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            searchFormElencoCosulentiExt.setFieldValue("pageNum", 0);
            searchFormElencoCosulentiExt.handleSubmit();
        }
    }

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchFormElencoCosulentiExt.setFieldValue("sort", columnName);
        searchFormElencoCosulentiExt.setFieldValue("direction", sorting.direction);
        searchFormElencoCosulentiExt.handleSubmit();
    };


    const handlePageChange = (pageNum: number) => {
        searchFormElencoCosulentiExt.setFieldValue("pageNum", pageNum - 1);
        searchFormElencoCosulentiExt.handleSubmit();
    };

    const showModifica = (stato:  DipendenteRegionaleDtoStatoEnum) =>
        stato === DipendenteRegionaleDtoStatoEnum.Censito
        && props.operations?.elencoFunzionalita?.includes('CONSULENTEESTERNO_PATCH')

    const showInserisci = () => {
        if (props.operations?.elencoFunzionalita?.includes('CONSULENTEESTERNO_INSERT'))
            {return true;}
        return false;
    }

    const showCancella = (stato: DipendenteRegionaleDtoStatoEnum) =>
        stato === DipendenteRegionaleDtoStatoEnum.Censito
        && props.operations?.elencoFunzionalita?.includes('CONSULENTEESTERNO_DELETE')

    const insertButton = {
        buttonProperties: { outline: true },
        title: 'Nuovo consulente esterno',
        buttonColor: 'primary',
        action: () => navigate(HREF_CONSULENTE_ESTERNO_INSERISCI),
        showButtonIcon: true,
        icon: 'it-plus',
        iconColor: 'primary'
    }

    const confermaEliminazione = (id: number) => {
        setConsulente(id)
        toggleModalElimininaRichiesta(!isOpenElimininaRichiesta)
    }

    const eliminaConsulente = () => {
        dispatch(deleteConsulente(consulente!)).unwrap().then( resp => {
            resp === 200 && doSearch();
        })
    }

    return (
        <div>
            <PageHeader showIcon={false} title='Elenco consulenti esterni'
                buttons={showInserisci()? [insertButton,] : []}
            />
            <Accordion className="form-custom form-bg filtri-ricerca" background="active">
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-2">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchFormElencoCosulentiExt.values.pageNum}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                        />
                        <Input
                            type="number"
                            id="input-codice"
                            placeholder="Inserisci un codice"
                            wrapperClassName="col col-md-6"
                            name="id"
                            invalid={!!searchFormElencoCosulentiExt.errors.id}
                            infoText={t(searchFormElencoCosulentiExt.errors.id || "") || ""}
                            value={searchFormElencoCosulentiExt.values.id == 0 ? '' : searchFormElencoCosulentiExt.values.id}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            id="input-nome"
                            placeholder="Inserisci un nome"
                            wrapperClassName="col col-md-6"
                            name="nome"
                            invalid={!!searchFormElencoCosulentiExt.errors.nome}
                            infoText={t(searchFormElencoCosulentiExt.errors.nome || "") || ""}
                            value={searchFormElencoCosulentiExt.values.nome}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            id="input-cognome"
                            placeholder="Inserisci un cognome"
                            wrapperClassName="col col-md-6"
                            name="cognome"
                            invalid={!!searchFormElencoCosulentiExt.errors.cognome}
                            infoText={t(searchFormElencoCosulentiExt.errors.cognome || "") || ""}
                            value={searchFormElencoCosulentiExt.values.cognome}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />
                        <div className="d-flex flex-row-reverse col-md-6">
                            <Button
                                color="primary"
                                className="mt-2"
                                onClick={() => {
                                    searchFormElencoCosulentiExt.setFieldValue("pageNum", 0);
                                    searchFormElencoCosulentiExt.handleSubmit();
                                }}
                            >
                                Applica
                            </Button>
                            <Button
                                outline
                                color="primary"
                                className="mt-2 mr-2"
                                onClick={searchFormElencoCosulentiExt.handleReset}
                            >
                                Pulisci
                            </Button>
                        </div>
                    </div>
                    
                </AccordionBody>
            </Accordion>
            <div className="mt-4 position-relative">
                <div>
                    {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && <CustomAlert color='info' message='Nessun risultato disponibile' showButton={false} />}
                    {searchStatus === STATUS_FULLFILLED && hasResults && (
                        <>
                        <Table striped responsive size="sm" className={searchResult.totalPages! === 1 ? 'my-5' : ''}>
                            <TableTheadCustom 
                                colonne={[
                                    {titolo: 'Codice CID', nomeColonna: 'id', style:{ minWidth: '20%', maxWidth: '20%' }},
                                    {titolo: 'Cognome e Nome', nomeColonna: 'nomeCompleto', style:{ minWidth: '20%', maxWidth: '20%' }},
                                    {titolo: 'Data inizio'},
                                    {titolo: 'Data fine'},
                                    {titolo: 'Stato'},
                                    {titolo: 'Azioni'}
                                ]} 
                                datiOrdinamento={searchFormElencoCosulentiExt.values}
                                eseguiOrdinamento={ordinamento}/>

                            <tbody>
                                {(searchResult.data || []).map((x, i, dataset) =>
                                    <Fragment key={x.id}><tr>
                                        <td>{x.id}</td>
                                        <td>{x.cognome} {x.nome}</td>
                                        <td>{formatDate(x.dataInizio!, false)}</td>
                                        <td>{formatDate(x.dataFine!, false)}</td>
                                        <td title={x.stato} >
                                            {x.stato}
                                        </td>
                                        <td>
                                            <UncontrolledDropdown direction="left">
                                                <DropdownToggle nav>
                                                    <Icon icon="it-settings" color="primary" size="sm" />
                                                </DropdownToggle>
                                                <DropdownMenu className="no-arrow">
                                                    <LinkList>
                                                        {showModifica(x.stato!) && (
                                                            <LinkListItem size="medium" onClick={() => { navigate(`${HREF_CONSULENTE_ESTERNO_MODIFICA}/${x.id}`) }}>
                                                                <span>Modifica consulente esterno</span>
                                                            </LinkListItem>
                                                        )
                                                        }
                                                        {showCancella(x.stato!) && (
                                                            <LinkListItem size="medium" onClick={() => confermaEliminazione(x.id!)}>
                                                                <span>Elimina consulente esterno</span>
                                                            </LinkListItem>
                                                        )
                                                        }
                                                        {(
                                                            <LinkListItem size="medium" onClick={() => { navigate(`${HREF_CONSULENTE_ESTERNO_DETTAGLIO}/${x.id}`) }}>
                                                                <span>Visualizza consulente esterno</span>
                                                            </LinkListItem>
                                                        )
                                                        }
                                                    </LinkList>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                    {dataset.length === 1 && <tr><td style={{height:100, border: 0}} colSpan={7}></td></tr>}
                                    </Fragment>
                                )}
                            </tbody>
                        </Table>
                        <Modal
                            isOpen={isOpenListDetails}
                            toggle={() => toggleModalListDetails(!isOpenListDetails)}
                            labelledBy='listDetails'
                            centered
                        >
                            <ModalHeader toggle={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }} id='listDetails'>
                                Elenco
                            </ModalHeader>
                            <ModalBody>
                                {elencoDetails?.map((value, index) => (
                                    <ul key={index}>
                                        <li>{`${value != null ? `[${value.id}]` : ''} ${value.descrizione != null ? `${value.descrizione}` : ''}`}</li>
                                    </ul>
                                ))}
                            </ModalBody>
                            <ModalFooter>
                                <Button color='secondary' onClick={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }}>
                                    Annulla
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <Modal
                            isOpen={isOpenElimininaRichiesta}
                            toggle={() => toggleModalElimininaRichiesta(!isOpenElimininaRichiesta)}
                            labelledBy='eliminaConsulente'
                            centered
                        >
                            <ModalHeader toggle={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); setConsulente(undefined) }} id='eliminaConsulente'>
                                Elimina Consulente
                            </ModalHeader>
                            <ModalBody>
                                {consulente && t('conferma eliminazione consulente')}
                            </ModalBody>
                            <ModalFooter>
                                <Button color='secondary' onClick={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); setConsulente(undefined) }}>
                                    Annulla
                                </Button>
                                <Button color='primary' onClick={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); eliminaConsulente() }}>
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <Pagination
                            totalCount={searchResult.totalElements || 0}
                            siblingCount={1}
                            currentPage={searchFormElencoCosulentiExt.values.pageNum + 1}
                            pageSize={searchResult.pageSize || 10}
                            onPageChange={(page: number) => {
                                handlePageChange(page);
                            }}
                            className="justify-content-center"
                        />
                    </>)}
                </div>
            </div>
        </div>
    )
}

export default ElencoConsulentiEsterni;

