import { showFieldIfNotNull } from 'utility/utility'
import { RichiestaAbilitazioneDto } from '../../services/ms-anagrafica-unica'

const AbilitazioneDetailsComponent = (props: {abilitazione: RichiestaAbilitazioneDto, except: Array<string>}) => {
    return (
        <div className="col-sm-12">
            {props.abilitazione != null &&
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light-candidato bg-light-gray">
                        <h5 className='pb-2 border-bottom font-weight-bold'>
                            Riepilogo
                        </h5>
                        <div className="row p-2 flex-auto">
                            <div className="col-12 col-lg-4">
                                <dt>{'Codice Fiscale'}</dt>
                                <dd>{showFieldIfNotNull(props.abilitazione.codiceFiscale)}</dd>
                            </div>
                            <div className="col-12 col-lg-4">
                                <dt>{'Codice CID'}</dt>
                                <dd>{showFieldIfNotNull(props.abilitazione.dipendenteRegionale?.id)}</dd>
                            </div>
                            <div className="col-12 col-lg-4">
                                <dt>{'Cognome'}</dt>
                                <dd>{showFieldIfNotNull(props.abilitazione.cognome)}</dd>
                            </div>
                            <div className="col-12 col-lg-4">
                                <dt>{'Nome'}</dt>
                                <dd>{showFieldIfNotNull(props.abilitazione.nome)}</dd>
                            </div>
                            <div className="col-12 col-lg-4">
                                <dt>{'Applicativo'}</dt>
                                <dd>{showFieldIfNotNull(props.abilitazione.applicativo)}</dd>
                            </div>
                            <div className="col-12 col-lg-4">
                                <dt>{'Stato'}</dt>
                                <dd>{showFieldIfNotNull(props.abilitazione.stato)}</dd>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default AbilitazioneDetailsComponent