import { useTranslation } from 'react-i18next'
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from '../../components/layout/sidemenu/sidemenuConstants'
import PageHeader from '../../components/common/page-header/PageHeader'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getContestoProposteIncarichi, saveStruttureOrganizzativeSelezionabili, saveDipendentiSelezionabili, reset, insertPropostaIncarico } from '../../store/proposteIncarichiSlice'
import { z } from 'zod'
import { FormsControllerApi, InsertUpdateIncarichiDirigenzialiRequestDto, InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica'
import { debounce } from 'lodash'
import { MS_AU_CONFIG } from '../../store/store-constants'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useFormik } from 'formik'
import { Col, Input, TextArea, Toggle } from 'design-react-kit'
import CustomAutocomplete from '../../components/common/custom-autocomplete/CustomAutocomplete'
import { Button } from 'reactstrap'
import DocumentUpload from '../../components/common/document-upload/DocumentUpload'
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect'
import { hideLoader, showLoader } from '../../store/loaderSlice'

const initialValuesNuovaProposta = {
    codiceIncaricoSoNuova: '',
    candidatoInternoNuova: true,
    inComandoNuova: false,
    tipoIncaricoNuova: '',
    noteNuova: '',
    nomeCognomeEsternoNuova: '',
    codiceFiscaleEsternoNuova: '',
    idDipendenteNuova: 0,
    idDocumentoNuova: 0,
};

const schemaConfermaNuovaProposta = z.object({
    codiceIncaricoSoNuova: z.string(),
    candidatoInternoNuova: z.boolean(),
    inComandoNuova: z.boolean(),
    tipoIncaricoNuova: z.string(),
    noteNuova: z.string().max(200).optional(),
    nomeCognomeEsternoNuova: z.string().max(50).optional(),
    codiceFiscaleEsternoNuova: z.string().max(16).optional(),
    idDipendenteNuova: z.number().gt(0, "Selezionare un candidato").optional(),
    idDocumentoNuova: z.number().gt(0),
});

const schemaSalvaNuovaProposta = z.object({
    codiceIncaricoSoNuova: z.string().optional(),
    candidatoInternoNuova: z.boolean().optional(),
    inComandoNuova: z.boolean().optional(),
    tipoIncaricoNuova: z.string().optional(),
    noteNuova: z.string().optional(),
    nomeCognomeEsternoNuova: z.string().max(50).optional(),
    codiceFiscaleEsternoNuova: z.string().max(16).optional(),
    idDipendenteNuova: z.number().optional(),
    idDocumentoNuova: z.number().optional()
});

function NuovaPropostaIncarico(props: { operations: RuoloUtenteAutenticatoDto }) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [tipoSalvataggio, setTipoSalvataggio] = useState('');
    const [tipoStruttura, setTipoStruttura] = useState('');
    const [codiceStruttura, setCodiceStruttura] = useState('');
    const [candidatoInterno, setCandidatoInterno] = useState<boolean>(true);
    const [inComando, setInComando] = useState<boolean>(false);
    const [tipoIncaricoSelect, setTipoIncaricoSelect] = useState<CustomSelectItem[]>([]);
    const { soSelezionabili } = useAppSelector((state) => state.proposteIncarichi);
    const [protocolloUscita, setProtocolloUscita] = useState<string | undefined>('');


    useEffect( () => {
        dispatch(reset())
        dispatch(getContestoProposteIncarichi()).unwrap().then(resp => {
            if (resp) {
                const selectItems: Array<CustomSelectItem> = [];
                resp.tipiIncaricoDirigenziale && resp.tipiIncaricoDirigenziale.forEach((f: any) => {
                    selectItems.push({
                        value: f.valore,
                        label: f.label
                    })
                })
                setTipoIncaricoSelect(selectItems)
            }
        })
    }, [])

    const validationSchemaNuovaProposta = tipoSalvataggio === "CONFERMA" ? toFormikValidationSchema(schemaConfermaNuovaProposta) : toFormikValidationSchema(schemaSalvaNuovaProposta);

    const searchFormNuovaProposta = useFormik({
        initialValues: initialValuesNuovaProposta,
        validationSchema: validationSchemaNuovaProposta,
        onSubmit: values => {
            const tipoIncarico = values.tipoIncaricoNuova.charAt(0).toUpperCase() + values.tipoIncaricoNuova.slice(1).toLowerCase()
            const formInserimento: InsertUpdateIncarichiDirigenzialiRequestDto = {
                ...values,
                idDipendente:  values.idDipendenteNuova ? values.idDipendenteNuova : undefined,
                idDocumento:  values.idDocumentoNuova ? values.idDocumentoNuova : undefined,
                tipoIncarico: InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum[tipoIncarico as keyof typeof InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum],
            }
            console.log('Invio form inserimento >>> ', formInserimento);
            dispatch(insertPropostaIncarico(formInserimento))
        },
    });

    const autocompleteStruttureOrganizzativeNuova = debounce(async (inputValue: string, callback: any) => {
        const apiNuovaProposta = new FormsControllerApi(MS_AU_CONFIG);
        const responseNuova = await apiNuovaProposta.getElencoSO({
            stato: 'CENSITA',
            descrizioneBreve: inputValue,
            tipoRichiesta: 'INSERIMENTO'
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (responseNuova.data.struttureOrganizzative) {
            dispatch(saveStruttureOrganizzativeSelezionabili(responseNuova.data))
            responseNuova.data.struttureOrganizzative.forEach(d => {
                options.push({ value: d.value, label: d.label });
            });
        }
        callback(options);
    }, 500)

    const autocompleteCandidatoInternoNuova = debounce(async (inputValue: string, callback: any) => {
        dispatch(showLoader());
        const apiNuova = new FormsControllerApi(MS_AU_CONFIG);
        const responseNuovaProposta = await apiNuova.getElencoDipendenti({
            categorie: candidatoInterno ? ['CD','SZ','SR'] : [],
            nomeCompleto: inputValue
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (responseNuovaProposta.data.dipendenti) {
            dispatch(saveDipendentiSelezionabili(responseNuovaProposta.data))
            responseNuovaProposta.data.dipendenti.forEach(d => {
                options.push({ value: String(d.value), label: d.label });
            });
            dispatch(hideLoader());
        }
        callback(options);
    }, 500)

    const handleStrutturaOrganizzativaSelectNuova = (value: string) => {
        console.log('handleStrutturaOrganizzativaSelect', value);
        searchFormNuovaProposta.setFieldValue('codiceIncaricoSo', value, false)
        const soSelezionata = soSelezionabili?.struttureOrganizzative && soSelezionabili?.struttureOrganizzative.filter( so => so.valore === value).at(0)
        const tipo = soSelezionata?.label.slice(0, soSelezionata?.label.indexOf(' - '))
        !!tipo && setTipoStruttura(tipo);
        setCodiceStruttura(value)
    }

    const impostaCandidatoInterno = () => {
        setCandidatoInterno(!candidatoInterno);
        searchFormNuovaProposta.setFieldValue('candidatoInterno', !candidatoInterno, false)
        searchFormNuovaProposta.setFieldValue('idDipendente', 0, false)
    }
    
    const impostaInComando = () => {
        const inComandoChanged = !inComando;
        setInComando(inComandoChanged);
        searchFormNuovaProposta.setFieldValue('inComando', inComandoChanged, false)
    }

    const setDocumentId = (name: string, id: number | undefined) => {
        if (id) {
            searchFormNuovaProposta.setFieldValue(name, id, false);
        } else {
            console.error('errore durante la chiamata di caricamento documento');
        }
    }

    const submitConfirm = () => {
        searchFormNuovaProposta.setFieldValue('requestType', 'CONFERMA');
        searchFormNuovaProposta.values.idDipendenteNuova === 0 && searchFormNuovaProposta.setFieldValue('idDipendente', undefined, false);
        setTimeout(() => {
            searchFormNuovaProposta.submitForm();
        }, 500);
    }


    const submitSave = () => {
        searchFormNuovaProposta.setFieldValue('requestType', 'SALVA');
        setTimeout(() => {
            searchFormNuovaProposta.submitForm();
        }, 500);
    }


    const showProtocollaOperations = () => {
        if (props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_PROTOCOLLA') && !!searchFormNuovaProposta.values.idDocumentoNuova) {
            return true
        }
        return false
    }

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI} title={t("nuova proposta incarichi")!} />
            <div className='form-custom form-bg form-inserimento border'>
                <div className="form-row">
                    <Col>
                        <CustomAutocomplete
                            required={true}
                            label="Denominazione SO"
                            placeholder="Seleziona una SO"
                            id="select-struttura-organizzativa"
                            invalid={!!searchFormNuovaProposta.errors.codiceIncaricoSoNuova}
                            infoText={t(searchFormNuovaProposta.errors.codiceIncaricoSoNuova || "") || ""}
                            loadOptionsFn={autocompleteStruttureOrganizzativeNuova}
                            handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelectNuova(e)}
                        />
                    </Col>
                </div>
                <div className="form-row">
                    <Col md="6">
                        <Input
                            type="text"
                            label="Codice struttura"
                            placeholder="Codice struttura"
                            name="codice-struttura"
                            value={codiceStruttura}
                            readOnly={true}
                            />
                    </Col>
                    <Col md="6">
                        <Input
                            type="text"
                            label="Tipo struttura"
                            placeholder="Tipo struttura"
                            name="tipo-struttura"
                            value={tipoStruttura}
                            readOnly={true}
                            />
                    </Col>
                </div>
                <div className="form-row ml-1">
                    <div className='col-12 col-xl-4'>
                        {searchFormNuovaProposta.values.candidatoInternoNuova ? 
                            <Toggle
                                label="Candidato interno"
                                disabled={false}
                                defaultChecked
                                onClick={impostaCandidatoInterno}
                            /> 
                        : 
                            <Toggle
                                label="Candidato interno"
                                disabled={false}
                                onClick={impostaCandidatoInterno}
                    />}
                    </div>
                </div>
                <div className="form-row">
                {!!candidatoInterno ? (
                    <Col>
                        <CustomAutocomplete
                            label="Candidato"
                            placeholder="Seleziona un candidato"
                            id="select-candidato"
                            invalid={!!searchFormNuovaProposta.errors.idDipendenteNuova}
                            infoText={t(searchFormNuovaProposta.errors.idDipendenteNuova || "") || ""}
                            loadOptionsFn={autocompleteCandidatoInternoNuova}
                            handleOptionSelect={(e: number) => searchFormNuovaProposta.setFieldValue('idDipendente', Number(e), false)}
                            required={true}
                        />
                    </Col>
                ) : (
                    <>
                    <Input type="text"
                        label="Candidato"
                        id="nomeCognomeEsterno"
                        placeholder="Nome e cognome del candidato"
                        wrapperClassName="col mt-5 required"
                        name="nomeCognomeEsterno"
                        invalid={!!searchFormNuovaProposta.errors.nomeCognomeEsternoNuova}
                        infoText={t(searchFormNuovaProposta.errors.nomeCognomeEsternoNuova || "") || ""}
                        value={searchFormNuovaProposta.values.nomeCognomeEsternoNuova}
                        onChange={(e) => searchFormNuovaProposta.setFieldValue('nomeCognomeEsterno', e.target.value, false)}
                    /> 
                    <Input type="text"
                        label="Codice Fiscale"
                        id="codiceFiscaleEsterno"
                        placeholder="Inserisci informazioni del dipendente"
                        wrapperClassName="col mt-5 required"
                        name="codiceFiscaleEsterno"
                        invalid={!!searchFormNuovaProposta.errors.codiceFiscaleEsternoNuova}
                        infoText={t(searchFormNuovaProposta.errors.codiceFiscaleEsternoNuova || "") || ""}
                        value={searchFormNuovaProposta.values.codiceFiscaleEsternoNuova}
                        onChange={(e) => searchFormNuovaProposta.setFieldValue('codiceFiscaleEsterno', e.target.value, false)}
                    />
                    </>
                )}
                </div>
                <div className="form-row ml-1">
                    <div className='col-12 col-xl-4'>
                        {searchFormNuovaProposta.values.inComandoNuova ? 
                            <Toggle
                                label="In comando"
                                disabled={false}
                                defaultChecked  
                                onClick={impostaInComando} 
                            /> 
                        : 
                            <Toggle
                                label="In comando"
                                disabled={false} 
                                onClick={impostaInComando} 
                    />}  
                    </div>
                </div>
                <div className="form-row mt-5">
                    <Col>
                        <CustomSelect label="Tipologia incarico"
                            placeholder="Seleziona tipologia"
                            wrapperClass="required"
                            options={tipoIncaricoSelect}
                            name="tipologia-incarico"
                            invalid={!!searchFormNuovaProposta.errors.tipoIncaricoNuova}
                            infoText={t(searchFormNuovaProposta.errors.tipoIncaricoNuova || "") || ""}
                            value={searchFormNuovaProposta.values.tipoIncaricoNuova}
                            onChange={(e) => searchFormNuovaProposta.setFieldValue('tipoIncaricoNuova', e, true)} />
                    </Col>
                </div>
                <TextArea
                    label="Note personale"
                    placeholder="Inserisci le note personali "
                    id="input-note"
                    name="note"
                    value={searchFormNuovaProposta.values.noteNuova}
                    onChange={(e) => searchFormNuovaProposta.setFieldValue('note', e.target.value, false)}
                    invalid={!!searchFormNuovaProposta.errors.noteNuova}
                    infoText={t(searchFormNuovaProposta.errors.noteNuova || "") || ""}
                />
                {!!tipoStruttura && <div className="form-row">
                    <DocumentUpload documentName={tipoStruttura === 'DIPARTIMENTO' ? t('dgr approvata') : t('proposta di incarico')}
                        className="col col-md-8 required my-3"
                        invalid={!!searchFormNuovaProposta.errors.idDocumentoNuova}
                        infoText={t(searchFormNuovaProposta.errors.idDocumentoNuova || "") || ""}
                        idDocumento={searchFormNuovaProposta.values.idDocumentoNuova}
                        setDocumentId={(id) => setDocumentId('idDocumento', id)}/>
                </div>}

                {protocolloUscita && (
                    <Input
                        type="text"
                        label="Numero procollo"
                        wrapperClassName="col mt-5"
                        value={protocolloUscita}
                        readOnly={true}
                    />
                )}

            </div>
            <div className="d-flex flex-row-reverse mt-2">
                <Button color='primary' className="mt-2 mr-2" onClick={() => { submitConfirm(); setTipoSalvataggio('CONFERMA') }}>Conferma</Button>
                <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSave(); setTipoSalvataggio('SALVA') }}>Salva</Button>
                <Button outline color='primary' disabled={!showProtocollaOperations()} className="mt-2 mr-2" onClick={() => setProtocolloUscita('XXX')}>Protocolla</Button>
                <Button color='secondary' className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`) }} >{t('annulla')} </Button>
            </div>
        </div>
    )
}

export default NuovaPropostaIncarico