import { useEffect } from 'react'
import CandidatoDetailsComponent from '../../components/candidato-detail-form/CandidatoDetailsComponent'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { CODICE_CID, CODICE_FISCALE, COGNOME, EMAIL, NOME, PEC } from './candidatiConstants';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import { Button } from 'design-react-kit';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from '../../components/layout/sidemenu/sidemenuConstants';
import { accettazioneContratto, detailsCandidato, patchAccettazioneContratto } from '../../store/candidatoSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { STATUS_FULLFILLED } from '../../store/store-constants';

const initialValues = {
    idDocumento: undefined,
}

const validateSchema = z.object({
    idDocumento: z.number().optional()
})

const GestioneAccettazioneContratto = () => {

    const { detailsResult, detailsStatus, updateStatus } = useAppSelector((state) => state.candidato);
    const fieldUsed: Array<string> = [COGNOME, NOME, CODICE_FISCALE, EMAIL, PEC, CODICE_CID];
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(detailsCandidato(Number.parseInt(id!)))
    }, [])

    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(validateSchema),
        onSubmit: (values) => {
            doInsert()
        },
    })

    const doInsert = () => {
        const body: patchAccettazioneContratto = {
            id: Number.parseInt(id!),
            idDocumento: form.values.idDocumento!
        }
        dispatch(accettazioneContratto(body))
        .unwrap()
        .then(res => {
            if(res) 
                navigate(HREF_ELENCO_CANDIDATI);
        })
    }


    const handleIdChange = (id: number | undefined) => {
        if (id && id != -1) {
            form.setFieldValue('idDocumento', id);
        } else {
            form.setFieldValue('idDocumento', undefined);
        }
    }
    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={"Gestione accettazione contratto"}></PageHeader>
            {detailsStatus == STATUS_FULLFILLED && <div>
                <div className='mt-3'>
                    <div className="row">
                        <CandidatoDetailsComponent fileds={fieldUsed} value={detailsResult!} />
                        {detailsResult?.elencoDocumenti != null && detailsResult.elencoDocumenti.length > 0 && <div>
                            {detailsResult?.elencoDocumenti!.map((item, index) => item.tipo == 'DICHIARIAZIONE_DISPONIBILITA_IMP' && <div key={index} className="p-3">
                                <DocumentUpload hiddenDeleteButton={true} documentName='Dichiarazione di disponibilità all’impiego ' idDocumento={item.documentoDto?.id} />
                            </div>)}
                        </div>}
                    </div>

                    <hr />
                    <h5>Upload file</h5>
                    <hr />
                    <div className="">
                        <DocumentUpload documentName='Contratto del candidato firmato ' setDocumentId={handleIdChange} />
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2" onClick={() => { form.handleSubmit() }}>Conferma</Button>
                        <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(HREF_ELENCO_CANDIDATI) }} >Indietro</Button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default GestioneAccettazioneContratto